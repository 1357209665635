import { IconButton, Paper, useTheme, Button } from "@mui/material";
import React from "react";
import TitleBox from "../widgets/TitleBox";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../redux/hooks";
import {
  defaultTemplateData,
  deleteTemplate,
} from "../../redux/template/templateSlice";
import ConfirmationPopover from "../utils/ConfirmationPopover";
import useRouteParam from "../../hooks/useRouteParam";
import { BlockAnimation } from "../../redux/animation/types";
import {
  createAnimationAsync,
  defaultAnimation,
  deleteAnimationAsync,
} from "../../redux/animation/animationSlice";
import MorePopover from "../utils/MorePopOver";
import { Edit, Delete } from "@mui/icons-material";
import DeleteConfirmationDialog from "../utils/DeleteConfirmationDialog";

interface Props {
  animations: BlockAnimation[];
  subTitle: string;
}

export default function Animations(props: Props) {
  const { animations, subTitle } = props;

  const projectId = useRouteParam("projectId");

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>(
    []
  );

  const navigate = useNavigate();

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleSelectedAnimationsDelete = React.useCallback(() => {
    selectedIds.forEach((id) => {
      dispatch(deleteAnimationAsync(Number(id)));
    });
  }, [
    selectedIds,
    dispatch,
  ]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Created on",
      minWidth: 100,
      flex: 1,
      renderCell(params) {
        return <>{new Date(params.value).toLocaleString()}</>;
      },
    },
    {
      field: "updatedDate",
      headerName: "updated on",
      minWidth: 100,
      flex: 1,
      renderCell(params) {
        return <>{new Date(params.value).toLocaleString()}</>;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      cellClassName: 'actions',
      headerName: 'Actions',
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            key="edit"

            icon={<Edit />}
            label="Edit"
            disabled={row.isDefault}
            className="textPrimary"
            onClick={() => navigate(`/animator/${projectId}/${id}`)}
            color="inherit"
          />,
          <DeleteConfirmationDialog onDeleteConfirm={() => dispatch(deleteAnimationAsync(Number(id)))}>
            <GridActionsCellItem
              key="delete"
              icon={<Delete />}
              label="Delete"
              disabled={row.isDefault}
              color="inherit"
            />
          </DeleteConfirmationDialog>,
        ];
      },
    }
  ];

  const handleAnimationCreate = React.useCallback(() => {
    dispatch(
      createAnimationAsync({ ...defaultAnimation, projectId: projectId })
    )
      .unwrap()
      .then((payload) => {
        navigate(`/animator/${projectId}/${payload.id}`);
      });
  }, [dispatch, defaultTemplateData, projectId]);

  return (
    <>
      <TitleBox
        avatarIcon={<ViewQuiltOutlinedIcon />}
        mainTitle="Animations"
        subTitle={subTitle}
      >
        <Button
          variant="contained"
          sx={{ marginRight: theme.spacing(1) }}
          onClick={handleAnimationCreate}
          endIcon={<AddIcon />}
        >
          ADD ANIMATION
        </Button>
        <DeleteConfirmationDialog onDeleteConfirm={handleSelectedAnimationsDelete}>
          <IconButton
            component="label"
            disabled={selectedIds.length < 1}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </DeleteConfirmationDialog>
      </TitleBox>
      <Paper sx={{ width: "100%", height: "700px", padding: theme.spacing(2) }}>
        <DataGrid
          rows={animations}
          columns={columns}
          pageSizeOptions={[10]}
          checkboxSelection
          onRowSelectionModelChange={(newSM) => {
            setSelectedIds(newSM);
          }}
          rowSelectionModel={selectedIds}
          disableRowSelectionOnClick
        />
      </Paper>
    </>
  );
}
