import { TimeStampEntry } from "../../Utility";
import { ActionSequence } from "../actionsequence/types/helpers/ActionSequence";
import { DynPropAssignment, DynProps } from "../dyndata/types";

export enum EventBehaviour {
  Default = "Default",
  Once = "Once",
  ToggleSource = "ToggleSource",
  ToggleInvariant = "ToggleInvariant",
}

export enum NonSourceTriggerBehaviour {
  DoNothing = "DoNothing",
  Untrigger = "Untrigger",
  Trigger = "Trigger",
}

export enum ModificationType {
  Add = "Add",
  Edit = "Edit",
}

export enum InputType {
  text = "text",
  textarea = "textArea",
  checkbox = "checkbox",
  color = "color",
  date = "date",
  email = "email",
  month = "month",
  number = "number",
  password = "password",
  range = "range",
  reset = "reset",
  search = "search",
  tel = "tel",
  time = "time",
  week = "week",
}

export interface BlockEvent {
  id: number;
  eventName: string;
  behaviour: EventBehaviour;
  onlyTriggerFrom: number[] | null;
  nonSourceTriggerBehaviour: NonSourceTriggerBehaviour;
  propagateSource: boolean;
  unTriggerOnClickAway: boolean;
}

export interface BlockActionAssociation {
  id: number;
  parentBlockActionId: number;
  childBlockActionId: number;
}

export interface PropAssignmentData {
  dynDataSetId: number;
  dynProp: DynProps;
}

export interface BlockDataActionSequence {
  actionSequenceId: number;
  actionSequence?: ActionSequence;
  blockDataId: number;
  scrollHeight: number;
  zeroBased: boolean;
}

export interface BlockData extends TimeStampEntry {
  id: number;
  displayName: string | null;
  locationPath: string;
  content: string;
  sortPath: number;
  blockType: BlockType;
  templateReference: BlockData | null;
  blockStyles: BlockStyle[];
  dynPropAssignments: DynPropAssignment[];
  blockEvents: BlockEvent[];
  overwriteWindowScroll: boolean;
  referenceProps: boolean;
  blockAttributes: BlockAttribute[];
  pageDataId?: number;
  templateDataId?: number;
  actionSequences: BlockDataActionSequence[];
}

export interface BlockAttribute {
  id: number;
  name: string;
  value: string;
}

export interface BlockStyleData extends TimeStampEntry {
  id: number;
  attributeKey: string;
  attributeValue: string;
}

export interface BlockStyle extends TimeStampEntry {
  id: number;
  mode: ScreenMode;
  selector: string;
  blockStyleData: BlockStyleData[];
  projectId: number;
  blockDataId?: number;
}

export type ReferenceProperties = {
  refType: ReferenceType;
  refId: number;
};

export type MoveProperties = {
  dragBlock: number;
  dropBlock: number;
  direction: CreateBlockDirection;
};

export interface BlockResponse {
  block: BlockData;
  changedBlocks: BlockData[];
}

export enum ReferenceType {
  Page,
  Template,
}

export enum ScreenMode {
  xs = "Xs",
  sm = "Sm",
  md = "Md",
  lg = "Lg",
  xl = "Xl",
  xxl = "Xxl",
}

export enum CreateBlockDirection {
  up = "Up",
  in = "In",
  down = "Down",
}

export const BlockTypes = {
  create: "create",
  move: "move",
};

export enum BlockType {
  A = "A",
  P = "P",
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
  H4 = "H4",
  H5 = "H5",
  H6 = "H6",
  DIV = "DIV",
  SPAN = "SPAN",
  UL = "UL",
  OL = "OL",
  LI = "LI",
  TABLE = "TABLE",
  TR = "TR",
  TD = "TD",
  TH = "TH",
  TBODY = "TBODY",
  THEAD = "THEAD",
  TFOOT = "TFOOT",
  IMG = "IMG",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  FORM = "FORM",
  INPUT = "INPUT",
  TEXTAREA = "TEXTAREA",
  BUTTON = "BUTTON",
  SELECT = "SELECT",
  OPTION = "OPTION",
  LOOP = "LOOP",
  GRID = "GRID",
  GRIDITEM = "GRIDITEM",
  CONTAINER = "CONTAINER",
  SECTION = "SECTION",
  NAV = "NAV",
  FOOTER = "FOOTER",
  HEADER = "HEADER",
  MAIN = "MAIN",
  ARTICLE = "ARTICLE",
  LABEL = "LABEL",
  OUTPUT = "OUTPUT",
  METER = "METER",
  PROGRESS = "PROGRESS",
}

export const VoidBlockTypes = [BlockType.IMG, BlockType.INPUT];

export const ContentBlockTypes = [
  BlockType.P,
  BlockType.H1,
  BlockType.H2,
  BlockType.H3,
  BlockType.H4,
  BlockType.H5,
  BlockType.H6,
  BlockType.LABEL,
];

export const ContentWithChildrenBlockTypes = [
  BlockType.BUTTON,
  BlockType.SPAN,
  BlockType.A,
  BlockType.LI,
];

export const CustomBlockType = [BlockType.LOOP, BlockType.CONTAINER];

// REST

export type BlockUpdate = {
  id: number;
  displayName: string | null;
  content: string;
  overwriteWindowScroll: boolean;
  referenceProps: boolean;
  blockType: BlockType;
  blockAttributes: BlockAttribute[];
};
