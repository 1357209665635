import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import yup from '../../validation/yup';
import { useAppDispatch } from '../../redux/hooks';
import { createUser } from '../../redux/users/usersSlice';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const defaultValues = {
    username: '',
    password: ''
}

interface Props {
    afterAddUser: () => void
}

const schema = yup.object().shape({
    username: yup.string().lowercase().required(),
    password: yup.string().required('Please Enter your password')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
})

export default function AddUserForm(props: Props) {

    const { afterAddUser: onAfterAddUser } = props;

    const dispatch = useAppDispatch();
    const theme = useTheme();

    const [loading, setLoading] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const { handleSubmit, register, setError, formState } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        mode: 'onTouched'
    });

    const onSubmit = React.useCallback((val: { username: string, password: string }) => {
        setLoading(true);
        dispatch(createUser(val))
            .unwrap()
            .then(() => {
                // successfully created user
                onAfterAddUser();
            })
            .catch((err) => {
                setError('username', {
                    message: err.error,
                });
            })
            .finally(() => setLoading(false))
    }, [setError, dispatch, setLoading, onAfterAddUser])

    return (

        <Box sx={{
            padding: theme.spacing(3),
            maxWidth: '300px'
        }}>

            <Stack
                component='form'
                spacing={2}
            >
                <Typography variant='subtitle1'>Create new user</Typography>
                <TextField
                    disabled={loading}
                    variant='filled'
                    id="username"
                    label="Username"
                    fullWidth
                    {...register('username')}
                    error={formState.errors.username !== undefined}
                    helperText={formState.errors.username?.message}
                />
                <TextField
                    disabled={loading}
                    variant='filled'
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(p => !p)}
                                    onMouseDown={() => setShowPassword(p => !p)}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    {...register('password')}
                    error={formState.errors.password !== undefined}
                    helperText={formState.errors.password?.message}

                />
            </Stack>
            <Box
                sx={{
                    marginTop: theme.spacing(3),
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <LoadingButton
                    variant='contained'
                    disabled={loading}
                    onClick={handleSubmit(onSubmit)}
                    loading={loading}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Box>
    );

}