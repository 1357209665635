import * as yup from "yup";
import Lazy from "yup/lib/Lazy";

import { AnyObject, Maybe, Optionals } from "yup/lib/types";

// custom validation mtehod types
declare module "yup" {
  interface StringSchema {
    unique(
      errorMessage: string,
      isUniqueFunc: (val: any) => Promise<boolean> | boolean
    ): this;
  }
  interface ArraySchema<
    T extends yup.AnySchema | Lazy<any, any>,
    C extends AnyObject = AnyObject,
    TIn extends Maybe<yup.TypeOf<T>[]> = yup.TypeOf<T>[] | undefined,
    TOut extends Maybe<yup.Asserts<T>[]> = yup.Asserts<T>[] | Optionals<TIn>
  > extends yup.BaseSchema<TIn, C, TOut> {
    uniqueByProp(errorMessage?: string, prop?: string): this;
  }
}

// custom implementations

yup.addMethod(
  yup.string,
  "unique",
  function (
    errorMessage: string,
    isUniqueFunc: (val: any) => Promise<boolean>
  ) {
    return this.test("unique", errorMessage, function (value: any) {
      const { path, createError } = this;

      return Promise.resolve(isUniqueFunc(value)).then((isUnique) => {
        if (!isUnique) {
          throw createError({ path, message: errorMessage });
        }
        return true;
      });
    });
  }
);

yup.addMethod(
  yup.array,
  "uniqueByProp",
  function (errorMessage?: string, prop?: string) {
    return this.test(
      "uniqueByProp",
      errorMessage ?? `Unique - validation failed ${prop ?? ""}`,
      (val: any[] | undefined) => {
        if (!val || !Array.isArray(val)) {
          return true;
        }

        let arr = [];
        let idx = 0;
        if (prop) {
          for (let i in val) {
            if (prop in val[i]) {
              arr[idx] = val[i][prop];
              idx++;
            }
          }
        }

        const unique = new Set(arr);
        return arr.length === unique.size;
      }
    );
  }
);

// Custom tests
export const yupObjectOf = <T extends yup.AnySchema>(schema: T) => ({
  name: "objectOf",
  exclusive: false,
  message: "Object values don't match the given schema",
  test: (value: any) => {
    return (
      value === null ||
      Object.values(value).every(() => schema.isValidSync(value))
    );
  },
});

export default yup;
