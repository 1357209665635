import { IconButton, Paper, useTheme, Button, Popover, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import TitleBox from '../widgets/TitleBox';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import MoreVert from '@mui/icons-material/MoreVert';
import { TemplateData } from '../../redux/template/types';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { useAppDispatch } from '../../redux/hooks';
import { createEmptyTemplate, createTemplate, defaultTemplateData, deleteTemplate } from '../../redux/template/templateSlice';
import ConfirmationPopover from '../utils/ConfirmationPopover';
import { useNavigate } from 'react-router';
import useRouteParam from '../../hooks/useRouteParam';
import { ReferenceType } from '../../redux/blocks/types';
import MorePopover from '../utils/MorePopOver';
import { Edit, Delete } from '@mui/icons-material';
import DeleteConfirmationDialog from '../utils/DeleteConfirmationDialog';

interface Props {
    templates: TemplateData[];
    subTitle: string;
}

export default function Template(props: Props) {

    const { templates, subTitle } = props;

    const projectId = useRouteParam('projectId');

    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);

    const navigate = useNavigate();

    const theme = useTheme();
    const dispatch = useAppDispatch();

    const handleTemplateDelete = React.useCallback((id: number) => {
        dispatch(deleteTemplate(id));
    }, [dispatch]);

    const handleTemplatesDelete = React.useCallback(() => {
        selectedIds.forEach(id => {
            dispatch(deleteTemplate(Number(id)));
        });
    }, [selectedIds, dispatch]);

    const handleTemplateEdit = React.useCallback((id: number) => {
        navigate(`/builder/${projectId}/${ReferenceType.Template}/${id}`);
    }, [navigate, projectId]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'createdDate',
            headerName: 'Created on',
            minWidth: 100,
            flex: 1,
            renderCell(params) {
                return (
                    <>
                        {new Date(params.value).toLocaleString()}
                    </>
                )
            },
        },
        {
            field: 'updatedDate',
            headerName: 'updated on',
            minWidth: 100,
            flex: 1,
            renderCell(params) {
                return (
                    <>
                        {new Date(params.value).toLocaleString()}
                    </>
                )
            },
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                return [
                    <GridActionsCellItem
                        key="edit"

                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={() => handleTemplateEdit(Number(id))}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={() => handleTemplateDelete(Number(id))}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];

    const handleTemplateCreate = React.useCallback(() => {
        dispatch(createEmptyTemplate({ ...defaultTemplateData, projectId: projectId }))
            .unwrap()
            .then(payload => {
                navigate(`/builder/${projectId}/${ReferenceType.Template}/${payload.id}`)
            });
    }, [dispatch, defaultTemplateData, projectId]);

    return (
        <>
            <TitleBox
                avatarIcon={<ViewQuiltOutlinedIcon />}
                mainTitle='Templates'
                subTitle={subTitle}
            >
                <Button variant="contained" sx={{ marginRight: theme.spacing(1) }} onClick={handleTemplateCreate} endIcon={<AddIcon />}>
                    ADD TEMPLATE
                </Button>
                <DeleteConfirmationDialog onDeleteConfirm={handleTemplatesDelete}>
                    <IconButton component="label" disabled={selectedIds.length < 1} >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </DeleteConfirmationDialog>
            </TitleBox>
            <Paper sx={{ width: '100%', height: '700px', padding: theme.spacing(2) }}>
                <DataGrid
                    rows={templates}
                    columns={columns}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    onRowSelectionModelChange={(newSM) => {
                        setSelectedIds(newSM);
                    }}
                    rowSelectionModel={selectedIds}
                    disableRowSelectionOnClick
                />
            </Paper>
        </>
    );
}