import { isEqual } from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router';

export default function useRouteParam(paramName: string) {

    const { [paramName]: idAsString } = useParams();

    const paramValue = React.useMemo(() => {
        if (!idAsString)
            return -1;
        const id = Number.parseInt(idAsString);
        if (Number.isNaN(id)) {
            return -1;
        }
        return id;
    }, [idAsString]);

    return paramValue;
};
