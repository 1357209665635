import React from 'react';
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import { FilledInput, FormControl, Grid, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import ValueWithUnitInput from '../../../../../utils/Inputs/ValueWithUnitInput';
import AdvancedColorPicker from '../../../../../utils/Inputs/AdvancedColorPicker';
import { Clear, East, FormatAlignCenter, FormatAlignJustify, FormatAlignLeft, FormatAlignRight, FormatItalic, FormatOverline, FormatStrikethrough, FormatUnderlined, TextRotationNone, West } from '@mui/icons-material';
import { getStyleById, isStyleInheritedAndChanged } from '../../../../../../Utility';
import FontFamilyInput from './FontFamilyInput';
import StyleTextField from '../../../../../utils/Inputs/StyleTextField';
import StyleSelect from '../../../../../utils/Inputs/StyleSelect';
import StyleToggleButtonGroup from '../../../../../utils/Inputs/StyleToggleButtonGroup';
import { BlockStyleAttribute } from '../BlockStyleInputs';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void, 
    selectedBlock?: BlockData,
}

export default function TypographyInputs(props: Props) {
    const { currentBlockStyle, handleAttributeChange, selectedBlock } = props;

    return (
        <>
            <FontFamilyInput {...props} />
            <BlockStyleDataLabel name="Weight" />
            <Grid item xs={10}>
                <StyleSelect {...props} attributeKey='font-weight'>
                    <MenuItem key="100" value="100">100 - Thin</MenuItem>
                    <MenuItem key="200" value="200">200 - Extra Light</MenuItem>
                    <MenuItem key="300" value="300">300 - Leight</MenuItem>
                    <MenuItem key="400" value="400">400 - Normal</MenuItem>
                    <MenuItem key="500" value="500">500 - Medium</MenuItem>
                    <MenuItem key="600" value="600">600 - Semi Bold</MenuItem>
                    <MenuItem key="700" value="700">700 - Bold</MenuItem>
                    <MenuItem key="800" value="800">800 - Extra Bold</MenuItem>
                    <MenuItem key="900" value="900">900 - Black</MenuItem>
                </StyleSelect>
            </Grid>
            <BlockStyleDataLabel name="Size" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='font-size' {...props} />
            </Grid>
            <BlockStyleDataLabel name="Height" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='line-height' {...props} />
            </Grid>
            <BlockStyleDataLabel name="Color" />
            <Grid item xs={10}>
                <AdvancedColorPicker attributeKey='color' {...props} />
            </Grid>
            <BlockStyleDataLabel name="Align" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='text-align'>
                    <ToggleButton value="left" key="left">
                        <Tooltip title="Aligns text to the left, maintaining the default reading flow">
                            <FormatAlignLeft />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="center" key="center">
                        <Tooltip title="Centers text horizontally within the element">
                            <FormatAlignCenter />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="right" key="right">
                        <Tooltip title="Aligns text to the right, often used for right-to-left reading flow">
                            <FormatAlignRight />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="justify" key="justify">
                        <Tooltip title="Stretches lines of text to fit the width of the element, creating a clean look on both sides">
                            <FormatAlignJustify />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Style" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='font-style'>
                    <ToggleButton value="normal" key="normal">
                        <Tooltip title="Displays text in a standard, upright font style">
                            <TextRotationNone />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="italic" key="italic">
                        <Tooltip title="Displays text in an italic style, typically used for emphasis or quotes">
                            <FormatItalic />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Format" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='text-decoration'>
                    <ToggleButton value="none" key="none">
                        <Tooltip title="No decoration is applied, displaying standard, unaltered text">
                            <Clear />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="underline" key="underline">
                        <Tooltip title="Adds a line below the text, often used for emphasis or hyperlinks">
                            <FormatUnderlined />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="overline" key="overline">
                        <Tooltip title="Places a line above the text">
                            <FormatOverline />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="line-through" key="line-through">
                        <Tooltip title="Adds a line through the middle of the text, typically used for indicating deletion or completion">
                            <FormatStrikethrough />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Spacing" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='letter-spacing' {...props} />
            </Grid>
            <BlockStyleDataLabel name="Indent" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='text-indent' {...props} />
            </Grid>
            <BlockStyleDataLabel name="Column" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='column-width' {...props} additionalEntries={['auto']} />
            </Grid>
            <BlockStyleDataLabel name="Count" />
            <Grid item xs={4}>
                <StyleTextField {...props} attributeKey='column-count' type='number' />
            </Grid>
            <BlockStyleDataLabel name="Direction" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='direction'>
                    <ToggleButton value="ltr" key="ltr">
                        <Tooltip title="Sets text direction from left to right, used for languages written in that orientation">
                            <East />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="rtl" key="rtl">
                        <Tooltip title="Sets text direction from right to left, used for languages like Arabic and Hebrew">
                            <West />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Wordbr." />
            <Grid item xs={4}>
                <StyleSelect {...props} attributeKey='word-break'>
                    <MenuItem key="normal" value="normal">Normal</MenuItem>
                    <MenuItem key="break-all" value="break-all">Break all</MenuItem>
                    <MenuItem key="break-word" value="break-word">Break Word</MenuItem>
                    <MenuItem key="keep-all" value="keep-all">Keep all</MenuItem>
                </StyleSelect>
            </Grid>
            <BlockStyleDataLabel name="Linebr." />
            <Grid item xs={4}>
                <StyleSelect {...props} attributeKey='line-break'>
                    <MenuItem key="normal" value="normal">Normal</MenuItem>
                    <MenuItem key="auto" value="auto">Break all</MenuItem>
                    <MenuItem key="loose" value="loose">Break Word</MenuItem>
                    <MenuItem key="strict" value="strict">Keep all</MenuItem>
                    <MenuItem key="anywhere" value="anywhere">Anywhere</MenuItem>
                </StyleSelect>
            </Grid>
            <BlockStyleDataLabel name="wrap" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='word-wrap'>
                    <ToggleButton value="normal" key="normal">
                        <Tooltip title="">
                            <span>
                                Normal
                            </span>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="break-word" key="break-word">
                        <Tooltip title="">
                            <span>
                                Break word
                            </span>
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
        </>
    )
}