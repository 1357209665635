import { Box } from '@mui/material';
import React from 'react';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { BlockData, BlockTypes, CreateBlockDirection } from '../../../redux/blocks/types';
import { Colors } from '../../../theme/Colors';

export interface Props {
    size: string,
    block: BlockData,
    direction: CreateBlockDirection
}

type DragItem = {
    path: string;
};

export default function BlockMapDropZone (props: Props) {
    const {size, block, direction} = props;

    //TODO: canDrop
    const [{ isOver }, drop] = useDrop(() => ({
        accept: [BlockTypes.create, BlockTypes.move],
        canDrop: (item: DragItem, DropTargetMonitor) => {
            console.log(`drag: ${item.path}, drop: ${block.locationPath}`);
            return !block.locationPath.startsWith(item.path);
        },
        drop: (item, monitor) => {
          console.log(`drag: ${item.path}, drop: ${block.locationPath}`);
          if (monitor.canDrop()) {
            return { block, direction };
          }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }))

//() => ({ block, direction });

    return (
        <Box ref={drop} sx={{ flexBasis: size, opacity: 0.5, ...(isOver && { backgroundColor: Colors.primary_main }) }}/> 
    );
}