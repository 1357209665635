import { PortraitOutlined, Add, Cancel, Delete, Edit, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, FormControl, Popover, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { GridActionsCellItem, GridColDef, GridEventListener, GridRenderEditCellParams, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, MuiEvent, useGridApiContext } from '@mui/x-data-grid';
import * as React from 'react';
import SnackBarOperations from '../../../components/SnackBar/SnackBarOperations';
import { selectUser } from '../../../redux/auth/authSlice';
import { ProjectRole, User } from '../../../redux/auth/types';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { selectProjectById } from '../../../redux/project/projectSlice';
import { addProjectRoleToUser, getProjectUsers, removeUserFromProject, selectProjectUsers, updateProjectRoles } from '../../../redux/users/usersSlice';
import UserAutoComplete from '../../users/UserAutoComplete';
import { userGridColDefs } from '../../utils/CommonGridColDefs';
import GridSelect from '../../utils/GridSelect';
import StyledDataGrid from '../../utils/StyledDataGrid';
import TitleBox from '../../widgets/TitleBox';

function CustomRoleEditComponent(props: GridRenderEditCellParams & { custom_prop_projRoles: ProjectRole[] }) {
    const { id, value, field, custom_prop_projRoles } = props;
    const apiRef = useGridApiContext();

    console.log(value)

    const handleValueChange = (newValue: any) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
        <GridSelect
            values={value}
            setValues={handleValueChange}
            idSelector={(p) => p.id}
            nameSelector={(p) => p.name}
            xs={4}
            gridsAndOptions={[
                {
                    options: custom_prop_projRoles,
                    title: 'Project roles'
                }
            ]}
        />
    )
}


interface Props {
    projectId: number
}

export default function ProjectUsers(props: Props) {

    const { projectId } = props;

    const [addUserAnchor, setAddUserAnchor] = React.useState<HTMLButtonElement | null>(null);
    const [userAddLoading, setUserAddLoading] = React.useState(false);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const project = useAppSelector(selectProjectById(projectId));

    const projectUsers = useAppSelector(selectProjectUsers(projectId));

    const projectRoles = React.useMemo(() => {
        return project?.projectRoles ?? [];
    }, [project]);

    const theme = useTheme();

    const [projectUsersLoading, setLoading] = React.useState(false);

    const me = useAppSelector(selectUser);

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        setLoading(true);
        dispatch(getProjectUsers(projectId))
            .unwrap()
            .catch((err) => SnackBarOperations.error(err.message))
            .finally(() => setLoading(false));
    }, [projectId, dispatch, setLoading]);

    const handleUserAdd = React.useCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
        setAddUserAnchor(ev.currentTarget)
    }, [setAddUserAnchor]);

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        if (typeof id === 'string') return;

        dispatch(removeUserFromProject({ id: projectId, userId: id }))
            .unwrap()
            .catch((err) => {
                SnackBarOperations.error(err.message);
            });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = async (newRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        console.log(updatedRow);
        const newRoles = (updatedRow as any).projectRoles as ProjectRole[];
        try {
            dispatch(updateProjectRoles({
                projectId,
                projectRoles: newRoles,
                userId: newRow.id,
            }))
        } catch (err: any) {
            SnackBarOperations.error(err.message);
            return oldRow;
        }
        return updatedRow;
    };

    const projectUsersColums: GridColDef[] = [
        ...userGridColDefs(me?.username),
        {
            field: 'projectRoles',
            headerName: 'Project roles',
            minWidth: 200,
            flex: 2,
            editable: true,
            renderCell(params) {
                const roles = params.value as ProjectRole[];
                return <Box sx={{
                    margin: '3px',
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>
                    {roles.map((r) => (
                        <Chip
                            key={r.id}
                            size='small'
                            sx={{
                                mr: '3px',
                                mb: '2px'
                            }}
                            label={r.name}
                        />
                    ))}
                </Box>
            },
            renderEditCell(params) {
                return <CustomRoleEditComponent {...params} custom_prop_projRoles={projectRoles} />
            },
            filterable: false,
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key="save"
                            icon={<Save />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key="cancel"
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key="edit"
                        icon={<Edit />}
                        label="Edit"
                        disabled={row.username === me?.username}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        key="delete"
                        icon={<Delete />}
                        label="Delete"
                        disabled={row.username === me?.username}
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        }
    ];

    const [userToAdd, setUserToAdd] = React.useState<User | null>(null);
    const [rolesToAdd, setRolesToAdd] = React.useState<ProjectRole[] | []>([]);

    const onUserChange = React.useCallback((user: User | null) => {
        setUserToAdd(user);
    }, [setUserToAdd]);

    const onSubmit = React.useCallback(() => {
        setUserAddLoading(true);
        const promises = rolesToAdd.map((r) => new Promise<void>((res, rej) => {
            dispatch(addProjectRoleToUser({ projectId, projectRoleId: r.id, userId: userToAdd?.id ?? 0 }))
                .unwrap()
                .then(() => res())
                .catch((err) => rej(err.message));
        }));
        Promise.all(promises)
            .catch((err) => SnackBarOperations.error(err.message))
            .finally(() => {
                setUserAddLoading(false);
                setAddUserAnchor(null);
            });
    }, [setUserAddLoading, dispatch, rolesToAdd, userToAdd]);

    return (
        <Box sx={{
            marginTop: theme.spacing(6)
        }}>
            <TitleBox
                avatarIcon={<PortraitOutlined />}
                mainTitle='Users'
                maintitleVariant='h6'
                subTitle='Project related users'>
                <Button variant="contained" onClick={handleUserAdd} sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />}>
                    ADD USER
                </Button>
                <Popover
                    open={Boolean(addUserAnchor)}
                    anchorEl={addUserAnchor}
                    onClose={() => setAddUserAnchor(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box sx={{ padding: theme.spacing(3), minWidth: '200px' }}>
                        <Stack
                            component="form"
                            sx={{
                                width: '100%',
                            }}
                            spacing={2}
                        >
                            <FormControl>
                                <UserAutoComplete excludeIds={projectUsers.map(x => x.id)} onChange={onUserChange} />
                            </FormControl>
                            <FormControl>
                                <GridSelect
                                    values={rolesToAdd}
                                    idSelector={(r) => r.id}
                                    nameSelector={(r) => r.name}
                                    setValues={setRolesToAdd}
                                    xs={12}
                                    gridsAndOptions={[{
                                        options: projectRoles,
                                        title: 'Project roles'
                                    }]}
                                />
                            </FormControl>
                        </Stack>
                        <Box
                            sx={{
                                marginTop: theme.spacing(3),
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <LoadingButton
                                variant='contained'
                                disabled={userToAdd === null || rolesToAdd === null}
                                onClick={() => {
                                    onSubmit();
                                }}
                                loading={userAddLoading}
                            >
                                Submit
                            </LoadingButton>
                        </Box>
                    </Box>
                </Popover>
            </TitleBox>
            <Box sx={{ height: '500px', width: '100%' }}>
                <StyledDataGrid
                    rows={projectUsers}
                    columns={projectUsersColums}
                    pageSizeOptions={[10]}
                    checkboxSelection={false}
                    disableRowSelectionOnClick
                    loading={projectUsersLoading}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    rowModesModel={rowModesModel}
                    editMode='row'
                />
            </Box>
        </Box>
    )
}