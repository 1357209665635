import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  BlockData,
  BlockType,
  ContentBlockTypes,
  ContentWithChildrenBlockTypes,
} from "../../../../../redux/blocks/types";
import { BlockAttributeTypes } from "../../../../../Utility";

interface Props {
  handleBlockChange: (block: BlockData) => void;
  handleBlockChangeAndSave: (block: BlockData) => void;
  currentBlock: BlockData;
}

export default function BaseInputs(props: Props) {
  const {
    handleBlockChange,
    handleBlockChangeAndSave,
    currentBlock,
  } = props;

  const theme = useTheme();

  const handleSwitchChange = React.useCallback(
    (block: BlockData) => {
      handleBlockChangeAndSave(block);
    },
    [handleBlockChangeAndSave]
  );

  const handleTypeChange = React.useCallback(
    (blockType: BlockType) => {
      const attributeTypes = BlockAttributeTypes[blockType]?.common;
      let changedBlock = {
        ...currentBlock,
        blockType,
        blockAttributes: currentBlock.blockAttributes.filter((x) =>
          attributeTypes.includes(x.name)
        ),
      };
      handleBlockChangeAndSave(changedBlock);
    },
    [currentBlock]
  );

  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">GENERAL</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="content-textarea"
          label="Displayname"
          placeholder="Displayname"
          fullWidth
          onChange={(e) =>
            handleBlockChange({ ...currentBlock, displayName: e.target.value })
          }
          variant="filled"
          value={currentBlock?.displayName ?? ""}
        />
        <FormControl
          variant="filled"
          fullWidth
          sx={{ marginBottom: theme.spacing(1) }}
        >
          <InputLabel id="block-type">Type</InputLabel>
          <Select
            labelId="block-type"
            value={currentBlock.blockType}
            onChange={(e) => handleTypeChange(e.target.value as BlockType)}
          >
            {Object.values(BlockType).map((blockType) => (
              <MenuItem key={blockType} value={blockType}>
                {blockType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {(ContentBlockTypes.includes(currentBlock.blockType) ||
          ContentWithChildrenBlockTypes.includes(currentBlock.blockType)) && (
          <TextField
            id="content-textarea"
            label="Content"
            placeholder="Content"
            multiline
            fullWidth
            onChange={(e) =>
              handleBlockChange({ ...currentBlock, content: e.target.value })
            }
            minRows={7}
            variant="filled"
            value={currentBlock?.content ?? ""}
          />
        )}
        <FormControlLabel
          sx={{ margin: 0 }}
          control={
            <Switch
              value={currentBlock.overwriteWindowScroll}
              onChange={(e, checked) =>
                handleSwitchChange({
                  ...currentBlock,
                  overwriteWindowScroll: checked,
                })
              }
            />
          }
          labelPlacement="start"
          label="Overwrite window scroll"
        />
        <FormControlLabel
          sx={{ margin: 0 }}
          control={
            <Switch
              checked={currentBlock.referenceProps}
              onChange={(e, checked) =>
                handleSwitchChange({ ...currentBlock, referenceProps: checked })
              }
            />
          }
          labelPlacement="start"
          label="Reference props"
        />
      </Grid>
    </Grid>
  );
}
