import React from 'react';
import useRouteParam from '../../hooks/useRouteParam';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectProjectById } from "../../redux/project/projectSlice";
import { getTemplates, selectProjectTemplates } from "../../redux/template/templateSlice";
import Template from "./Template";

export default function ProjectTemplates() {
   
    const projectId = useRouteParam('projectId');

    const templates = useAppSelector(selectProjectTemplates(projectId));
    const selectedProject = useAppSelector(selectProjectById(projectId));
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(getTemplates());
    }, [])

    return (
        <Template templates={templates} subTitle={`Templates related to project ${selectedProject?.name}`}/>
    );
}