import { AccountTree, Dashboard, Public } from '@mui/icons-material';
import { Checkbox, Dialog, DialogContent, FormControlLabel, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import React from 'react';
import useRouteParam from '../../../hooks/useRouteParam';
import { appendBlockTemplate } from '../../../redux/blocks/blockSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectGlobalTemplates, selectProjectTemplates, selectSystemTemplates } from '../../../redux/template/templateSlice';
import { TemplateType } from '../../../redux/template/types';
import TitleBox from '../../widgets/TitleBox';
import TemplateGrid from './TemplateGrid';

interface Props {
    open: boolean,
    onClose: () => void,
}

export default function TemplateDialog(props: Props) {
    const { open, onClose } = props;
    
    const projectId = useRouteParam('projectId');
    const refType = useRouteParam('ref');
    const refId = useRouteParam('refId');

    const [templateType, setTemplateType] = React.useState(TemplateType.local);
    const [referenced, setReferenced] = React.useState(true);
    
    const projectTemplates = useAppSelector(selectProjectTemplates(projectId));
    const globalTemplates = useAppSelector(selectGlobalTemplates);
    const systemTemplates = useAppSelector(selectSystemTemplates);
    const dispatch = useAppDispatch();

    const handleChange = (
        _: React.MouseEvent<HTMLElement>,
        newTemplateType: TemplateType,
    ) => {
        if (newTemplateType !== null)
            setTemplateType(newTemplateType);
    };

    const handleTemplateAdd = React.useCallback((id: number) => {
        dispatch(appendBlockTemplate({refId: refId, refType: refType, templateId: id, referenced: templateType === TemplateType.system ? false : referenced}));
        onClose();
    }, [dispatch, onClose, refId, refType, referenced]);

    const templates = templateType === TemplateType.system ? 
                        systemTemplates : templateType === TemplateType.global ? 
                            globalTemplates : projectTemplates;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
            fullWidth>
            <DialogContent                
                sx={{height: '80vh'}}>
                <TitleBox
                    mainTitle='Templates'
                    subTitle='select a template'>
                    <FormControlLabel control={<Checkbox checked={referenced} onChange={(e, checked) => setReferenced(checked)} />} label="Referenced" />
                    <ToggleButtonGroup color="secondary" value={templateType} onChange={handleChange} exclusive={true}>
                        <ToggleButton value={TemplateType.system} key={TemplateType.system}>
                            <Dashboard />
                        </ToggleButton>
                        <ToggleButton value={TemplateType.global} key={TemplateType.global}>
                            <Public />
                        </ToggleButton>
                        <ToggleButton value={TemplateType.local} key={TemplateType.local}>
                            <AccountTree />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </TitleBox>
                <TemplateGrid templates={templates} handleTemplateAdd={handleTemplateAdd} />
            </DialogContent>
        </Dialog>
    );
}