import { Add, Cancel, Delete, DeleteOutlined, Edit, EditOutlined, EmailOutlined, Save, SaveOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridEventListener, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, GridRowSelectionModel, MuiEvent } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { createMailConnection, createMailTemplate, defaultMailConnection, defaultMailTemplate, deleteMailConnection, deleteMailTemplate, getConnections, getMailTemplates, selectMailConnectionsByProjectId, selectMailTemplatesByProjectId, updateMailConnection, updateMailTemplate } from '../../../redux/mail/mailSlice';
import { MailConnection, MailTemplate } from '../../../redux/mail/types';
import ConfirmationPopover from '../../utils/ConfirmationPopover';
import StyledDataGrid from '../../utils/StyledDataGrid';
import TitleBox from '../../widgets/TitleBox';
import { deleteLanguage } from '../../../redux/language/languageSlice';
import yup from '../../../validation/yup';
import SnackBarOperations from '../../../components/SnackBar/SnackBarOperations';
import DeleteConfirmationDialog from '../../utils/DeleteConfirmationDialog';
import { useNavigate } from 'react-router';

interface Props {
    projectId: number
}

export default function MailSettings(props: Readonly<Props>) {
    const { projectId } = props;
    const theme = useTheme();

    const [selectedConnectionIds, setSelectedConnectionIds] = React.useState<GridRowSelectionModel>([]);
    const [connectionConfirmationPopoverOpen, setConnectionConfirmationPopoverOpen] = useState(false);
    const [connectionRowModesModel, setConnectionRowModesModel] = React.useState<GridRowModesModel>({});

    const [selectedTemplateIds, setSelectedTemplateIds] = React.useState<GridRowSelectionModel>([]);
    const [templateConfirmationPopoverOpen, setTemplateConfirmationPopoverOpen] = useState(false);
    const [editTemplate, setEditTemplate] = useState<MailTemplate | undefined>();

    const dispatch = useAppDispatch();
    const connections = useAppSelector(selectMailConnectionsByProjectId(projectId));
    const templates = useAppSelector(selectMailTemplatesByProjectId(projectId));
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(getConnections(projectId));
        dispatch(getMailTemplates(projectId));
    }, []);

    const connectionsDatatableColumns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
        },
        {
            field: 'host',
            headerName: 'Host',
            minWidth: 100,
            flex: 1,
            editable: true,
        },
        {
            field: 'port',
            headerName: 'Port',
            minWidth: 100,
            flex: 1,
            editable: true,
        },
        {
            field: 'user',
            headerName: 'User',
            minWidth: 100,
            flex: 1,
            editable: true,
        },
        {
            field: 'password',
            headerName: 'Password',
            minWidth: 100,
            flex: 1,
            editable: true,
            type: 'password'
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                const isInEditMode = connectionRowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key="save"
                            icon={<Save />}
                            label="Save"
                            onClick={handleConnectionSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key="cancel"
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleConnectionCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key="edit"

                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={handleConnectionEditClick(id)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleConnectionDeleteClick(id)}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        },
    ];


    const templatesDatatableColumns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                return [
                    <GridActionsCellItem
                        key="edit"

                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={() => navigate(`/project-detail/${projectId}/settings/mailtemplate/${id}`)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleTemplateDelete(id)}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];

    const handleConnectionAdd = React.useCallback(() => {
        dispatch(createMailConnection({ ...defaultMailConnection, projectId }));
        //TODO: https://www.npmjs.com/package/react-syntax-highlighter
    }, [dispatch, projectId]);

    const handleConnectionConfirmationPopoverSubmit = React.useCallback(() => {
        selectedConnectionIds.forEach((id) => {
            dispatch(deleteMailConnection(Number(id)));
        })
    }, [dispatch, selectedConnectionIds, dispatch]);

    const handleTemplateAdd = React.useCallback(() => {
        dispatch(createMailTemplate({ ...defaultMailTemplate, projectId }))
            .unwrap()
            .then(payload => {
                navigate(`/project-detail/${projectId}/settings/mailtemplate/${payload.id}`)
            });
    }, [dispatch, projectId])

    const handleTemplatesDelete = React.useCallback(() => {
        selectedTemplateIds.forEach((id) => {
            dispatch(deleteMailTemplate(Number(id)));
        })
    }, [selectedTemplateIds, dispatch]);

    const handleTemplateDelete = React.useCallback((id: GridRowId) => () => {
        dispatch(deleteMailTemplate(Number(id)));
    }, [dispatch]);

    const handleConnectionRowEditStart = React.useCallback((
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleConnectionRowEditStop: GridEventListener<'rowEditStop'> = React.useCallback((params, event) => {
        event.defaultMuiPrevented = true;
    }, []);

    const mailServerSchema = React.useMemo(() => yup.object().shape({
        host: yup.string().required('Host is required'),
        user: yup.string().required('User is required'),
        password: yup.string().required('Password is required'),
        port: yup.number().moreThan(0, 'Port must be more than 0')
    }), []);

    const processConnectionRowUpdate = React.useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };

        try {
            await mailServerSchema.validate(updatedRow, { abortEarly: false });
            await dispatch(updateMailConnection(newRow as MailConnection));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                err.errors.forEach((strErr) => SnackBarOperations.error(strErr));
                throw err;
            }
            console.error(err);
        }

        return updatedRow;
    }, [dispatch, mailServerSchema]);

    const handleConnectionEditClick = React.useCallback((id: GridRowId) => () => {
        setConnectionRowModesModel({ ...connectionRowModesModel, [id]: { mode: GridRowModes.Edit } });
    }, [setConnectionRowModesModel, connectionRowModesModel]);

    const handleConnectionSaveClick = React.useCallback((id: GridRowId) => () => {
        setConnectionRowModesModel({ ...connectionRowModesModel, [id]: { mode: GridRowModes.View } });
    }, [setConnectionRowModesModel, connectionRowModesModel]);

    const handleConnectionCancelClick = React.useCallback((id: GridRowId) => () => {
        setConnectionRowModesModel({
            ...connectionRowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    }, [setConnectionRowModesModel, connectionRowModesModel]);

    const handleConnectionDeleteClick = React.useCallback((id: GridRowId) => () => {
        dispatch(deleteMailConnection(Number(id)))
    }, [dispatch]);

    return (
        <>
            <TitleBox
                avatarIcon={<EmailOutlined />}
                mainTitle='Mail server connections'
                subTitle='Project related users'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>
                <ConfirmationPopover
                    handleClose={() => setConnectionConfirmationPopoverOpen(false)}
                    handleSubmit={handleConnectionConfirmationPopoverSubmit}
                    open={connectionConfirmationPopoverOpen}>
                    <IconButton component="label" disabled={selectedConnectionIds.length < 1} onClick={() => setConnectionConfirmationPopoverOpen(true)}>
                        <DeleteOutlined />
                    </IconButton>
                </ConfirmationPopover>
                <Button variant="contained" onClick={handleConnectionAdd} sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />}>
                    ADD CONNECTION
                </Button>
            </TitleBox>
            <Box sx={{ height: '300px', width: '100%', mb: theme.spacing(2) }}>
                <StyledDataGrid
                    rows={connections}
                    columns={connectionsDatatableColumns}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    onRowSelectionModelChange={(newSM) => {
                        setSelectedConnectionIds(newSM);
                    }}
                    rowSelectionModel={selectedConnectionIds}
                    disableRowSelectionOnClick
                    loading={false}
                    editMode='row'
                    rowModesModel={connectionRowModesModel}
                    onRowModesModelChange={setConnectionRowModesModel}
                    onRowEditStart={handleConnectionRowEditStart}
                    onRowEditStop={handleConnectionRowEditStop}
                    processRowUpdate={processConnectionRowUpdate}
                />
            </Box>
            <TitleBox
                avatarIcon={<EmailOutlined />}
                mainTitle='Mail templates'
                subTitle='Project related users'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>
                <ConfirmationPopover
                    handleClose={() => setTemplateConfirmationPopoverOpen(false)}
                    handleSubmit={handleTemplatesDelete}
                    open={templateConfirmationPopoverOpen}>
                    <IconButton component="label" disabled={selectedTemplateIds.length < 1} onClick={() => setTemplateConfirmationPopoverOpen(true)}>
                        <DeleteOutlined />
                    </IconButton>
                </ConfirmationPopover>
                <Button variant="contained" onClick={handleTemplateAdd} sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />}>
                    ADD TEMPLATE
                </Button>
            </TitleBox>
            <Box sx={{ height: '300px', width: '100%', mb: theme.spacing(2) }}>
                <StyledDataGrid
                    rows={templates}
                    columns={templatesDatatableColumns}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    onRowSelectionModelChange={(newSM) => {
                        setSelectedTemplateIds(newSM);
                    }}
                    rowSelectionModel={selectedTemplateIds}
                    disableRowSelectionOnClick
                    loading={false}
                />
            </Box>
        </>
    )
}