import { AsyncThunk, AsyncThunkOptions, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { isPrimitive } from 'util';
import type { RootState, AppDispatch } from './store'

/**
 * Dispatch function for the application store
 * 
 * Use .unwrap() to use values in 'then'/'catch' or 'try'/'catch': 
 * @example
 * const dispatch = useAppDispatch();
 * // ...
 * const value1 = await dispatch(asyncAction());
 * // 'value1' is undefined
 * const value2 = await dispatch(asyncAction()).unwrap();
 * // 'value2' holds the value resolved by 'asyncAction'
 */
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

interface AxiosErrorType {
  response: {
    data: {
      message: string
    },
    status: number,
    statusText: string
  }
}

export class BackendError {
  constructor(public status: number, public statusText: string, public message: string) { }
}

function isNormalizedError<T>(obj: T): obj is T & AxiosErrorType {
  return obj
    && typeof obj === 'object'
    && 'response' in obj
    && typeof obj.response === 'object' && (obj.response !== null)
    && 'data' in obj.response
    && typeof obj.response.data === 'object' && (obj.response.data !== null)
    && 'message' in obj.response.data && typeof obj.response.data.message === 'string'
    && 'status' in obj.response && typeof obj.response.status === 'number'
    && 'statusText' in obj.response && typeof obj.response.statusText === 'string';
}

const defaultError: BackendError = {
  message: 'An unknown error occured',
  status: 500,
  statusText: 'Server Error'
};

function checkUnknownErrorProperty(str: string, unknownError: unknown): BackendError | undefined {
  if (!unknownError) return undefined;
  const err = unknownError as any;
  if (str in err && typeof err[str] === 'string') return new BackendError(
    500,
    'Server Error',
    (err[str] as string),
  );
  return undefined;
}

interface AsyncAppThunkConfig {
  state: RootState;
  dispatch?: AppDispatch;
  serializedErrorType: BackendError;
};

export const createAppAsyncThunk = <ReturnType, ThunkArg = void>
  (typePrefix: string, func: AsyncThunkPayloadCreator<ReturnType, ThunkArg, AsyncAppThunkConfig>) =>
  createAsyncThunk(typePrefix, func, {
    serializeError(x): BackendError {
      if (!x) return new BackendError(
        500,
        'Server Error',
        'An error ocurred',
      )
      if (isNormalizedError(x)) {
        return new BackendError(
          x.response.status,
          x.response.statusText,
          x.response.data.message,
        );
      }
      if (typeof x === 'object') {
        let err = checkUnknownErrorProperty('error', x)
          ?? checkUnknownErrorProperty('data', x)
          ?? defaultError;
        return err;
      }
      return defaultError;
    },
  });
