import { MoreVert } from '@mui/icons-material';
import { IconButton, List, ListItem, ListItemButton, ListItemText, Popover, Theme } from '@mui/material';
import { Box, SxProps, useTheme } from '@mui/system';
import * as React from 'react';

interface MoreAction {
    text: string;
    action: () => void;
    customStyles?: SxProps<Theme>,
    disabled?: boolean
}

interface Props {
    actions: MoreAction[],
}

export default function MorePopover(props: Props) {
    const { actions } = props;

    const [ae, setAe] = React.useState<HTMLButtonElement | null>(null);

    const theme = useTheme();

    const onClose = React.useCallback(() => {
        setAe(null);
    }, [setAe]);

    return (
        <>
            <IconButton onClick={(ev) => setAe(ev.currentTarget)} size='small'>
                <MoreVert />
            </IconButton>
            <Popover
                open={Boolean(ae)}
                anchorEl={ae}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List disablePadding dense>
                    {
                        actions.map((a) => (
                            <ListItem key={'list-' + a.text} disablePadding>
                                <ListItemButton 
                                    disabled={a.disabled}
                                    sx={{ 
                                        paddingLeft: theme.spacing(3), 
                                        paddingRight: theme.spacing(3), 
                                        ...a.customStyles
                                    }}
                                    onClick={a.action}
                                >
                                    <ListItemText primary={a.text}/>
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                </List>
            </Popover>
        </>    
    );
}