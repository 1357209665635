import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  BlockAttribute,
  BlockData,
  BlockType,
  BlockUpdate,
} from "../../../../../redux/blocks/types";
import { BlockAttributeTypes } from "../../../../../Utility";
import { AddOutlined, Remove } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { selectDynData } from "../../../../../redux/dyndata/dyndataSlice";
import { selectMedia } from "../../../../../redux/media/mediaSlice";
import useRouteParam from "../../../../../hooks/useRouteParam";
import FullScreenDialog from "./FullScreenDialog";
import Media from "../../../../media/Media";
import TitleBox from "../../../../widgets/TitleBox";
import { updateBlock } from "../../../../../redux/blocks/blockSlice";

interface Props {
  handleBlockChange: (block: BlockData) => void;
  handleBlockChangeAndSave: (block: BlockData) => void;
  currentBlock: BlockData;
}

export default function AttributeInputs(props: Props) {
  const {
    handleBlockChange,
    handleBlockChangeAndSave,
    currentBlock,
  } = props;

  const [mediaDialogOpen, setMediaDialogOpen] = React.useState(false);
  const [currentAttribute, setCurrentAttribute] = React.useState<
    BlockAttribute | undefined
  >();

  const projectId = useRouteParam("projectId");

  const dataSets = useAppSelector(selectDynData);
  const media = useAppSelector(selectMedia);
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const handleAttributeChange = React.useCallback(
    (attr: BlockAttribute) => {
      const changedBlock: BlockData = {
        ...currentBlock,
        blockAttributes: [
          ...currentBlock.blockAttributes.filter((x) => x.id !== attr.id),
          attr,
        ],
      };
      handleBlockChange(changedBlock);
    },
    [currentBlock, handleBlockChange]
  );

  const handleAttributeChangeAndSave = React.useCallback(
    (attr: BlockAttribute) => {
      const changedBlock: BlockData = {
        ...currentBlock,
        blockAttributes: [
          ...currentBlock.blockAttributes.filter((x) => x.id !== attr.id),
          attr,
        ],
      };
      handleBlockChangeAndSave(changedBlock);
    },
    [currentBlock, handleBlockChangeAndSave]
  );

  const handleAttributeDelete = React.useCallback(
    (id: number) => {
      const changedBlock: BlockData = {
        ...currentBlock,
        blockAttributes: currentBlock.blockAttributes.filter(
          (x) => x.id !== id
        ),
      };
      handleBlockChangeAndSave(changedBlock);
    },
    [currentBlock, handleBlockChangeAndSave]
  );

  const handleMediaDialogOpen = (attr: BlockAttribute) => {
    setCurrentAttribute(attr);
    setMediaDialogOpen(true);
  };

  const handleMediaDialogClose = () => {
    setCurrentAttribute(undefined);
    setMediaDialogOpen(false);
  };

  const handleMediaSelect = (id: number) => {
    if (currentAttribute)
      handleAttributeChangeAndSave({
        ...currentAttribute,
        value: id.toString(),
      });
    handleMediaDialogClose();
  };

  const attributesInputs = React.useMemo(
    () =>
      [...currentBlock.blockAttributes]
        .sort((a, b) => a.id - b.id)
        .map((attr) => (
          <TableRow key={attr.id} sx={{ "&>*": { padding: "0 !important" } }}>
            <TableCell>
              <Autocomplete
                value={attr.name}
                disableClearable
                onChange={(event: any, newValue: string | null) =>
                  handleAttributeChange({ ...attr, name: newValue ?? "" })
                }
                options={BlockAttributeTypes[
                  currentBlock.blockType
                ]?.common.filter(
                  (x) =>
                    currentBlock.blockAttributes.findIndex(
                      (y) => y.name === x
                    ) === -1
                )}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    {...params}
                    fullWidth
                    hiddenLabel
                  />
                )}
              />
            </TableCell>
            <TableCell>
              {attr.name === "data-set" ? (
                <Select
                  labelId="data-set-select"
                  onChange={(e) =>
                    handleAttributeChangeAndSave({
                      ...attr,
                      value: e.target.value,
                    })
                  }
                  value={attr.value}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {dataSets.map((set) => (
                    <MenuItem key={set.id} value={set.id.toString()}>
                      {set.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : attr.name === "src" &&
                currentBlock.blockType === BlockType.IMG ? (
                <Box
                  sx={{
                    width: "100%",
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                  }}
                >
                  <Button fullWidth onClick={() => handleMediaDialogOpen(attr)}>
                    {attr.value === ""
                      ? "Select Image"
                      : media.find((x) => x.id.toString() === attr.value)
                          ?.name ?? "image not found"}
                  </Button>
                </Box>
              ) : (
                <TextField
                  variant="filled"
                  label="Value"
                  fullWidth
                  value={attr.value}
                  onChange={(e) =>
                    handleAttributeChange({ ...attr, value: e.target.value })
                  }
                />
              )}
            </TableCell>
            <TableCell sx={{ width: "5px" }}>
              <IconButton onClick={() => handleAttributeDelete(attr.id)}>
                <Remove />
              </IconButton>
            </TableCell>
          </TableRow>
        )),
    [
      currentBlock,
      handleAttributeChange,
      handleAttributeChangeAndSave,
      dataSets,
    ]
  );

  const handleBlockAttributeCreate = React.useCallback(() => {
    if (currentBlock)
      dispatch(
        updateBlock({
          ...currentBlock,
          blockAttributes: [
            ...currentBlock.blockAttributes,
            { id: 0, name: "", value: "" },
          ],
        } as BlockUpdate)
      );
  }, [currentBlock, dispatch]);

  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={1}>
      <Grid item xs={12}>
        <TitleBox
          mainTitle={"ATTRIBUTES"}
          maintitleVariant="subtitle1"
          subTitle={"Configure block attributes"}
        >
          <Tooltip title="Add attribute">
            <span>
              <IconButton
                aria-label="add"
                disabled={
                  BlockAttributeTypes[currentBlock.blockType]?.common.length < 1
                }
                onClick={handleBlockAttributeCreate}
              >
                <AddOutlined />
              </IconButton>
            </span>
          </Tooltip>
        </TitleBox>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "50%" }}>Attribute</TableCell>
                <TableCell sx={{ width: "50%" }}>Value</TableCell>
                <TableCell sx={{ width: "37px" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{attributesInputs}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <FullScreenDialog
        title="MEDIA"
        handleCloseDialog={handleMediaDialogClose}
        open={mediaDialogOpen}
      >
        <Media onSelectMedia={handleMediaSelect} />
      </FullScreenDialog>
    </Grid>
  );
}
