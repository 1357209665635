import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import {
  BackendError,
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/hooks";
import {
  getGlobalSequence,
  getSequence,
  selectSequence,
} from "../../../../../redux/actionsequence/sequenceSlice";
import {
  removeSequenceFromBock,
  selectSelectedBlock,
  updateSequenceAssignment,
} from "../../../../../redux/blocks/blockSlice";
import SnackBarOperations from "../../../../../components/SnackBar/SnackBarOperations";
import { BlockDataActionSequence } from "../../../../../redux/blocks/types";
import ActionSequenceType from "../../../../../redux/actionsequence/types/helpers/ActionSequenceType";
import useRouteParam from "../../../../../hooks/useRouteParam";
import MorePopover from "../../../../utils/MorePopOver";

interface Props {
  sequenceId: number;
}

const BlockActionGridItem: React.FC<Props> = (props) => {
  const { sequenceId } = props;

  const projectId = useRouteParam("projectId");

  const sequence = useAppSelector(selectSequence(sequenceId));
  const currentBlock = useAppSelector(selectSelectedBlock);

  const sequenceLoaded = useMemo(() => Boolean(sequence), [sequence]);

  const theme = useTheme();

  useEffect(() => {
    if (!sequence) {
      return;
    }
    if (sequence.isGlobal) {
      dispatch(getGlobalSequence({ id: sequenceId }));
    } else {
      dispatch(getSequence({ id: sequenceId, projectId }));
    }
  }, [sequenceId, projectId, sequenceLoaded]);

  const dispatch = useAppDispatch();

  const sequenceInfo = useMemo(() => {
    return currentBlock?.actionSequences?.find(
      (x) => x.actionSequenceId === sequenceId
    );
  }, [currentBlock, sequenceId]);

  const navigate = useNavigate();

  const handleRemoveActionSequence = useCallback(() => {
    dispatch(
      removeSequenceFromBock({
        actionSequenceId: sequenceId,
        blockDataId: currentBlock?.id ?? -1,
      })
    );
  }, [sequenceId, currentBlock]);

  const handleChangeActionSequence = useCallback(
    (change: Partial<BlockDataActionSequence>) => {
      if (!sequenceInfo) {
        return;
      }
      dispatch(updateSequenceAssignment({ ...sequenceInfo, ...change }))
        .unwrap()
        .catch((err) => {
          if (err instanceof BackendError) {
            SnackBarOperations.error(err.message);
          } else {
            console.error(err);
          }
        });
    },
    [sequenceInfo, dispatch]
  );

  const handleGoToActionSequence = useCallback(() => {
    if (sequence?.isGlobal) {
      navigate(`/sequencebuilder/${sequence.id}`);
    }
  }, [sequence, navigate]);

  if (!sequence) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader
          action={
            <MorePopover
              actions={[
                {
                  text: "Edit sequence",
                  action() {
                    handleGoToActionSequence();
                  },
                },
                {
                  text: "Remove from block",
                  customStyles: {
                    color: theme.palette.error.main,
                  },
                  action() {
                    handleRemoveActionSequence();
                  },
                },
              ]}
            />
          }
          title={sequence.name}
          subheader={
            <Chip
              variant="outlined"
              size="medium"
              sx={{
                mt: "10px",
              }}
              label={`Trigger: ${sequence.type}`}
            />
          }
        />
        <CardContent>
          {sequence.type === ActionSequenceType.OnScroll && (
            <Box>
              <FormGroup>
                <Tooltip
                  title={
                    <Typography>
                      An action trigger is zero based, if the offset is related
                      to scroll y of 0.
                    </Typography>
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sequenceInfo?.zeroBased}
                        onChange={(_ev, checked) =>
                          handleChangeActionSequence({ zeroBased: checked })
                        }
                      />
                    }
                    label="Zero based"
                  />
                </Tooltip>
                <Tooltip
                  title={
                    <Typography>
                      Scroll height is the offset for the trigger point.
                    </Typography>
                  }
                >
                  <FormControlLabel
                    control={
                      <TextField
                        value={sequenceInfo?.scrollHeight}
                        type="number"
                        onChange={(ev) => {
                          const n = Number(ev.target.value);
                          if (isNaN(n)) {
                            return;
                          }
                          handleChangeActionSequence({ scrollHeight: n });
                        }}
                      />
                    }
                    label="Scroll height"
                  />
                </Tooltip>
              </FormGroup>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default BlockActionGridItem;
