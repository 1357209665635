import React, { useCallback } from "react";
import {
  createGlobalSequence,
  deleteGlobalSequence,
  selectGlobalSequences,
} from "../../redux/actionsequence/sequenceSlice";
import {
  BackendError,
  useAppDispatch,
  useAppSelector,
} from "../../redux/hooks";
import ActionSequences from "./ActionSequences";
import SnackBarOperations from "../../components/SnackBar/SnackBarOperations";
import { useNavigate } from "react-router";

const GlobalActionSequences: React.FC = () => {
  const sequences = useAppSelector(selectGlobalSequences);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSequenceCreate = useCallback(async () => {
    try {
      const result = await dispatch(createGlobalSequence()).unwrap();
      SnackBarOperations.success(`Created sequence ${result.name}`);
    } catch (err) {
      if (err instanceof BackendError) {
        SnackBarOperations.error(`Could not create sequence: ${err.message}`);
      } else {
        SnackBarOperations.error("Unknown error creating sequence");
      }
    }
  }, [dispatch]);

  const handleSequencesDelete = useCallback(
    async (ids: number[]) => {
      const promises = ids.map((x) =>
        dispatch(deleteGlobalSequence({ id: x })).unwrap()
      );
      try {
        const result = await Promise.all(promises);
        if (result.length === 1) {
          SnackBarOperations.success(`Successfully deleted sequence`);
        } else {
          SnackBarOperations.success(
            `Successfully deleted ${result.length} sequences`
          );
        }
      } catch (err) {
        if (err instanceof BackendError) {
          SnackBarOperations.error(`Could not delete sequence: ${err.message}`);
        } else {
          SnackBarOperations.error("Unknown error deleting sequence");
        }
      }
    },
    [dispatch]
  );

  const handleSequenceEditClick = useCallback(
    (id: number) => {
      navigate(`/sequencebuilder/${id}`);
    },
    [navigate]
  );

  return (
    <ActionSequences
      actionSequences={sequences}
      subTitle="Edit global action sequences"
      handleSequenceCreate={handleSequenceCreate}
      handleSequenceEditClick={handleSequenceEditClick}
      handleSequencesDelete={handleSequencesDelete}
    />
  );
};

export default GlobalActionSequences;
