import { Check, Close, Save, Cancel, Edit, Delete, Add, PortraitOutlined } from '@mui/icons-material';
import { Box, Button, Chip, Popover, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridEventListener, GridRenderEditCellParams, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, MuiEvent, useGridApiContext } from '@mui/x-data-grid';
import * as React from 'react';
import { selectProjectById, addOrUpdateProjectRole, removeProjectRole } from '../../../redux/project/projectSlice';
import { ProjectPermission, ProjectRole, PROJECT_PERMISSIONS } from '../../../redux/auth/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { LightTooltip } from '../../utils/LightToolTip';
import StyledDataGrid from '../../utils/StyledDataGrid';
import TitleBox from '../../widgets/TitleBox';
import AddProjectRoleForm from './AddProjectRoleForm';
import SnackBarOperations from '../../../components/SnackBar/SnackBarOperations';
import GridSelect from '../../utils/GridSelect';
import DeleteConfirmationDialog from '../../utils/DeleteConfirmationDialog';

function CustomRoleEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleValueChange = (newValue: any) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
        <GridSelect
            values={value}
            setValues={handleValueChange}
            idSelector={(p) => p}
            nameSelector={(p) => p}
            xs={4}
            gridsAndOptions={[
                {
                    options: PROJECT_PERMISSIONS.filter(x => x.startsWith('ANIMATION')),
                    title: 'Animations'
                },
                {
                    options: PROJECT_PERMISSIONS.filter(x => x.startsWith('DATASET')),
                    title: 'Datasets'
                },
                {
                    options: PROJECT_PERMISSIONS.filter(x => x.startsWith('LANGUAGE')),
                    title: 'Languages'
                },
                {
                    options: PROJECT_PERMISSIONS.filter(x => x.startsWith('MAIL_CONNECTION')),
                    title: 'Mail connections'
                },
                {
                    options: PROJECT_PERMISSIONS.filter(x => x.startsWith('MAIL_TEMPLATE')),
                    title: 'Mail templates'
                },
                {
                    options: PROJECT_PERMISSIONS.filter(x => x.startsWith('MEDIA')),
                    title: 'Media'
                },
                {
                    options: PROJECT_PERMISSIONS.filter(x => x.startsWith('MODEL')),
                    title: 'Models'
                },
                {
                    options: PROJECT_PERMISSIONS.filter(x => x.startsWith('PAGE')),
                    title: 'Pages'
                },
                {
                    options: PROJECT_PERMISSIONS.filter(x => x.startsWith('PROJECT')),
                    title: 'Projects'
                },
                {
                    options: PROJECT_PERMISSIONS.filter(x => x.startsWith('TEMPLATE')),
                    title: 'Templates'
                },
                {
                    options: PROJECT_PERMISSIONS.filter(x => x.startsWith('TRANSLATION_TAG')),
                    title: 'Translation tags'
                }
            ]}
        />
    )
}


interface Props {
    projectId: number;
}
export default function ProjectRoles(props: Props) {

    const { projectId } = props;

    const theme = useTheme();
    const dispatch = useAppDispatch();

    const project = useAppSelector(selectProjectById(projectId));

    const [addRoleAnchor, setAddRoleAnchor] = React.useState<HTMLButtonElement | null>(null);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const [projectRoleAddLoading, setProjectRoleAddLoading] = React.useState(false);

    const projectRoles = React.useMemo(() => {
        return project?.projectRoles ?? [];
    }, [project]);

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleRoleAdd = React.useCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
        setAddRoleAnchor(ev.currentTarget)
    }, [setAddRoleAnchor]);

    const handleDeleteClick = (id: GridRowId) => () => {
        // TODO: delete role
        console.log('Delete: ', id);
        const idx = projectRoles.findIndex(x => x.id === id);
        if (idx >= 0) {
            dispatch(removeProjectRole(projectRoles[idx]))
                .catch((err) => SnackBarOperations.error(err.message))
        }
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = async (newRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        console.log(updatedRow);
        try {
            await dispatch(addOrUpdateProjectRole(newRow as ProjectRole));
        } catch (err: any) {
            SnackBarOperations.error(err.message);
            return oldRow;
        }
        return updatedRow;
    };

    const onProjectRoleSubmit = React.useCallback((role: ProjectRole) => {
        setProjectRoleAddLoading(true);
        dispatch(addOrUpdateProjectRole(role))
            .unwrap()
            .then(() => {
                setAddRoleAnchor(null);
            })
            .catch((err) => {
                SnackBarOperations.error(err.message);
            })
            .finally(() => setProjectRoleAddLoading(false))
    }, [dispatch, setProjectRoleAddLoading, setAddRoleAnchor]);

    const projectRoleColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Role name',
            minWidth: 100,
            flex: .3,
            editable: true,
        },
        {
            field: 'permissions',
            headerName: 'Permissions',
            minWidth: 100,
            flex: 1,
            editable: true,
            renderCell(params) {
                let roles = [...params.value] as ProjectPermission[];
                roles.sort((a, b) => {
                    if (a.includes('ADMIN')) {
                        return -1;
                    } else if (b.includes('ADMIN')) {
                        return 1;
                    } else {
                        return a.localeCompare(b);
                    }
                });

                if (roles.length > 10) {
                    return <Box sx={{
                        margin: '3px',
                        display: 'flex',
                        flexWrap: 'wrap'
                    }}>
                        {roles.slice(0, 10).map((r) => (
                            <Chip
                                key={r}
                                size='small'
                                sx={{
                                    mr: '3px',
                                    mb: '2px'
                                }}
                                label={r}
                            />
                        ))}
                        <LightTooltip
                            title={
                                <Box
                                    sx={{
                                        padding: '10px'
                                    }}
                                >
                                    {
                                        roles.map((r) => (
                                            <Chip
                                                key={r}
                                                size='small'
                                                sx={{
                                                    mr: '3px',
                                                    mb: '2px'
                                                }}
                                                label={r}
                                            />
                                        ))
                                    }
                                </Box>
                            }
                        >
                            <Chip
                                size='small'
                                sx={{
                                    mr: '3px',
                                    mb: '2px'
                                }}
                                label={`+${roles.length - 10}`}
                            />
                        </LightTooltip>
                    </Box>
                }
                return <Box sx={{
                    margin: '3px',
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>
                    {roles.map((r) => (
                        <Chip
                            key={r}
                            size='small'
                            sx={{
                                mr: '3px',
                                mb: '2px'
                            }}
                            label={r}
                        />
                    ))}
                </Box>
            },
            renderEditCell(params) {
                return <CustomRoleEditComponent {...params} />
            },
            filterable: false,
        },
        {
            field: 'isDefault',
            headerName: 'Is default',
            minWidth: 100,
            flex: .2,
            renderCell(params) {
                const def = !!params.value;
                return def ? <Check /> : <Close />
            },
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key="save"
                            icon={<Save />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key="cancel"
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key="edit"
                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleDeleteClick(id)}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];


    return (
        <Box>
            <TitleBox
                avatarIcon={<PortraitOutlined />}
                mainTitle='Roles'
                maintitleVariant='h6'
                subTitle='Project related roles'>
                <Button variant="contained" onClick={handleRoleAdd} sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />}>
                    ADD ROLE
                </Button>
                <Popover
                    open={Boolean(addRoleAnchor)}
                    anchorEl={addRoleAnchor}
                    onClose={() => setAddRoleAnchor(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <AddProjectRoleForm
                        onSubmit={onProjectRoleSubmit}
                        projectId={projectId}
                        projectName={project?.name ?? ''}
                        submitLoading={projectRoleAddLoading}
                    />
                </Popover>
            </TitleBox>
            <Box sx={{ height: '500px', width: '100%' }}>
                <StyledDataGrid
                    rows={projectRoles}
                    columns={projectRoleColumns}
                    pageSizeOptions={[10]}
                    editMode='row'
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={setRowModesModel}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    disableRowSelectionOnClick
                />
            </Box>
        </Box>
    )

}