import React, { ReactNode } from 'react';
import { BlockStyle } from '../../../redux/blocks/types';
import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { Colors } from '../../../theme/Colors';

interface Props {
    baseBlockStyle: BlockStyle,
    attributeKey: string,
    handleBlockStyleDataReset: (attributeKey: string) => void,
    children: ReactNode,
}

const BlockStyleDataResetBadge = (props: Props) => {
    const { baseBlockStyle, attributeKey, handleBlockStyleDataReset, children } = props;
    const invisible = baseBlockStyle.blockStyleData.findIndex(x => x.attributeKey === attributeKey) < 0;

    return (
        <Badge
            badgeContent={
                <Tooltip title='Reset the current style.'>
                    <Box sx={{ 
                        height: '10px', 
                        width: '10px', 
                        cursor: 'pointer',
                        transition: 'all 300ms ease',
                        '&:hover': {
                            height: '15px', 
                            width: '15px', 
                        }
                        }} onClick={() => handleBlockStyleDataReset(attributeKey)}>

                    </Box>
                </Tooltip>
            }
            invisible={invisible}
            color="secondary"
            sx={{
                width: '100%',
                '& .MuiBadge-badge': {
                    width: 'auto',
                    height: 'auto',
                    minWidth: 0,
                    padding: 0,
                    '&:hover': {
                        backgroundColor: Colors.info_main,
                    }
                }
            }}>
            {children}
        </Badge >
    )
}

export default BlockStyleDataResetBadge;