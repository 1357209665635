/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React from 'react';
import { BlockData, ScreenMode } from '../../../redux/blocks/types';
import { DynDataEntry, DynDataSet } from '../../../redux/dyndata/types';
import Block from '../Block';
import { useAppSelector } from '../../../redux/hooks';
import { selectDynData } from '../../../redux/dyndata/dyndataSlice';

interface Props {
    blockData: BlockData,
    blocks: BlockData[];
    scrollHeight: number;
    canvasHeight: number;
    canvasMode: ScreenMode;
    dataEntries: DynDataEntry[];
}

const LoopBlock = (props: Props) => {

    const { blocks, blockData, canvasMode, dataEntries = [] } = props;
    const dataSets = useAppSelector(selectDynData);

    const dataSetId = blockData.blockAttributes.find(x => x.name === 'data-set')?.value;
    const dataSet = dataSets.find(x => x.id === Number(dataSetId));

    return (
        <>
            {blocks?.length ?
                dataSet?.dynDataEntries?.map((entry) => {

                    return blocks
                        .filter(x => x.locationPath?.split('-').length - 1 === blockData.locationPath?.split('-').length)
                        .sort((a, b) => a.sortPath - b.sortPath)
                        .map((block, index) => (
                            <Block
                                key={`${block.id}-${entry.id}`}
                                currentBlock={block}
                                blocks={blocks.filter(x => x.locationPath?.startsWith(block.locationPath + '-'))}
                                canvasMode={canvasMode}
                                dataEntries={[...dataEntries, entry]}
                            />
                        ))
                }) : null}
        </>
    );
}

export default LoopBlock;