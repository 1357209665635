import { Box } from "@mui/material";
import React from "react";
import { NodeProps } from "reactflow";
import BottomHandle from "../../helpers/BottomHandle";
import StartAction from "../../../../../../redux/actionsequence/types/actions/control/StartAction";
import createNodeComponent from "../../../../../../functions/createNodeComponent";

const StartNodeComponent = createNodeComponent<StartAction>(
  (props: NodeProps<StartAction>) => {
    const { id } = props;
    return (
      <Box key={id}>
        <BottomHandle />
      </Box>
    );
  }
);

export default StartNodeComponent;
