import { createTheme } from "@mui/material";
import { Colors } from "./Colors";

export const OuterTheme = createTheme({
    palette: {
      primary: {
        main: Colors.primary_main,        
      },
      secondary: {
        main: Colors.secondary_main
      },
      background: {
        default: Colors.background_default,
        paper: 'white'
      },
      common: {
        black: Colors.common_black,
      },
      info: {
        main: Colors.info_main,
      },
      text: {
        secondary: Colors.text_silver,
      }
    },
    typography: {
      fontSize: 12,
    }
  });
  