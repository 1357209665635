import {
  Box,
  Avatar,
  Typography,
  useTheme,
  TypographyVariant,
  Divider,
} from "@mui/material";
import React, { ReactNode } from "react";
import { Colors } from "../../theme/Colors";

interface Props {
  children?: ReactNode;
  avatarIcon?: ReactNode;
  mainTitle: string;
  subTitle?: string;
  maintitleVariant?: TypographyVariant;
  subTitleVariant?: TypographyVariant;
}

export default function TitleBox(props: Props) {
  const {
    children,
    avatarIcon,
    mainTitle,
    subTitle,
    maintitleVariant,
    subTitleVariant,
  } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {avatarIcon && (
        <Avatar
          sx={{
            backgroundColor: Colors.primary_main,
            alignSelf: "center",
            marginRight: theme.spacing(1),
          }}
        >
          {avatarIcon}
        </Avatar>
      )}
      <Box sx={{ flex: 1 }}>
        <Typography
          sx={{ fontWeight: "bold" }}
          variant={maintitleVariant ?? "h4"}
        >
          {mainTitle}
        </Typography>
        {subTitle && (
          <Typography variant={subTitleVariant ?? "subtitle1"}>
            {subTitle}
          </Typography>
        )}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
}
