import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  Alert,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  BlockData,
  BlockEvent,
  BlockStyle,
  EventBehaviour,
  NonSourceTriggerBehaviour,
} from "../../../../redux/blocks/types";
import { DeleteOutlined } from "@mui/icons-material";
import ConfirmationPopover from "../../../utils/ConfirmationPopover";
import {
  BackendError,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/hooks";
import {
  createBlockStyle,
  defaultBlockStyle,
  defaultEvent,
  deleteEvent,
  selectBlocks,
  selectPageEvents,
  selectSelectedBlock,
  updateEvent,
} from "../../../../redux/blocks/blockSlice";
import useRouteParam from "../../../../hooks/useRouteParam";
import yup from "../../../../validation/yup";
import SnackBarOperations from "../../../../components/SnackBar/SnackBarOperations";

import { Colors } from "../../../../theme/Colors";
import { useSelector } from "react-redux";
import { BlockInfoTabsContext } from "../BlockInfoTabs";
import prettyBlockString from "../../../../functions/utils/prettyBlockString";

interface Props {
  blockEvent: BlockEvent;
}

export default function BlockEventBox(props: Readonly<Props>) {
  const { blockEvent } = props;

  const pageId = useRouteParam("refId");
  const projectId = useRouteParam("projectId");

  const [confirmationPopoverOpen, setConfirmationPopoverOpen] =
    React.useState(false);
  const [currentBlockEvent, setCurrentBlockEvent] =
    React.useState<BlockEvent>(defaultEvent);

  const dispatch = useAppDispatch();

  const selectedBlock = useSelector(selectSelectedBlock);

  const blockHasEventSelectorStyle = React.useMemo(
    () =>
      (selectedBlock?.blockStyles ?? []).some((x) =>
        new RegExp(`^&(\\.${blockEvent.eventName})$`).test(x.selector)
      ),
    [selectedBlock, blockEvent.eventName]
  );

  React.useEffect(() => {
    console.log(
      selectedBlock?.blockStyles.map((x) => x.selector),
      blockHasEventSelectorStyle,
      blockEvent.eventName
    );
  }, [selectedBlock]);

  const pageEvents = useAppSelector(selectPageEvents(pageId));

  const pageBlocks = useAppSelector(selectBlocks);

  const onlyTriggerFrom = React.useMemo(
    () => blockEvent.onlyTriggerFrom ?? [],
    [blockEvent]
  );

  const relevantEventBlocks = React.useMemo(() => {
    return pageBlocks
      .filter((x) => onlyTriggerFrom.includes(x.id))
      .reduce((accumulator, block) => {
        accumulator[block.id] = block;
        return accumulator;
      }, {} as Record<number, BlockData>);
  }, [pageBlocks, onlyTriggerFrom]);

  React.useEffect(() => {
    setCurrentBlockEvent(blockEvent);
  }, [blockEvent]);

  const getEventValidationSchema = React.useCallback(
    (id: number) =>
      yup.object().shape({
        eventName: yup.string().unique(
          "There already exists an event with this name on the same page.",
          (eventName: string) =>
            !pageEvents
              .filter((x) => x.id !== id)
              .map((x) => x.eventName)
              .includes(eventName)
        ),
      }),
    [pageEvents]
  );

  const handleConfirmationPopoverClose = React.useCallback(() => {
    setConfirmationPopoverOpen(false);
  }, [setConfirmationPopoverOpen]);

  const handleConfirmationPopoverSubmit = React.useCallback(() => {
    setConfirmationPopoverOpen(false);
    dispatch(deleteEvent(blockEvent.id));
  }, [setConfirmationPopoverOpen, dispatch, blockEvent]);

  const handleBlockEventDelete = React.useCallback(() => {
    setConfirmationPopoverOpen(true);
  }, [setConfirmationPopoverOpen]);

  const updateBlockEvent = React.useCallback(
    async (be: BlockEvent) => {
      try {
        //await getEventValidationSchema(be.id).validate(be);
        await dispatch(updateEvent(be));
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          SnackBarOperations.error(err.message);
        }
        if (err instanceof BackendError) {
          SnackBarOperations.error(
            `Error updating event ${be.eventName}: ${err.message}`
          );
        }
        console.error(err);
      }
    },
    [getEventValidationSchema, dispatch]
  );

  const handleBlockEventChange = React.useCallback(
    async (blockEvent: BlockEvent) => {
      await updateBlockEvent(blockEvent);
    },
    [updateBlockEvent]
  );

  const handleBlockEventSave = React.useCallback(async () => {
    await updateBlockEvent(currentBlockEvent);
  }, [updateBlockEvent, currentBlockEvent]);

  const theme = useTheme();

  const { setTab } = React.useContext(BlockInfoTabsContext);

  const handleCreateSelectorForEvent = React.useCallback(() => {
    const newStyle: BlockStyle = {
      ...defaultBlockStyle,
      selector: `&.${blockEvent.eventName}`,
      blockDataId: selectedBlock?.id,
      projectId,
    };
    dispatch(createBlockStyle(newStyle))
      .unwrap()
      .then(() => {
        // switch to style tab
        setTab("2");
      });
  }, [setTab, dispatch, blockEvent.eventName]);

  return (
    <Grid container spacing={theme.spacing(3)}>
      <Grid item xs={10}>
        <TextField
          id="outlined-basic"
          fullWidth
          label="Name"
          size="small"
          variant="filled"
          value={currentBlockEvent.eventName}
          onChange={(e) =>
            setCurrentBlockEvent({
              ...currentBlockEvent,
              eventName: e.target.value,
            })
          }
          onBlur={handleBlockEventSave}
        />
      </Grid>
      <Grid
        item
        xs={2}
        sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
      >
        <ConfirmationPopover
          handleClose={handleConfirmationPopoverClose}
          handleSubmit={handleConfirmationPopoverSubmit}
          open={confirmationPopoverOpen}
        >
          <IconButton
            onClick={() => handleBlockEventDelete()}
            component="label"
          >
            <DeleteOutlined />
          </IconButton>
        </ConfirmationPopover>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              value={currentBlockEvent.unTriggerOnClickAway}
              onChange={(e, checked) =>
                handleBlockEventChange({
                  ...currentBlockEvent,
                  unTriggerOnClickAway: checked,
                })
              }
            />
          }
          labelPlacement="start"
          label="Untrigger on click away"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="filled">
          <InputLabel id="select-eventbeh-label" shrink>
            Event behaviour
          </InputLabel>
          <Select
            labelId="select-eventbeh-label"
            id="select-eventbeh"
            value={currentBlockEvent.behaviour}
            onChange={(e) => {
              const val = e.target.value;
              handleBlockEventChange({
                ...currentBlockEvent,
                behaviour: val as EventBehaviour,
              });
            }}
          >
            <MenuItem value={EventBehaviour.Default}>
              <Tooltip
                title={<Typography>Event can run multiple times</Typography>}
                placement="right"
              >
                <span>Default</span>
              </Tooltip>
            </MenuItem>
            <MenuItem value={EventBehaviour.Once}>
              <Tooltip
                title={<Typography>Event can run only once</Typography>}
                placement="right"
              >
                <span>Once</span>
              </Tooltip>
            </MenuItem>
            <MenuItem value={EventBehaviour.ToggleSource}>
              <Tooltip
                title={
                  <Typography>
                    Event will be toggled only when triggered from specific
                    source blocks
                  </Typography>
                }
                placement="right"
              >
                <span>Toggle Source</span>
              </Tooltip>
            </MenuItem>
            <MenuItem value={EventBehaviour.ToggleInvariant}>
              <Tooltip
                title={
                  <Typography>
                    Event can run multiple times. Toggles when run multiple
                    times.
                  </Typography>
                }
                placement="right"
              >
                <span>Toggle</span>
              </Tooltip>
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          dispay:
            blockEvent.behaviour === EventBehaviour.ToggleSource
              ? "unset"
              : "none",
        }}
      >
        <FormControl
          fullWidth
          variant="filled"
          sx={{
            display:
              blockEvent.behaviour !== EventBehaviour.ToggleSource
                ? "none"
                : undefined,
          }}
        >
          <InputLabel id="select-trigger-only-from" shrink>
            Trigger only from
          </InputLabel>
          <Select
            labelId="select-trigger-only-from"
            id="select-tr-only-from"
            multiple
            label="Trigger only from"
            value={onlyTriggerFrom}
            onChange={(e) =>
              handleBlockEventChange({
                ...blockEvent,
                onlyTriggerFrom: e.target.value as number[],
              })
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {selected
                  .map((x: number) => relevantEventBlocks[x])
                  .filter((x: BlockData) => x)
                  .map((val: BlockData) => {
                    const str = prettyBlockString(val);
                    return (
                      <Chip
                        sx={{ mb: theme.spacing(0.5), mr: theme.spacing(0.5) }}
                        variant="outlined"
                        key={val.id}
                        label={str}
                      />
                    );
                  })}
              </Box>
            )}
          >
            {pageBlocks.map((pageBlock) => (
              <MenuItem
                key={pageBlock.id}
                value={pageBlock.id}
                style={{
                  color: onlyTriggerFrom.includes(pageBlock.id)
                    ? Colors.primary_main
                    : "unset",
                }}
              >
                {prettyBlockString(pageBlock)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          dispay:
            blockEvent.behaviour === EventBehaviour.ToggleSource
              ? "unset"
              : "none",
        }}
      >
        <FormControl fullWidth variant="filled">
          <InputLabel id="select-eventnonsourcetrigger-label" shrink>
            When triggered from other sources
          </InputLabel>
          <Select
            labelId="select-eventnonsourcetrigger-label"
            id="select-nonsourcetriggerbeh"
            value={currentBlockEvent.nonSourceTriggerBehaviour}
            onChange={(e) => {
              const val = e.target.value;
              handleBlockEventChange({
                ...currentBlockEvent,
                nonSourceTriggerBehaviour: val as NonSourceTriggerBehaviour,
              });
            }}
          >
            <MenuItem value={NonSourceTriggerBehaviour.DoNothing}>
              <Tooltip
                title={
                  <Typography>
                    Do nothing if the event is triggered from a source other
                    than 'Only trigger from'
                  </Typography>
                }
                placement="right"
              >
                <span>Do nothing</span>
              </Tooltip>
            </MenuItem>
            <MenuItem value={NonSourceTriggerBehaviour.Untrigger}>
              <Tooltip
                title={
                  <Typography>
                    Untrigger the event if it is triggered from a source other
                    than 'Only trigger from'
                  </Typography>
                }
                placement="right"
              >
                <span>Untrigger</span>
              </Tooltip>
            </MenuItem>
            <MenuItem value={NonSourceTriggerBehaviour.Trigger}>
              <Tooltip
                title={
                  <Typography>
                    Trigger the event if it is triggered from a source other
                    than 'Only trigger from'
                  </Typography>
                }
                placement="right"
              >
                <span>Trigger</span>
              </Tooltip>
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: blockHasEventSelectorStyle ? "none" : undefined }}
      >
        <Alert severity="info">
          <Typography>
            This block may not have a selector for this event.
          </Typography>
          <Typography>
            You can{" "}
            <Box
              component="span"
              sx={{ color: Colors.primary_main, cursor: "pointer" }}
              onClick={handleCreateSelectorForEvent}
            >
              create the selector '<i>{`&.${blockEvent.eventName}`}</i>' on this
              block
            </Box>
          </Typography>
        </Alert>
      </Grid>
    </Grid>
  );
}
