import {
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { uniqueFilter } from "../../Utility";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import {
  AuthenticateResponse,
  GlobalObjectActions,
  GlobalObjectTypes,
  GlobalRole,
  ProjectRole,
  User,
} from "./types";

const PREFIX = "auth";

export interface AuthState {
  token: string;
  userId: string | null;
  user: User | null;
  loading: boolean;
  authRedirectPath: string;
}

export const initialState: AuthState = {
  userId: null,
  token: "",
  user: null,
  loading: false,
  authRedirectPath: "/",
};

export const authenticate = createAppAsyncThunk(
  `${PREFIX}/login`,
  async (args: { username: string; password: string }) => {
    const response = await axios.post<AuthenticateResponse>(
      "/authentication",
      args
    );
    return response.data;
  }
);

export const refreshToken = createAppAsyncThunk(
  `${PREFIX}/refresh`,
  async () => {
    const response = await axios.get<AuthenticateResponse>(
      "/authentication/refresh-token"
    );
    return response.data;
  }
);

const authSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string | null>) => {
      state.userId = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.userId = null;
      state.token = "";
    },
    setRedirectPath: (state, action: PayloadAction<string>) => {
      state.authRedirectPath = action.payload;
    },
    addProjectRole: (state, action: PayloadAction<ProjectRole>) => {
      if (state.user) {
        state.user.projectRoles.push(action.payload);
      }
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authenticate.fulfilled, (state, action) => {
      state.token = action.payload.jwtToken;
      state.userId = action.payload.username;
      state.user = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        username: action.payload.username,
        id: action.payload.id,
        projectRoles: action.payload.projectRoles,
        userRoles: action.payload.userRoles,
        email: action.payload.email,
        lastLogin: action.payload.lastLogin,
        isActive: action.payload.isActive,
      };
    });

    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.token = action.payload.jwtToken;
      state.userId = action.payload.username;
      state.user = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        username: action.payload.username,
        id: action.payload.id,
        projectRoles: action.payload.projectRoles,
        userRoles: action.payload.userRoles,
        email: action.payload.email,
        lastLogin: action.payload.lastLogin,
        isActive: action.payload.isActive,
      };
    });

    // base cases

    // sets loadind to 'true' while request is pending for all thunks
    builder.addMatcher(isPending, (state) => {
      state.loading = true;
    });

    // logs error if a request is rejected
    builder.addMatcher(isRejected, (_, action) => {
      console.error("rejected: ", action.error);
    });

    // sets loading to 'false' if a request completed (rejected or resolved)
    builder.addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
      state.loading = false;
    });
  },
});

export const {
  logout,
  setRedirectPath,
  setToken,
  setUserId,
  addProjectRole,
  setUser,
} = authSlice.actions;

// selectors
export const selectIsAuthenticated = (rootState: RootState): boolean =>
  rootState.auth.token !== "";
export const selectUser = (rootState: RootState): User | null =>
  rootState.auth.user;
export const selectUserRoles = (rootState: RootState): GlobalRole[] =>
  rootState.auth.user?.userRoles ?? [];
export const selectProjectRoles =
  (projectId: number) => (rootState: RootState) =>
    rootState.auth.user
      ? rootState.auth.user.projectRoles.filter(
          (x) => x.projectDataId === projectId
        )
      : [];
export const selectProjectPermissions =
  (projectId: number) => (rootState: RootState) =>
    selectProjectRoles(projectId)(rootState)
      .flatMap((x) => x.permissions)
      .filter(uniqueFilter);

export const selectUserCan =
  (action: GlobalObjectActions, objectType: GlobalObjectTypes) =>
  (rootState: RootState) => {
    const roles = rootState.auth.user?.userRoles.flatMap((x) => x.permissions);
    if (!roles) return false;

    const admin = [`${objectType}_ADMIN`];
    const deleter = [...admin, `${objectType}_DELETER`];
    const editor = [...deleter, `${objectType}_EDITOR`];
    const viewer = [...editor, `${objectType}_VIEWER`];

    switch (action) {
      case "VIEW":
        return roles.some((x) => viewer.includes(x));
      case "EDIT":
        return roles.some((x) => editor.includes(x));
      case "DELETE":
        return roles.some((x) => deleter.includes(x));
      default:
        return false;
    }
  };

export default authSlice.reducer;
