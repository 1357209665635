import { TimeStampEntry } from "../../Utility";

export interface TemplateData extends TimeStampEntry {
    id: number,
    name: string,
    projectId?: number,
    systemId: string,
}

export enum TemplateType{
    local,
    global,
    system,
}
