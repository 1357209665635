import { useContext } from "react";
import { ActionExecutionContext } from "../context/blockActions/ActionExecutionContext";

/**
 * Use the action debugging context with this hook
 * Can be used to read debugging state, step and reset
 */
const useActionDebugContext = () => {
  return useContext(ActionExecutionContext);
};

export default useActionDebugContext;
