import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import TopHandle from "../../helpers/TopHandle";
import BottomHandle from "../../helpers/BottomHandle";
import React from "react";
import clsx from "clsx";
import createNodeComponent from "../../../../../../functions/createNodeComponent";
import IfElseAction, {
  IfElseOperator,
} from "../../../../../../redux/actionsequence/types/actions/control/IfElseAction";
import { HandleType } from "../../../../../../redux/actionsequence/types/helpers/ActionConnection";

const IfElseNodeComponent = createNodeComponent<IfElseAction>((props) => {
  const { onChange, actionData, errors } = props;

  return (
    <>
      <TopHandle />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ["&>.formItem"]: {
            marginBottom: "20px",
          },
        }}
      >
        <TextField
          className={clsx("nodrag", "formItem")}
          color="primary"
          fullWidth
          label="Action name"
          defaultValue={actionData.name}
          size="small"
          variant="filled"
          required
          error={"name" in errors}
          helperText={errors.name}
          onChange={(ev) => onChange("name", ev.target.value)}
        />

        <TextField
          className="nodrag"
          color="primary"
          fullWidth
          label="Left"
          defaultValue={actionData.left ?? ""}
          size="small"
          variant="filled"
          required
          error={"left" in errors}
          helperText={errors.left}
          onChange={(ev) => onChange("left", ev.target.value)}
        />
        <FormControl
          className={"nodrag"}
          variant="filled"
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="select-request-method-input-label">
            Operator
          </InputLabel>
          <Select
            labelId="select-request-method-input-label"
            value={actionData.operator}
            onChange={(ev) => {
              onChange("operator", ev.target.value as IfElseOperator);
            }}
            label="Operator"
            error={errors.operator !== undefined}
          >
            <MenuItem value={"=="}>{"=="}</MenuItem>
            <MenuItem value={"!="}>{"!="}</MenuItem>
            <MenuItem value={">"}>{">"}</MenuItem>
            <MenuItem value={"<"}>{"<"}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          className="nodrag"
          color="primary"
          fullWidth
          label="Right"
          defaultValue={actionData.right ?? ""}
          size="small"
          variant="filled"
          required
          error={"right" in errors}
          helperText={errors.right}
          onChange={(ev) => onChange("right", ev.target.value)}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          paddingBottom: "20px",
          marginTop: "30px",
        }}
      >
        <Typography
          sx={{ position: "absolute", display: "inline-block", left: "4%" }}
          color="primary"
        >
          TRUE
        </Typography>
        <Typography
          sx={{ position: "absolute", display: "inline-block", right: "2%" }}
          color="primary"
        >
          FALSE
        </Typography>
      </Box>
      <BottomHandle
        style={{
          left: "15%",
        }}
        id={HandleType.IfElseTrue}
      />
      <BottomHandle
        style={{
          left: "85%",
        }}
        id={HandleType.IfElseFalse}
      />
    </>
  );
});

export default IfElseNodeComponent;
