import React, { useEffect } from "react";
import TopHandle from "../../helpers/TopHandle";
import { Box, TextField } from "@mui/material";
import BottomHandle from "../../helpers/BottomHandle";
import useActionDebugContext from "../../../../../../hooks/useActionDebugContext";
import createNodeComponent from "../../../../../../functions/createNodeComponent";
import SetVariableAction from "../../../../../../redux/actionsequence/types/actions/data/SetVariableAction";

const SetVariableComponent = createNodeComponent<SetVariableAction>((props) => {
  const { actionData, errors, onChange } = props;

  const { processState } = useActionDebugContext();

  useEffect(() => console.log(actionData.expression), [actionData.expression]);

  return (
    <>
      <TopHandle />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ["&>.MuiTextField-root"]: {
            marginBottom: "20px",
          },
        }}
      >
        <TextField
          className="nodrag"
          color="primary"
          fullWidth
          label="Action name"
          defaultValue={actionData.name}
          size="small"
          variant="filled"
          required
          error={"name" in errors}
          helperText={errors.name}
          onChange={(ev) => onChange("name", ev.target.value)}
        />
        <TextField
          className="nodrag"
          color="primary"
          fullWidth
          label="Variable name"
          defaultValue={actionData.variableName}
          size="small"
          variant="filled"
          required
          error={"variableName" in errors}
          helperText={errors.variableName}
          onChange={(ev) => onChange("variableName", ev.target.value)}
        />

        <TextField
          className="nodrag"
          color="primary"
          fullWidth
          label="Expression"
          defaultValue={actionData.expression ?? ""}
          size="small"
          variant="filled"
          required
          error={"expression" in errors}
          helperText={errors.expression}
          onChange={(ev) => onChange("expression", ev.target.value)}
        />
      </Box>
      <BottomHandle />
    </>
  );
});

export default SetVariableComponent;
