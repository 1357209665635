import { Add, Check, Close, DeleteOutlined, PortraitOutlined } from '@mui/icons-material';
import { Button, Chip, Grid, IconButton, Paper, Popover, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { changeUserState, getUsers, selectUsers } from '../../redux/users/usersSlice';
import TitleBox from '../widgets/TitleBox';
import { selectUser } from '../../redux/auth/authSlice';
import { useNavigate } from 'react-router';
import StyledDataGrid from '../utils/StyledDataGrid';
import AddUserForm from './AddUserForm';
import MorePopover from '../utils/MorePopOver';
import { userGridColDefs } from '../utils/CommonGridColDefs';
import { GlobalRole } from '../../redux/auth/types';
import { getFormattedDate } from '../../Utility';

export default function Users() {

    const theme = useTheme();
    const dispatch = useAppDispatch();
    const nav = useNavigate();
    const [loading, setLoading] = React.useState(false);

    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);
    const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLButtonElement | null>(null);
    const [adduserAnchor, setAddUserAnchor] = React.useState<HTMLButtonElement | null>(null);

    const users = useAppSelector(selectUsers);
    const me = useAppSelector(selectUser);
    
    React.useEffect(() => {
        setLoading(true);
        dispatch(getUsers())
        .finally(() => setLoading(false));
    }, [setLoading, dispatch]);
    
    const amISelected = React.useMemo(() => {
        if (me) {
            return selectedIds.includes(me?.id);
        }
        return false;
    }, [me, selectedIds]); 

    const handleAddUserClick = React.useCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
        setAddUserAnchor(ev.currentTarget);
    }, [setAddUserAnchor])

    const handleAddUserClose = React.useCallback(() => {
        setAddUserAnchor(null);
    }, [setAddUserAnchor]);

    const datatableColumns : GridColDef[] = [
        ...userGridColDefs(me?.username),
        {
            field: 'lastLogin',
            headerName: 'Last login',
            minWidth: 100,
            flex: 1,
            renderCell(params) {
                const lastLogin: number | undefined = params.value;
                if (lastLogin)
                    return getFormattedDate(lastLogin, true);
                return 'never';
            }
        },
        {
            field: 'isActive',
            headerName: 'Active',
            minWidth: 50,
            flex: .4,
            renderCell(params) {
                const isActive = params.value ? true : false;
                return isActive ? <Check/> : <Close/>
            },
        },
        {
            field: 'userRoles',
            headerName: 'Global roles',
            minWidth: 200,
            flex: 2,
            renderCell(params) {
                const roles = params.value as GlobalRole[];
                return <Box sx={{
                    margin: '3px',
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>
                    {roles.map((r) => (
                        <Chip
                         size='small'
                         sx={{
                            mr: '3px',
                            mb: '2px'
                         }}
                         label={r.name}
                        />
                    ))}
                </Box>
            },
            filterable: false,
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: .5,
            renderCell(params) {
                const isActiveNow = params.row.isActive ? true : false;
                return (
                    <MorePopover
                        actions={[
                            {
                                action() {
                                    nav(`/users/${params.row.id}`)
                                },
                                text: "Edit"
                            },
                            {
                                action() {
                                    dispatch(changeUserState({
                                        userId: params.row.id,
                                        state: !isActiveNow,
                                    }));
                                },
                                text: isActiveNow ? 'Deactivate' : 'Set active',
                                customStyles: {
                                    color: isActiveNow ? theme.palette.error.main : 'unset'
                                },
                                disabled: me?.id === params.row.id
                            }
                        ]}
                    />
                )
            }
        }
    ];

    return (
        <>
            <TitleBox
                avatarIcon={<PortraitOutlined />}
                mainTitle='User management'
                subTitle='Edit contact details and permissions'
                key={'users-main-page'}
            />
            <Grid container spacing={3}>
                <Paper sx={{ width: '100%', margin: theme.spacing(3), marginTop: theme.spacing(4), padding: theme.spacing(2)}}>
                    <TitleBox
                        mainTitle='All users'
                        maintitleVariant='h6'
                        subTitle='Every user on the application'
                        subTitleVariant='subtitle2'
                        key={'users-main-page-table'}
                    >
                        <IconButton disabled={amISelected} component="label">
                            <DeleteOutlined />
                        </IconButton>
                        <Popover
                            open={Boolean(adduserAnchor)}
                            anchorEl={adduserAnchor}
                            onClose={handleAddUserClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <AddUserForm
                                afterAddUser={handleAddUserClose} 
                            />
                        </Popover>
                        <Button 
                            variant="contained" 
                            sx={{ marginLeft: theme.spacing(1) }} 
                            endIcon={<Add />}
                            onClick={handleAddUserClick}
                        >
                            ADD USER
                        </Button>
                    </TitleBox>
                    <Box sx={{ height: '500px', width: '100%'}}>
                        <StyledDataGrid
                            rows={users}
                            columns={datatableColumns}
                            pageSizeOptions={[10]}
                            checkboxSelection
                            onRowSelectionModelChange={(newSM) => {
                                setSelectedIds(newSM);
                            }}
                            rowSelectionModel={selectedIds}
                            disableRowSelectionOnClick
                            loading={loading}
                        />
                    </Box>
                </Paper>
            </Grid>
        </>
    )
}