import React from 'react';
import { useAppSelector } from "../../redux/hooks";
import { selectGlobalTemplates } from "../../redux/template/templateSlice";
import Template from "./Template";

export default function GlobalTemplates() {
    const templates = useAppSelector(selectGlobalTemplates);

    return (
        <Template templates={templates} subTitle={'Manage your global templates.'}/>
    );
}