/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';

import React from 'react';
import { BlockData, ScreenMode } from '../../redux/blocks/types';
import { DynDataEntry } from '../../redux/dyndata/types';
import Block from './Block';
import { useAppSelector } from '../../redux/hooks';
import { selectBlockEvents } from '../../redux/blocks/blockSlice';

interface Props {
    blocks: BlockData[];
    canvasMode: ScreenMode;
    dataEntries: DynDataEntry[];
}

const BlockWall = (props: Props) => {

    const { blocks, canvasMode, dataEntries } = props;

    const displayedBlocks = React.useMemo(() => {
       return blocks?.filter(x => x.locationPath?.indexOf('-') === -1)
        .sort((a, b) => a.sortPath - b.sortPath)
        .map((block, index) => (
            <Block
                key={block.id}
                currentBlock={block}
                blocks={blocks.filter(x => x.locationPath?.startsWith(block.id + '-'))}
                canvasMode={canvasMode}
                dataEntries={dataEntries}
            />
        ));
    }, [blocks, canvasMode, dataEntries]);

    return (
        <>
            {displayedBlocks}
        </>
    );
}

export default BlockWall;