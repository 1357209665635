import React, {
  ComponentType,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import NodeWrapper from "../helpers/NodeWrapper";
import { produce } from "immer";
import actions, {
  ActionType,
  AnyAction,
} from "../../../../../redux/actionsequence/types/actions";
import BaseAction from "../../../../../redux/actionsequence/types/actions/BaseAction";
import SequenceContext from "../../../../../context/SequenceContext";
import { AnyObjectSchema, ValidationError } from "yup";
import yup from "../../../../../validation/yup";
import { NodeProps } from "reactflow";
import { Typography } from "@mui/material";

type ForwardedNodeProps<ACT> = {
  /**
   * This function changes the field of an action
   * in the backend
   */
  onChange: <K extends keyof ACT>(prop: K, change: ACT[K]) => Promise<void>;
  /**
   * This data is reactive, so use this one instead of 'data'
   * for things like text fields etc.
   */
  actionData: ACT;
  /**
   * Contains any errors that may arise when changing action data.
   * This is used to identify if there is anything wrong with the way
   * the action is configured right now
   */
  errors: {
    [K in keyof ACT]?: string;
  };
};

export type NodeComponentProps<ACT> = ForwardedNodeProps<ACT> & NodeProps<ACT>;

const asNode = <ACT extends AnyAction, T extends NodeComponentProps<ACT>>(
  Component: ComponentType<T>
) => {
  return (hocProps: Omit<T, "onChange" | "actionData" | "errors">) => {
    type ErrorState = {
      [K in keyof ACT]?: string;
    };

    const { data, id } = hocProps;

    const [actionData, setActionData] = useState<ACT>(data);
    const [errors, setErrors] = useState<ErrorState>({});

    const { updateNodeData } = useContext(SequenceContext);

    const validate = useCallback(
      async <K extends keyof ACT>(prop: K, change: ACT[K]) => {
        const newData = produce(actionData, (draft) => {
          //@ts-ignore
          draft[prop] = change;
          return draft;
        });

        const baseSchema: yup.SchemaOf<Omit<BaseAction, "id">> = yup
          .object()
          .shape({
            name: yup.string().required("Name is required"),
            type: yup.mixed().oneOf(Object.values(ActionType)).required(),
          });

        try {
          const schema = actions[actionData.type].validationSchema;
          if (schema) {
            await baseSchema
              .concat(schema as AnyObjectSchema)
              .validate(newData, { abortEarly: false });
          } else {
            await baseSchema.validate(newData, { abortEarly: false });
          }
        } catch (err) {
          if (err instanceof ValidationError) {
            const inner = err.inner;
            setErrors((prev) => {
              inner.forEach((ie) => {
                prev[ie.path as K] = ie.message;
              });
              return prev;
            });
            return;
          }
        }

        setErrors((prev) => {
          delete prev[prop];
          return prev;
        });
      },
      [setErrors, actionData]
    );

    const onChange = useCallback(
      async <K extends keyof ACT>(prop: K, change: ACT[K]) => {
        await validate(prop, change);
        setActionData((ad) => {
          return produce(ad, (newData) => {
            //@ts-ignore
            newData[prop] = change;
            return newData;
          });
        });
      },
      [validate]
    );

    useEffect(() => {
      const hasErrors = Object.keys(errors).length > 0;
      // Debounced save
      const t = setTimeout(() => {
        if (!hasErrors) {
          updateNodeData(id, actionData);
        }
      }, 1000);

      return () => clearTimeout(t);
    }, [actionData, id]);

    const title = useMemo(() => {
      return actions[data.type].name;
    }, [data]);

    const tooltip = useMemo(() => {
      const tt = actions[data.type].description;
      if (typeof tt == "string") {
        return <Typography>{tt}</Typography>;
      }
      return tt;
    }, [data.type]);

    return (
      <NodeWrapper
        id={id}
        title={title}
        isStart={data.type === ActionType.Start}
        info={tooltip}
      >
        <Component
          {...(hocProps as T)}
          onChange={onChange}
          actionData={actionData}
          errors={errors}
        />
      </NodeWrapper>
    );
  };
};

export default asNode;
