import React from 'react';
import { BlockAttribute, BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import { ViewDay, ViewColumn, GridView, ViewArray, FormatColorText, VisibilityOff, VerticalAlignBottom, VerticalAlignCenter, Expand, Clear, Start, KeyboardTab, ZoomOutMap, FormatListBulleted, FormatListNumbered, List, SortByAlpha, ViewWeek } from '@mui/icons-material';
import { Grid, Typography, Box, ToggleButtonGroup, ToggleButton, TextField, Tooltip } from '@mui/material';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import { getStyleById, isStyleInheritedAndChanged } from '../../../../../../Utility';
import StyleToggleButtonGroup from '../../../../../utils/Inputs/StyleToggleButtonGroup';
import { BlockStyleAttribute } from '../BlockStyleInputs';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void, 
    selectedBlock?: BlockData
}

export default function ListElementInputs(props: Props) {
    return (
        <>
            <BlockStyleDataLabel name="Style" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='list-style-type'>
                    <ToggleButton value="none" key="none">
                        <Tooltip title="No marker">
                            <Clear />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="disc" key="disc">
                        <Tooltip title="Circle marker">
                            <FormatListBulleted />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="square" key="square">
                        <Tooltip title="Square marker">
                            <List />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="decimal" key="decimal">
                        <Tooltip title="Decimal numbers">
                            <FormatListNumbered />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="lower-alpha" key="lower-alpha">
                        <Tooltip title="Lowercase alphabetic">
                            <ViewWeek />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="lower-roman" key="lower-roman">
                        <Tooltip title="Lowercase Roman numerals">
                            <Clear />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
        </>
    );
}