import { TextField, InputAdornment, Select, MenuItem, IconButton, Popover, List, ListItem, ListItemButton, ListItemText, FilledInput, FormControl, ToggleButtonGroup } from '@mui/material';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { BlockData, BlockStyle } from '../../../redux/blocks/types';
import { extractNumber, getStyleById, isStyleInheritedAndChanged } from '../../../Utility';
import { add, debounce } from 'lodash';
import { BlockStyleAttribute } from '../../builder/BlockInfo/BlockDetail/BlockStyleEdit/BlockStyleInputs';
import BlockStyleDataResetBadge from './BlockStyleDataResetBadge';

interface Props {
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], save?: boolean, ignoreDefault?: boolean) => void,
    attributeKey: string,
    selectedBlock?: BlockData
    children: ReactNode,
    handleBlockStyleDataReset: (attributeKey: string) => void,
    baseBlockStyle: BlockStyle,
    additionalStyle?: any,
}

export default function StyleToggleButtonGroup(props: Props) {
    const { currentBlockStyle, attributeKey, handleAttributeChange, selectedBlock, children, additionalStyle } = props;

    return (
        <BlockStyleDataResetBadge {...props}>
            <ToggleButtonGroup
                value={currentBlockStyle.blockStyleData.find(x => x.attributeKey === attributeKey)?.attributeValue ?? getStyleById(selectedBlock, attributeKey)}
                onChange={(e, value) => handleAttributeChange([{ attributeKey, attributeValue: value }], true)}
                exclusive={true}
                size='small'
                fullWidth
                sx={{...additionalStyle}}>
                {children}
            </ToggleButtonGroup>
        </BlockStyleDataResetBadge>
    );
}