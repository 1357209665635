import React from 'react';
import { Box, Button, Popover, useTheme } from '@mui/material';
import { ColorLens } from '@mui/icons-material';
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';

interface Props {
    gradient: string,
    setBackground: (background: string) => void, 
}

const defaultGradient = 'linear-gradient(90deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)';

export default function GradientBackground(props: Props) {
    const { gradient, setBackground } = props;

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [color, setColor] = React.useState(defaultGradient);
    const { setGradient } = useColorPicker(color, setColor);
    
    const theme = useTheme();

    React.useEffect(() => {
        const background = gradient.startsWith('radial') || gradient.startsWith('linear') ? gradient : defaultGradient;

        setGradient(background);
        setColor(background);
    }, [gradient])

    const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(!open);
        setAnchorEl(event.currentTarget);
    }, [setOpen, setAnchorEl, open]);

    const handleClose = React.useCallback(() => {
        setBackground(color);
        setOpen(false);
    }, [setOpen, color]);

    return (
        <div>
            <Button
                variant='contained'
                style={{ backgroundImage: gradient }}
                onClick={handleClick}
                fullWidth
                endIcon={<ColorLens/>}
            >
            </Button>
            <Popover
                id="color-picker"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ padding: theme.spacing(1) }}>
                    <ColorPicker
                        value={color}
                        onChange={setColor}
                        hideColorGuide
                        hideAdvancedSliders
                        hideInputType 
                        hideColorTypeBtns
                        hideEyeDrop/>
                </Box>
            </Popover>
        </div>
    )
}