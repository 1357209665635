import { Box } from '@mui/system';
import React from 'react';
import ProjectRoles from './ProjectRoles';
import ProjectUsers from './ProjectUsers';

interface Props {
    projectId: number
}

export default function ProjectUsersAndRoles(props: Props) {
    const { projectId } = props

    return (
        <Box>
            <ProjectRoles projectId={projectId} />
            <ProjectUsers projectId={projectId} />
        </Box>
    )
}