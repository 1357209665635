import { Add, Delete, DeleteOutlined, Edit, LanguageOutlined, TextSnippetOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, useTheme } from '@mui/material';
import React, { useState } from 'react';
import TitleBox from '../../widgets/TitleBox';
import { createRequest, defaultRequest, deleteRequest, getRequests, selectRequests } from '../../../redux/requests/requestSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { GridActionsCellItem, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { createModel, defaultModel, deleteModel } from '../../../redux/dyndata/dyndataSlice';
import ConfirmationPopover from '../../utils/ConfirmationPopover';
import MorePopover from '../../utils/MorePopOver';
import StyledDataGrid from '../../utils/StyledDataGrid';
import { useNavigate } from 'react-router';
import { BlockRequest } from '../../../redux/requests/types';
import DeleteConfirmationDialog from '../../utils/DeleteConfirmationDialog';

interface Props {
    projectId: number
}

export default function Requests(props: Props) {
    const { projectId } = props;
    const [requestConfirmationPopoverOpen, setRequestConfirmationPopoverOpen] = useState(false);
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);

    const theme = useTheme();
    const dispatch = useAppDispatch();

    const requests = useAppSelector(selectRequests);
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(getRequests(projectId));
    }, []);

    const handleRequestCreate = React.useCallback(() => {
        dispatch(createRequest({...defaultRequest, projectId}))
            .unwrap()
            .then(payload => {
                navigate(`/project-detail/${projectId}/settings/request/${payload.id}`)
            });
    }, [dispatch, projectId, navigate]);

    const handleRequestDelete = React.useCallback((id: number) => {
        dispatch(deleteRequest(id));
    }, [dispatch]);

    const handleRequestEdit = React.useCallback((id: number) => {
        navigate(`/project-detail/${projectId}/settings/request/${id}`);
    }, [navigate, projectId]);

    const handleRequestConfirmationPopoverSubmit = React.useCallback(() => {
        selectedIds.forEach(x => dispatch(deleteRequest(Number(x))));
        setRequestConfirmationPopoverOpen(false);
    }, [selectedIds, setRequestConfirmationPopoverOpen, dispatch])

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                return [
                    <GridActionsCellItem
                        key="edit"

                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={() => handleRequestEdit(Number(id))}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={() => handleRequestDelete(Number(id))}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}                            
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Requests'
                subTitle='Lorem ipsum'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>
                <ConfirmationPopover
                    handleClose={() => setRequestConfirmationPopoverOpen(false)}
                    handleSubmit={handleRequestConfirmationPopoverSubmit}
                    open={requestConfirmationPopoverOpen}>
                    <IconButton component="label" onClick={() => setRequestConfirmationPopoverOpen(true)}>
                        <DeleteOutlined />
                    </IconButton>
                </ConfirmationPopover>
                <Button variant="contained" sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />} onClick={handleRequestCreate}>
                    ADD REQUEST
                </Button>
            </TitleBox>
            <Box sx={{ height: '600px', width: '100%', mb: theme.spacing(2) }}>
                <StyledDataGrid
                    rows={requests}
                    columns={columns}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    onRowSelectionModelChange={(newSM) => {
                        setSelectedIds(newSM);
                    }}
                    rowSelectionModel={selectedIds}
                    disableRowSelectionOnClick
                    loading={false}
                />
            </Box>
        </>
    )
}