import {
  AddOutlined,
  DeleteOutline,
  Laptop,
  Monitor,
  OpenInNew,
  RemoveOutlined,
  SaveOutlined,
  StayPrimaryPortrait,
  Tablet,
  TabletAndroid,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  FilledInput,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useRouteParam from "../../../../hooks/useRouteParam";
import {
  createBlockStyle,
  defaultBlock,
  defaultBlockStyle,
  deleteBlockStyle,
  getGlobalStyles,
  selectGlobalBlockStyles,
  updateBlockStyle,
} from "../../../../redux/blocks/blockSlice";
import {
  BlockData,
  BlockStyle,
  BlockStyleData,
  ReferenceType,
  ScreenMode,
} from "../../../../redux/blocks/types";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import TitleBox from "../../../widgets/TitleBox";
import BlockStyleInputs from "./BlockStyleEdit/BlockStyleInputs";
import { debounce, forEach } from "lodash";
import { getReferencedBlockStyles } from "../../../../Utility";
import { Colors } from "../../../../theme/Colors";
import { useNavigate } from "react-router";

interface Props {
  selectedBlock: BlockData | undefined;
}

function getCurrentBlockStyle(
  blockStyle: BlockStyle,
  blockStyles: BlockStyle[],
  referencedStyles: BlockStyle[],
  screenMode: ScreenMode,
  selector: string
): BlockStyle {

  const stylesByMode = (styles: BlockStyle[], mode: ScreenMode) =>
    styles.filter((x) => x.mode === mode && x.selector === selector);

  const stylesToMerge: BlockStyle[] = [];
  const refStylesToMerge: BlockStyle[] = [];

  switch (screenMode) {
    case ScreenMode.xxl:
      stylesToMerge.push(
        ...stylesByMode(blockStyles, ScreenMode.xs),
        ...stylesByMode(blockStyles, ScreenMode.sm),
        ...stylesByMode(blockStyles, ScreenMode.md),
        ...stylesByMode(blockStyles, ScreenMode.lg),
        ...stylesByMode(blockStyles, ScreenMode.xl),
        ...stylesByMode(blockStyles, ScreenMode.xxl)
      );
      refStylesToMerge.push(
        ...stylesByMode(referencedStyles, ScreenMode.xs),
        ...stylesByMode(referencedStyles, ScreenMode.sm),
        ...stylesByMode(referencedStyles, ScreenMode.md),
        ...stylesByMode(referencedStyles, ScreenMode.lg),
        ...stylesByMode(referencedStyles, ScreenMode.xl),
        ...stylesByMode(referencedStyles, ScreenMode.xxl)
      );
      break;
    case ScreenMode.xl:
      stylesToMerge.push(
        ...stylesByMode(blockStyles, ScreenMode.xs),
        ...stylesByMode(blockStyles, ScreenMode.sm),
        ...stylesByMode(blockStyles, ScreenMode.md),
        ...stylesByMode(blockStyles, ScreenMode.lg),
        ...stylesByMode(blockStyles, ScreenMode.xl)
      );
      refStylesToMerge.push(
        ...stylesByMode(referencedStyles, ScreenMode.xs),
        ...stylesByMode(referencedStyles, ScreenMode.sm),
        ...stylesByMode(referencedStyles, ScreenMode.md),
        ...stylesByMode(referencedStyles, ScreenMode.lg),
        ...stylesByMode(referencedStyles, ScreenMode.xl)
      );
      break;
    case ScreenMode.lg:
      stylesToMerge.push(
        ...stylesByMode(blockStyles, ScreenMode.xs),
        ...stylesByMode(blockStyles, ScreenMode.sm),
        ...stylesByMode(blockStyles, ScreenMode.md),
        ...stylesByMode(blockStyles, ScreenMode.lg)
      );
      refStylesToMerge.push(
        ...stylesByMode(referencedStyles, ScreenMode.xs),
        ...stylesByMode(referencedStyles, ScreenMode.sm),
        ...stylesByMode(referencedStyles, ScreenMode.md),
        ...stylesByMode(referencedStyles, ScreenMode.lg)
      );
      break;
    case ScreenMode.md:
      stylesToMerge.push(
        ...stylesByMode(blockStyles, ScreenMode.xs),
        ...stylesByMode(blockStyles, ScreenMode.sm),
        ...stylesByMode(blockStyles, ScreenMode.md)
      );
      refStylesToMerge.push(
        ...stylesByMode(referencedStyles, ScreenMode.xs),
        ...stylesByMode(referencedStyles, ScreenMode.sm),
        ...stylesByMode(referencedStyles, ScreenMode.md)
      );
      break;
    case ScreenMode.sm:
      stylesToMerge.push(
        ...stylesByMode(blockStyles, ScreenMode.xs),
        ...stylesByMode(blockStyles, ScreenMode.sm)
      );
      refStylesToMerge.push(
        ...stylesByMode(referencedStyles, ScreenMode.xs),
        ...stylesByMode(referencedStyles, ScreenMode.sm)
      );
      break;
    default:
      return {
        ...blockStyle,
        blockStyleData: [
          ...stylesByMode(blockStyles, ScreenMode.xs).flatMap(x => x.blockStyleData),
          ...stylesByMode(referencedStyles, ScreenMode.xs).flatMap(x => x.blockStyleData)
        ],
      };
  }

  return {
    ...blockStyle,
    blockStyleData: [
      ...mergeBlockStyles(refStylesToMerge),
      ...mergeBlockStyles(stylesToMerge),
    ],
  };
}

function mergeBlockStyles(blockStyles: BlockStyle[]): BlockStyleData[] {
  const mergedStyles: {
    [key: string]: { data: BlockStyleData; mode: ScreenMode };
  } = {};
  const priority = ["Xs", "Sm", "Md", "Lg", "Xl", "Xxl"];

  for (const blockStyle of blockStyles) {
    for (const style of blockStyle.blockStyleData) {
      const existing = mergedStyles[style.attributeKey];
      if (
        !existing ||
        priority.indexOf(blockStyle.mode) >= priority.indexOf(existing.mode)
      ) {
        mergedStyles[style.attributeKey] = {
          data: style,
          mode: blockStyle.mode,
        };
      }
    }
  }

  return Object.values(mergedStyles).map((entry) => entry.data);
}

export default function BlockStyleTab(props: Props) {
  const { selectedBlock } = props;

  const [styleId, setStyleId] = useState(0);
  const [selector, setSelector] = useState("");
  const [canvasMode, setCanvasMode] = useState(ScreenMode.xs);
  const projectId = useRouteParam("projectId");

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const globalStyles = useAppSelector(selectGlobalBlockStyles);

  const mergedBlockStyles = useMemo(() => {
    const styles = selectedBlock ? selectedBlock.blockStyles : globalStyles;
    const blockStyle = styles.find(
      (x) => x.mode === canvasMode && x.id === styleId
    );
    if (!blockStyle) {
      return blockStyle;
    }
    const referencedStyles = selectedBlock?.templateReference
      ? getReferencedBlockStyles(selectedBlock.templateReference)
      : [];

    let newBlockStyle = getCurrentBlockStyle(
      blockStyle,
      styles,
      referencedStyles,
      canvasMode,
      blockStyle.selector
    );
    return newBlockStyle;
  }, [selectedBlock, globalStyles, canvasMode, styleId]);

  useEffect(() => {
    if (baseSelectorBlockStyle) {
      setStyleId(baseSelectorBlockStyle.id);
    }
  }, []);

  useEffect(() => {
    let existingStyle = styles.find(
      (x) => x.mode === canvasMode && x.selector === selector
    );
    if (!existingStyle) {
      existingStyle = styles.find(
        (x) => x.mode === canvasMode && x.selector === ""
      );
    }

    if (existingStyle) setStyleId(existingStyle.id);
    else handleBlockStyleCreate("");
  }, [selectedBlock]);

  const styles = useMemo(() => {
    if (selectedBlock) {
      return [...new Set(selectedBlock.blockStyles)];
    } else {
      return [...new Set(globalStyles)];
    }
  }, [selectedBlock, globalStyles]);

  const blockStyle = useMemo(
    () =>
      styles.find((x) => x.mode === canvasMode && x.id === styleId) ?? {
        ...defaultBlockStyle,
        mode: canvasMode,
        selector: "",
        projectId,
      },
    [styles, canvasMode, styleId, projectId]
  );

  useEffect(() => {
    setSelector(blockStyle.selector);
  }, [blockStyle.selector]);

  const baseSelectorBlockStyle = useMemo(
    () => styles.find((x) => x.mode === canvasMode && x.selector === ""),
    [styles, canvasMode]
  );

  const handleBlockStyleCreate = React.useCallback(
    (selector?: string, screenMode?: ScreenMode) => {
      let newStyle = { ...defaultBlockStyle, projectId, mode: canvasMode };

      if (selector !== undefined) newStyle = { ...newStyle, selector };

      if (screenMode !== undefined)
        newStyle = { ...newStyle, mode: screenMode };

      dispatch(createBlockStyle(newStyle))
        .unwrap()
        .then((payload) => {
          setStyleId(payload.data.id);
        });
    },
    [dispatch, defaultBlockStyle, projectId, setStyleId, canvasMode]
  );

  const handleBlockStyleDelete = React.useCallback(
    (id: number) => {
      dispatch(deleteBlockStyle(id))
        .unwrap()
        .then(() => {
          setStyleId(baseSelectorBlockStyle?.id ?? 0);
        });
    },
    [dispatch, setStyleId, projectId, baseSelectorBlockStyle]
  );

  const handleSelectorSave = useCallback(
    (newSelector: string) => {
      dispatch(updateBlockStyle({ ...blockStyle, selector: newSelector }));
    },
    [dispatch, blockStyle]
  );

  const navigateToReferencedTemplate = React.useCallback(() => {
    if (selectedBlock?.templateReference)
      navigate(
        `/builder/${projectId}/${ReferenceType.Template}/${selectedBlock.templateReference.templateDataId}`
      );
  }, [selectedBlock, navigate, projectId]);

  useEffect(() => {
    const shouldSave = selector !== "" && selector !== blockStyle.selector;
    const t = setTimeout(() => {
      if (shouldSave) {
        handleSelectorSave(selector);
      }
    }, 500);

    return () => clearTimeout(t);
  }, [selector]);

  const blockStyleChips = useMemo(() => {
    return styles
      .sort((a, b) => {
        if (a.selector === "" && b.selector !== "") return -1;
        if (a.selector !== "" && b.selector === "") return 1;

        return a.selector.localeCompare(b.selector);
      })
      .filter((x) => x.mode == canvasMode)
      .map((blockStyle) => {
        const selector =
          blockStyle.selector === "" ? "default" : blockStyle.selector;
        return (
          <Chip
            key={blockStyle.id}
            label={selector}
            onClick={() => setStyleId(blockStyle.id)}
            onDelete={
              blockStyle.selector === ""
                ? undefined
                : () => handleBlockStyleDelete(blockStyle.id)
            }
            variant="outlined"
            sx={{
              marginRight: theme.spacing(1),
              borderColor:
                styleId === blockStyle.id ? Colors.primary_main : undefined,
            }}
          />
        );
      });
  }, [styles, selector, setStyleId, theme, canvasMode, styleId]);

  const handleChange = React.useCallback(
    (_: React.MouseEvent<HTMLElement>, newScreenMode: ScreenMode) => {
      if (newScreenMode !== null) {
        setCanvasMode(newScreenMode);
        const existingStyle = styles.find(
          (x) => x.mode === newScreenMode && x.selector === selector
        );
        if (!existingStyle) {
          handleBlockStyleCreate(selector, newScreenMode);
        } else {
          setStyleId(existingStyle.id);
        }
      }
    },
    [setCanvasMode, selector, styles]
  );

  return (
    <>
      <Box
        sx={{
          "&>*:not(:last-child)": { marginBottom: theme.spacing(1) },
          paddingLeft: theme.spacing(1),
          paddingTop: theme.spacing(1),
          paddingRight: theme.spacing(1),
          flexShrink: 0,
        }}
      >
        <TitleBox
          mainTitle={selectedBlock ? "Styles" : "Global Styles"}
          subTitle={
            selectedBlock
              ? "Edit styles of the selected block"
              : "Edit global Styles"
          }
          maintitleVariant="h6"
          subTitleVariant="subtitle2"
        >
          <Tooltip title="Edit referenced template">
            <span>
              <IconButton
                aria-label="add"
                disabled={!selectedBlock?.templateReference}
                onClick={navigateToReferencedTemplate}
              >
                <OpenInNew />
              </IconButton>
            </span>
          </Tooltip>
        </TitleBox>
        <ToggleButtonGroup
          value={canvasMode}
          onChange={handleChange}
          exclusive={true}
          size="small"
          fullWidth
        >
          <ToggleButton value={ScreenMode.xs} key={ScreenMode.xs}>
            <Tooltip title="For phones less than 576px wide">
              <StayPrimaryPortrait />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={ScreenMode.sm} key={ScreenMode.sm}>
            <Tooltip title="For tablets and phones, 576px and up">
              <TabletAndroid />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={ScreenMode.md} key={ScreenMode.md}>
            <Tooltip title="For small laptops and tablets, 768px and up">
              <Tablet />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={ScreenMode.lg} key={ScreenMode.lg}>
            <Tooltip title="For laptops and desktops, 992px and up">
              <Laptop />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={ScreenMode.xl} key={ScreenMode.xl}>
            <Tooltip title="For large desktops, 1200px and up">
              <Monitor />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={ScreenMode.xxl} key={ScreenMode.xxl}>
            <Tooltip title="For larger desktops, 1400px and up">
              <Monitor />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
        <TitleBox
          mainTitle={"Selector"}
          subTitle={"Edit global Styles"}
          maintitleVariant="subtitle1"
          subTitleVariant="subtitle2"
        >
          <Tooltip title="Create a new selector">
            <span>
              <IconButton
                aria-label="add"
                onClick={() => handleBlockStyleCreate()}
              >
                <AddOutlined />
              </IconButton>
            </span>
          </Tooltip>
        </TitleBox>
        {styles.findIndex((x) => x.selector === "") < 0 && (
          <Chip
            label={"default"}
            onClick={() => setStyleId(0)}
            sx={{ marginRight: theme.spacing(1) }}
          />
        )}
        {blockStyleChips}
        <TextField
          fullWidth
          variant="filled"
          value={selector}
          disabled={blockStyle.selector === ""}
          onChange={(e) => setSelector(e.target.value)}
          label="Selector"
          size="small"
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          flex: 1,
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(1),
          }}
        >
          {mergedBlockStyles && (
            <BlockStyleInputs
              baseStyle={blockStyle}
              currentBlockStyle={mergedBlockStyles}
              selectedBlock={selectedBlock}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
