import { createSlice, isAnyOf, isRejected } from "@reduxjs/toolkit";
import axios from "axios";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import {
  AddFolderArgs as MediaFileAndPath,
  MediaFile,
  UploadFileArgs,
  UploadFilesArgs,
  RenameMediaArgs,
} from "./types";

const PREFIX = "media";

const prefix = (str: string) => `${PREFIX}/${str}`;

export interface MediaState {
  files: MediaFile[];
}

export const initialState: MediaState = {
  files: [],
};

export const defaultFile: MediaFile = {
  id: 0,
  extension: "",
  path: "",
  url: "",
  name: "newfile",
  size: "",
  directoryPath: "",
};

export const defaultFolder: MediaFile = {
  id: 0,
  extension: "folder",
  path: "",
  url: "",
  name: "newfolder",
  size: "",
  directoryPath: "",
};

export const uploadNewFile = createAppAsyncThunk(
  prefix("uploadNewFile"),
  async (args: UploadFileArgs) => {
    const formData = new FormData();
    formData.append("file", args.formFile);
    formData.append("fileName", args.fileName);

    const response = await axios.put<MediaFile>(`/media/upload/${args.id}`, {
      formData,
      filePath: args.path,
    });

    return response.data;
  }
);

export const uploadFiles = createAppAsyncThunk(
  prefix("uploadFiles"),
  async (args: UploadFilesArgs) => {
    const { formFiles, path, projectId } = args;

    const formData = new FormData();
    for (let i = 0; i < formFiles.length; i++) {
      formData.append("file", formFiles[i]);
    }
    formData.append("filePath", path);
    formData.append("projectId", projectId.toString());

    const response = await axios.post<MediaFile[]>("/media/upload", formData);

    return response.data;
  }
);

export const addFolder = createAppAsyncThunk(
  prefix("addFolder"),
  async (args: MediaFileAndPath) => {
    const response = await axios.post<MediaFile[]>("/media/folder/add", {
      folder: args.media,
      path: args.path,
    });
    return response.data;
  }
);

export const moveFile = createAppAsyncThunk(
  prefix("moveFile"),
  async (args: MediaFileAndPath) => {
    const response = await axios.post<MediaFile>("/media/folder/move", {
      file: args.media,
      filePath: args.path,
    });

    return response.data;
  }
);

export const renameMedia = createAppAsyncThunk(
  prefix("renameMedia"),
  async (args: RenameMediaArgs) => {
    const response = await axios.post<MediaFile[]>("/media/rename", {
      file: args.media,
      filePath: args.path,
      fileName: args.name,
    });

    return response.data;
  }
);

export const updateFile = createAppAsyncThunk(
  prefix("updateFile"),
  async (args: MediaFileAndPath) => {
    const response = await axios.put<MediaFile>(`/media/${args.media.id}`, {
      fileName: args.media.name,
      filePath: args.media.path,
    });

    return response.data;
  }
);

export const getFiles = createAppAsyncThunk(
  prefix("getFiles"),
  async (projectId: number) => {
    const response = await axios.get<MediaFile[]>(`/media/${projectId}`);
    return response.data;
  }
);

export const deleteFile = createAppAsyncThunk(
  prefix("deleteFile"),
  async (fileId: number) => {
    await axios.delete(`/media/${fileId}`);
    return fileId;
  }
);

const mediaSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadNewFile.fulfilled, (state, action) => {
      const index = state.files.findIndex((x) => x.id === action.payload.id);
      if (index >= 0) {
        state.files[index] = action.payload;
      } else {
        state.files.push(action.payload);
      }
    });

    builder.addCase(getFiles.fulfilled, (state, action) => {
      state.files = action.payload;
    });

    builder.addCase(deleteFile.fulfilled, (state, action) => {
      state.files = state.files.filter((x) => x.id !== action.payload);
    });

    builder.addCase(renameMedia.fulfilled, (state, action) => {
      state.files = [
        ...state.files.filter(
          (x) => !action.payload.some((y) => y.id === x.id)
        ),
        ...action.payload,
      ];
    });

    builder.addMatcher(
      isAnyOf(moveFile.fulfilled, updateFile.fulfilled),
      (state, action) => {
        const index = state.files.findIndex((x) => x.id === action.payload.id);
        if (index >= 0) {
          state.files[index] = action.payload;
        }
      }
    );

    builder.addMatcher(
      isAnyOf(uploadFiles.fulfilled, addFolder.fulfilled),
      (state, action) => {
        for (let key in action.payload) {
          const file = action.payload[key];
          const index = state.files.findIndex((x) => x.id === file.id);
          if (index >= 0) {
            state.files[index] = file;
          } else {
            state.files.push(file);
          }
        }
      }
    );

    // base cases

    // logs error if a request is rejected
    builder.addMatcher(isRejected, (_, action) => {
      console.error(action.error.message);
    });
  },
});

export const selectMedia = (rootState: RootState) => rootState.media.files;

export default mediaSlice.reducer;
