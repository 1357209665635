import {
  DragIndicator,
  ExpandLess,
  ExpandLessRounded,
  ExpandMore,
  PlayArrow,
  Redo,
  Stop,
} from "@mui/icons-material";
import {
  Box,
  Tooltip,
  Typography,
  IconButton,
  Button,
  Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { Colors } from "../../../theme/Colors";
import SequenceDebugInfo from "./DebugInfo";
import { ProcessState } from "../../../context/blockActions/ActionExecutionContext";

interface Props {
  handleStepClick: () => void;
  handleContinueClick: () => void;
  handleCancelClick: () => void;
  isFinished: boolean;
  info: ProcessState;
}

const DebugControlFloater: React.FC<Props> = (props) => {
  const {
    handleContinueClick,
    handleStepClick,
    handleCancelClick,
    isFinished,
    info,
  } = props;

  const [expanded, setExpanded] = useState(false);

  const [width, setWidth] = useState(400);

  useEffect(() => {
    if (expanded) {
      setWidth(600);
    }
    setWidth(400);
  }, [expanded]);

  return (
    <Draggable bounds="parent" handle=".drag-handle">
      <Box
        sx={{
          position: "absolute",
          top: 20,
          right: 100,
          float: "left",
          background: Colors.common_black,
          border: Colors.primary_main,
          borderRadius: 1,
          width: `${width}px`,
          minWidth: `${width}px`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
            }}
          >
            <Box
              className="drag-handle"
              style={{
                cursor: "move",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "10px",
                }}
              >
                <DragIndicator
                  sx={{
                    color: Colors.text_silver,
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                ["&>.Mui-disabled"]: {
                  color: "gray",
                },
              }}
            >
              <Tooltip title={<Typography>Run</Typography>}>
                <IconButton
                  size="large"
                  sx={{ color: Colors.primary_main }}
                  onClick={handleContinueClick}
                  disabled={isFinished}
                >
                  <PlayArrow />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography>Step</Typography>}>
                <IconButton
                  size="large"
                  sx={{ color: Colors.background_default }}
                  onClick={handleStepClick}
                  disabled={isFinished}
                >
                  <Redo />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography>Stop debugging</Typography>}>
                <IconButton
                  size="large"
                  sx={{
                    color: Colors.bloxxi_red,
                  }}
                  onClick={handleCancelClick}
                >
                  <Stop />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <Button
              size="large"
              sx={{ color: Colors.text_silver, marginLeft: "50px" }}
              endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
              onClick={() => setExpanded((prev) => !prev)}
            >
              {expanded ? "Close" : "Show info"}
            </Button>
          </Box>
        </Box>
        <Collapse in={expanded}>
          <Box sx={{ color: Colors.text_silver }}>
            <SequenceDebugInfo info={info} />
          </Box>
        </Collapse>
      </Box>
    </Draggable>
  );
};

export default DebugControlFloater;
