import { TextField, InputAdornment, Select, MenuItem, IconButton, Popover, List, ListItem, ListItemButton, ListItemText, FilledInput, FormControl } from '@mui/material';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { BlockData, BlockStyle } from '../../../redux/blocks/types';
import { extractNumber, getStyleById, isStyleInheritedAndChanged } from '../../../Utility';
import { add, debounce } from 'lodash';
import { BlockStyleAttribute } from '../../builder/BlockInfo/BlockDetail/BlockStyleEdit/BlockStyleInputs';
import BlockStyleDataResetBadge from './BlockStyleDataResetBadge';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], save?: boolean, ignoreDefault?: boolean) => void,
    attributeKey: string,
    selectedBlock?: BlockData
    handleBlockStyleDataReset: (attributeKey: string) => void,
    children: ReactNode,
}

export default function StyleSelect(props: Props) {
    const { currentBlockStyle, attributeKey, handleAttributeChange, selectedBlock, children } = props;

    return (
        <BlockStyleDataResetBadge {...props}>
            <FormControl variant="filled" fullWidth hiddenLabel>
                <Select
                    id={`${attributeKey}-select`}
                    value={currentBlockStyle.blockStyleData.find(x => x.attributeKey === attributeKey)?.attributeValue ?? getStyleById(selectedBlock, attributeKey)}
                    fullWidth
                    input={<FilledInput />}
                    onChange={(e) => handleAttributeChange([{ attributeKey: attributeKey, attributeValue: e.target.value }], true, false)}
                    size='small'>
                    {children}
                </Select>
            </FormControl>
        </BlockStyleDataResetBadge>
    );
}