import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { EditUserData } from "../../components_new/users/UserDetails";
import { GlobalRole, ProjectRole, User } from "../auth/types";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import { UserOnlineInfo } from "./types";

const PREFIX = "users";
const prefix = (str: string) => `${PREFIX}/${str}`;

export interface UsersState {
  users: User[];
  globalRoles: GlobalRole[];
  online: UserOnlineInfo[];
}

export const initialState: UsersState = {
  users: [],
  globalRoles: [],
  online: [],
};

export const getUsers = createAppAsyncThunk(prefix("get"), async () => {
  const response = await axios.get<User[]>("/user");
  return response.data;
});

export const getProjectUsers = createAppAsyncThunk(
  prefix("getProjectUsers"),
  async (projectId: number) => {
    const response = await axios.get<User[]>(`/user/project/${projectId}`);
    return response.data;
  }
);

export const getUser = createAppAsyncThunk(
  prefix("getSingle"),
  async (id: number) => {
    const response = await axios.get<User>(`/user/${id}`);
    return response.data;
  }
);

export const addRoleToUser = createAppAsyncThunk(
  prefix("addRoleToUser"),
  async (args: { userId: number; role: GlobalRole }) => {
    const response = await axios.post<GlobalRole>(
      `/user/${args.userId}/add-role`,
      args.role
    );
    return response.data;
  }
);

export const removeRoleFromuser = createAppAsyncThunk(
  prefix("removeRoleFromUser"),
  async (args: { userId: number; roleId: number }) => {
    await axios.post(`/users/${args.userId}/remove-role`, {
      globalRoleId: args.roleId,
    });
  }
);

export const getGlobalRoles = createAppAsyncThunk(
  prefix("getRoles"),
  async () => {
    const response = await axios.get<GlobalRole[]>("/user/roles");
    return response.data;
  }
);

export const setUserData = createAppAsyncThunk(
  prefix("setUserData"),
  async (args: { userData: EditUserData; userId: number }) => {
    await axios.post(`/user/${args.userId}/update-info`, args.userData);
  }
);

export const createUser = createAppAsyncThunk(
  prefix("craeteUser"),
  async (args: { username: string; password: string }) => {
    const response = await axios.post<User>("/user/create", args);
    return response.data;
  }
);

export const changeUserState = createAppAsyncThunk(
  prefix("changeState"),
  async (args: { userId: number; state: boolean }) => {
    await axios.post(`/user/${args.userId}/set-active`, { active: args.state });
  }
);

export const getOnlineUsers = createAppAsyncThunk(
  prefix("getOnlineUsers"),
  async () => {
    const response = await axios.get<UserOnlineInfo[]>("/user/online");
    return response.data;
  }
);

export const addProjectRoleToUser = createAppAsyncThunk(
  prefix("addProjectToUser"),
  async (args: {
    projectId: number;
    projectRoleId: number;
    userId: number;
  }) => {
    const response = await axios.post<ProjectRole>(
      "/project/addroletouser",
      args
    );
    return response.data;
  }
);

export const updateProjectRoles = createAppAsyncThunk(
  prefix("updateProjectRoles"),
  async (args: {
    projectId: number;
    userId: number;
    projectRoles: ProjectRole[];
  }) => {
    const { projectId, userId, projectRoles } = args;
    await axios.post(`/project/${projectId}/updateuserroles/${userId}`, {
      projectRoleIds: projectRoles.map((x) => x.id),
    });
  }
);

export const removeUserFromProject = createAppAsyncThunk(
  prefix("removeUser"),
  async (args: { id: number; userId: number }) => {
    const { id, userId } = args;
    await axios.patch(`/project/${id}/removeUser/${userId}`);
  }
);

const usersSlice = createSlice({
  initialState,
  name: PREFIX,
  reducers: {
    addOnlineUser(state, action: PayloadAction<UserOnlineInfo>) {
      state.online.push(action.payload);
    },
    removeOnlineUser(state, action: PayloadAction<UserOnlineInfo>) {
      state.online = state.online.filter(
        (x) => x.username !== action.payload.username
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });

    builder.addCase(getUser.fulfilled, (state, action) => {
      const requestedUserid = action.meta.arg;
      const index = state.users.findIndex((x) => x.id === requestedUserid);
      if (index >= 0) state.users[index] = action.payload;
      else state.users.push(action.payload);
    });

    builder.addCase(addRoleToUser.fulfilled, (state, action) => {
      const requestedUserId = action.meta.arg.userId;
      const userIndex = state.users.findIndex((x) => x.id === requestedUserId);
      if (userIndex >= 0) {
        state.users[userIndex].userRoles.push(action.payload);
      }
      const roleIndex = state.globalRoles.findIndex(
        (x) => x.id === action.payload.id
      );
      if (roleIndex >= 0) {
        state.globalRoles[roleIndex] = action.payload;
      } else {
        state.globalRoles.push(action.payload);
      }
    });

    builder.addCase(getGlobalRoles.fulfilled, (state, action) => {
      state.globalRoles = action.payload;
    });

    builder.addCase(setUserData.fulfilled, (state, action) => {
      const requestedUserId = action.meta.arg.userId;
      const userIndex = state.users.findIndex((x) => x.id === requestedUserId);
      if (userIndex >= 0) {
        state.users[userIndex] = {
          ...state.users[userIndex],
          ...action.meta.arg.userData,
        };
      }
    });

    builder.addCase(createUser.fulfilled, (state, action) => {
      state.users.push(action.payload);
    });

    builder.addCase(removeRoleFromuser.fulfilled, (state, action) => {
      const requestedUserId = action.meta.arg.userId;
      const userIndex = state.users.findIndex((x) => x.id === requestedUserId);
      if (userIndex >= 0) {
        state.users[userIndex].userRoles = state.users[
          userIndex
        ].userRoles.filter((x) => x.id !== action.meta.arg.roleId);
      }
    });

    builder.addCase(changeUserState.fulfilled, (state, action) => {
      const requestedUserId = action.meta.arg.userId;
      const userIndex = state.users.findIndex((x) => x.id === requestedUserId);
      if (userIndex >= 0)
        state.users[userIndex].isActive = action.meta.arg.state;
    });

    builder.addCase(getOnlineUsers.fulfilled, (state, action) => {
      state.online = action.payload;
    });

    builder.addCase(getProjectUsers.fulfilled, (state, action) => {
      const gotUsers = action.payload;
      for (let i = 0; i < gotUsers.length; i++) {
        const user = gotUsers[i];
        const index = state.users.findIndex((x) => x.id === user.id);
        if (index >= 0) state.users[index] = user;
        else state.users.push(user);
      }
    });

    builder.addCase(addProjectRoleToUser.fulfilled, (state, action) => {
      const { userId, projectRoleId } = action.meta.arg;
      const index = state.users.findIndex((x) => x.id === userId);
      if (index >= 0) {
        const roleIndex = state.users[index].projectRoles.findIndex(
          (x) => x.id === projectRoleId
        );
        if (roleIndex >= 0) {
          console.log("User already has role");
          return;
        }
        state.users[index].projectRoles.push(action.payload);
      }
    });

    builder.addCase(updateProjectRoles.fulfilled, (state, action) => {
      const { userId, projectRoles, projectId } = action.meta.arg;
      const userIndex = state.users.findIndex((x) => x.id === userId);
      if (userIndex >= 0) {
        state.users[userIndex].projectRoles = [
          ...state.users[userIndex].projectRoles.filter(
            (x) => x.projectDataId !== projectId
          ),
          ...projectRoles,
        ];
      }
    });

    builder.addCase(removeUserFromProject.fulfilled, (state, action) => {
      const { id, userId } = action.meta.arg;
      const index = state.users.findIndex((x) => x.id === userId);
      if (index >= 0) {
        state.users[index].projectRoles = state.users[
          index
        ].projectRoles.filter((x) => x.projectDataId !== id);
      }
    });
  },
});

export const { addOnlineUser, removeOnlineUser } = usersSlice.actions;

export const selectUsers = (rootState: RootState) => rootState.users.users;
export const selectUserById = (id: number) => (rootState: RootState) => {
  const users = rootState.users.users.filter((x) => x.id === id);
  if (users.length === 1) return users[0];
  if (users.length <= 0) {
    return undefined;
  }
  console.error(
    "State consistency failure! Cannot have multiple users with the same ids: ",
    users.map((x) => x.id)
  );
};
export const selectAllUserRoles = (rootState: RootState) =>
  rootState.users.globalRoles;
export const selectOnlineUsers = (rootState: RootState) =>
  rootState.users.online;
export const selectProjectUsers = (projId: number) => (rootState: RootState) =>
  rootState.users.users.filter((x) =>
    x.projectRoles.some((x) => x.projectDataId === projId)
  );
export default usersSlice.reducer;
