import { createSlice, isRejected } from "@reduxjs/toolkit";
import axios from "axios";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import { PageData } from "./types";

const PREFIX = "page";

const prefix = (str: string) => `${PREFIX}/${str}`;

export interface PageState {
  pages: PageData[];
}

const initialState: PageState = {
  pages: [],
};

export const defaultPageData: PageData = {
  id: 0,
  name: "new Page",
  metaDescription: "",
  metaTitle: "",
  dynamic: false,
  withFooter: false,
  withNavbar: false,
  blockData: [],
};

export const getPages = createAppAsyncThunk(
  prefix("getPages"),
  async (projectId: number) => {
    const response = await axios.get<PageData[]>(`/page/${projectId}`);
    return response.data;
  }
);

export const createPage = createAppAsyncThunk(
  prefix("createPage"),
  async function (page: PageData) {
    const response = await axios.post<PageData>("/page", page);
    return response.data;
  }
);

export const updatePage = createAppAsyncThunk(
  prefix("updatePageData"),
  async (page: PageData) => {
    const response = await axios.post<PageData>("/page/update", page);
    return response.data;
  }
);

export const deletePage = createAppAsyncThunk(
  prefix("deletePage"),
  async (id: number) => {
    await axios.delete(`/page/${id}`);
    return id;
  }
);

const pageSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // base cases
    builder.addCase(getPages.fulfilled, (state, action) => {
      state.pages = action.payload;
    });

    builder.addCase(createPage.fulfilled, (state, action) => {
      state.pages.push(action.payload);
    });

    builder.addCase(updatePage.fulfilled, (state, action) => {
      const index = state.pages.findIndex((x) => x.id === action.payload.id);
      state.pages[index] = action.payload;
    });

    builder.addCase(deletePage.fulfilled, (state, action) => {
      state.pages = state.pages.filter((x) => x.id !== action.payload);
    });

    // logs error if a request is rejected
    builder.addMatcher(isRejected, (_, action) => {
      console.error(action.error.message);
    });
  },
});

export const selectPages = (rootState: RootState) =>
  rootState.pages!.pages ?? [];
export const selectPageById = (id: number) => (rootState: RootState) =>
  rootState.pages.pages.find((x) => x.id === id);

export default pageSlice.reducer;
