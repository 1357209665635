import { HandleType } from "../../redux/actionsequence/types/helpers/ActionConnection";

type WithSourceAndTarget = {
  source: string;
  target: string;
  sourceHandle?: string | null;
  targetHandle?: string | null;
};

export function getIndegree<T extends WithSourceAndTarget>(
  nodeId: string,
  edges: T[]
) {
  return edges.filter((x) => x.target === nodeId).length;
}

export function getOutgoing<T extends WithSourceAndTarget>(
  nodeId: string,
  edges: T[]
) {
  return edges.filter((x) => x.source === nodeId);
}

export function getOutgoingNodeIds<T extends WithSourceAndTarget>(
  nodeId: string,
  edges: T[]
) {
  return getOutgoing(nodeId, edges).map((x) => x.target);
}

export function getIncoming<T extends WithSourceAndTarget>(
  nodeId: string,
  edges: T[]
) {
  return edges.filter((x) => x.target === nodeId);
}

export function getIncomingNodeIds<T extends WithSourceAndTarget>(
  nodeId: string,
  edges: T[]
) {
  return getIncoming(nodeId, edges).map((x) => x.source);
}

/**
 * If a node is invalidated, all it's follow-up nodes need to be evaluated as well
 * This function computes every node that needs to be invalidated if a node is marked as 'invalid'
 * @param invalidNode Node that has been invalidated
 * @param edges All edges in the graph
 * @returns an array of all the nodes to be invalidated
 */
export function getPathToInvalidate<T extends WithSourceAndTarget>(
  invalidEdge: T | undefined,
  edges: T[]
) {
  if (!invalidEdge || invalidEdge.targetHandle === HandleType.LoopEnd) {
    return [];
  }
  return getPathToInvalidateAux(invalidEdge.target, edges, []);
}

function getPathToInvalidateAux<T extends WithSourceAndTarget>(
  node: string,
  edges: T[],
  invalidated: Readonly<string[]> | undefined = []
) {
  const direct = edges
    .filter((x) => x.source === node && x.targetHandle !== HandleType.LoopEnd)
    .map((x) => x.target);
  const toInvalidate: string[] = [];
  for (const nd of direct) {
    const degree = getIndegree(nd, edges);
    const incoming = getIncoming(nd, edges);
    // if indegree 1 -> trivial because parent node is invalid, so this node is invalid
    // if every parent node is invalid -> also invalidate
    if (degree === 1 || incoming.every((i) => invalidated.includes(i.source))) {
      toInvalidate.push(nd);
    }
  }

  // Get next plane of nodes to invalidate
  for (const nd of toInvalidate) {
    const next = getPathToInvalidateAux(nd, edges, [
      ...invalidated,
      ...toInvalidate,
    ]);
    for (const i of next) {
      toInvalidate.push(i);
    }
  }

  return toInvalidate;
}

/**
 * Breadth first topological sort for a directed graph
 * @param nodes the nodes of the graph
 * @param edges the edges of the graph
 * @returns a list of nodes which are in breadth-first topological order
 */
export function topologicalSort<T extends WithSourceAndTarget>(
  nodes: string[],
  edges: T[]
) {
  const topo: string[] = [];
  const ready: string[] = [];

  const inCount: Record<string, number> = {};
  for (const n of nodes) {
    inCount[n] = getIndegree(n, edges);
    if (inCount[n] === 0) {
      ready.push(n);
    }
  }

  while (ready.length) {
    const node = ready.pop()!;
    topo.push(node);
    for (const edge of getOutgoing(node, edges)) {
      const opposite = nodes.find((x) => x === edge.target);
      if (opposite) {
        inCount[opposite] = inCount[opposite] - 1;
        if (inCount[opposite] === 0) {
          ready.push(opposite);
        }
      }
    }
  }

  return topo;
}

/**
 * Gets the loop the node with a given id is currently in
 * If none -> returns undefined
 * @param nodeId Node id to look for
 * @param edges edges of the graph
 * @returns the loop nodes id if a loop is found, otherwise undefined
 */
export function getLoop<T extends WithSourceAndTarget>(
  nodeId: string,
  edges: T[]
): string | undefined {
  return getLoopAux(nodeId, edges);
}

function getLoopAux<T extends WithSourceAndTarget>(
  startNode: string,
  edges: T[],
  currentNode?: string
): string | undefined {
  const prev = getIncoming(currentNode ?? startNode, edges);
  for (const prevEdge of prev) {
    const n = prevEdge.source;
    if (prevEdge.sourceHandle === HandleType.LoopStart) {
      return n;
    } else {
      return getLoopAux(startNode, edges, n);
    }
  }
  return undefined;
}
