import { Box, Paper, TextField, useTheme } from '@mui/material';
import React from 'react';
import useRouteParam from '../../../hooks/useRouteParam';
import { selectAnimationById, updateAnimationAsync } from '../../../redux/animation/animationSlice';
import { BlockAnimation } from '../../../redux/animation/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import TitleBox from '../../widgets/TitleBox';

interface Props {
    currentAnimation: BlockAnimation | undefined,
    handleBlockAnimationChange: (animation: BlockAnimation) => void,
    handleBlockAnimationSave: () => void,
}

export default function AnimationDetailTab(props: Props) {
    const { currentAnimation, handleBlockAnimationChange, handleBlockAnimationSave } = props;
    const theme = useTheme();

    return (
        <Box sx={{ width: '100%', height: '100%', '&>*': {marginBottom: theme.spacing(1)} }}>
            <TitleBox
                mainTitle='Animation details'
                subTitle='Edit animation details'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>
            </TitleBox>
            <TextField
                id="animation-name"
                label="Name"
                placeholder="Fade"
                fullWidth
                variant="filled"
                value={currentAnimation?.name ?? ''}
                onChange={(e) => handleBlockAnimationChange({ ...currentAnimation as BlockAnimation, name: e.target.value })}
                onBlur={handleBlockAnimationSave} />
            <TextField
                id="animation-ease-function"
                label="Ease function"
                placeholder="ease-in"
                fullWidth
                variant="filled"
                value={currentAnimation?.easeFunction ?? ''}
                onChange={(e) => handleBlockAnimationChange({ ...currentAnimation as BlockAnimation, easeFunction: e.target.value })}
                onBlur={handleBlockAnimationSave} />
            <TextField
                id="animation-timing"
                label="Milliseconds"
                placeholder="3000"
                fullWidth
                variant="filled"
                type="number"
                value={currentAnimation?.milliseconds ?? ''}
                onChange={(e) => handleBlockAnimationChange({ ...currentAnimation as BlockAnimation, milliseconds: Number(e.target.value) })}
                onBlur={handleBlockAnimationSave} />
        </Box>
    )
}