import { FilledInput, FormControl, Grid, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React from 'react';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import ValueWithUnitInput from '../../../../../utils/Inputs/ValueWithUnitInput';
import { BlockData, BlockStyle, BlockType } from '../../../../../../redux/blocks/types';
import { ViewDay, ViewColumn, GridView, ViewArray, FormatColorText, VisibilityOff, Visibility, Height, Clear, West, East, SyncAlt, FilterCenterFocus, ArrowLeft, ArrowRight, ArrowUpward, ArrowDownward, OpenInFull } from '@mui/icons-material';
import { getStyleById, isStyleInheritedAndChanged } from '../../../../../../Utility';
import StyleToggleButtonGroup from '../../../../../utils/Inputs/StyleToggleButtonGroup';
import StyleSelect from '../../../../../utils/Inputs/StyleSelect';
import { BlockStyleAttribute } from '../BlockStyleInputs';
import StyleTextField from '../../../../../utils/Inputs/StyleTextField';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void,
    selectedBlock?: BlockData,
}

export default function SizeInputs(props: Props) {
    return (
        <>
            <BlockStyleDataLabel name="Sizing" />
            <Grid item xs={10} >
                <StyleSelect {...props} attributeKey='box-sizing'>
                    <MenuItem key="content-box" value="content-box">content-box</MenuItem>
                    <MenuItem key="border-box" value="border-box">border-box</MenuItem>
                </StyleSelect>
            </Grid>
            <BlockStyleDataLabel name="Width" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='width' {...props} additionalEntries={['auto']} />
            </Grid>
            <BlockStyleDataLabel name="Height" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='height' {...props} additionalEntries={['auto']} />
            </Grid>
            <BlockStyleDataLabel name="Min W" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='min-width' {...props} />
            </Grid>
            <BlockStyleDataLabel name="Min H" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='min-height' {...props} />
            </Grid>
            <BlockStyleDataLabel name="Max W" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='max-width' {...props} additionalEntries={['none']} />
            </Grid>
            <BlockStyleDataLabel name="Max H" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='max-height' {...props} additionalEntries={['none']} />
            </Grid>
            <BlockStyleDataLabel name="Overflow" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='overflow'>
                    <ToggleButton value="visible" key="visible">
                        <Tooltip title="Content that overflows the box is shown outside the element's boundaries">
                            <Visibility />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="hidden" key="hidden">
                        <Tooltip title="Excess content beyond the element's boundaries is not visible">
                            <VisibilityOff />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="scroll" key="scroll">
                        <Tooltip title="Adds scrollbars to the element if the content overflows">
                            <Height />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="none" key="none">
                        <Tooltip title="Automatically provides scrollbars only if the content overflows">
                            <span>
                                Auto
                            </span>
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Fit" />
            <Grid item xs={10} >
                <StyleSelect {...props} attributeKey='object-fit'>
                    <MenuItem key="fill" value="fill">Fill</MenuItem>
                    <MenuItem key="contain" value="contain">Contain</MenuItem>
                    <MenuItem key="cover" value="cover">Cover</MenuItem>
                    <MenuItem key="ScaleDown" value="scale-down">Scale down</MenuItem>
                    <MenuItem key="none" value="none">None</MenuItem>
                </StyleSelect>
            </Grid>
            <BlockStyleDataLabel name="Position" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='object-position'>
                    <ToggleButton value="center" key="center">
                        <Tooltip title="Centers content in both directions">
                            <FilterCenterFocus />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="left" key="left">
                        <Tooltip title="Aligns content to the left, centered vertically">
                            <West />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="right" key="right">
                        <Tooltip title="Aligns content to the right, centered vertically">
                            <East />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="top" key="top">
                        <Tooltip title="Aligns content to the top, centered horizontally">
                            <ArrowUpward />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="bottom" key="bottom">
                        <Tooltip title="Aligns content to the bottom, centered horizontally">
                            <ArrowDownward />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Float" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='float'>
                    <ToggleButton value="none" key="none">
                        <Tooltip title="The element does not float and is displayed in its normal position in the document flow">
                            <Clear />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="left" key="left">
                        <Tooltip title="The element floats to the left side of its containing block, allowing text and inline elements to wrap around its right side">
                            <West />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="right" key="right">
                        <Tooltip title="The element floats to the right side of its containing block, allowing text and inline elements to wrap around its left side">
                            <East />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Clear" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='clear'>
                    <ToggleButton value="none" key="none">
                        <Tooltip title="Allows the element to be adjacent to floating elements on both sides">
                            <Clear />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="left" key="left">
                        <Tooltip title="Prevents the element from being adjacent to floating elements on the left side">
                            <West />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="right" key="right">
                        <Tooltip title="Prevents the element from being adjacent to floating elements on the right side">
                            <East />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="both" key="both">
                        <Tooltip title="Ensures the element does not sit next to floating elements on either side">
                            <SyncAlt />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Resize" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='resize'>
                    <ToggleButton value="none" key="none">
                        <Tooltip title="Resizing is disabled for this element. Its size is fixed based on the content or specified dimensions">
                            <Clear />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="horizontal" key="horizontal">
                        <Tooltip title="Enables horizontal resizing of this element. Click and drag the resize handle on the right edge to adjust its width">
                            <Height sx={{ transform: 'rotate(90deg)' }} />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="vertical" key="vertical">
                        <Tooltip title="Enables vertical resizing of this element. Click and drag the resize handle at the bottom edge to adjust its height">
                            <Height />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="both" key="both">
                        <Tooltip title="Allows resizing of this element in both horizontal and vertical directions. Click and drag the resize handle at the bottom-right corner to adjust its size as needed">
                            <OpenInFull />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            {
                props.selectedBlock?.blockType === BlockType.IMG || props.selectedBlock?.blockType === BlockType.VIDEO ?
                    <>
                        <BlockStyleDataLabel name="Ratio" />
                        <Grid item xs={10}>
                            <StyleTextField attributeKey='aspect-ratio' {...props} />
                        </Grid>
                    </> : null
            }
        </>
    );
}