import axios, { AxiosRequestConfig } from "axios";

import { setToken, setUser, setUserId } from "./redux/auth/authSlice";
import { AuthenticateResponse } from "./redux/auth/types";
import { store } from "./redux/store";

export type TokenPromiseFunction = {
  resolve: (token: unknown) => void;
  reject: (reason?: any) => void;
};

/**
 * Before every request:
 * add auth token from localstorage to request header
 * On request error: do nothing and pass error down for handling
 */
axios.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

// variable to keep track if there is a 'refresh-token' request in progress
let isRefreshing = false;

// queue for failing requests during token refreshing
let failedQueue: TokenPromiseFunction[] = [];

// function to retry all requests that were held off while the
// token was refreshed after the token is refreshed
const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((promise: TokenPromiseFunction) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }

    failedQueue = [];
  });
};

/**
 * Axios interceptor for responses:
 * on successful response:
 *  - just pass the response back to the initiator
 * on failed response:
 *  - if the error does not indicate authentication failure:
 *    - just pass the error onto the initiator
 *  - if the error is in authentication context:
 *    - send a request for a new token and hold off all requests while this request is pending
 *    - send all requests that arrive while refreshing to a queue
 *    - all requests in this queue will be executed once a new token has been given
 */
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config as AxiosRequestConfig & {
      isRetry: boolean;
    };
    if (
      error?.response?.status &&
      error.response.status === 401 &&
      !originalRequest.isRetry &&
      !originalRequest.url?.includes("refresh-token") &&
      !originalRequest.url?.includes("authentication")
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest.isRetry = true;
      isRefreshing = true;

      const isFirstRequest = store.getState().auth.token === "";

      return new Promise((resolve, reject) => {
        axios
          .get<AuthenticateResponse>("/authentication/refresh-token", {
            headers: {
              "X-IS-FST": isFirstRequest,
            },
          })
          .then(({ data }) => {
            const {
              jwtToken,
              firstName,
              lastName,
              id,
              email,
              lastLogin,
              projectRoles,
              userRoles,
              username,
              isActive,
            } = data;

            if (!jwtToken || !username) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject("New token could not be retrieved. Please log in again.");
            }

            store.dispatch(setUserId(username));
            store.dispatch(setToken(jwtToken));
            store.dispatch(
              setUser({
                email,
                firstName,
                lastName,
                lastLogin,
                id,
                projectRoles,
                userRoles,
                username,
                isActive,
              })
            );

            processQueue(null, jwtToken);
            resolve(
              axios({
                ...originalRequest,
                headers: {
                  ...originalRequest.headers,
                  Authorization: `Bearer ${jwtToken}`,
                },
              })
            );
          })
          .catch((err) => {
            location.href = "/auth";
            processQueue(err, null);
            reject(error);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);
