import {
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import axios from "axios";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import { MailConnection, MailTemplate } from "./types";

const PREFIX = "mail";

const prefix = (str: string) => `${PREFIX}/${str}`;

export interface MailState {
  loading: boolean;
  templates: MailTemplate[];
  connections: MailConnection[];
}

export const initialState: MailState = {
  loading: false,
  templates: [],
  connections: [],
};

export const defaultMailConnection: MailConnection = {
  id: 0,
  host: "",
  port: 0,
  password: "",
  user: "",
};

export const defaultMailTemplate: MailTemplate = {
  id: 0,
  name: "newTemplate",
  content: "",
};

export const getConnections = createAppAsyncThunk(
  prefix("getConnections"),
  async (projectId: number) => {
    const response = await axios.get<MailConnection[]>(`/mail/${projectId}`);
    return response.data;
  }
);

export const createMailConnection = createAppAsyncThunk(
  prefix("createMailConnection"),
  async (mailConnection: MailConnection) => {
    const response = await axios.post<MailConnection>("/mail", mailConnection);
    return response.data;
  }
);

export const updateMailConnection = createAppAsyncThunk(
  prefix("updateMailConnection"),
  async (mailConnection: MailConnection) => {
    const response = await axios.post<MailConnection>(
      "/mail/update",
      mailConnection
    );
    return response.data;
  }
);

export const deleteMailConnection = createAppAsyncThunk(
  prefix("deleteMailConnection"),
  async (id: number) => {
    await axios.delete(`/mail/${id}`);
    return id;
  }
);

export const getMailTemplates = createAppAsyncThunk(
  prefix("getMailTemplates"),
  async (projectId: number) => {
    const response = await axios.get<MailTemplate[]>(
      `/mail/mailtemplate/${projectId}`
    );
    return response.data;
  }
);

export const createMailTemplate = createAppAsyncThunk(
  prefix("createMailTemplate"),
  async (mailTemplate: MailTemplate) => {
    const response = await axios.post<MailTemplate>(
      "/mail/mailtemplate",
      mailTemplate
    );
    return response.data;
  }
);

export const updateMailTemplate = createAppAsyncThunk(
  prefix("updateMailTemplate"),
  async (mailTemplate: MailTemplate) => {
    const response = await axios.post<MailTemplate>(
      "/mail/mailtemplate/update",
      mailTemplate
    );
    return response.data;
  }
);

export const deleteMailTemplate = createAppAsyncThunk(
  prefix("deleteMailTemplate"),
  async (id: number) => {
    await axios.delete(`/mail/mailtemplate/${id}`);
    return id;
  }
);

const mailSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createMailConnection.fulfilled, (state, action) => {
      state.connections.push(action.payload);
    });

    builder.addCase(updateMailConnection.fulfilled, (state, action) => {
      const index = state.connections.findIndex(
        (x) => x.id === action.payload.id
      );
      if (index >= 0) {
        state.connections[index] = action.payload;
      }
    });

    builder.addCase(deleteMailConnection.fulfilled, (state, action) => {
      state.connections = state.connections.filter(
        (x) => x.id !== action.payload
      );
    });

    builder.addCase(createMailTemplate.fulfilled, (state, action) => {
      state.templates.push(action.payload);
    });

    builder.addCase(updateMailTemplate.fulfilled, (state, action) => {
      const index = state.templates.findIndex(
        (x) => x.id === action.payload.id
      );
      if (index >= 0) {
        state.templates[index] = action.payload;
      }
    });

    builder.addCase(deleteMailTemplate.fulfilled, (state, action) => {
      state.templates = state.templates.filter((x) => x.id !== action.payload);
    });

    builder.addCase(getConnections.fulfilled, (state, action) => {
      state.connections = action.payload;
    });

    builder.addCase(getMailTemplates.fulfilled, (state, action) => {
      state.templates = action.payload;
    });

    // sets loadind to 'true' while request is pending for all thunks
    builder.addMatcher(isPending, (state) => {
      state.loading = true;
    });

    // logs error if a request is rejected
    builder.addMatcher(isRejected, (_, action) => {
      console.error(action.error.message);
    });

    // sets loading to 'false' if a request completed (rejected or resolved)
    builder.addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
      state.loading = false;
    });
  },
});

export const selectMailConnectionsByProjectId =
  (projectId: number) =>
    (rootState: RootState): MailConnection[] =>
      rootState.mail.connections.filter((x) => x.projectId === projectId);
export const selectMailTemplatesByProjectId =
  (projectId: number) =>
    (rootState: RootState): MailTemplate[] =>
      rootState.mail.templates.filter((x) => x.projectId === projectId);
export const selectMailTemplateById =
  (templateId: number) =>
    (rootState: RootState): MailTemplate =>
      rootState.mail.templates.find((x) => x.id === templateId) ?? defaultMailTemplate;

export default mailSlice.reducer;
