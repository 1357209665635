import { Avatar, Badge, Box, Card, Paper, styled, Typography } from '@mui/material';
import * as React from 'react';
import { useAppSelector } from '../../redux/hooks';
import { selectOnlineUsers } from '../../redux/users/usersSlice';
import { getFormattedDate } from '../../Utility';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

export default function OnlineUserWidget() {

    const online = useAppSelector(selectOnlineUsers);


    return (
        <Paper sx={{
            p: '20px',
        }}>
            <Typography 
                variant='h6'
                sx={{
                    mb: '20px'
                }}
            >
                {`${online.length} user${online.length === 1 ? '': 's'} online:`}
            </Typography>
            {
                online.map(x => {
                    const namedisplay = x.firstName && x.lastName 
                        ? `${x.firstName} ${x.lastName} (${x.username})` : x.username
                    return (
                    <Paper key={`online-badge-${x.username}`} sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-start', 
                        alignItems: 'center', 
                        p: '10px',
                        mb: '15px' 
                    }}>
                        <Box
                            sx={{
                                mr: '10px'
                            }}
                        >
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                            >
                                <Avatar 
                                src="/placeholder.JPG"
                                alt="placeholder"
                                sx={{
                                    width: 40,
                                    height: 40,
                                }}
                                />
                            </StyledBadge>
                        </Box>
                        <Box>
                            <Typography sx={{ fontWeight: 'bold' }}>{namedisplay}</Typography>
                            <Typography variant='body2'>{`Active since: ${getFormattedDate(x.activeSince, true)}`}</Typography>
                        </Box>
                    </Paper>
                )})
            }
        </Paper>
    ) 
}