import { Grid } from '@mui/material';
import React from 'react';
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import ValueWithUnitInput from '../../../../../utils/Inputs/ValueWithUnitInput';
import { BlockStyleAttribute } from '../BlockStyleInputs';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void, 
    selectedBlock?: BlockData,
}

export default function GapInputs(props: Props) {

    return (
        <>
            <BlockStyleDataLabel name="colgap" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='column-gap' {...props} />
            </Grid>
            <BlockStyleDataLabel name="rowgap" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey='row-gap' {...props} />
            </Grid>
        </>
    )
}