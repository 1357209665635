import React, { createContext, useMemo } from "react";
import { ActionState } from "./blockActions/ActionExecutionContext";
import useActionDebugContext from "../hooks/useActionDebugContext";

type NodeInfoContextType = {
  nodeId: string | undefined;
  nodeState: ActionState | undefined;
};

const initial: NodeInfoContextType = {
  nodeId: undefined,
  nodeState: undefined,
};

const NodeInfoContext = createContext(initial);

export default NodeInfoContext;

interface Props {
  id: string;
}

export const NodeInfoContextProvider = (
  props: React.PropsWithChildren<Props>
) => {
  const { children, id } = props;

  const { processState } = useActionDebugContext();

  const value = useMemo(
    () => ({
      nodeId: id,
      nodeState: processState.actionStates[id],
    }),
    [processState, id]
  );

  return (
    <NodeInfoContext.Provider value={value}>
      {children}
    </NodeInfoContext.Provider>
  );
};
