import React from "react";
import {
  BlockData,
  BlockType,
  PropAssignmentData,
} from "../../../../../redux/blocks/types";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { AddOutlined, Block, Remove } from "@mui/icons-material";
import {
  DynPropAssignment,
  DynProps,
} from "../../../../../redux/dyndata/types";
import TitleBox from "../../../../widgets/TitleBox";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  defaultPropAssignment,
  selectBlock,
  selectBlocks,
  updateBlockPropAssignments,
} from "../../../../../redux/blocks/blockSlice";
import useRouteParam from "../../../../../hooks/useRouteParam";
import { selectPageById } from "../../../../../redux/page/pageSlice";
import {
  selectDynData,
  selectDynModels,
} from "../../../../../redux/dyndata/dyndataSlice";
import { zhCN } from "@mui/x-data-grid";

interface Props {
  currentBlock: BlockData;
}

interface SelectionProperty {
  dynProp: DynProps;
  dynDatSetId?: number;
  id: number;
}

export default function PropAssignmentInputs(props: Props) {
  const { currentBlock } = props;

  const refType = useRouteParam("ref");
  const refId = useRouteParam("refId");

  const dispatch = useAppDispatch();
  const selectedPage = useAppSelector(selectPageById(refId));
  const blocks = useAppSelector(selectBlocks);
  const models = useAppSelector(selectDynModels);
  const dataSets = useAppSelector(selectDynData);

  const currentProps = React.useMemo((): SelectionProperty[] => {
    let properties: SelectionProperty[] = [];
    let id = 1;

    if (selectedPage) {
      const pageModel = models.find(
        (x) => x.id === selectedPage?.dynDataSet?.dynModelId
      );
      pageModel?.dynPropSets.forEach((x) => {
        if (
          !properties.find(
            (y) =>
              y.dynProp.id === x.id &&
              y.dynDatSetId === selectedPage.dynDataSet?.id
          )
        ) {
          properties.push({
            dynProp: x,
            dynDatSetId: selectedPage.dynDataSet?.id,
            id,
          });
          id++;
        }
      });
    }

    const loopBlocks = blocks.filter(
      (x) =>
        x.blockType === BlockType.LOOP &&
        currentBlock.locationPath.startsWith(x.locationPath)
    );

    loopBlocks.forEach((x) => {
      const dataSetId = x.blockAttributes.find(
        (y) => y.name === "data-set"
      )?.value;
      const loopModel = models.find((y) => y.id.toString() === dataSetId);
      loopModel?.dynPropSets.forEach((y) => {
        if (
          !properties.find(
            (z) => z.dynProp.id === y.id && z.dynDatSetId === dataSetId
          )
        ) {
          properties.push({ dynProp: y, dynDatSetId: Number(dataSetId), id });
          id++;
        }
      });
    });

    return properties;
  }, [selectedPage, models, dataSets, currentBlock, blocks]);

  const handleAssignmentDelete = React.useCallback(
    (id: number) => {
      if (currentBlock)
        dispatch(
          updateBlockPropAssignments(
            currentBlock.dynPropAssignments.filter((x) => x.id !== id)
          )
        );
    },
    [currentBlock, dispatch]
  );

  const handleAssignmentChange = React.useCallback(
    (assignment: DynPropAssignment) => {
      if (currentBlock)
        dispatch(
          updateBlockPropAssignments([
            ...currentBlock.dynPropAssignments.filter(
              (x) => x.id !== assignment.id
            ),
            assignment,
          ])
        );
    },
    [currentBlock, dispatch]
  );

  const handleAssignmentDynPropChange = React.useCallback(
    (assignment: DynPropAssignment, id: number) => {
      const selectionProperty = currentProps.find((x) => x.id === id);
      if (currentBlock && selectionProperty)
        dispatch(
          updateBlockPropAssignments([
            ...currentBlock.dynPropAssignments.filter(
              (x) => x.id !== assignment.id
            ),
            {
              ...assignment,
              dynPropId: selectionProperty.dynProp.id,
              dynDataSetId: selectionProperty.dynDatSetId,
            },
          ])
        );
    },
    [currentBlock, dispatch, currentProps]
  );

  const handlePropAssignmentCreate = React.useCallback(() => {
    if (currentBlock)
      dispatch(
        updateBlockPropAssignments([
          ...currentBlock.dynPropAssignments,
          { ...defaultPropAssignment, blockDataId: currentBlock.id },
        ])
      );
  }, [currentBlock, dispatch]);

  const assignmentInputs = React.useMemo(
    () =>
      [...currentBlock.dynPropAssignments]
        .sort((a, b) => a.id - b.id)
        .map((assignment) => (
          <TableRow
            key={assignment.id}
            sx={{ "&>*": { padding: "0 !important" } }}
          >
            <TableCell>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="block-prop-select">Block property</InputLabel>
                <Select
                  labelId="block-prop-select"
                  onChange={(e) =>
                    handleAssignmentChange({
                      ...assignment,
                      blockProp: e.target.value,
                    })
                  }
                  value={assignment.blockProp}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="content">
                    <em>content</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="prop-select">Property</InputLabel>
                <Select
                  labelId="prop-select"
                  onChange={(e) =>
                    handleAssignmentDynPropChange(
                      assignment,
                      Number(e.target.value)
                    )
                  }
                  value={
                    currentProps.find(
                      (x) =>
                        x.dynDatSetId === assignment.dynDataSetId &&
                        x.dynProp.id === assignment.dynPropId
                    )?.id
                  }
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {currentProps.map((property) => {
                    const model = models.find(
                      (x) => x.id === property.dynProp.dynModelId
                    );

                    return (
                      <MenuItem key={property.id} value={property.id}>
                        <em>{`${model?.name}-${property?.dynDatSetId}-${property.dynProp.tag}`}</em>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell sx={{ width: "5px" }}>
              <IconButton onClick={() => handleAssignmentDelete(assignment.id)}>
                <Remove />
              </IconButton>
            </TableCell>
          </TableRow>
        )),
    [
      currentBlock,
      handleAssignmentDelete,
      handleAssignmentChange,
      models,
      currentProps,
    ]
  );

  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={1}>
      <Grid item xs={12}>
        <TitleBox
          mainTitle={"PROPERTY ASSIGNMENTS"}
          maintitleVariant="subtitle1"
          subTitle={"Configure block property assignments."}
        >
          <Tooltip title="Add property assignment">
            <span>
              <IconButton aria-label="add" onClick={handlePropAssignmentCreate}>
                <AddOutlined />
              </IconButton>
            </span>
          </Tooltip>
        </TitleBox>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "50%" }}>block property</TableCell>
                <TableCell sx={{ width: "50%" }}>prop</TableCell>
                <TableCell sx={{ width: "5px" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{assignmentInputs}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
