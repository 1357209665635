import { TextField, InputAdornment, Select, MenuItem, IconButton, Popover, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { BlockData, BlockStyle } from '../../../redux/blocks/types';
import { extractNumber, getStyleById, isStyleInheritedAndChanged } from '../../../Utility';
import { add, debounce } from 'lodash';
import { BlockStyleAttribute } from '../../builder/BlockInfo/BlockDetail/BlockStyleEdit/BlockStyleInputs';
import BlockStyleDataResetBadge from './BlockStyleDataResetBadge';

interface Props {
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    attributeKey: string,
    selectedBlock?: BlockData
    type?: string,
    handleBlockStyleDataReset: (attributeKey: string) => void,
    baseBlockStyle: BlockStyle,
}

export default function StyleTextField(props: Props) {
    const { currentBlockStyle, attributeKey, handleAttributeChange, selectedBlock, type } = props;

    const [inputValue, setInputValue] = useState('0px');

    useEffect(() => {
        const value = currentBlockStyle.blockStyleData.find(x => x.attributeKey === attributeKey)?.attributeValue ?? getStyleById(selectedBlock, attributeKey);
        setInputValue(value);
    }, [currentBlockStyle])

    const handleInputChange = React.useCallback((attributeValue: string) => {
        setInputValue(attributeValue);
        debouncedChangeHandler([{ attributeKey, attributeValue }]);
    }, [handleAttributeChange, attributeKey]);

    const debouncedChangeHandler = useCallback(
        debounce(handleAttributeChange, 1000),
        [handleAttributeChange],
    );

    const handleBlur = React.useCallback(() => {
        debouncedChangeHandler.flush();
    }, [debouncedChangeHandler]);

    return (
        <BlockStyleDataResetBadge {...props}>
            <TextField
                value={inputValue}
                onChange={(e) => handleInputChange(e.target.value)}
                onBlur={handleBlur}
                variant='filled'
                size='small'
                type={type}
                hiddenLabel
                fullWidth
            />
        </BlockStyleDataResetBadge>
    );
}