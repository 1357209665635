import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import {
  DynDataEntry,
  DynDataSet,
  DynDataType,
  DynModel,
  DynProps,
} from "./types";
import { BlockType } from "../blocks/types";

const PREFIX = "dyndata";

const prefix = (str: string) => `${PREFIX}/${str}`;

export interface DynDataState {
  models: DynModel[];
  dataSets: DynDataSet[];
  entries: DynDataEntry[];
}

export const initialState: DynDataState = {
  models: [],
  dataSets: [],
  entries: [],
};

export const defaultEntry: DynDataEntry = {
  id: 0,
  name: "new Entry",
  data: JSON.stringify({}),
  projectId: 0,
};

export const defaultModel: DynModel = {
  id: 0,
  dynPropSets: [],
  name: "newModel",
  projectId: 0,
};

export const defaultProp: DynProps = {
  id: 0,
  dynModelId: 0,
  tag: "newPropTag",
  type: DynDataType.text,
};

export const defaultDataSet: DynDataSet = {
  id: 0,
  name: "newDataSet",
  dynDataEntries: [],
  dynModelId: undefined,
  projectId: 0,
};

export const createModel = createAppAsyncThunk(
  prefix("createModel"),
  async (newModel: DynModel) => {
    const response = await axios.post<DynModel>("/dyndata/model", newModel);
    return response.data;
  }
);

export const updateModel = createAppAsyncThunk(
  prefix("updateModel"),
  async (dynModel: DynModel) => {
    const response = await axios.post<DynModel>(
      "/dyndata/model/update",
      dynModel
    );
    return response.data;
  }
);

export const deleteModel = createAppAsyncThunk(
  prefix("deleteModel"),
  async (id: number) => {
    await axios.delete(`/dyndata/model/${id}`);
    return id;
  }
);

export const getModels = createAppAsyncThunk(
  prefix("getModels"),
  async (projectId: number) => {
    const response = await axios.get<DynModel[]>(`/dyndata/model/${projectId}`);
    return response.data;
  }
);

export const getDataSets = createAppAsyncThunk(
  prefix("getDataSets"),
  async (projectId: number) => {
    const response = await axios.get<DynDataSet[]>(`/dyndata/${projectId}`);
    return response.data;
  }
);

export const getDataEntries = createAppAsyncThunk(
  prefix("getDataEntries"),
  async (projectId: number) => {
    const response = await axios.get<DynDataEntry[]>(
      `/dyndata/entry/${projectId}`
    );
    return response.data;
  }
);

export const createDataEntry = createAppAsyncThunk(
  prefix("createDataEntry"),
  async (newEntry: DynDataEntry) => {
    const response = await axios.post<DynDataEntry>("/dyndata/entry", newEntry);
    return response.data;
  }
);

export const createDataSet = createAppAsyncThunk(
  prefix("createDataSet"),
  async (newDataSet: DynDataSet) => {
    const response = await axios.post<DynDataSet>("/dyndata", newDataSet);
    return response.data;
  }
);

export const updateDataEntry = createAppAsyncThunk(
  prefix("updateDataEntry"),
  async (newEntry: DynDataEntry) => {
    const response = await axios.post<DynDataEntry>(
      "/dyndata/entry/update",
      newEntry
    );
    return response.data;
  }
);

export const updateDataSet = createAppAsyncThunk(
  prefix("updateDataSet"),
  async (dynDataSet: DynDataSet) => {
    const response = await axios.post<DynDataSet>(
      "/dyndata/update",
      dynDataSet
    );
    return response.data;
  }
);

export const deleteDataEntry = createAppAsyncThunk(
  prefix("deleteDataEntry"),
  async (id: number) => {
    await axios.delete(`/dyndata/entry/${id}`);
    return id;
  }
);

export const deleteDataSet = createAppAsyncThunk(
  prefix("deleteDataSet"),
  async (id: number) => {
    await axios.delete(`/dyndata/${id}`);
    return id;
  }
);

export const deleteDynProp = createAppAsyncThunk(
  prefix("deleteDynProp"),
  async (propId: number) => {
    await axios.delete(`/dyndata/dynprop/${propId}`);
    return propId;
  }
);

export const updateDynProp = createAppAsyncThunk(
  prefix("updateDynProp"),
  async (dynProp: DynProps) => {
    const response = await axios.post<DynProps>(
      "/dyndata/dynprop/update",
      dynProp
    );
    return response.data;
  }
);

const dyndataSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createModel.fulfilled, (state, action) => {
      state.models.push(action.payload);
    });

    builder.addCase(updateModel.fulfilled, (state, action) => {
      const index = state.models.findIndex((x) => x.id === action.payload.id);
      if (index >= 0) {
        state.models[index] = action.payload;
      }
    });

    builder.addCase(deleteModel.fulfilled, (state, action) => {
      state.models = state.models.filter((x) => x.id !== action.payload);
    });

    builder.addCase(getModels.fulfilled, (state, action) => {
      state.models = action.payload;
    });

    builder.addCase(getDataEntries.fulfilled, (state, action) => {
      state.entries = action.payload;
    });

    builder.addCase(getDataSets.fulfilled, (state, action) => {
      state.dataSets = action.payload;
    });

    builder.addCase(createDataEntry.fulfilled, (state, action) => {
      state.entries.push(action.payload);
    });

    builder.addCase(createDataSet.fulfilled, (state, action) => {
      state.dataSets.push(action.payload);
    });

    builder.addCase(updateDataEntry.fulfilled, (state, action) => {
      const index = state.entries.findIndex((x) => x.id === action.payload.id);
      if (index >= 0) {
        state.entries[index] = action.payload;
      }
    });

    builder.addCase(updateDataSet.fulfilled, (state, action) => {
      const index = state.dataSets.findIndex((x) => x.id === action.payload.id);
      if (index >= 0) {
        state.dataSets[index] = action.payload;
      }
    });

    builder.addCase(deleteDataSet.fulfilled, (state, action) => {
      state.dataSets = state.dataSets.filter((x) => x.id !== action.payload);
    });

    builder.addCase(deleteDataEntry.fulfilled, (state, action) => {
      state.entries = state.entries.filter((x) => x.id !== action.payload);
    });

    builder.addCase(deleteDynProp.fulfilled, (state, action) => {
      const index = state.models.findIndex((x) => x.dynPropSets.findIndex(y => y.id === action.payload) >= 0);
      if (index >= 0) {
        state.models[index] = {
          ...state.models[index],
          dynPropSets: [
            ...state.models[index].dynPropSets.filter(
              (x) => x.id !== action.payload
            ),
          ],
        };
      }
    });

    builder.addCase(updateDynProp.fulfilled, (state, action) => {
      const modelIndex = state.models.findIndex(
        (x) => x.id === action.payload.dynModelId
      );
      if (modelIndex < 0) return;

      const propIndex = state.models[modelIndex].dynPropSets.findIndex(
        (x) => x.id === action.payload.id
      );

      if (propIndex >= 0)
        state.models[modelIndex].dynPropSets[propIndex] = action.payload;
    });

    builder.addMatcher(isRejected, (_, action) => {
      console.error(action.error.message);
    });
  },
});

// selectors
export const selectDynModelById = (modelId: number) => (rootState: RootState) =>
  rootState.dyndata.models.find((x) => x.id === modelId) ?? defaultModel;
export const selectDynDataSetById =
  (dataSetId: number) => (rootState: RootState) =>
    rootState.dyndata.dataSets.find((x) => x.id === dataSetId) ??
    defaultDataSet;
export const selectDynDataEntrySetById =
  (entryId: number) => (rootState: RootState) =>
    rootState.dyndata.entries.find((x) => x.id === entryId) ?? defaultEntry;
export const selectEntries = (rootState: RootState) =>
  rootState.dyndata.entries;

export const selectDynModels = (rootState: RootState) => {
  return rootState.dyndata.models;
};

export const selectDynData = (rootState: RootState) => {
  return rootState.dyndata.dataSets;
};

export default dyndataSlice.reducer;
