import React from 'react';
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import { ViewDay, ViewColumn, GridView, ViewArray, FormatColorText, VisibilityOff, AlignHorizontalCenter, AlignHorizontalLeft, AlignHorizontalRight, AlignVerticalTop, AlignVerticalBottom, ArrowLeft, ArrowRight, ArrowDownward, ArrowUpward, ArrowForward, ArrowBack, Start } from '@mui/icons-material';
import { Grid, Typography, Box, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import ValueWithUnitInput from '../../../../../utils/Inputs/ValueWithUnitInput';
import { getStyleById } from '../../../../../../Utility';
import StyleToggleButtonGroup from '../../../../../utils/Inputs/StyleToggleButtonGroup';
import { BlockStyleAttribute } from '../BlockStyleInputs';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void, 
    selectedBlock?: BlockData,
}

export default function FlexInputs(props: Props) {
    const { currentBlockStyle, handleAttributeChange, selectedBlock } = props;

    return (
        <>
            <BlockStyleDataLabel name="Direction" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='flex-direction'>
                    <ToggleButton value="row" key="row">
                        <Tooltip title="Arranges items horizontally from left to right">
                            <ArrowForward />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="row-reverse" key="row-reverse">
                        <Tooltip title="Arranges items horizontally from right to left">
                            <ArrowBack />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="column" key="column">
                        <Tooltip title="Stacks items vertically from top to bottom">
                            <ArrowDownward />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="column-reverse" key="column-reverse">
                        <Tooltip title="Stacks items vertically from bottom to top">
                            <ArrowUpward />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Align" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='align-items'>
                    <ToggleButton value="start" key="start">
                        <Tooltip title="Aligns items to the start of the cross axis">
                            <Start />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="center" key="center">
                        <Tooltip title="Centers items along the cross axis">
                            <AlignHorizontalCenter />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="end" key="end">
                        <Tooltip title="Aligns items to the end of the cross axis">
                            <GridView />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="stretch" key="stretch">
                        <Tooltip title="Stretches items to fill the container along the cross axis">
                            <ViewArray />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="baseline" key="baseline">
                        <Tooltip title="Aligns items based on the baseline of their content">
                            <ViewArray />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Justify" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='justify-content'>
                    <ToggleButton value="start" key="start">
                        <Tooltip title="Aligns items to the start of the main axis">
                            <Start />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="center" key="center">
                        <Tooltip title="Centers items along the main axis">
                            <AlignHorizontalCenter />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="end" key="end">
                        <Tooltip title="Aligns items to the end of the main axis">
                            <GridView />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="space-between" key="space-between">
                        <Tooltip title="Distributes items evenly, with the first item at the start and the last item at the end">
                            <ViewArray />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="space-around" key="space-around">
                        <Tooltip title="Distributes items evenly with equal space around them">
                            <ViewArray />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="space-evenly" key="space-evenly">
                        <Tooltip title="Distributes items so that the spacing between any two items (and the space to the edges) is equal">
                            <ViewArray />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="wrap" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='flex-wrap'>
                    <ToggleButton value="wrap" key="wrap">
                        <Tooltip title="Allows flex items to wrap onto multiple lines if needed">
                            <ViewDay />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="wrap-reverse" key="wrap-reverse">
                        <Tooltip title="Allows flex items to wrap onto multiple lines in reverse order">
                            <ViewColumn />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="nowrap" key="nowrap">
                        <Tooltip title="Keeps all flex items on a single line, even if it causes overflow">
                            <GridView />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
        </>
    );
}