import { Box } from '@mui/system';
import * as React from 'react';
import OnlineUserWidget from '../widgets/OnlineUsersWidget';

export default function Dashboard() {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
            }}
        >
            <OnlineUserWidget />
        </Box>
    )
}