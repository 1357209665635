export interface ProjectFont {
    id: number,
    family: string,
    weight: number,
    style: FontStyle,
    fileId?: number,
    projectId?: number,
}

export enum FontStyle {
    normal = 'normal',
    italic = 'italic',
    oblique = 'oblique'
}