import { Alert, Button, Popover } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

interface Props {
    children: React.ReactNode;
    open: boolean,
    handleClose: () => void,
    handleSubmit: () => void,
}

export default function ConfirmationPopover(props: Props) {
    const { open, handleClose, handleSubmit, children } = props;

    const divRef = React.useRef<HTMLDivElement | null>(null);

    return (
        <>
            {children}
            <Box sx={{
                display: 'inline'
            }} ref={divRef}/>
            <Popover
                open={open}
                sx={{
                    marginTop: '5px'
                }}
                anchorEl={divRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                >
                <Alert severity="warning"
                    action={
                        <Button onClick={handleSubmit} color="inherit" size="small">
                            CONFIRM
                        </Button>
                    }>
                    Are you sure? 
                </Alert>

            </Popover>
        </>
    );
}