import { createSlice, isRejected, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { ProjectRole } from "../auth/types";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import {
  DeploymentData,
  DeploymentProperties,
  ProjectData,
  ProjectDataSubmission,
} from "./types";
import { addProjectRole } from "../auth/authSlice";

const PREFIX = "project";

const prefix = (str: string) => `${PREFIX}/${str}`;

export interface ProjectState {
  projects: ProjectData[];
  selectedProject: ProjectData | undefined;
}

const initialState: ProjectState = {
  projects: [],
  selectedProject: undefined,
};

export const defaultProjectData: ProjectDataSubmission = {
  id: 0,
  name: "new Project",
  pages: [],
  deployments: [],
};

export const getProjects = createAppAsyncThunk(
  prefix("getProjects"),
  async () => {
    const response = await axios.get<ProjectData[]>("/project");
    return response.data;
  }
);

export const getProjectDetails = createAppAsyncThunk(
  prefix("getProjectDetails"),
  async (projectId: number) => {
    const response = await axios.get<ProjectData>(`/project/${projectId}`);
    return response.data;
  }
);

export const createProject = createAppAsyncThunk(
  prefix("createProject"),
  async (project: ProjectDataSubmission, thunkAPI) => {
    const response = await axios.post<ProjectData>("/project", project);
    // add created project admin role to creation user
    if (response.data.projectRoles.length > 0) {
      thunkAPI.dispatch(addProjectRole(response.data.projectRoles[0]));
    }
    return response.data;
  }
);

export const updateProject = createAppAsyncThunk(
  prefix("updateProject"),
  async (arg: { id: number; update: { name: string } }) => {
    const response = await axios.post<ProjectData>(
      `/project/${arg.id}/update`,
      arg.update
    );
    return response.data;
  }
);

export const deleteProject = createAppAsyncThunk(
  prefix("deleteProject"),
  async (id: number) => {
    await axios.delete(`/project/${id}`);
    return id;
  }
);

export const deleteDeployments = createAppAsyncThunk(
  prefix("deleteDeployments"),
  async (args: { id: number; projectId: number }) => {
    await axios.delete(`/deployment/${args.id}`);
    return args;
  }
);

export const addOrUpdateProjectRole = createAppAsyncThunk(
  prefix("addOrUpdateProjectRole"),
  async (r: ProjectRole) => {
    const response = await axios.patch<ProjectRole>("/project/role", {
      ...r,
      projectId: r.projectDataId,
    });
    return response.data;
  }
);

export const removeProjectRole = createAppAsyncThunk(
  prefix("removeProjectRole"),
  async (r: ProjectRole) => {
    const response = await axios.patch<number>("/project/remrole", {
      ...r,
      projectId: r.projectDataId,
    });
    return response.data;
  }
);

export const deployData = createAppAsyncThunk(
  prefix("deploy"),
  async function (args: DeploymentProperties) {
    const response = await axios.post<DeploymentData>("/deployment", args);
    return response.data;
  }
);

const projectSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    selectProject(state, action: PayloadAction<ProjectData | undefined>) {
      const project = action.payload;
      const currentSelectedProject = state.selectedProject;

      if (project?.id != currentSelectedProject?.id) {
        state.selectedProject = project;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
    });
    builder.addCase(getProjectDetails.fulfilled, (state, action) => {
      const idx = state.projects.findIndex((x) => x.id === action.meta.arg);
      if (idx >= 0) {
        state.projects[idx] = action.payload;
      }
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.projects.push(action.payload);
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      const index = state.projects.findIndex((x) => x.id === action.payload.id);
      state.projects[index] = action.payload;
      if (state.selectedProject?.id === action.payload.id) {
        state.selectedProject = action.payload;
      }
    });
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.projects = state.projects.filter((x) => x.id !== action.payload);
    });
    builder.addCase(addOrUpdateProjectRole.fulfilled, (state, action) => {
      const index = state.projects.findIndex(
        (x) => x.id === action.payload.projectDataId
      );
      if (index >= 0) {
        const roleIndex = state.projects[index].projectRoles.findIndex(
          (x) => x.id === action.payload.id
        );
        if (roleIndex >= 0) {
          state.projects[index].projectRoles[roleIndex] = action.payload;
        } else {
          state.projects[index].projectRoles.push(action.payload);
        }
      }
    });

    builder.addCase(removeProjectRole.fulfilled, (state, action) => {
      const index = state.projects.findIndex(
        (x) => x.id === action.meta.arg.projectDataId
      );
      if (index >= 0) {
        state.projects[index].projectRoles = state.projects[
          index
        ].projectRoles.filter((x) => x.id !== action.payload);
      }
    });

    builder.addCase(deleteDeployments.fulfilled, (state, action) => {
      const index = state.projects.findIndex(
        (x) => x.id === action.payload.projectId
      );
      if (index >= 0) {
        state.projects[index].deployments = state.projects[
          index
        ].deployments.filter((x) => x.id !== action.payload.id);
      }
    });

    builder.addCase(deployData.fulfilled, (state, action) => {
      const index = state.projects.findIndex(
        (x) => x.id === action.payload.projectId
      );
      if (index >= 0) {
        state.projects[index].deployments.push(action.payload);
      }
    });
    // logs error if a request is rejected
    builder.addMatcher(isRejected, (_, action) => {
      console.error(action);
    });
  },
});

export const { selectProject } = projectSlice.actions;

export const selectProjects = (rootState: RootState) =>
  rootState.projects!.projects ?? [];
export const selectSelectedProject = (
  rootState: RootState
): ProjectData | undefined => rootState.projects!.selectedProject;
export const selectProjectById = (id: number) => (rootState: RootState) => {
  const projects = rootState.projects.projects.filter((x) => x.id === id);
  if (projects.length === 1) return projects[0];
  if (projects.length <= 0) {
    return undefined;
  }
  console.error(
    "State consistency failure! Cannot have multiple users with the same ids: ",
    projects.map((x) => x.id)
  );
};

export default projectSlice.reducer;
