import { Add, Cancel, Delete, DeleteOutlined, Edit, FontDownload, Save } from '@mui/icons-material';
import { Box, Button, IconButton, useTheme } from '@mui/material';
import React, { useState } from 'react';
import TitleBox from '../../widgets/TitleBox';
import { createRequest, defaultRequest, deleteRequest, getRequests } from '../../../redux/requests/requestSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { GridActionsCellItem, GridColDef, GridEventListener, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, GridRowSelectionModel, MuiEvent } from '@mui/x-data-grid';
import ConfirmationPopover from '../../utils/ConfirmationPopover';
import MorePopover from '../../utils/MorePopOver';
import StyledDataGrid from '../../utils/StyledDataGrid';
import { useNavigate } from 'react-router';
import { BlockRequest } from '../../../redux/requests/types';
import { createFont, defaultFont, deleteFont, getFonts, selectFonts, updateFont } from '../../../redux/fonts/fontSlice';
import { deleteLanguage } from '../../../redux/language/languageSlice';
import yup from '../../../validation/yup';
import SnackBarOperations from '../../../components/SnackBar/SnackBarOperations';
import { updateMailConnection } from '../../../redux/mail/mailSlice';
import { MailConnection } from '../../../redux/mail/types';
import { FontStyle, ProjectFont } from '../../../redux/fonts/types';
import CustomGridMediaSelect from './CustomGridMediaSelect';
import { selectMedia } from '../../../redux/media/mediaSlice';
import { number } from 'yup';
import DeleteConfirmationDialog from '../../utils/DeleteConfirmationDialog';

interface Props {
    projectId: number
}

export default function Requests(props: Props) {
    const { projectId } = props;
    const [fontConfirmationPopoverOpen, setFontConfirmationPopoverOpen] = useState(false);
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);
    const [fontRowModesModel, setFontRowModesModel] = React.useState<GridRowModesModel>({});

    const theme = useTheme();
    const dispatch = useAppDispatch();

    const fonts = useAppSelector(selectFonts);
    const media = useAppSelector(selectMedia);
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(getFonts(projectId));
    }, []);

    const handleFontCreate = React.useCallback(() => {
        dispatch(createFont({ ...defaultFont, projectId }));
    }, [dispatch, projectId, navigate]);

    const handleFontsConfirmationPopoverSubmit = React.useCallback(() => {
        selectedIds.forEach(x => dispatch(deleteFont(Number(x))));
        setFontConfirmationPopoverOpen(false);
    }, [selectedIds, setFontConfirmationPopoverOpen, dispatch])

    const handleFontEditClick = React.useCallback((id: GridRowId) => () => {
        setFontRowModesModel({ ...fontRowModesModel, [id]: { mode: GridRowModes.Edit } });
    }, [setFontRowModesModel, fontRowModesModel]);

    const handleFontSaveClick = React.useCallback((id: GridRowId) => () => {
        setFontRowModesModel({ ...fontRowModesModel, [id]: { mode: GridRowModes.View } });
    }, [setFontRowModesModel, fontRowModesModel]);

    const handleFontCancelClick = React.useCallback((id: GridRowId) => () => {
        setFontRowModesModel({
            ...fontRowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    }, [setFontRowModesModel, fontRowModesModel]);

    const handleFontDeleteClick = React.useCallback((id: GridRowId) => () => {
        dispatch(deleteFont(Number(id)));
    }, [dispatch]);

    const handleFontRowEditStart = React.useCallback((
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleFontRowEditStop: GridEventListener<'rowEditStop'> = React.useCallback((params, event) => {
        event.defaultMuiPrevented = true;
    }, []);

    const fontSchema = React.useMemo(() => yup.object().shape({
        weight: yup.number().required('Weight is required'),
        fileId: yup.number().required('File is required'),
    }), []);

    const processFontRowUpdate = React.useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };

        try {
            await fontSchema.validate(updatedRow, { abortEarly: false });
            await dispatch(updateFont(newRow as ProjectFont));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                err.errors.forEach((strErr) => SnackBarOperations.error(strErr));
                throw err;
            }
            console.error(err);
        }

        return updatedRow;
    }, [dispatch, fontSchema]);

    const columns: GridColDef[] = [
        {
            field: 'fileId',
            headerName: 'file',
            minWidth: 100,
            flex: 1,
            editable: true,
            type: 'number',
            renderEditCell(params) {
                return <CustomGridMediaSelect {...params} />
            },
            renderCell(params) {
                const file = media.find(x => x.id === params.value);
                if (file)
                    return `${file.name}${file.extension}`;
                else
                    return 'no file selected'
            }
        },
        {
            field: 'family',
            headerName: 'family',
            minWidth: 100,
            flex: 1,
            editable: true,
        },
        {
            field: 'weight',
            headerName: 'weight',
            minWidth: 100,
            flex: 1,
            editable: true,
            type: 'singleSelect',
            valueOptions: [100, 200, 300, 400, 500, 600, 700, 800, 900],
        },
        {
            field: 'style',
            headerName: 'style',
            minWidth: 100,
            flex: 1,
            editable: true,
            type: 'singleSelect',
            valueOptions: [FontStyle.normal, FontStyle.italic, FontStyle.oblique],
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                const isInEditMode = fontRowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key="save"
                            icon={<Save />}
                            label="Save"
                            onClick={handleFontSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key="cancel"
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleFontCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key="edit"

                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={handleFontEditClick(id)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleFontDeleteClick(id)}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}                            
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        },
    ];

    return (
        <>
            <TitleBox
                avatarIcon={<FontDownload />}
                mainTitle='Fonts'
                subTitle='Lorem ipsum'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>
                <ConfirmationPopover
                    handleClose={() => setFontConfirmationPopoverOpen(false)}
                    handleSubmit={handleFontsConfirmationPopoverSubmit}
                    open={fontConfirmationPopoverOpen}>
                    <IconButton component="label" onClick={() => setFontConfirmationPopoverOpen(true)}>
                        <DeleteOutlined />
                    </IconButton>
                </ConfirmationPopover>
                <Button variant="contained" sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />} onClick={handleFontCreate}>
                    ADD FONT
                </Button>
            </TitleBox>
            <Box sx={{ height: '600px', width: '100%', mb: theme.spacing(2) }}>
                <StyledDataGrid
                    rows={fonts}
                    columns={columns}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    onRowSelectionModelChange={(newSM) => {
                        setSelectedIds(newSM);
                    }}
                    rowSelectionModel={selectedIds}
                    disableRowSelectionOnClick
                    loading={false}
                    editMode='row'
                    rowModesModel={fontRowModesModel}
                    onRowModesModelChange={setFontRowModesModel}
                    onRowEditStart={handleFontRowEditStart}
                    onRowEditStop={handleFontRowEditStop}
                    processRowUpdate={processFontRowUpdate}
                />
            </Box>
        </>
    )
}