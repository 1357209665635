import { ThemeContext } from '@emotion/react';
import { Box, Paper, useTheme } from '@mui/material';
import React from 'react';
import useRouteParam from '../../../../hooks/useRouteParam';
import { ReferenceType } from '../../../../redux/blocks/types';
import TitleBox from '../../../widgets/TitleBox';
import PageDetailInputs from './ContextDetailEdit/PageDetailInputs';
import TemplateDetailInputs from './ContextDetailEdit/TemplateDetailInputs';

export default function ContextTab() {

    const theme = useTheme();

    const refType = useRouteParam('ref');

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <TitleBox
                mainTitle={refType === ReferenceType.Page ? 'Page details' : 'Template details'}
                subTitle='Edit details'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>
            </TitleBox>
                {
                    refType === ReferenceType.Page ? (
                        <PageDetailInputs />
                    ) : refType === ReferenceType.Template ? (
                        <TemplateDetailInputs />
                    ) : null
                }
        </Box>
    )
}