import React, { useEffect } from "react";
import SequenceBuilder from "./SequenceBuilder";
import useRouteParam from "../../../hooks/useRouteParam";
import { useAppDispatch } from "../../../redux/hooks";
import { getGlobalSequence } from "../../../redux/actionsequence/sequenceSlice";
import { SequenceContextProvider } from "../../../context/SequenceContext";

const GlobalSequenceBuilder: React.FC = () => {
  const id = useRouteParam("sequenceId");

  useEffect(() => {
    dispatch(getGlobalSequence({ id }));
  }, []);

  const dispatch = useAppDispatch();

  return (
    <SequenceContextProvider>
      <SequenceBuilder />
    </SequenceContextProvider>
  );
};

export default GlobalSequenceBuilder;
