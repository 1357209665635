import { Cancel } from '@mui/icons-material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { Autocomplete, Chip, FilledInput, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, Tab, TextField, Theme, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { GlobalRole, GLOBAL_PERMISSIONS, Permission, Role } from '../../redux/auth/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getGlobalRoles, selectAllUserRoles } from '../../redux/users/usersSlice';

interface Props {
    onSubmit: (role: GlobalRole) => void;
    submitLoading: boolean;
    userRoles: GlobalRole[];
}

function getStyles(name: Permission, permissions: readonly Permission[] | undefined, theme: Theme) {
    if (!permissions) {
        return {};
    }
    return {
      fontWeight:
        permissions.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}

type RoleOption = {
    label: string,
    role: GlobalRole
}

export default function AddRoleForm(props: Props) {

    const { onSubmit, submitLoading, userRoles } = props;
    
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const [mode, setMode] = React.useState<'new' | 'existing'>('new');
    const [name, setName] = React.useState<string>('');
    const [permissions, setPermissions] = React.useState<Permission[]>([]);
    const [existingRole, setExisingRole] = React.useState<RoleOption | null>(null);

    const allRoles = useAppSelector(selectAllUserRoles);

    const roleOptions : RoleOption[] = React.useMemo(() => {
        return allRoles.map((r) => ({
            label: r.name,
            role: r,
        }))
    }, [allRoles]);

    React.useEffect(() => {
        if (allRoles.length <= 0) {
            dispatch(getGlobalRoles());
        }
    }, [dispatch]);

    const handlePermissionsChange = React.useCallback((event: SelectChangeEvent<typeof permissions>) => {
        const { target: { value }} = event;
        if (typeof value === 'string') {
            return;
        }
        setPermissions(value);
    }, [setPermissions]);

    const handlePermissionDelete = React.useCallback((val: Permission) => {
        setPermissions((prev) => prev.filter(x => x !== val));
    }, [setPermissions]);

    const handleExistingPermissionChange = React.useCallback((newValue: RoleOption | null) => {
        setExisingRole(newValue);
    }, [setExisingRole]);

    const userAlreadyHasSelectedRole = React.useMemo(() => {
        if (!existingRole) return false;
        return userRoles.findIndex(x => x.id === existingRole.role.id) >= 0;
    }, [userRoles, existingRole]);

    const isLoadingButtonDisabled = React.useMemo(() => {
        if (mode === 'new') {
            return ((name?.length ?? 0) <= 0) || (permissions.length <= 0);
        } else {
            return existingRole === null || userAlreadyHasSelectedRole;
        }
    }, [mode, existingRole, name, permissions, userRoles]);
    
    return (
        <Box sx={{
            padding: theme.spacing(3)
        }}>
            <TabContext value={mode}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(ev, newValue) => setMode(newValue)} aria-label="lab API tabs example">
                        <Tab label="Create new role" value="new" />
                        <Tab label="Add exising role" value="existing" />
                    </TabList>
                </Box>
                <TabPanel value='new'>
                    <Stack
                        component="form"
                        sx={{
                            width: '100%',
                        }}
                        spacing={2}
                        noValidate
                        autoComplete='off'
                    >
                        <FormControl>
                            <TextField 
                                value={name} 
                                onChange={(ev) => setName(ev.target.value)}
                                label='Role name' 
                                variant='filled' id='role-name' 
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id='permission-select-label'>Permissions</InputLabel>
                            <Select 
                                labelId='permission-select-label'
                                id='permission-select'
                                multiple
                                value={permissions}
                                onChange={handlePermissionsChange}
                                input={<FilledInput/>}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxWidth: '200px' }}>
                                        {selected.map((value) => (
                                            <Chip
                                                onDelete={() => handlePermissionDelete(value)} 
                                                size='small' key={value} 
                                                label={value}
                                                deleteIcon={
                                                    <Cancel
                                                    onMouseDown={(event) => event.stopPropagation()}
                                                    />
                                                }
                                            />
                                        ))}
                                    </Box>
                                )}
                            >
                                {GLOBAL_PERMISSIONS.map((permission) => (
                                    <MenuItem
                                        key={permission}
                                        value={permission}
                                        style={getStyles(permission, permissions, theme)}
                                        >
                                        {permission}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                        </FormControl>
                    </Stack>
                </TabPanel>
                <TabPanel value='existing'>
                <Stack
                        component="form"
                        sx={{
                            width: '100%',
                        }}
                        spacing={2}
                        noValidate
                        autoComplete='off'
                    >
                        <Autocomplete 
                            disablePortal
                            options={roleOptions}
                            sx={{ width: '100%'}}
                            renderInput={(params) => <TextField variant='filled' {...params} label="Role" />}
                            value={existingRole}
                            onChange={(ev, newVal) => handleExistingPermissionChange(newVal)}
                        />
                        {userAlreadyHasSelectedRole && <Typography color={theme.palette.error.main}>User already has role</Typography>}
                        <Typography>Permissions</Typography>
                        {existingRole && 
                            <List disablePadding sx={{ maxHeight: '200px', overflowY: 'scroll' }} dense>
                            {
                                existingRole.role.permissions.map(p => (
                                    <ListItem disablePadding dense>
                                        <ListItemText primary={p} />
                                    </ListItem>
                                ))
                            }
                            </List>
                        }
                    </Stack>
                </TabPanel>
            </TabContext>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <LoadingButton 
                    variant='contained' 
                    disabled={isLoadingButtonDisabled}
                    onClick={() => {
                        if (mode === 'new') {
                            onSubmit({
                                id: 0,
                                name: name,
                                permissions: permissions
                            });
                        } else {
                            onSubmit(existingRole!.role);
                        }
                    }}
                    loading={submitLoading}
                    >
                        Submit
                </LoadingButton>
            </Box>
        </Box>
    )
}