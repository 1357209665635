import {
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { BlockData, BlockStyle } from "../../../redux/blocks/types";
import {
  extractNumber,
  getStyleById,
  isStyleInheritedAndChanged,
} from "../../../Utility";
import { add, debounce } from "lodash";
import { getUnit } from "@mui/material/styles/cssUtils";
import { BlockStyleAttribute } from "../../builder/BlockInfo/BlockDetail/BlockStyleEdit/BlockStyleInputs";
import BlockStyleDataResetBadge from "./BlockStyleDataResetBadge";

interface Props {
  baseBlockStyle: BlockStyle;
  currentBlockStyle: BlockStyle;
  handleAttributeChange: (
    blockAttributes: BlockStyleAttribute[],
    ignoreDefault?: boolean
  ) => void;
  handleBlockStyleDataReset: (attributeKey: string) => void;
  attributeKey: string;
  selectedBlock?: BlockData;
  additionalEntries?: string[];
}

export default function ValueWithUnitInput(props: Props) {
  const {
    currentBlockStyle,
    attributeKey,
    handleAttributeChange,
    selectedBlock,
    additionalEntries = [],
  } = props;

  const [unit, setUnit] = useState("px");
  const [inputValue, setInputValue] = useState("0px");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const theme = useTheme();

  useEffect(() => {
    const value =
      currentBlockStyle.blockStyleData.find(
        (x) => x.attributeKey === attributeKey
      )?.attributeValue ?? getStyleById(selectedBlock, attributeKey);
    const newInputValue = additionalEntries.includes(value)
      ? value
      : extractNumber(value);
    setUnit(getUnit(value));
    setInputValue(newInputValue);
  }, [currentBlockStyle]);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleUnitChange = React.useCallback(
    (changedUnit: string) => {
      setUnit(changedUnit);

      if (additionalEntries.includes(changedUnit))
        handleAttributeChange(
          [{ attributeKey, attributeValue: changedUnit }],
          true
        );
      else {
        const numberValue = additionalEntries.includes(inputValue)
          ? 100
          : inputValue;
        handleAttributeChange(
          [{ attributeKey, attributeValue: `${numberValue}${changedUnit}` }],
          true
        );
      }

      handleClose();
    },
    [setUnit, handleClose, attributeKey, additionalEntries]
  );

  const debouncedChangeHandler = useCallback(
    debounce(handleAttributeChange, 1000),
    [handleAttributeChange]
  );

  const handleInputChange = React.useCallback(
    (value: string) => {
      const validPattern = /^-?(?:\d+\.)?\d*$|^-?\.\d+$/;
      if (value !== "" && !validPattern.test(value)) return;

      let newValue = value;

      const u = additionalEntries.includes(unit) ? "px" : unit;

      const newAttrValue = `${newValue}${u}`;
      if (u != unit)
        setUnit(u);
      setInputValue(newValue);
      debouncedChangeHandler([{ attributeKey, attributeValue: newAttrValue }]);
    },
    [
      additionalEntries,
      unit,
      setUnit,
      debouncedChangeHandler,
      attributeKey,
      setInputValue,
    ]
  );

  const handleBlur = React.useCallback(() => {
    debouncedChangeHandler.flush();
}, [debouncedChangeHandler]);

  return (
    <>
      <BlockStyleDataResetBadge {...props}>
        <TextField
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
          onBlur={handleBlur}
          variant="filled"
          size="small"
          hiddenLabel
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  sx={{
                    minWidth: theme.spacing(3),
                    pt: theme.spacing(1),
                    pb: theme.spacing(0.9),
                    borderRadius: 0,
                    borderTopRightRadius: theme.shape.borderRadius,
                    boxSizing: "unset",
                  }}
                  size="small"
                  onClick={handleClick}
                >
                  {additionalEntries.find((x) => x === inputValue) ? "-" : unit}
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </BlockStyleDataResetBadge>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton
              disabled={unit === "px"}
              onClick={() => handleUnitChange("px")}
            >
              <ListItemText primary="px" />
            </ListItemButton>
            <ListItemButton
              disabled={unit === "%"}
              onClick={() => handleUnitChange("%")}
            >
              <ListItemText primary="%" />
            </ListItemButton>
            <ListItemButton
              disabled={unit === "em"}
              onClick={() => handleUnitChange("em")}
            >
              <ListItemText primary="em" />
            </ListItemButton>
            <ListItemButton
              disabled={unit === "rem"}
              onClick={() => handleUnitChange("rem")}
            >
              <ListItemText primary="rem" />
            </ListItemButton>
            <ListItemButton
              disabled={unit === "ch"}
              onClick={() => handleUnitChange("ch")}
            >
              <ListItemText primary="ch" />
            </ListItemButton>
            <ListItemButton
              disabled={unit === "vw"}
              onClick={() => handleUnitChange("vw")}
            >
              <ListItemText primary="vw" />
            </ListItemButton>
            <ListItemButton
              disabled={unit === "vh"}
              onClick={() => handleUnitChange("vh")}
            >
              <ListItemText primary="vh" />
            </ListItemButton>
            {additionalEntries.map((entry) => (
              <ListItemButton
                key={entry}
                disabled={unit === entry}
                onClick={() => handleUnitChange(entry)}
              >
                <ListItemText primary={entry} />
              </ListItemButton>
            ))}
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
