import * as React from 'react';
import { Check, Close } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { GlobalRole } from "../../redux/auth/types";
import { getFormattedDate } from "../../Utility";

export const userGridColDefs : (me: string | undefined) => GridColDef[] = (me: string | undefined) => [
    {
        field: 'username',
        headerName: 'Username',
        minWidth: 100,
        flex: 1,
        renderCell(params) {
            const username = params.value as string;
            if (username && username == me) {
                return `${username} (me)`
            }
            return username;
        }
    },
    {
        field: 'firstName',
        headerName: 'First name',
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'lastName',
        headerName: 'Last name',
        minWidth: 100,
        flex: 1,
    },
    {
        field: 'email',
        headerName: 'Email',
        minWidth: 100,
        flex: 1,
    }
]