import React, { useEffect, useState } from "react";
import { ProcessState } from "../../../context/blockActions/ActionExecutionContext";
import { Box, Tab, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Colors } from "../../../theme/Colors";
import DarkModeDataGrid from "../../utils/DarkModeDataGrid";

interface Props {
  info: ProcessState;
}

interface Variable {
  key: string;
  value: any;
}
interface Output {
  id: string;
  name: string;
  value: any;
}

const loopMatch = /_loop_iter_(?<loopId>.*)/g;

function truncate(str: string, n: number) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

const SequenceDebugInfo: React.FC<Props> = (props) => {
  const { info } = props;

  const [variables, setVariables] = useState<Variable[]>([]);
  const [outputs, setOutputs] = useState<Output[]>([]);

  // Set states debounced
  // This is because if a sequence is run,
  //    not debugged, we don't have to rerender on every change
  useEffect(() => {
    const t = setTimeout(() => {
      let vars: { key: string; value: any }[] = [];
      Object.entries(info.variables).forEach(([key, val]) => {
        let name = key;
        const loop = loopMatch.exec(key);
        if (loop?.groups) {
          const loopId = loop.groups["loopId"];
          const loopactName = info.actions.find((x) => x.id === loopId)?.name;
          if (loopactName) {
            name = `Loop (${loopactName})`;
          } else {
            name = "Loop iteration";
          }
        }
        vars.push({
          key: name,
          value: val,
        });
      });
      setVariables(vars);
    }, 500);

    return () => clearTimeout(t);
  }, [info.variables, setVariables]);

  useEffect(() => {
    const t = setTimeout(() => {
      let out: { id: string; name: string; value: any }[] = [];
      Object.entries(info.actionStates).forEach(([key, val]) => {
        let name = info.actions.find((x) => x.id === key)?.name;
        if (!name || !val.output) {
          return;
        }
        out.push({
          id: key,
          name,
          value: val.output,
        });
      });
      setOutputs(out);
    }, 500);

    return () => clearTimeout(t);
  }, [info.actionStates, info.actions]);

  const [tab, setTab] = useState("1");

  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={(_, val) => setTab(val)}
          aria-label="lab API tabs example"
        >
          <Tab label="Variables" value="1" />
          <Tab label="Outputs" value="2" />
        </TabList>
      </Box>
      <TabPanel value="1">
        <Box>
          <Typography variant="h6">Variables</Typography>
          <DarkModeDataGrid
            getRowId={(row) => row.key}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            hideFooterPagination
            hideFooter
            hideFooterSelectedRowCount
            showCellVerticalBorder
            sx={{
              minHeight: 300,
            }}
            rows={variables}
            columns={[
              {
                field: "key",
                headerName: "Name",
                flex: 0.4,
                sortable: false,
                renderCell(params) {
                  return (
                    <Tooltip
                      title={<Typography>{params.value}</Typography>}
                      placement="left"
                      arrow
                    >
                      <Typography>{truncate(`${params.value}`, 15)}</Typography>
                    </Tooltip>
                  );
                },
              },
              {
                field: "value",
                headerName: "Value",
                flex: 0.6,
                sortable: false,
                renderCell(params) {
                  return (
                    <Tooltip
                      placement="right"
                      title={
                        <Typography>
                          <pre>{JSON.stringify(params.value)}</pre>
                        </Typography>
                      }
                      arrow
                    >
                      <Typography>
                        <pre>{truncate(JSON.stringify(params.value), 20)}</pre>
                      </Typography>
                    </Tooltip>
                  );
                },
              },
            ]}
          />
        </Box>
      </TabPanel>
      <TabPanel value="2">
        <Box>
          <Typography variant="h6">Outputs</Typography>
          <DarkModeDataGrid
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            hideFooterPagination
            hideFooter
            hideFooterSelectedRowCount
            showCellVerticalBorder
            sx={{
              minHeight: 300,
            }}
            rows={outputs}
            columns={[
              {
                field: "name",
                headerName: "Action",
                flex: 0.4,
                sortable: false,
                renderCell(params) {
                  return (
                    <Tooltip
                      title={<Typography>{params.value}</Typography>}
                      placement="left"
                      arrow
                    >
                      <Typography>{truncate(`${params.value}`, 20)}</Typography>
                    </Tooltip>
                  );
                },
              },
              {
                field: "value",
                headerName: "Value",
                flex: 0.6,
                sortable: false,
                renderCell(params) {
                  return (
                    <Tooltip
                      title={
                        <Typography>
                          <pre>{JSON.stringify(params.value)}</pre>
                        </Typography>
                      }
                      placement="right"
                      arrow
                    >
                      <Typography>
                        <pre>{truncate(JSON.stringify(params.value), 20)}</pre>
                      </Typography>
                    </Tooltip>
                  );
                },
              },
            ]}
          />
        </Box>
      </TabPanel>
      <TabPanel value="3">Item Three</TabPanel>
    </TabContext>
  );
};

export default SequenceDebugInfo;
