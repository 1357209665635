import React from "react";
import { Outlet } from "react-router";
import TranslationContext from "./TranslationContext";
import FakeLocalStorageContextProvider from "./localStorage/FakeLocalStorageContextProvider";
import FakeBlockDataContextProvider from "./blockData/FakeBlockDataContextProvider";
import ActionExecutionContextProvider from "./blockActions/ActionExecutionContext";
import FakeBlockEventContext from "./blockEvents/FakeBlockEventContext";

export default function ActionDebugContextOutlet() {
  return (
    <FakeLocalStorageContextProvider>
      <FakeBlockDataContextProvider>
        <FakeBlockEventContext>
          <ActionExecutionContextProvider>
            <TranslationContext>
              <Outlet />
            </TranslationContext>
          </ActionExecutionContextProvider>
        </FakeBlockEventContext>
      </FakeBlockDataContextProvider>
    </FakeLocalStorageContextProvider>
  );
}
