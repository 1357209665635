import React, { ReactElement, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Colors } from '../../theme/Colors';
import { ThemeProvider } from '@emotion/react';
import { InnerTheme } from '../../theme/InnerTheme';

interface DeleteConfirmationDialogProps {
  children: ReactElement;
  onDeleteConfirm: () => void;
  dialogTitle?: string;
  dialogContent?: string;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  children,
  onDeleteConfirm,
  dialogTitle = "Confirm Delete",
  dialogContent = "Are you sure you want to delete this item?",
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onDeleteConfirm();
    handleClose();
  };

  // Enhancing children with the click handler
  const enhancedChild = React.cloneElement(children, { onClick: handleClickOpen });

  return (
    <>
      {enhancedChild}
      <ThemeProvider theme={InnerTheme}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogContent}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' onClick={handleClose}>Cancel</Button>
            <Button variant='contained' onClick={handleDelete} autoFocus sx={{ backgroundColor: Colors.bloxxi_red }}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default DeleteConfirmationDialog;
