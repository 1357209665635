import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography, useTheme } from '@mui/material';
import { AddOutlined, DeleteOutlined, Remove } from '@mui/icons-material';
import { defaultBlockStyleData } from '../../../redux/blocks/blockSlice';
import { BlockStyleData } from '../../../redux/blocks/types';
import { useAppDispatch } from '../../../redux/hooks';
import ConfirmationPopover from '../../utils/ConfirmationPopover';
import { BlockAnimationKeyFrame } from '../../../redux/animation/types';
import { deleteAnimationFrameAsync, updateAnimationFrameAsync } from '../../../redux/animation/animationSlice';
import yup from '../../../validation/yup';

interface Props {
    animationKeyFrame: BlockAnimationKeyFrame,
    handleBlockAnimationSave: () => void,
    handleFrameChange: (frame: BlockAnimationKeyFrame) => void,
}

export default function AnimationFrameStyleBox(props: Props) {
    const { animationKeyFrame, handleFrameChange, handleBlockAnimationSave } = props;

    const [confirmationPopoverOpen, setConfirmationPopoverOpen] = React.useState(false);
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const handleConfirmationPopoverClose = React.useCallback(() => {
        setConfirmationPopoverOpen(false);
    }, [setConfirmationPopoverOpen]);

    const handleConfirmationPopoverSubmit = React.useCallback(() => {
        setConfirmationPopoverOpen(false);
        dispatch(deleteAnimationFrameAsync(animationKeyFrame.id));
    }, [setConfirmationPopoverOpen, dispatch, animationKeyFrame]);

    const handleBlockStyleDelete = React.useCallback(() => {
        setConfirmationPopoverOpen(true);
    }, [setConfirmationPopoverOpen]);

    const handleBlockStyleDataCreate = React.useCallback(() => {
        const newBlockStyle: BlockAnimationKeyFrame = { ...animationKeyFrame, blockStyleData: [...animationKeyFrame.blockStyleData, defaultBlockStyleData] };
        dispatch(updateAnimationFrameAsync(newBlockStyle));
    }, [animationKeyFrame, defaultBlockStyleData, dispatch]);

    const handleBlockStyleDataChange = React.useCallback((styleData: BlockStyleData) => {
        let styles = [...animationKeyFrame.blockStyleData.filter(x => x.id !== styleData.id), styleData];
        handleFrameChange({ ...animationKeyFrame, blockStyleData: styles });
    }, [animationKeyFrame]);

    const handleBlockStyleDataDelete = React.useCallback((id: number) => {
        dispatch(updateAnimationFrameAsync({ ...animationKeyFrame, blockStyleData: animationKeyFrame.blockStyleData.filter(x => x.id !== id) }))
    }, [dispatch, animationKeyFrame]);

    const styleBlocks = React.useMemo(() => [...animationKeyFrame.blockStyleData]
        .sort((a, b) => a.id - b.id).map(styleData => (
            <TableRow key={styleData.id} sx={{ '&>*': { padding: '0 !important' } }}>
                <TableCell>
                    <TextField
                        size='small'
                        id="outlined-basic"
                        hiddenLabel
                        variant="filled"
                        value={styleData.attributeKey}
                        onChange={(e) => handleBlockStyleDataChange({ ...styleData, attributeKey: e.target.value })}
                        onBlur={handleBlockAnimationSave}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        size='small'
                        id="outlined-basic"
                        hiddenLabel
                        variant="filled"
                        value={styleData.attributeValue}
                        onChange={(e) => handleBlockStyleDataChange({ ...styleData, attributeValue: e.target.value })}
                        onBlur={handleBlockAnimationSave}
                    />
                </TableCell>
                <TableCell sx={{ width: '5px' }}>
                    <IconButton onClick={() => handleBlockStyleDataDelete(styleData.id)}>
                        <Remove />
                    </IconButton>
                </TableCell>
            </TableRow>
        )), [handleBlockStyleDataChange, handleBlockAnimationSave, animationKeyFrame]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Toolbar disableGutters variant='dense' sx={{ position: 'relative' }}>
                    <Typography variant="h6" sx={{ flex: 1 }}>
                        {animationKeyFrame.percent}%
                    </Typography>
                    <IconButton aria-label="add" onClick={handleBlockStyleDataCreate}>
                        <AddOutlined />
                    </IconButton>
                    <ConfirmationPopover
                        handleClose={handleConfirmationPopoverClose}
                        handleSubmit={handleConfirmationPopoverSubmit}
                        open={confirmationPopoverOpen}>
                        <IconButton onClick={() => handleBlockStyleDelete()} component="label" disabled={animationKeyFrame.percent === 0 || animationKeyFrame.percent === 100}>
                            <DeleteOutlined />
                        </IconButton>
                    </ConfirmationPopover>
                </Toolbar>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Attribute</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell sx={{ width: '5px' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {styleBlocks}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}