import React, { createContext, useEffect, useMemo, useState } from "react";
import { ProjectData } from "../../../redux/project/types";
import { BackendError, useAppDispatch, useAppSelector } from "../../../redux/hooks";
import projectSlice, { getProjectDetails, selectProjectById } from "../../../redux/project/projectSlice";
import useRouteParam from "../../../hooks/useRouteParam";
import { getFiles } from "../../../redux/media/mediaSlice";
import { getPages } from "../../../redux/page/pageSlice";


interface ProjectContextType {
  id: number;
  project: ProjectData | undefined;
  loading: boolean;
}

const initial: ProjectContextType = {
  id: -1,
  project: undefined,
  loading: false
}

export const ProjectContext = createContext(initial);

const ProjectContextProvider: React.FC<React.PropsWithChildren> = (props) => {

  const {
    children
  } = props;

  const projectId = useRouteParam('projectId');
  const project = useAppSelector(selectProjectById(projectId));

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        dispatch(getProjectDetails(projectId));
        dispatch(getFiles(projectId));
        dispatch(getPages(projectId))
      } catch (err) {
        // ignored
      }
    }
    setLoading(true);
    getData().finally(() => setLoading(false));
  }, [dispatch, projectId])

  const ctxValue = useMemo(() => {
    return ({
      id: project?.id ?? -1,
      project: project,
      loading: loading,
    })
  }, [project, loading])

  return (
    <ProjectContext.Provider value={ctxValue}>
      {
        children
      }
    </ProjectContext.Provider>
  )
}

export default ProjectContextProvider;