import { Handle, HandleProps, Position } from "reactflow";
import { HANDLE_STYLE } from "./NodeWrapper";
import React from "react";

const BottomHandle = (
  props: Partial<HandleProps & Omit<React.HTMLAttributes<HTMLDivElement>, "id">>
) => {
  return (
    <Handle
      {...props}
      style={{ ...HANDLE_STYLE, bottom: -10, ...props.style }}
      position={Position.Bottom}
      type="source"
    />
  );
};

export default BottomHandle;
