import { ColorLens } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, Popover, TextField, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { BlockData, BlockStyle } from '../../../redux/blocks/types';
import { getStyleById, isStyleInheritedAndChanged } from '../../../Utility';
import ColorPicker from "react-best-gradient-color-picker";
import { BlockStyleAttribute } from '../../builder/BlockInfo/BlockDetail/BlockStyleEdit/BlockStyleInputs';
import BlockStyleDataResetBadge from './BlockStyleDataResetBadge';

interface Props {
  baseBlockStyle: BlockStyle,
  currentBlockStyle: BlockStyle,
  handleAttributeChange: (blockAttributes: BlockStyleAttribute[], save?: boolean, ignoreDefault?: boolean) => void,
  handleBlockStyleDataReset: (attributeKey: string) => void,
  attributeKey: string,
  selectedBlock?: BlockData,
}

function AdvancedColorPicker(props: Props) {
  const { currentBlockStyle, attributeKey, selectedBlock, handleAttributeChange } = props;
  const [color, setColor] = useState('#000');
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const theme = useTheme();

  React.useEffect(() => {
    const changedColor = currentBlockStyle.blockStyleData.find(x => x.attributeKey === attributeKey)?.attributeValue ?? getStyleById(selectedBlock, attributeKey);
    setColor(changedColor);
  }, [currentBlockStyle, attributeKey, setColor, getStyleById, selectedBlock])

  const handleColorSave = () => {
    handleAttributeChange([{ attributeKey, attributeValue: color }], true);
  }

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  }, [setOpen, setAnchorEl, open]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
    handleColorSave();
  }, [handleColorSave, setOpen]);

  return (
    <div>
      <BlockStyleDataResetBadge {...props}>
        <Button
          variant='contained'
          style={{ backgroundColor: color }}
          onClick={handleClick}
          fullWidth>
          <ColorLens />
        </Button>
      </BlockStyleDataResetBadge>
      <Popover
        id="color-picker"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Box sx={{ padding: theme.spacing(1) }}>
          <ColorPicker
            value={color}
            onChange={setColor}
            hideColorGuide
            hideAdvancedSliders
            hideInputType
            hideGradientControls
            hideGradientType
            hideColorTypeBtns />
        </Box>
      </Popover>
    </div>
  );
}

export default AdvancedColorPicker;
