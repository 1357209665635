import React from "react";
import { Box, TextField } from "@mui/material";
import TopHandle from "../../helpers/TopHandle";
import BottomHandle from "../../helpers/BottomHandle";
import clsx from "clsx";
import createNodeComponent from "../../../../../../functions/createNodeComponent";
import DelayAction from "../../../../../../redux/actionsequence/types/actions/control/DelayAction";

const DelayNodeComponent = createNodeComponent<DelayAction>((props) => {
  const { actionData, errors, onChange } = props;
  return (
    <>
      <TopHandle />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ["&>.formItem"]: {
            marginBottom: "20px",
          },
        }}
      >
        <TextField
          className={clsx("nodrag", "formItem")}
          color="primary"
          fullWidth
          label="Action name"
          defaultValue={actionData.name}
          size="small"
          variant="filled"
          required
          error={"name" in errors}
          helperText={errors.name}
          onChange={(ev) => onChange("name", ev.target.value)}
        />
        <TextField
          className={clsx("nodrag", "formItem")}
          color="primary"
          type="number"
          fullWidth
          label="Delay"
          defaultValue={actionData.delayMs}
          size="small"
          variant="filled"
          required
          error={"delayMs" in errors}
          helperText={errors.name}
          onChange={(ev) => onChange("delayMs", Number(ev.target.value))}
        />
      </Box>
      <BottomHandle />
    </>
  );
});

export default DelayNodeComponent;
