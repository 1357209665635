import React from 'react';
import { BlockStyle } from '../../redux/blocks/types';
import { ViewDay, ViewColumn, GridView, ViewArray, FormatColorText, VisibilityOff } from '@mui/icons-material';
import { Grid, Typography, Box, ToggleButtonGroup, ToggleButton, Badge } from '@mui/material';

interface Props {
    name: string,
    size?: number
}

export default function BlockStyleDataLabel(props: Props) {
    const { name, size = 2 } = props;

    return (
        <Grid item xs={size} sx={{ margin: 'auto' }}>
            <Box>
                <Typography variant='subtitle2' component='div'>
                    {name}
                </Typography>
            </Box>
        </Grid>
    );
}