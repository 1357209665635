export enum HandleType {
  IfElseTrue = "IfElseTrue",
  IfElseFalse = "IfElseFalse",
  LoopStart = "LoopStart",
  LoopEnd = "LoopEnd",
  LoopExit = "LoopExit",
}

export enum ConnectionType {
  Cancellable = "Cancellable",
}

export interface ActionConnection {
  id: string;
  source: string;
  sourceHandle: HandleType | null;
  target: string;
  targetHandle: HandleType | null;
  type?: ConnectionType;
}
