import { produce } from "immer";
import { getLoop, getOutgoing, getPathToInvalidate } from "./utils/graphUtils";
import { HandleType } from "../redux/actionsequence/types/helpers/ActionConnection";
import {
  ExecutionContext,
  ProcessState,
} from "../context/blockActions/ActionExecutionContext";
import actions, { AnyAction } from "../redux/actionsequence/types/actions";

const getLoopIterationVariable = (loopId: string) => `_loop_iter_${loopId}`;

async function step(processState: ProcessState, context: ExecutionContext) {
  // Find first in queue, or undefined is array is empty
  const currentAction = processState.queue.find(Boolean);
  if (!currentAction) {
    return processState;
  }

  // Check if every previous actio is completed or skipped before executing this one
  const prev = processState.connections.filter(
    (x) => x.target === currentAction
  );
  if (
    !prev
      .filter((x) => x.targetHandle !== HandleType.LoopEnd)
      .every(
        (p) =>
          processState.actionStates[p.source].status === "finished" ||
          processState.actionStates[p.source].status === "removed"
      )
  ) {
    console.error("Previous action is not finished, cannot step");
    return produce(processState, (state) => {
      // remove current action
      state.queue.shift();
      // add this action to the back of the queue -> will be executed later
      state.queue.push(currentAction);
    });
  }
  // all previous actions finished -> execute this one
  const actionToExecute = processState.actions.find(
    (x) => x.id === currentAction
  ) as AnyAction;

  if (!actionToExecute) {
    console.error("No action to execute");
    return processState;
  }

  // measure start time
  const start = performance.now();

  const execute = actions[actionToExecute.type].execute;

  const executionArgs = {
    sourceBlock: processState.metadata?.blockContext ?? -1,
    additional: processState.args,
  };

  // Execute the action
  const { controlOptions, output, set } = await execute(
    //@ts-ignore ignore this argument as 'never'
    actionToExecute,
    processState,
    context,
    executionArgs
  );

  // measure end time
  const end = performance.now();

  return produce(processState, (state) => {
    // read and save result
    state.actionStates[currentAction].status = "finished";
    state.queue.shift();
    state.actionStates[currentAction].output = output;
    if (set) {
      for (const [key, val] of Object.entries(set)) {
        state.variables[key] = val;
      }
    }
    state.actionStates[currentAction].durationMS = end - start;

    // Control next execution
    if (controlOptions.event) {
      // Exit loop on 'break' and start loop over on 'continue'
      const handleToTarget =
        controlOptions.event === "break"
          ? HandleType.LoopExit
          : HandleType.LoopStart;

      const loopId = getLoop(currentAction, state.connections);
      if (!loopId) {
        console.error(
          `Cannot execute ${controlOptions.event} outside of a loop`
        );
      } else {
        controlOptions.next = [];
        const loopOutgoing = state.connections.filter(
          (x) => x.source === loopId && x.sourceHandle === handleToTarget
        );
        for (const n of loopOutgoing.map((x) => x.source)) {
          state.queue.push(n);
          state.actionStates[n].status = "waiting";
        }
        if (controlOptions.event === "break") {
          const iterVariable = getLoopIterationVariable(loopId);
          state.variables[iterVariable] = 0;
        }
      }
    }
    if (controlOptions.loop) {
      const iterVariable = getLoopIterationVariable(currentAction);
      let num = Number(state.variables[iterVariable]);
      if (isNaN(num)) {
        num = controlOptions.loop.max;
      } else {
        num = num - 1;
      }
      state.variables[iterVariable] = num;
      if (num <= 0) {
        controlOptions.next = getOutgoing(
          currentAction,
          processState.connections
        )
          .filter((x) => x.sourceHandle === HandleType.LoopExit)
          .map((x) => x.target);
      }
    }
    for (const opt of controlOptions.next) {
      state.queue.push(opt);
      state.actionStates[opt].status = "waiting";
    }
    // If for an action will not be executed according to control flow (switch, if/else)
    // all actions that are children of these actions do not have to run as well
    // this step calculates the entire path of actions to remove from the sequence execution
    for (const opt of controlOptions.remove ?? []) {
      state.actionStates[opt].status = "removed";
      const invalidPath = state.connections.find(
        (x) => x.source === currentAction && x.target === opt
      );
      const pathToInvalidate = getPathToInvalidate(
        invalidPath,
        state.connections
      );
      for (const toRemove of pathToInvalidate) {
        state.actionStates[toRemove].status = "removed";
      }
    }

    if (state.queue.length <= 0) {
      state.isActive = false;
    }
    state.last = currentAction;
    return state;
  });
}

export default step;
