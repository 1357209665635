import React from 'react';
import BlockWall from '../../components/BlockBuilder/BlockWall';
import CustomCursor from '../../components/BlockBuilder/CustomCursor';
import TranslationContext from '../../context/TranslationContext';
import BlockCanvas from '../builder/BlockCanvas';
import { BlockData, BlockType, ReferenceType, ScreenMode } from '../../redux/blocks/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getBlocks, getGlobalStyles } from '../../redux/blocks/blockSlice';
import useRouteParam from '../../hooks/useRouteParam';
import { getTemplates } from '../../redux/template/templateSlice';
import { getDataSets, getModels } from '../../redux/dyndata/dyndataSlice';
import { getPages, selectPages } from '../../redux/page/pageSlice';
import { PageData } from '../../redux/page/types';
import { useNavigate } from 'react-router';
import LoadingScreen from './LoadingScreen';
import { Hidden } from '@mui/material';
import { deployData } from '../../redux/project/projectSlice';
import { ImageDimensionInfo } from '../../redux/project/types';

const defaultImageDimensionInfo: ImageDimensionInfo = {
    id: '',
    src: '',
    xs: { width: 0, height: 0 },
    sm: { width: 0, height: 0 },
    md: { width: 0, height: 0 },
    lg: { width: 0, height: 0 },
    xl: { width: 0, height: 0 }
}

export default function Deployment() {
    const ref = React.useRef<HTMLDivElement>(null);
    const [canvasMode, setCanvasMode] = React.useState<ScreenMode>(ScreenMode.xxl);
    const [imageLoadCount, setImageLoadCount] = React.useState(0);

    const [processedPages, setProcessedPages] = React.useState<number[]>([]);
    const [currentPage, setCurrentPage] = React.useState<PageData | undefined>();
    const [result, setResult] = React.useState<ImageDimensionInfo[]>([]);
    const [pages, setPages] = React.useState<PageData[]>([]);
    const [blocks, setBlocks] = React.useState<BlockData[]>([]);

    const projectId = useRouteParam('projectId');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        Promise.all([
            dispatch(getTemplates()),
            dispatch(getDataSets(projectId)),
            dispatch(getModels(projectId)),
            dispatch(getGlobalStyles(projectId)),
            dispatch(getPages(projectId))
                .unwrap()
                .then(payload => setPages(payload)),
        ])
    }, [])

    React.useEffect(() => {
        if (!pages.length)
            return;

        setCurrentPage(pages[0]);
        dispatch(getBlocks({ refId: pages[0].id, refType: ReferenceType.Page }))
            .unwrap()
            .then(payload => {
                if(payload.findIndex(x => x.blockType === BlockType.IMG) < 0) {
                    setProcessedPages([...processedPages, pages[0].id]);
                } else {
                    setBlocks(payload)
                }
            });
    }, [pages])


    React.useEffect(() => {
        if (!blocks.length || ref.current === null)
            return;

        const images = ref.current.querySelectorAll('img');
        
        images.forEach((img) => {
            img.addEventListener('load', handleImageLoad);
        });
    }, [blocks])

    React.useEffect(() => {
        if (ref.current === null)
            return;

        const images = ref.current.querySelectorAll('img');

        if (images.length && images.length === imageLoadCount) {
            setTimeout(() => gatherImageDimensions(), 5);
        }

    }, [imageLoadCount, canvasMode])

    React.useEffect(() => {
        if (!pages.length)
            return;

        const nextPage = pages.find(x => !processedPages.includes(x.id));

        if (nextPage) {
            setCurrentPage(nextPage);
            dispatch(getBlocks({ refId: nextPage.id, refType: ReferenceType.Page }))
                .unwrap()
                .then(payload => {
                    if(payload.findIndex(x => x.blockType === BlockType.IMG) < 0) {
                        setProcessedPages([...processedPages, nextPage.id]);
                    } else {
                        setBlocks(payload)
                    }
                });
        } else {
            dispatch(deployData({ imageInfo: result, projectId }))
                .finally(() => {
                    navigate(`/project-detail/${projectId}`, { replace: true });
                });
        }
    }, [processedPages])

    const handleImageLoad = (event: Event) => {
        const img = event.currentTarget as HTMLImageElement;
        img.removeEventListener('load', handleImageLoad);

        setImageLoadCount(prev => prev + 1);
    }

    const gatherImageDimensions = () => {
        if (ref.current === null)
            return;

        let newResult = [...result];
        const images = ref.current.querySelectorAll('img');
        images.forEach((img) => {
            const imageIndex = result.findIndex(x => x.id === img.id);
            const dimension = {
                width: img.clientWidth,
                height: img.clientHeight,
            };

            if (imageIndex >= 0) {
                newResult[imageIndex] = {
                    ...newResult[imageIndex],
                    [canvasMode.toLowerCase()]: dimension
                };
            } else {
                let newInfo: ImageDimensionInfo = {
                    ...defaultImageDimensionInfo,
                    [canvasMode.toLowerCase()]: dimension,
                    id: img.id,
                    src: img.getAttribute('src') ?? img.src,
                };
                newResult.push(newInfo);
            }
        });

        setResult(newResult);

        switch (canvasMode) {
            case ScreenMode.xxl:
                setCanvasMode(ScreenMode.xl);
                break;
            case ScreenMode.xl:
                setCanvasMode(ScreenMode.lg);
                break;
            case ScreenMode.lg:
                setCanvasMode(ScreenMode.md);
                break;
            case ScreenMode.md:
                setCanvasMode(ScreenMode.sm);
                break;
            case ScreenMode.sm:
                setCanvasMode(ScreenMode.xs);
                break;
            default:
                setImageLoadCount(0);
                setCanvasMode(ScreenMode.xxl);

                if (currentPage)
                    setProcessedPages([...processedPages, currentPage.id])
        }
    }

    return (
        <>
            <LoadingScreen />
            <div ref={ref} style={{overflow: 'hidden', height: '100vh', opacity: 0}}>
                <TranslationContext>
                    <BlockCanvas drawerOpen={false} canvasMode={canvasMode}>
                        <CustomCursor />
                        <BlockWall
                            blocks={blocks}
                            canvasMode={canvasMode}
                            dataEntries={[]}
                        />
                    </BlockCanvas>
                </TranslationContext>
            </div>
        </>
    )
}