import React, { createContext, useCallback, useEffect, useMemo } from "react";
import { AnyAction } from "../redux/actionsequence/types/actions";
import { ActionConnectionSubmission } from "../redux/actionsequence/types/helpers/ActionConnectionSubmission";
import { ActionNode } from "../redux/actionsequence/types/helpers/ActionNode";
import { ActionConnection } from "../redux/actionsequence/types/helpers/ActionConnection";
import { ActionSequence } from "../redux/actionsequence/types/helpers/ActionSequence";
import useRouteParam from "../hooks/useRouteParam";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  addActionConnection,
  addActionConnectionToGlobalSequence,
  addActionNode,
  addActionNodeToGlobalSequence,
  deleteActionConnection,
  deleteActionConnectionOnGlobalSequence,
  deleteActionNode,
  deleteActionNodeOnGlobalSequence,
  getGlobalSequence,
  selectSequence,
  updateActionNode,
  updateActionNodeOnGlobalSequence,
  updateGlobalSequence,
  updateSequence,
} from "../redux/actionsequence/sequenceSlice";
import { produce } from "immer";
import { ActionNodeSubmission } from "../redux/actionsequence/types/helpers/ActionNodeSubmission";
import ActionSequenceType from "../redux/actionsequence/types/helpers/ActionSequenceType";

export type SequenceChange = {
  name: string;
  type: ActionSequenceType;
};

interface SequenceContextType {
  addNode(submission: ActionNodeSubmission<AnyAction>): Promise<ActionNode>;
  addConnection(
    submission: ActionConnectionSubmission
  ): Promise<ActionConnection>;
  deleteNode(id: string): Promise<string>;
  deleteConnection(id: string): Promise<string>;
  updateNode(submission: ActionNode): Promise<ActionNode>;
  onSequenceChange(submission: SequenceChange): Promise<ActionSequence>;
  updateNodeData(nodeId: string, submission: AnyAction): Promise<void>;
  sequenceId: number | undefined;
}

const initial: SequenceContextType = {
  addNode(submission) {
    return Promise.reject(new Error("Context not initialized yet"));
  },
  addConnection(submission) {
    return Promise.reject(new Error("Context not initialized yet"));
  },
  deleteNode(id) {
    return Promise.reject(new Error("Context not initialized yet"));
  },
  deleteConnection(id) {
    return Promise.reject(new Error("Context not initialized yet"));
  },
  updateNode(submission) {
    return Promise.reject(new Error("Context not initialized yet"));
  },
  updateNodeData(nodeId, submission) {
    return Promise.reject(new Error("Context not initialized yet"));
  },
  onSequenceChange(submission) {
    return Promise.reject(new Error("Context not initialized yet"));
  },
  sequenceId: undefined,
};

const SequenceContext = createContext(initial);

export const SequenceContextProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  const { children } = props;

  const sequenceId = useRouteParam("sequenceId");

  const sequence = useAppSelector(selectSequence(sequenceId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getGlobalSequence({ id: sequenceId }));
  }, [sequenceId, dispatch]);

  const handleAddNode = useCallback(
    (submission: ActionNodeSubmission<AnyAction>) => {
      if (sequence?.isGlobal) {
        return dispatch(
          addActionNodeToGlobalSequence({ id: sequenceId, submission })
        ).unwrap();
      } else {
        return dispatch(
          addActionNode({
            id: sequenceId,
            projectId: sequence!.projectDataId!,
            submission,
          })
        ).unwrap();
      }
    },
    [sequence, dispatch, sequenceId]
  );

  const handleDeleteNode = useCallback(
    (nodeId: string) => {
      if (sequence?.isGlobal) {
        return dispatch(
          deleteActionNodeOnGlobalSequence({ id: sequenceId, nodeId })
        ).unwrap();
      } else {
        return dispatch(
          deleteActionNode({
            id: sequenceId,
            projectId: sequence!.projectDataId!,
            nodeId,
          })
        ).unwrap();
      }
    },
    [sequence, dispatch, sequenceId]
  );

  const handleAddConnection = useCallback(
    (submission: ActionConnectionSubmission) => {
      if (sequence?.isGlobal) {
        return dispatch(
          addActionConnectionToGlobalSequence({ id: sequenceId, submission })
        ).unwrap();
      } else {
        return dispatch(
          addActionConnection({
            id: sequenceId,
            projectId: sequence!.projectDataId!,
            submission,
          })
        ).unwrap();
      }
    },
    [sequence, dispatch, sequenceId]
  );

  const handleDeleteConnection = useCallback(
    (connectionId: string) => {
      if (sequence?.isGlobal) {
        return dispatch(
          deleteActionConnectionOnGlobalSequence({
            id: sequenceId,
            connectionId,
          })
        ).unwrap();
      } else {
        return dispatch(
          deleteActionConnection({
            id: sequenceId,
            projectId: sequence!.projectDataId!,
            connectionId,
          })
        ).unwrap();
      }
    },
    [sequence, dispatch, sequenceId]
  );

  const handleUpdateNode = useCallback(
    (submission: ActionNode) => {
      if (sequence?.isGlobal) {
        return dispatch(
          updateActionNodeOnGlobalSequence({ id: sequenceId, submission })
        ).unwrap();
      } else {
        return dispatch(
          updateActionNode({
            id: sequenceId,
            projectId: sequence!.projectDataId!,
            submission,
          })
        ).unwrap();
      }
    },
    [sequence, dispatch, sequenceId]
  );

  const handleSequenceChange = useCallback(
    (submission: SequenceChange) => {
      if (sequence?.isGlobal) {
        return dispatch(
          updateGlobalSequence({ id: sequenceId, submission })
        ).unwrap();
      } else {
        return dispatch(
          updateSequence({
            id: sequenceId,
            projectId: sequence!.projectDataId!,
            submission,
          })
        ).unwrap();
      }
    },
    [sequence, sequenceId, dispatch]
  );

  const handleNodeDataChange = useCallback(
    (nodeId: string, submission: AnyAction) => {
      const node = sequence?.actions?.find((x) => x.id === nodeId);
      if (node) {
        const updated = produce(node, (s) => {
          s.data = submission;
        });
        return new Promise<void>((res, rej) => {
          handleUpdateNode(updated)
            .then(() => res())
            .catch(rej);
        });
      }
      return Promise.resolve();
    },
    [handleUpdateNode, sequence]
  );

  const value = useMemo<SequenceContextType>(() => {
    if (!sequence) {
      return initial;
    } else {
      return {
        sequenceId: sequence.id,
        addConnection: handleAddConnection,
        addNode: handleAddNode,
        updateNode: handleUpdateNode,
        deleteConnection: handleDeleteConnection,
        deleteNode: handleDeleteNode,
        onSequenceChange: handleSequenceChange,
        updateNodeData: handleNodeDataChange,
      };
    }
  }, [
    sequence,
    handleAddConnection,
    handleAddNode,
    handleUpdateNode,
    handleDeleteConnection,
    handleDeleteNode,
  ]);
  return (
    <SequenceContext.Provider value={value}>
      {children}
    </SequenceContext.Provider>
  );
};

export default SequenceContext;
