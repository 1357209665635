import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { BlockData } from '../../redux/blocks/types';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

interface Props {
    paths: string[],
    projectId: number,
    open: boolean,
    handleClose: () => void,
    handleSubmit: (path: string) => void,
}

export default function MediaMoveDialog(props: Props) {
    const { paths, open, projectId, handleClose, handleSubmit } = props;
    const [folderName, setFolderName] = React.useState("");

    const handleCancel = React.useCallback(() => {
        setFolderName("");
        handleClose();
    }, [setFolderName, handleClose]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Move</DialogTitle>
            <DialogContent>
                <FormControl
                    variant="filled"
                    fullWidth>
                    <InputLabel id="path-select">Path</InputLabel>
                    <Select
                        labelId="path-select"
                        onChange={(e) => setFolderName(e.target.value)}
                        value={folderName}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={projectId.toString()}>
                            \{projectId}
                        </MenuItem>
                        {paths.map((p) => (
                            <MenuItem key={p} value={p}>
                                {p}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button disabled={folderName === ""} onClick={() => handleSubmit(folderName)}>Move</Button>
            </DialogActions>
        </Dialog>
    );
}