import { Cancel } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Chip, FilledInput, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Theme, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { ProjectPermission, ProjectRole, PROJECT_PERMISSIONS } from '../../../redux/auth/types';
import GridSelect from '../../utils/GridSelect';

interface Props {
    onSubmit: (role: ProjectRole) => void;
    submitLoading: boolean;
    projectId: number;
    projectName: string;
}

export default function AddProjectRoleForm(props: Props) {

    const { onSubmit, submitLoading, projectId, projectName } = props;
    
    const theme = useTheme();

    const [name, setName] = React.useState<string>('');
    const [permissions, setPermissions] = React.useState<ProjectPermission[]>([]);

    const isLoadingButtonDisabled = React.useMemo(() => {
            return ((name?.length ?? 0) <= 0) || (permissions.length <= 0);
    }, [name, permissions]);
    
    return (
        <Box sx={{
            padding: theme.spacing(3)
        }}>
            <Stack
                component="form"
                sx={{
                    width: '100%',
                }}
                spacing={2}
                noValidate
                autoComplete='off'
            >
                <FormControl>
                    <TextField 
                        value={name} 
                        onChange={(ev) => setName(ev.target.value)}
                        label='Role name' 
                        variant='filled' id='role-name' 
                    />
                </FormControl>
                <FormControl>
                    <InputLabel id='permission-select-label'>Permissions</InputLabel>
                    <GridSelect
                        values={permissions}
                        setValues={setPermissions}
                        idSelector={(p) => p}
                        nameSelector={(p) => p}
                        xs={4}
                        gridsAndOptions={[
                            {
                                options: PROJECT_PERMISSIONS.filter(x => x.startsWith('ANIMATION')),
                                title: 'Animations'
                            },
                            {
                                options: PROJECT_PERMISSIONS.filter(x => x.startsWith('DATASET')),
                                title: 'Datasets'
                            },
                            {
                                options: PROJECT_PERMISSIONS.filter(x => x.startsWith('LANGUAGE')),
                                title: 'Languages'
                            },
                            {
                                options: PROJECT_PERMISSIONS.filter(x => x.startsWith('MAIL_CONNECTION')),
                                title: 'Mail connections'
                            },
                            {
                                options: PROJECT_PERMISSIONS.filter(x => x.startsWith('MAIL_TEMPLATE')),
                                title: 'Mail templates'
                            },
                            {
                                options: PROJECT_PERMISSIONS.filter(x => x.startsWith('MEDIA')),
                                title: 'Media'
                            },
                            {
                                options: PROJECT_PERMISSIONS.filter(x => x.startsWith('MODEL')),
                                title: 'Models'
                            },
                            {
                                options: PROJECT_PERMISSIONS.filter(x => x.startsWith('PAGE')),
                                title: 'Pages'
                            },
                            {
                                options: PROJECT_PERMISSIONS.filter(x => x.startsWith('PROJECT')),
                                title: 'Projects'
                            },
                            {
                                options: PROJECT_PERMISSIONS.filter(x => x.startsWith('TEMPLATE')),
                                title: 'Templates'
                            },
                            {
                                options: PROJECT_PERMISSIONS.filter(x => x.startsWith('TRANSLATION_TAG')),
                                title: 'Translation tags'
                            }
                        ]}
                    />
                </FormControl>
                <FormControl>
                </FormControl>
            </Stack>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <LoadingButton 
                    variant='contained' 
                    disabled={isLoadingButtonDisabled}
                    onClick={() => {
                        onSubmit({
                            id: 0,
                            name: name,
                            permissions: permissions,
                            isDefault: false,
                            projectDataId: projectId,
                            projectName: projectName
                        });
                    }}
                    loading={submitLoading}
                    >
                        Submit
                </LoadingButton>
            </Box>
        </Box>
    )
}