import React from "react";
import LocalStorageContext from "./LocalStorageContext";

const LocalStorageContextProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  const { children } = props;

  return (
    <LocalStorageContext.Provider value={localStorage}>
      {children}
    </LocalStorageContext.Provider>
  );
};

export default LocalStorageContextProvider;
