import RemoveIcon from "@mui/icons-material/CancelOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Divider,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import { useReactFlow } from "reactflow";
import { NodeInfoContextProvider } from "../../../../../context/NodeInfoContext";
import NodeStatusIcon from "./NodeStatusIcon";
import SequenceContext from "../../../../../context/SequenceContext";
import { Colors } from "../../../../../theme/Colors";

export const HANDLE_STYLE = {
  width: 15,
  height: 15,
  background: "#99c448",
  border: "1px solid #414a4c",
};

interface Props {
  id: string;
  isStart?: boolean;
  info?: React.ReactNode;
  title?: string;
}

const NodeWrapper = (props: React.PropsWithChildren & Props) => {
  const { children, id, isStart, info, title } = props;

  const { setNodes, setEdges } = useReactFlow();

  const { deleteNode } = useContext(SequenceContext);

  const infoIcon = useMemo(() => {
    if (!info) {
      return null;
    }

    const iconStyles = {
      width: "20px",
      height: "20px",
      color: "#848484",
      transition: ".3s",
      [":hover"]: {
        color: "#ffffff",
      },
    };

    return (
      <Box
        className="nodrag"
        sx={{
          background: Colors.node_background,
          height: "20px",
          width: "20px",
        }}
      >
        <Tooltip title={info} arrow placement="top-start">
          <InfoIcon sx={iconStyles} />
        </Tooltip>
      </Box>
    );
  }, [info]);

  const onNodeDelete = useCallback(async () => {
    setNodes((nds) => nds.filter((x) => x.id !== id));
    setEdges((eds) => eds.filter((x) => x.source !== id && x.target !== id));
    await deleteNode(id);
  }, [id, setNodes, setEdges, deleteNode]);

  const theme = useTheme();

  return (
    <NodeInfoContextProvider id={id}>
      <Paper
        sx={{
          background: Colors.node_background,
          boxShadow:
            "2px 2px 0 #99c448, -2px -2px 0 #99c448, -2px 2px 0 #99c448, 2px -2px 0 #99c448",
          transition: "all .3s ease-in-out",
          borderTopLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          color: "#ffffff",
          minWidth: 200,
          width: "100%",
          textAlign: "left",
          [":hover"]: {
            boxShadow: "5px 5px 0 #99c448, -5px -5px 0 #99c448",
          },
        }}
      >
        <Box>
          <NodeStatusIcon />
          <Box
            id="header"
            sx={{
              position: "relative",
              width: "100%",
              minWidth: 250,
              paddingLeft: 0,
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <Box
              className="nodrag"
              onClick={onNodeDelete}
              sx={{
                background: "transparent",
                height: "20px",
                width: "20px",
                display: isStart ? "none" : undefined,
                cursor: "pointer",
              }}
            >
              <RemoveIcon
                sx={{
                  width: "20px",
                  height: "20px",
                  color: "#848484",
                  transition: ".3s",
                  [":hover"]: {
                    color: "#c6574f",
                  },
                }}
              />
            </Box>
            {infoIcon}
          </Box>
          <Box sx={{ paddingBottom: "20px" }}>
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                marginBottom: isStart ? "0px" : "10px",
                padding: theme.spacing(1),
              }}
            >
              <Typography variant="h5">{title ?? "Action"}</Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: theme.palette.text.secondary }}
              >
                {id}
              </Typography>
              {!isStart && <Divider />}
            </Box>
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Paper>
    </NodeInfoContextProvider>
  );
};

export default NodeWrapper;
