import React from 'react';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import { Box, Button, FilledInput, FormControl, Grid, MenuItem, Popover, Select, TextField, ToggleButton, ToggleButtonGroup, Tooltip, useTheme } from '@mui/material';
import AdvancedColorPicker from '../../../../../utils/Inputs/AdvancedColorPicker';
import { BlockData, BlockStyle, BlockStyleData } from '../../../../../../redux/blocks/types';
import { Clear, Remove, MoreHoriz, Image, Palette, Gradient, Add, Delete } from '@mui/icons-material';
import { StyleTable, getStyleById, splitCssBackgroundImages } from '../../../../../../Utility';
import GradientBackground from '../../../../../utils/Inputs/GradientBackground';
import BackgroundInputs from './BackgroundInputs';
import { current } from '@reduxjs/toolkit';
import { defaultBlockStyle, defaultBlockStyleData, updateBlockStyle } from '../../../../../../redux/blocks/blockSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../../redux/hooks';
import { selectMedia } from '../../../../../../redux/media/mediaSlice';
import StyleSelect from '../../../../../utils/Inputs/StyleSelect';
import { BlockStyleAttribute } from '../BlockStyleInputs';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void, 
    selectedBlock?: BlockData,
}

const blendOptions = ['normal', 'multiply', 'screen', 'overlay', 'darken', 'lighten', 'color-dodge', 'color-burn', 'hard-light', 'soft-light', 'difference', 'exclusion', 'hue', 'saturation', 'color', 'luminosity'];

const processBlockStyleDataChange = (blockStyle: BlockStyle, blockStyleData: BlockStyleData) => {
    const index = blockStyle.blockStyleData.findIndex(x => x.attributeKey === blockStyleData.attributeKey);
    let styles = [...blockStyle.blockStyleData];

    if (index > -1) {
        if (blockStyleData.attributeValue === '') {
            styles.splice(index, 1);
        } else {
            styles[index] = blockStyleData;
        }

        blockStyle.blockStyleData = styles;
    } else {
        blockStyle.blockStyleData = [...styles, blockStyleData];
    }
}

const getIndexedValue = (attributeKey: string, blockStyle: BlockStyle, index: number): string => {
    if (index === -1)
        return '';

    let values = [];
    const style = blockStyle.blockStyleData.find(x => x.attributeKey === attributeKey);

    if (!style)
        return '';

    if (attributeKey === 'background-image')
        values = splitCssBackgroundImages(style.attributeValue);
    else
        values = style.attributeValue.split(', ');

    if (values.length - 1 >= index)
        return values[index];

    return '';
}

export default function BackgroundList(props: Props) {
    const { currentBlockStyle, handleAttributeChange, selectedBlock, baseBlockStyle } = props;

    const [currentIndex, setCurrentIndex] = React.useState(-1);
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [blockStyle, setBlockStyle] = React.useState(defaultBlockStyle);

    const theme = useTheme();
    const dispatch = useDispatch();

    const splitValues = splitCssBackgroundImages(blockStyle.blockStyleData.find(x => x.attributeKey === 'background-image')?.attributeValue ?? '');

    const backgroundImage = React.useMemo(() => getIndexedValue('background-image', currentBlockStyle, currentIndex), [currentIndex, currentBlockStyle]);
    const backgroundRepeat = React.useMemo(() => getIndexedValue('background-repeat', currentBlockStyle, currentIndex), [currentIndex, currentBlockStyle]);
    const backgroundSize = React.useMemo(() => getIndexedValue('background-size', currentBlockStyle, currentIndex), [currentIndex, currentBlockStyle]);
    const backgroundAttachment = React.useMemo(() => getIndexedValue('background-attachment', currentBlockStyle, currentIndex), [currentIndex, currentBlockStyle]);
    const backgroundOrigin = React.useMemo(() => getIndexedValue('background-origin', currentBlockStyle, currentIndex), [currentIndex, currentBlockStyle]);
    const backgroundPosition = React.useMemo(() => getIndexedValue('background-position', currentBlockStyle, currentIndex), [currentIndex, currentBlockStyle]);

    const media = useAppSelector(selectMedia);

    React.useEffect(() => {
        setBlockStyle(currentBlockStyle);
    }, [currentBlockStyle])

    const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        setCurrentIndex(index);
        setOpen(!open);
        setAnchorEl(event.currentTarget);
    }, [setOpen, setAnchorEl, setCurrentIndex, open]);

    const handleClose = React.useCallback(() => {
        setOpen(false);
        setCurrentIndex(-1);
    }, [setOpen, setCurrentIndex]);

    const processBackgroundValues = React.useCallback((blockStyle: BlockStyle, values: string[], attributeKey: string, blockStyleData: BlockStyleData | undefined) => {
        const processStyleData = blockStyleData ?? { ...defaultBlockStyleData, attributeKey: attributeKey };
        const defaultValue = StyleTable.find(x => x.name === attributeKey)!.defaultValue
        values.push(defaultValue);
        processBlockStyleDataChange(blockStyle, { ...processStyleData, attributeValue: values.join(', ') });
    }, [StyleTable, defaultBlockStyleData]);

    const handleBackgroundCreate = React.useCallback(() => {
        let changedStyle = { ...baseBlockStyle };

        ['background-image', 'background-attachment', 'background-repeat', 'background-size', 'background-origin', 'background-position'].forEach(x => {
            const styleData = blockStyle.blockStyleData.find(y => y.attributeKey === x);
            let values: string[] = [];

            if (styleData && styleData.attributeValue !== '')
                values = x === 'background-image' ? splitCssBackgroundImages(styleData.attributeValue) : styleData.attributeValue.split(', ');

            processBackgroundValues(changedStyle, values, x, styleData);
        });

        dispatch(updateBlockStyle(changedStyle));
    }, [baseBlockStyle, dispatch, blockStyle]);

    const handleBackgroundDelete = React.useCallback((index: number) => {
        let changedStyle = { ...baseBlockStyle };

        ['background-image', 'background-attachment', 'background-repeat', 'background-size', 'background-origin', 'background-position'].forEach(x => {
            const attributeIndex = blockStyle.blockStyleData.findIndex(y => y.attributeKey === x);

            if (attributeIndex < 0)
                return;

            let styleData = { ...blockStyle.blockStyleData[attributeIndex] };
            let values: string[] = [];

            if (styleData)
                values = x === 'background-image' ? splitCssBackgroundImages(styleData.attributeValue) : styleData.attributeValue.split(', ');

            if (values.length - 1 >= index) {
                values.splice(index, 1);
                styleData.attributeValue = values.join(', ');
            }

            processBlockStyleDataChange(changedStyle, styleData);
        });

        dispatch(updateBlockStyle(changedStyle));
    }, [dispatch, baseBlockStyle, blockStyle]);

    const handleBackgroundAttributeChange = React.useCallback((attributeKey: string, value: string, save: boolean = false) => {
        const attributeIndex = blockStyle.blockStyleData.findIndex(y => y.attributeKey === attributeKey);
        let attributeValue = value;

        if (attributeIndex >= 0) {
            let styleData = { ...blockStyle.blockStyleData[attributeIndex] };
            let values: string[] = [];

            if (styleData)
                values = attributeKey === 'background-image' ? splitCssBackgroundImages(styleData.attributeValue) : styleData.attributeValue.split(', ');

            if (values.length - 1 >= currentIndex) {
                values[currentIndex] = value;
            }
            attributeValue = values.join(', ');
        }

        handleAttributeChange([{attributeKey, attributeValue}], true);
    }, [blockStyle, currentIndex]);

    return (
        <>
            <BlockStyleDataLabel size={10} name='Backgrounds' />
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button sx={{ minWidth: 0 }} onClick={handleBackgroundCreate}>
                    <Add />
                </Button>
            </Grid>
            {
                splitValues.map((x, index) => {

                    const selectedBackgroundMediaName = !x.startsWith('radial')
                        && !x.startsWith('linear')
                        && x !== 'url()'
                        ? media.find(y => y.id.toString() === x)?.name ?? ''
                        : '';

                    return (
                        <React.Fragment key={index}>
                            <Grid item xs={10}>
                                <Button onClick={(e) => handleClick(e, index)} fullWidth sx={{ backgroundImage: x }}
                                    endIcon={x.startsWith('linear-gradient') || x.startsWith('radial-gradient') ? <Palette /> : <Image />}>
                                    {x.startsWith('linear-gradient') || x.startsWith('radial-gradient') ? 'Gradient' : selectedBackgroundMediaName}
                                </Button>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                                <Button sx={{ minWidth: 0 }} onClick={() => handleBackgroundDelete(index)}>
                                    <Delete />
                                </Button>
                            </Grid>
                        </React.Fragment>
                    )
                })
            }
            <BlockStyleDataLabel name="Blend" />
            <Grid item xs={4}>
                <StyleSelect {...props} attributeKey='background-blend-mode'>
                    {blendOptions.map((option) => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                </StyleSelect>
            </Grid>
            <BlockStyleDataLabel name="Color" />
            <Grid item xs={4}>
                <AdvancedColorPicker attributeKey='background-color' {...props} />
            </Grid>
            <BlockStyleDataLabel name="Clip" />
            <Grid item xs={10}>
                <StyleSelect {...props} attributeKey='background-clip'>
                    <MenuItem key="padding-box" value="padding-box">padding-box</MenuItem>
                    <MenuItem key="border-box" value="border-box">border-box</MenuItem>
                    <MenuItem key="content-box" value="content-box">content-box</MenuItem>
                </StyleSelect>
            </Grid>
            <Popover
                id="background-config"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ padding: theme.spacing(1), width: '350px' }}>
                    <Grid container rowSpacing={0.5} columnSpacing={0.5} sx={{ marginTop: theme.spacing(0), marginBottom: theme.spacing(1) }}>
                        <BackgroundInputs
                            background={backgroundImage}
                            attachment={backgroundAttachment}
                            origin={backgroundOrigin}
                            position={backgroundPosition}
                            repeat={backgroundRepeat}
                            size={backgroundSize}
                            handleAttributeChange={handleBackgroundAttributeChange} />
                    </Grid>
                </Box>
            </Popover>
        </>
    )
}