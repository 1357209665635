import { IconButton, Typography, useTheme } from "@mui/material";
import React, { useCallback, useContext, useMemo } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  getSmoothStepPath,
  useReactFlow,
} from "reactflow";
import ClearIcon from "@mui/icons-material/Clear";
import SequenceContext from "../../../../../context/SequenceContext";
import useActionDebugContext from "../../../../../hooks/useActionDebugContext";

const ButtonEdge = (props: EdgeProps) => {
  const {
    id,
    label,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    target,
    source,
  } = props;

  const { setEdges } = useReactFlow();

  const { deleteConnection } = useContext(SequenceContext);

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onEdgeClick = useCallback(() => {
    setEdges((edges) => edges.filter((x) => x.id !== id));
    deleteConnection(id);
  }, [id, setEdges, deleteConnection]);

  const labelIfNotEmpty = useMemo(() => {
    if (!label) {
      return null;
    }
    return <Typography>{label}</Typography>;
  }, [label]);

  const theme = useTheme();

  const {
    processState: { isActive, queue, last, actionStates },
  } = useActionDebugContext();

  const isNext = useMemo(() => {
    if (!isActive) {
      return false;
    }
    const next = queue.find(Boolean);
    return (
      next &&
      next === target &&
      last === source &&
      actionStates[next].status !== "executing"
    );
  }, [isActive, queue, last, actionStates]);

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          ...style,
          stroke: "#99c448",
          strokeWidth: 2,
          strokeOpacity: 0.75,
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
            pointerEvents: "all",
          }}
        >
          {labelIfNotEmpty}
          <IconButton
            size="small"
            sx={{
              background: "#414a4c",
              transition: ".3s",
              [":hover"]: { background: "#c6574f" },
            }}
            onClick={onEdgeClick}
          >
            <ClearIcon
              sx={{ color: "#ffffff", width: "20px", height: "20px" }}
            />
          </IconButton>
        </div>
      </EdgeLabelRenderer>
      {isNext && (
        <circle r="6" fill={`${theme.palette.primary.main}`} className="circle">
          <animateMotion dur="3s" repeatCount="indefinite" path={edgePath} />
        </circle>
      )}
    </>
  );
};

export default ButtonEdge;
