import { Button } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { selectMedia } from '../../../redux/media/mediaSlice';
import { useAppSelector } from '../../../redux/hooks';
import FullScreenDialog from '../../builder/BlockInfo/BlockDetail/BlockDetailEdit/FullScreenDialog';
import Media from '../../media/Media';
import { ThemeProvider } from '@emotion/react';
import { InnerTheme } from '../../../theme/InnerTheme';

export default function CustomGridMediaSelect(props: GridRenderEditCellParams) {
    const { id, value, field } = props;
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
    const apiRef = useGridApiContext();
    const media = useAppSelector(selectMedia);

    const handleValueChange = (fileId: number) => {
        apiRef.current.setEditCellValue({ id, field, value: fileId });
        handleMediaDialogOpen();
    };

    function handleMediaDialogOpen(): void {
        setMediaDialogOpen(prev => !prev);
    }

    return (
        <>
            <Button onClick={() => handleMediaDialogOpen()}>
                {!value ? 'Select Image' : media.find(x => x.id === value)?.name ?? 'image not found'}
            </Button>
            <ThemeProvider theme={InnerTheme}>
                <FullScreenDialog title='MEDIA' handleCloseDialog={handleMediaDialogOpen} open={mediaDialogOpen}>
                    <Media onSelectMedia={handleValueChange} includedFileTypes={['.otf', '.ttf', '.woff', '.woff2']}/>
                </FullScreenDialog>
            </ThemeProvider>
        </>
    )
}