import React from 'react';
import { MediaFile } from '../../redux/media/types';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { ListItemButton, ListItemIcon, ListItemText, List, ListItem, Checkbox, Collapse } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

export interface Props {
    files: MediaFile[],
    currentFolder: string,
    folderOpen: boolean,
    level: number,
    selectedFolder: string,
    selectedFiles: number[],
    handleChangeFolder: (path: string) => void,
    handleSelectedFilesChanged: (id: number) => void
}

export default function MediaTree(props: Props) {
    const { files, currentFolder, level, selectedFolder, handleChangeFolder, handleSelectedFilesChanged, selectedFiles, folderOpen } = props;

    const [openFolders, setOpenFolders] = React.useState<number[]>([]);

    const theme = useTheme();

    React.useEffect(() =>  {
        setOpenFolders(folders.map(x => x.id));
    }, []);

    const handleOpenFoldersChange = React.useCallback((id: number) => {
        if (openFolders.includes(id)) {
            setOpenFolders(openFolders.filter(x => x !== id));
        } else {
            setOpenFolders([...openFolders, id]);
        }
    }, [openFolders, setOpenFolders]);

    const folders = React.useMemo(() => files.filter(x => {
        return x.extension === 'folder'
            && x.directoryPath.replace(currentFolder, "").replace(x.name, "").length <= 2
    }), [files, currentFolder]);

    const currentFiles = React.useMemo(() => files.filter(x => {
        return x.extension !== 'folder'
            && x.directoryPath.replace(currentFolder, "").replace(x.name, "").length < 2
    }), [files, currentFolder]);

    const folderItems = React.useMemo(() => folders.map(folder => {
        const childItems = files.filter(x => x.directoryPath.startsWith(folder.directoryPath) && !folders.find(y => y.id === x.id) && !currentFiles.find(y => y.id === x.id));
        return (
            <React.Fragment key={`media-folder-${folder.id}`}>
                <ListItem
                    dense
                    disablePadding
                    secondaryAction={
                        <Checkbox
                            edge="end"
                            onChange={() => handleSelectedFilesChanged(folder.id)}
                            checked={selectedFiles.includes(folder.id)}
                        />
                    }>
                    <ListItemButton
                        sx={{ paddingLeft: theme.spacing(level) }}
                        dense
                        selected={selectedFolder === folder.directoryPath}
                        onClick={() => { handleChangeFolder(folder.directoryPath); handleOpenFoldersChange(folder.id) }}>
                        <ListItemIcon>
                            {childItems.length ? (openFolders.includes(folder.id) ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />) : <ExpandMoreOutlinedIcon sx={{ opacity: 0 }} />}
                            <FolderOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={folder.name} />
                    </ListItemButton>
                </ListItem>
                <MediaTree
                    files={childItems}
                    currentFolder={folder.directoryPath}
                    level={level + 1}
                    folderOpen={openFolders.includes(folder.id)}
                    selectedFolder={selectedFolder}
                    handleChangeFolder={handleChangeFolder}
                    handleSelectedFilesChanged={handleSelectedFilesChanged}
                    selectedFiles={selectedFiles} />
            </React.Fragment >
        )
    }), [folders, files, currentFolder, currentFiles, selectedFiles, handleSelectedFilesChanged, handleChangeFolder, handleOpenFoldersChange, openFolders, selectedFolder]);

    const fileItems = React.useMemo(() => currentFiles.map(file => (
        <ListItem
            key={`media-file-${file.id}`}
            dense
            disablePadding
            secondaryAction={
                <Checkbox
                    edge="end"
                    onChange={() => handleSelectedFilesChanged(file.id)}
                    checked={selectedFiles.includes(file.id)}
                />
            }>
            <ListItemButton
                sx={{ paddingLeft: theme.spacing(level) }}
                dense
                onClick={() => { handleChangeFolder(file.directoryPath); }}>
                <ListItemIcon>
                    <ExpandMoreOutlinedIcon sx={{ opacity: 0 }} />
                    <ImageOutlinedIcon />
                </ListItemIcon>
                <ListItemText sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}} primary={file.name + file.extension} />
            </ListItemButton>
        </ListItem>
    )), [handleSelectedFilesChanged, selectedFiles, theme, handleChangeFolder, currentFiles]);

    return (
        <List
            key={currentFolder}
            disablePadding
            sx={{ width: '100%', bgcolor: 'background.paper' }}
        >
            <Collapse in={folderOpen || level === 3}>
                {folderItems}
                {fileItems}
            </Collapse>
        </List>
    );
0 }