import React from 'react';
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { BorderAll, BorderStyle, NorthEast, NorthWest, SouthEast, SouthWest } from '@mui/icons-material';
import ValueWithUnitInput from '../../../../../utils/Inputs/ValueWithUnitInput';
import BorderConfigInputs from './BorderConfigInputs';
import { BlockStyleAttribute } from '../BlockStyleInputs';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void, 
    selectedBlock?: BlockData
}

export default function BorderInputs(props: Props) {
    const [radiusMode, setRadiusMode] = React.useState("all");

    return (
        <>
            <BlockStyleDataLabel name="Radius" />
            <Grid item xs={10}>
                <ToggleButtonGroup value={radiusMode}
                    onChange={(e, value) => setRadiusMode(value)}
                    exclusive={true}
                    size='small'
                    fullWidth>
                    <ToggleButton value="all" key="all">
                        <BorderAll />
                    </ToggleButton>
                    <ToggleButton value="partial" key="partial">
                        <BorderStyle />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            {
                radiusMode === "all" ?
                    <>
                        <Grid item xs={2} />
                        <Grid item xs={10}>
                            <ValueWithUnitInput attributeKey='border-radius' {...props} />
                        </Grid>
                    </>
                    : 
                    <>
                        <Grid item xs={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <NorthWest />
                        </Grid>
                        <Grid item xs={4}>
                            <ValueWithUnitInput attributeKey='border-top-left-radius' {...props} />
                        </Grid>
                        <Grid item xs={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                            <NorthEast />
                        </Grid>
                        <Grid item xs={4}>
                            <ValueWithUnitInput attributeKey='border-top-right-radius' {...props} />
                        </Grid>
                        <Grid item xs={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                            <SouthWest />
                        </Grid>
                        <Grid item xs={4}>
                            <ValueWithUnitInput attributeKey='border-bottom-left-radius' {...props} />
                        </Grid>
                        <Grid item xs={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                            <SouthEast />
                        </Grid>
                        <Grid item xs={4}>
                            <ValueWithUnitInput attributeKey='border-bottom-right-radius' {...props} />
                        </Grid>
                    </>
            }
            <BorderConfigInputs {...props} />
        </>
    )
}