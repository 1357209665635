import React from 'react';

/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { styled } from '@mui/material';

import clsx from 'clsx';
import { NoEncryption, NoMeals } from '@mui/icons-material';

const PREFIX = 'CustomCursor';

const classes = {
    cursor: `${PREFIX}-cursor`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.cursor}`]: {
        position: 'fixed',
        minHeight: '10px',
        minWidth: '10px',
        transform: 'translate(-50%,-50%)',
        pointerEvents: 'none',
        cursor: 'none',
        display: 'none',
        zIndex: 9999
    }
}));

const CustomCursor = () => {

    const cursorRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        document.addEventListener('mousemove', mouseHandler);
        document.addEventListener('mouseleave', mouseHandler);
    }, [])
    
    const mouseHandler = (e: MouseEvent) => {
        if(cursorRef.current){
            cursorRef.current.style.left = e.pageX + 'px';
            cursorRef.current.style.top = e.pageY + 'px';
        }
    }

    return (
        <Root id="custom-cursor" className={clsx(classes.cursor, 'custom-cursor')} ref={cursorRef}>

        </Root>
    );
}

export default CustomCursor;