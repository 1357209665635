export const Colors = {
  primary_main: "#75B53B",
  secondary_main: "#7d7d7d",
  background_default: "rgb(224, 224, 224)",
  background_grey: "rgb(214, 214, 214)",
  common_black: "#1A1C15",
  info_main: "#00D3FD",
  text_silver: "#b1b1b1",
  bloxxi_red: "#FA2934",
  inner_button_background: "black",
  inner_button_hover_background: "#303030",
  inner_icon_button_background: "#414141",
  inner_icon_button_disabled: "#858484",
  node_background: "#414a4c",
  primary_button_hover: "#4f7928",
  fontColor: "#f2f0f0",
};
