import BaseAction from "../BaseAction";
import { ActionType } from "..";

export enum RequestMethod {
  get = "Get",
  post = "Post",
  put = "Put",
  delete = "Delete",
}

export default interface RequestAction extends BaseAction {
  type: ActionType.Request;
  method: RequestMethod;
  url: string;
  queryParameters?: Record<string, string>;
  requestHeaders?: Record<string, string>;
  body?: string;
}
