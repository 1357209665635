import { Grid, Button, TextField, FormControl, Select, FilledInput, MenuItem, ToggleButton, ToggleButtonGroup, debounce } from '@mui/material';
import React, { useCallback } from 'react';
import { getStyleById } from '../../../../../../Utility';
import AdvancedColorPicker from '../../../../../utils/Inputs/AdvancedColorPicker';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import GradientBackground from '../../../../../utils/Inputs/GradientBackground';
import { BorderAll, BorderStyle, Image, Palette } from '@mui/icons-material';
import Media from '../../../../../media/Media';
import FullScreenDialog from '../../BlockDetailEdit/FullScreenDialog';
import { useAppSelector } from '../../../../../../redux/hooks';
import { selectMedia } from '../../../../../../redux/media/mediaSlice';

interface Props {
    background: string,
    repeat: string,
    size: string,
    attachment: string,
    origin: string,
    position: string,
    handleAttributeChange: (attributeKey: string, attributeValue: string, ignoreDefault?: boolean) => void,
}

enum BackgroundType {
    image = 'image',
    gradient = 'gradient'
}

export default function BackgroundInputs(props: Props) {
    const { background, repeat, size, attachment, origin, position, handleAttributeChange } = props;
    const [backgroundType, setBackgroundType] = React.useState(BackgroundType.image);

    const [backgroundSize, setBackgroundSize] = React.useState('auto auto');
    const [backgroundPosition, setBackgroundPosition] = React.useState('');
    const [mediaDialogOpen, setMediaDialogOpen] = React.useState(false);

    const media = useAppSelector(selectMedia);

    const selectedBackgroundMediaName = !background.startsWith('radial') 
                                    && !background.startsWith('linear') 
                                    && background !== 'url()'
                                    ? media.find(x => x.id.toString() === background)?.name ?? ''
                                    : '';

    const handleBackgroundTypeChange = React.useCallback((type: BackgroundType) => {
        if (type === null)
            return;

        setBackgroundType(type);
    }, [setBackgroundType]);

    const setBackground = React.useCallback((changedBackground: string) => {
        handleAttributeChange('background-image', changedBackground, true);
    }, []);

    React.useEffect(() => {
        if (background.startsWith('radial') || background.startsWith('linear'))
            setBackgroundType(BackgroundType.gradient);
        else
            setBackgroundType(BackgroundType.image);
    }, [background])

    React.useEffect(() => {
        setBackgroundSize(size);
    }, [size])

    React.useEffect(() => {
        setBackgroundPosition(position);
    }, [position])

    const handlePositionChange = (value: string) => {
        setBackgroundPosition(value);
        debouncedChangeHandler('background-position', value, true)
    }  

    const handleSizeChange = (value: string) => {
        setBackgroundSize(value);
        debouncedChangeHandler('background-size', value, true)
    }  

    const debouncedChangeHandler = useCallback(
        debounce(handleAttributeChange, 1000),
        [background, repeat, size, attachment, origin, position, handleAttributeChange],
    );

    function handleMediaSelect(id: number): void {
        setBackground(id.toString());
        setMediaDialogOpen(false);
    }

    return (
        <>
            <BlockStyleDataLabel name="Type" />
            <Grid item xs={10}>
                <ToggleButtonGroup value={backgroundType}
                    onChange={(e, value) => handleBackgroundTypeChange(value)}
                    exclusive={true}
                    size='small'
                    fullWidth>
                    <ToggleButton value={BackgroundType.image} key={BackgroundType.image}>
                        <Image />
                    </ToggleButton>
                    <ToggleButton value={BackgroundType.gradient} key={BackgroundType.gradient}>
                        <Palette />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Image" />
            <Grid item xs={10}>
                {
                    backgroundType === BackgroundType.gradient ? (
                        <GradientBackground gradient={background} setBackground={setBackground} />
                    ) : (
                        <Button fullWidth onClick={() => setMediaDialogOpen(true)} endIcon={<Image />}> { background === `url('')` ? 'Choose image' : selectedBackgroundMediaName} </Button>
                    )
                }
            </Grid>
            <BlockStyleDataLabel name="Size" />
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    size='small'
                    variant='filled'
                    hiddenLabel
                    onChange={(e) => handleSizeChange(e.target.value)}
                    value={backgroundSize} />
            </Grid>
            <BlockStyleDataLabel name="Attach." />
            <Grid item xs={4}>
                <FormControl variant="filled" fullWidth hiddenLabel>
                    <Select
                        id='background-attachment-select'
                        value={attachment}
                        fullWidth
                        input={<FilledInput />}
                        onChange={(e) => handleAttributeChange('background-attachment', e.target.value, true)}
                        size='small'
                    >
                        <MenuItem key="scroll" value="scroll">scroll</MenuItem>
                        <MenuItem key="fixed" value="fixed">fixed</MenuItem>
                        <MenuItem key="local" value="local">local</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <BlockStyleDataLabel name="Origin" />
            <Grid item xs={10}>
                <FormControl variant="filled" fullWidth hiddenLabel>
                    <Select
                        id='background-origin-select'
                        value={origin}
                        fullWidth
                        input={<FilledInput />}
                        onChange={(e) => handleAttributeChange('background-origin', e.target.value, true)}
                        size='small'
                    >
                        <MenuItem key="padding-box" value="padding-box">padding-box</MenuItem>
                        <MenuItem key="border-box" value="border-box">border-box</MenuItem>
                        <MenuItem key="content-box" value="content-box">content-box</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <BlockStyleDataLabel name="Position" />
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    size='small'
                    variant='filled'
                    hiddenLabel
                    value={backgroundPosition}
                    onChange={(e) => handlePositionChange(e.target.value)} />
            </Grid>
            <BlockStyleDataLabel name="Repeat" />
            <Grid item xs={4}>
                <FormControl variant="filled" fullWidth hiddenLabel>
                    <Select
                        id='background-repeat-select'
                        value={repeat}
                        fullWidth
                        input={<FilledInput />}
                        onChange={(e) => handleAttributeChange('background-repeat', e.target.value, true)}
                        size='small'
                    >
                        <MenuItem key="repeat" value="repeat">repeat</MenuItem>
                        <MenuItem key="repeat-x" value="repeat-x">repeat-x</MenuItem>
                        <MenuItem key="repeat-y" value="repeat-y">repeat-y</MenuItem>
                        <MenuItem key="no-repeat" value="no-repeat">no-repeat</MenuItem>
                        <MenuItem key="space" value="space">space</MenuItem>
                        <MenuItem key="round" value="round">round</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <FullScreenDialog title='MEDIA' handleCloseDialog={() => setMediaDialogOpen(false)} open={mediaDialogOpen}>
                <Media onSelectMedia={handleMediaSelect} />
            </FullScreenDialog>
        </>
    )
}