export interface BlockRequest {
    id: number,
    name: string,
    uri: string,
    method: RequestType,
    header: RequestHeaderEntry[],
    projectId?: number,
}

export interface RequestHeaderEntry {
    id: number,
    name: string,
    value: string,
    BlockRequestId?: number,
}

export enum RequestType {
    GET = "GET",
    POST = "POST",
}