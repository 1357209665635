import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Colors } from "../../theme/Colors";

const StyledDataGrid = styled(DataGrid)(() => ({
  borderColor: Colors.text_silver,
  "& .MuiDataGrid-withBorderColor": {
    borderColor: Colors.text_silver,
  },
}));

export default StyledDataGrid;
