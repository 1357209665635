export interface DynProps {
    id: number,
    type: DynDataType,
    tag: string,
    dynModelId: number
}

export interface DynModel {
    id: number,
    name: string,
    dynPropSets: DynProps[],
    projectId: number,
}

export interface DynDataEntryProp {
    tag: string,
    value: string,
}

export interface DynDataEntry {
    id: number,
    name: string,
    data: string,
    dynDataSetId?: number,
    dynModelId?: number,
    projectId: number,
}

export interface DynDataSet {
    id: number,
    name: string,
    dynDataEntries: DynDataEntry[],
    dynModelId?: number,
    projectId: number,
}

export interface DynPropAssignment {
    id: number,
    dynPropId?: number,
    blockProp: string,
    dynDataSetId?: number,
    blockDataId?: number
}

export enum DynDataType {
    text = 'Text',
    number = 'Number',
    boolean = 'Boolean',
    mediaFile = 'MediaFile'
} 
