import * as React from 'react';
import Grid from '@mui/material/Grid';
import AddBlockCard from '../BlockToolBar/AddBlockCard';
import { FilterFramesOutlined, HMobiledataOutlined, HorizontalSplitOutlined, InsertLinkOutlined, KeyboardOutlined, NotesOutlined, SquareOutlined, TableRowsOutlined, ViewWeekOutlined } from '@mui/icons-material';
import { Box, Card, CardActionArea, CardContent, CardMedia, Divider, Paper, Typography, useTheme } from '@mui/material';
import CardWidget from '../../widgets/CardWidget';
import { TemplateData } from '../../../redux/template/types';
import { template } from 'lodash';

interface Props {
    templates: TemplateData[],
    handleTemplateAdd: (id: number) => void
}

export default function TemplateGrid(props: Props) {
    const { templates, handleTemplateAdd } = props;
    const theme = useTheme();

    const templateCards = React.useMemo(() => templates.map(t => (
        <Grid key={t.id} item xs={3}>
            <Card>
                <CardActionArea onClick={() => handleTemplateAdd(t.id)}>
                    <CardMedia
                        component="img"
                        height="200"
                        image="/placeholder.JPG"
                        alt="placeholder"
                    />
                    <CardContent>
                        <Typography variant="h6">
                            {t.name}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )), [template, handleTemplateAdd, templates]);

    return (
        <Grid sx={{ padding: theme.spacing(1) }} container spacing={1}>
            {templateCards}
        </Grid>
    );
}