import { Add, DeleteOutlined, TranslateOutlined, LanguageOutlined, MoreVert, Delete, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, List, ListItem, ListItemButton, ListItemText, Popover, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { createModel, defaultModel, deleteModel, getModels, selectDynModels } from '../../../redux/dyndata/dyndataSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ConfirmationPopover from '../../utils/ConfirmationPopover';
import MorePopover from '../../utils/MorePopOver';
import StyledDataGrid from '../../utils/StyledDataGrid';
import TitleBox from '../../widgets/TitleBox';
import DeleteConfirmationDialog from '../../utils/DeleteConfirmationDialog';

interface Props {
    projectId: number
}

export default function DynamicDataModelSettings(props: Props) {
    const { projectId } = props;
    const [modelConfirmationPopoverOpen, setModelConfirmationPopoverOpen] = useState(false);
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const models = useAppSelector(selectDynModels);

    React.useEffect(() => {
        dispatch(getModels(projectId));
    }, [])

    const handleModelCreate = React.useCallback(() => {
        dispatch(createModel({ ...defaultModel, projectId }))
            .unwrap()
            .then(payload => {
                navigate(`/project-detail/${projectId}/settings/model/${payload.id}`)
            });
    }, [dispatch, projectId, navigate]);

    const handleModelDelete = React.useCallback((id: number) => {
        dispatch(deleteModel(id));
    }, [dispatch]);

    const handleModelEdit = React.useCallback((id: number) => {
        navigate(`/project-detail/${projectId}/settings/model/${id}`);
    }, [navigate, projectId]);

    const handleLanguageConfirmationPopoverSubmit = React.useCallback(() => {
        selectedIds.forEach(x => dispatch(deleteModel(Number(x))));
        setModelConfirmationPopoverOpen(false);
    }, [selectedIds, setModelConfirmationPopoverOpen, dispatch])

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                return [
                    <GridActionsCellItem
                        key="edit"

                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={() => handleModelEdit(Number(id))}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={() => handleModelDelete(Number(id))}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}                            
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        },
    ];

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Models'
                subTitle='Lorem ipsum'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>
                <ConfirmationPopover
                    handleClose={() => setModelConfirmationPopoverOpen(false)}
                    handleSubmit={handleLanguageConfirmationPopoverSubmit}
                    open={modelConfirmationPopoverOpen}>
                    <IconButton component="label" onClick={() => setModelConfirmationPopoverOpen(true)}>
                        <DeleteOutlined />
                    </IconButton>
                </ConfirmationPopover>
                <Button variant="contained" sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />} onClick={handleModelCreate}>
                    ADD MODEL
                </Button>
            </TitleBox>
            <Box sx={{ height: '600px', width: '100%', mb: theme.spacing(2) }}>
                <StyledDataGrid
                    rows={models}
                    columns={columns}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    onRowSelectionModelChange={(newSM) => {
                        setSelectedIds(newSM);
                    }}
                    rowSelectionModel={selectedIds}
                    disableRowSelectionOnClick
                    loading={false}
                />
            </Box>
        </>
    )
}