import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import { BlockAnimation, BlockAnimationKeyFrame } from "./types";

const PREFIX = "animation";

export type AnimationState = {
  animations: BlockAnimation[];
};

export const defaultAnimationKeyFrame: BlockAnimationKeyFrame = {
  id: 0,
  percent: 0,
  blockStyleData: [],
};

export const defaultAnimation: BlockAnimation = {
  id: 0,
  name: "new animation",
  blockAnimationKeyFrames: [
    defaultAnimationKeyFrame,
    { ...defaultAnimationKeyFrame, percent: 100 },
  ],
  easeFunction: "ease",
  milliseconds: 3000,
};

export const initialState: AnimationState = {
  animations: [],
};

export const getAnimationsAsync = createAppAsyncThunk(
  `${PREFIX}/get`,
  async () => {
    const response = await axios.get<BlockAnimation[]>("/animation");
    return response.data;
  }
);

export const updateAnimationFrameAsync = createAppAsyncThunk(
  `${PREFIX}/frame/update`,
  async (animationKeyFrame: BlockAnimationKeyFrame) => {
    const response = await axios.post<BlockAnimationKeyFrame>(
      "/animation/frame/update",
      animationKeyFrame
    );

    return response.data;
  }
);

export const updateAnimationAsync = createAppAsyncThunk(
  `${PREFIX}/update`,
  async (animation: BlockAnimation) => {
    const response = await axios.post<BlockAnimation>(
      "/animation/update",
      animation
    );

    return response.data;
  }
);

export const createAnimationAsync = createAppAsyncThunk(
  `${PREFIX}/create`,
  async (animation: BlockAnimation) => {
    const response = await axios.post<BlockAnimation>("/animation", animation);
    return response.data;
  }
);

export const deleteAnimationAsync = createAppAsyncThunk(
  `${PREFIX}/delete`,
  async (id: number) => {
    await axios.delete(`/animation/${id}`);
    return id;
  }
);

export const deleteAnimationFrameAsync = createAppAsyncThunk(
  `${PREFIX}/frame/delete`,
  async (id: number) => {
    await axios.delete(`/animation/frame/${id}`);
    return id;
  }
);

export const animationSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get animations
    builder.addCase(getAnimationsAsync.fulfilled, (state, action) => {
      state.animations = action.payload;
    });
    // update
    builder.addCase(updateAnimationAsync.fulfilled, (state, action) => {
      const index = state.animations.findIndex(
        (x) => x.id === action.payload.id
      );
      if (index < 0) {
        state.animations.push(action.payload);
      } else {
        state.animations[index] = action.payload;
      }
    });
    // create
    builder.addCase(createAnimationAsync.fulfilled, (state, action) => {
      state.animations.push(action.payload);
    });
    // delete
    builder.addCase(deleteAnimationAsync.fulfilled, (state, action) => {
      state.animations = state.animations.filter(
        (x) => x.id !== action.payload
      );
    });
    // update aniamtion key frame
    builder.addCase(updateAnimationFrameAsync.fulfilled, (state, action) => {
      const index = state.animations.findIndex((x) =>
        x.blockAnimationKeyFrames.find((y) => y.id === action.payload.id)
      );
      if (index >= 0) {
        const frameIndex = state.animations[
          index
        ].blockAnimationKeyFrames.findIndex((x) => x.id === action.payload.id);
        state.animations[index].blockAnimationKeyFrames[frameIndex] =
          action.payload;
      }
    });
    // delete aniamtion key frame
    builder.addCase(deleteAnimationFrameAsync.fulfilled, (state, action) => {
      const index = state.animations.findIndex((x) =>
        x.blockAnimationKeyFrames.find((y) => y.id === action.payload)
      );
      if (index >= 0) {
        state.animations[index].blockAnimationKeyFrames = state.animations[
          index
        ].blockAnimationKeyFrames.filter((x) => x.id !== action.payload);
      }
    });
  },
});

// selectors

export const selectGlobalAnimations = (s: RootState) =>
  s.animations.animations.filter((x) => !x.projectId);
export const selectProjectAnimations = (s: RootState) =>
  s.animations.animations.filter((x) => x.projectId);
export const selectAnimationById = (id: number) => (s: RootState) =>
  s.animations.animations.find((x) => x.id === id) ?? defaultAnimation;

export default animationSlice.reducer;
