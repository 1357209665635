import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useAppSelector } from "../../redux/hooks";
import { selectSelectedBlock } from "../../redux/blocks/blockSlice";
import { BorderBottom, BorderLeft, BorderRight, BorderTop } from "@mui/icons-material";

const SelectedBlockOverlay: React.FC = () => {
    const [targetRect, setTargetRect] = useState<DOMRect>();
    const [targetMargins, setTargetMargins] = useState({ top: 0, right: 0, bottom: 0, left: 0 });
    const [targetPadding, setTargetPadding] = useState({ top: 0, right: 0, bottom: 0, left: 0 });
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isHovering, setIsHovering] = useState<boolean>(true);

    const selectedBlock = useAppSelector(selectSelectedBlock);

    const canvas = document.getElementById('canvas');
    const target = useMemo(
        () => document.getElementById(`b${selectedBlock?.id ?? ""}`),
        [selectedBlock]
    );

    const handleStartHover = () => {
        console.log('start hover');
        setIsHovering(true);
    }

    const handleStopHover = () => {
        console.log('stop hover');
        setIsHovering(false);
    }

    const updatePosition = useCallback(() => {
        if (target) {
            setTargetRect(target.getBoundingClientRect());
            setIsVisible(true);
            const computedStyle = window.getComputedStyle(target);
            setTargetMargins({
                top: parseInt(computedStyle.marginTop, 10),
                right: parseInt(computedStyle.marginRight, 10),
                bottom: parseInt(computedStyle.marginBottom, 10),
                left: parseInt(computedStyle.marginLeft, 10),
            });
            setTargetPadding({
                top: parseInt(computedStyle.paddingTop, 10),
                right: parseInt(computedStyle.paddingRight, 10),
                bottom: parseInt(computedStyle.paddingBottom, 10),
                left: parseInt(computedStyle.paddingLeft, 10),
            });

            if(canvas) {
                const canvasRect = canvas.getBoundingClientRect();
                const isCursorOver = (e: MouseEvent) => {
                    const mouseX = e.clientX;
                    const mouseY = e.clientY;
                    return mouseX >= canvasRect.left && mouseX <= canvasRect.right && mouseY >= canvasRect.top && mouseY <= canvasRect.bottom;
                };
        
                document.addEventListener('mousemove', (e) => {
                    setIsHovering(isCursorOver(e));
                }, { once: true });
            }
        }
    }, [target, setTargetRect, setIsVisible]);

    useEffect(() => {

        if (!target || !canvas) {
            setIsVisible(false);
            setIsHovering(false);
            return;
        }

        updatePosition();

        const config = {
            attributes: true,
            childList: true,
            subtree: true,
            characterData: false,
            attributeOldValue: false,
            characterDataOldValue: false,
          };

        const observer = new MutationObserver((mutations) => {
            console.log("Observed mutations: ", mutations);
            for (const mutation of mutations) {
              if (mutation.type === "attributes" || mutation.type === "childList") {
                updatePosition();
              }
            }
          });
        
        const resizeObserver = new ResizeObserver(updatePosition);
        observer.observe(target, config);
        resizeObserver.observe(canvas);
        window.addEventListener("resize", updatePosition);
        window.addEventListener("scroll", updatePosition);
        canvas.addEventListener("scroll", updatePosition);
        canvas.addEventListener('mouseenter', handleStartHover);
        canvas.addEventListener('mouseleave', handleStopHover);

        return () => {
            console.log("Clean up useffect");
            observer.disconnect();
            window.removeEventListener("resize", updatePosition);
            window.removeEventListener("scroll", updatePosition);
            canvas.removeEventListener('mouseenter', handleStartHover);
            canvas.removeEventListener('mouseleave', handleStopHover);
            canvas.removeEventListener("scroll", updatePosition);
        };
    }, [target]);

    const overlayStyle: React.CSSProperties = useMemo(() => {
        if (targetRect && targetPadding) {
            return {
                position: "fixed",
                top: targetRect.top + window.scrollY,
                left: targetRect.left + window.scrollX,
                width: targetRect.width,
                height: targetRect.height,
                outline: "dashed 2px grey",
                zIndex: 1000,
                pointerEvents: "none",
                borderTop: isHovering ? `${targetPadding.top}px solid rgba(23, 253, 0, 0.2)` : "none",
                borderBottom: isHovering ? `${targetPadding.bottom}px solid rgba(23, 253, 0, 0.2)` : "none",
                borderLeft: isHovering ? `${targetPadding.left}px solid rgba(23, 253, 0, 0.1)` : "none",
                borderRight: isHovering ? `${targetPadding.right}px solid rgba(23, 253, 0, 0.1)` : "none",
            };
        }
        return {};
    }, [targetRect, targetPadding, isHovering]);

    const marginStyle: React.CSSProperties = useMemo(() => {
        if (targetMargins && targetRect && targetPadding) {
            return {
                position: "absolute",
                width: targetRect.width,
                height: targetRect.height,
                top: `-${targetMargins.top + targetPadding.top}px`,
                left: `-${targetMargins.left + targetPadding.left}px`,
                pointerEvents: "none",
                borderTop: `${targetMargins.top}px solid rgba(255, 200, 0, 0.2)`,
                borderBottom: `${targetMargins.bottom}px solid rgba(255, 200, 0, 0.2)`,
                borderLeft: `${targetMargins.left}px solid rgba(255, 200, 0, 0.1)`,
                borderRight: `${targetMargins.right}px solid rgba(255, 200, 0, 0.1)`,
                boxSizing: 'content-box',
                display: isHovering ? "block" : "none",
            };
        }
        return {};
    }, [targetRect, targetMargins, targetPadding, isHovering]);

    if (!isVisible || !targetRect) return null;

    return <div id="overlay" style={overlayStyle}>
        <div id="overlay-margin" style={marginStyle} />
    </div>;
};

export default SelectedBlockOverlay;