import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
  FormEvent,
  useMemo,
  useCallback,
} from "react";
import EventContext, {
  Subscription,
} from "../../context/blockEvents/EventContext";
import { TranslationContext } from "../../context/TranslationContext";
import {
  BlockAttributeTypes,
  CustomComponentsType,
  GetStyle,
  getReferencedBlockEvents,
  getReferencedProps,
} from "../../Utility";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import * as blockTypes from "../../redux/blocks/types";
import { DynDataEntry } from "../../redux/dyndata/types";
import {
  selectBlock,
  selectSelectedBlock,
} from "../../redux/blocks/blockSlice";
import { BlockAnimation } from "../../redux/animation/types";
import dompurify from "dompurify";
import ContainerBlock from "./BlockTypes/ContainerBlock";
import GridBlock from "./BlockTypes/GridBlock";
import GridItemBlock from "./BlockTypes/GridItemBlock";
import LoopBlock from "./BlockTypes/LoopBlock";
import { selectMedia } from "../../redux/media/mediaSlice";
import { selectDynModels } from "../../redux/dyndata/dyndataSlice";
import DynamicBlock from "../../components_new/builder/DynamicBlock";
import ActionRunContext from "../../context/blockActions/ActionRunContext";
import { selectSequences } from "../../redux/actionsequence/sequenceSlice";
import ActionSequenceType from "../../redux/actionsequence/types/helpers/ActionSequenceType";
import { ActionType } from "../../redux/actionsequence/types/actions";
import TriggerEventAction from "../../redux/actionsequence/types/actions/data/TriggerEventAction";

interface Props {
  currentBlock: blockTypes.BlockData;
  blocks: blockTypes.BlockData[];
  canvasMode: blockTypes.ScreenMode;
  dataEntries: DynDataEntry[];
  scrollOverwriteId?: number;
}

const customComponents: CustomComponentsType = {
  [blockTypes.BlockType.LOOP]: LoopBlock,
  [blockTypes.BlockType.CONTAINER]: ContainerBlock,
};

const Block = (props: Props) => {
  const { currentBlock, blocks, canvasMode, dataEntries, scrollOverwriteId } =
    props;

  const media = useAppSelector(selectMedia);
  const dispatch = useAppDispatch();
  const { run } = useContext(ActionRunContext);
  const translationContext = useContext(TranslationContext);
  const [triggered, setTriggered] = useState<string[]>([]);
  const triggerRef = useRef(triggered);

  const blockRef: any = useRef(null);
  const { subscribe, dispatch: dispatchEvent } = useContext(EventContext);
  const triggerActionRef = useRef<number[]>([]);
  const eventRef = useRef(currentBlock.blockEvents);
  const models = useAppSelector(selectDynModels);
  const selectedBlock = useAppSelector(selectSelectedBlock);
  const animations: BlockAnimation[] = [];

  const propBlock = currentBlock.referenceProps
    ? getReferencedProps(currentBlock)
    : currentBlock;
  const styleBlock =
    selectedBlock?.id === currentBlock.id ? selectedBlock : currentBlock;

  const sequences = useMemo(
    () =>
      currentBlock.actionSequences
        .filter((x) => x.actionSequence)
        .map((x) => x.actionSequence!) ?? [],
    [currentBlock.actionSequences]
  );

  const runSequence = useCallback(
    (sequence: (typeof sequences)[number], args?: any) => {
      return run({
        actions: sequence.actions.map((x) => x.data),
        connections: sequence.actionConnections,
        context: {
          source: currentBlock.id,
        },
        args,
      });
    },
    [sequences, run, currentBlock.id]
  );

  const events = React.useMemo(
    () => getReferencedBlockEvents(currentBlock),
    [currentBlock.blockEvents, currentBlock.templateReference]
  );

  useEffect(() => {
    triggerRef.current = triggered;
  }, [triggered]);

  useEffect(() => {
    const initialTriggers = sequences.filter(
      (x) => x.type === ActionSequenceType.OnLoad
    );
    if (initialTriggers.length) {
      initialTriggers.forEach((x) => {
        run({
          actions: x.actions.map((x) => x.data),
          connections: x.actionConnections,
          context: {
            source: currentBlock.id,
          },
        });
      });
    }

    const hasUntriggerOnClickAway = events.filter(
      (x) => x.unTriggerOnClickAway
    ).length;

    const hasOnScrollTriggers = sequences.filter(
      (x) => x.type === ActionSequenceType.OnScroll
    ).length;

    if (hasUntriggerOnClickAway) {
      document.body.addEventListener("click", clickAwayEventHandler, {
        capture: true,
      });
    }

    const canvasElement = scrollOverwriteId
      ? document.getElementById(`b${scrollOverwriteId}`)
      : document.getElementById("canvas");

    const handleCanvasScroll = () => {
      if (canvasElement)
        handleScroll(canvasElement.scrollTop, canvasElement.offsetHeight);
    };

    if (canvasElement && hasOnScrollTriggers) {
      canvasElement.addEventListener("scroll", handleCanvasScroll);
    }

    return () => {
      if (hasUntriggerOnClickAway) {
        document.body.removeEventListener("click", clickAwayEventHandler);
      }

      if (canvasElement && hasOnScrollTriggers)
        canvasElement.removeEventListener("scroll", handleCanvasScroll);
    };
  }, []);

  useEffect(() => {
    if (!events.length) return;

    events.forEach((x) => {
      subscribe(x.eventName, {
        initiallyTriggered: false,
        on(...args) {
          setTriggered((prev) => {
            prev = prev.filter((y) => y !== x.eventName);
            prev.push(x.eventName);
            return prev;
          });
        },
        type: x.behaviour,
        source: currentBlock.id,
        onlyBySource: x.onlyTriggerFrom ?? [],
        nonSourceTriggerBehaviour: x.nonSourceTriggerBehaviour,
        off(...args) {
          setTriggered((prev) => {
            return prev.filter((y) => y !== x.eventName);
          });
        },
      } as Subscription);
    });

    eventRef.current = events;
  }, [currentBlock, events, subscribe]);

  const handleScroll = (scrollHeight: number, canvasHeight: number) => {
    currentBlock.actionSequences
      .filter((x) => x.actionSequence?.type === ActionSequenceType.OnScroll)
      .forEach((as) => {
        const actionSequence = as.actionSequence!;

        const scrollHeightToCheck = as.zeroBased
          ? scrollHeight > (as.scrollHeight ?? 0)
          : (as?.scrollHeight ?? 0) + scrollHeight + canvasHeight >
            blockRef?.current?.offsetTop;

        if (
          scrollHeightToCheck &&
          !triggerActionRef.current.includes(actionSequence.id)
        ) {
          runSequence(actionSequence);
          triggerActionRef.current = [
            ...triggerActionRef.current,
            actionSequence.id,
          ];
        } else if (
          !scrollHeightToCheck &&
          triggerActionRef.current.includes(actionSequence.id)
        ) {
          actionSequence.actions
            .filter((x) => x.type === ActionType.TriggerEvent)
            .forEach((act) => {
              const eventAction = act.data as TriggerEventAction;
              if (eventAction?.event) {
                dispatchEvent(eventAction.event, currentBlock.id, {
                  specific: "off",
                });
              }
            });
          triggerActionRef.current = triggerActionRef.current.filter(
            (id) => id !== actionSequence.id
          );
        }
      });
  };

  const clickAwayEventHandler = React.useCallback(
    (e: MouseEvent) => {
      if (
        eventRef.current.filter((x) => x.unTriggerOnClickAway).length &&
        triggerRef.current.length &&
        blockRef.current &&
        !blockRef.current.contains(e.target)
      ) {
        eventRef.current.forEach((blockEvent) => {
          dispatchEvent(blockEvent.eventName, currentBlock.id, {
            specific: "off",
          });
        });
      }
    },
    [
      triggered,
      dispatchEvent,
      triggerRef.current,
      blockRef.curr,
      eventRef.current,
    ]
  );

  const blockClickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      if (currentBlock.blockType === blockTypes.BlockType.A)
        e.preventDefault();
      
      dispatch(selectBlock(currentBlock.id));

      if (sequences.length) {
        sequences
          .filter((x) => x.type === ActionSequenceType.OnClick)
          .forEach((x) => {
            runSequence(x);
          });
      }
    },
    [dispatch, currentBlock, sequences, runSequence]
  );

  const handleSubmit = React.useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const formDataObject: { [key: string]: FormDataEntryValue } = {};
      // Iterate over the FormData entries (key-value pairs)
      formData.forEach((value, key) => {
        // Add each entry as a property to the formDataObject
        formDataObject[key] = value;
      });

      if (sequences.length) {
        sequences
          .filter((x) => x.type === ActionSequenceType.OnSubmit)
          .forEach((x) => {
            runSequence(x, formDataObject);
          });
      }
    },
    [runSequence, sequences]
  );

  const childBlocks = React.useMemo(() => {
    return blocks.length
      ? blocks.filter(
          (x) =>
            x.locationPath?.split("-").length - 1 ===
            currentBlock.locationPath?.split("-").length
        )
      : [];
  }, [blocks, currentBlock]);

  const displayedBlocks: ReactNode = React.useMemo(
    () =>
      childBlocks
        .sort((a, b) => a.sortPath - b.sortPath)
        .map((block) => {
          return (
            <Block
              key={block.id}
              currentBlock={block}
              blocks={blocks.filter((x) =>
                x.locationPath?.startsWith(block.locationPath + "-")
              )}
              canvasMode={canvasMode}
              dataEntries={dataEntries}
              scrollOverwriteId={
                currentBlock.overwriteWindowScroll
                  ? currentBlock.id
                  : scrollOverwriteId
              }
            />
          );
        }),
    [blocks, scrollOverwriteId, childBlocks, canvasMode, dataEntries]
  );

  const blockContent = React.useMemo(() => {
    let content = translationContext.translate(propBlock.content ?? "");
    const contentAssignment = currentBlock.dynPropAssignments.find(
      (x) => x.blockProp === "content"
    );

    if (contentAssignment) {
      const data = dataEntries.find(
        (x) => x.dynDataSetId === contentAssignment.dynDataSetId
      )?.data;
      const model = models.find(
        (x) =>
          x.dynPropSets.findIndex((y) => y.id === contentAssignment.dynPropId) >
          -1
      );
      const property = model?.dynPropSets.find(
        (x) => x.id === contentAssignment.dynPropId
      );
      if (data && contentAssignment.dynPropId && property) {
        const dataObj = JSON.parse(data);
        content = content.replace(`%%${property.tag}%%`, dataObj[property.tag]);
      }
    }
    return dompurify.sanitize(content);
  }, [
    translationContext,
    currentBlock.dynPropAssignments,
    dataEntries.length,
    propBlock.content,
  ]);

  let attributesObject = propBlock.blockAttributes.reduce<
    Record<string, string | Function>
  >((acc, curr) => {
    if (
      BlockAttributeTypes[propBlock.blockType].common.includes(curr.name)
    ) {
      if (
        curr.name === "src" &&
        propBlock.blockType === blockTypes.BlockType.IMG
      ) {
        acc[curr.name] =
          `root${media.find((x) => x.id.toString() === curr.value)?.url}` ?? "";
      } else {
        acc[curr.name] = curr.value;
      }
    }
    return acc;
  }, {});

  if (currentBlock.blockType === blockTypes.BlockType.FORM) {
    attributesObject["onSubmit"] = handleSubmit;
  }

  const CustomComponent = customComponents[currentBlock.blockType];

  const styles = GetStyle(canvasMode, styleBlock, media);

  if (CustomComponent) {
    return (
      <CustomComponent
        {...props}
        blocks={blocks}
        blockData={currentBlock}
        styles={styles}
        canvasMode={canvasMode}
        scrollOverwriteId={scrollOverwriteId}
        id={`b${currentBlock.id}`}
      >
        {displayedBlocks}
      </CustomComponent>
    );
  }

  return (
    <DynamicBlock
      tagName={currentBlock.blockType.toLowerCase()}
      children={
        blockTypes.VoidBlockTypes.includes(currentBlock.blockType) ||
        blockTypes.ContentBlockTypes.includes(
          currentBlock.blockType
        ) ? undefined : blockTypes.ContentWithChildrenBlockTypes.includes(
            currentBlock.blockType
          ) ? (
          <>
            {" "}
            {blockContent} {displayedBlocks}{" "}
          </>
        ) : (
          displayedBlocks
        )
      }
      id={`b${currentBlock.id}`}
      onClick={blockClickHandler}
      ref={blockRef}
      className={triggered.join(" ")}
      dangerouslySetInnerHTML={
        blockTypes.ContentBlockTypes.includes(currentBlock.blockType)
          ? { __html: blockContent }
          : undefined
      }
      {...attributesObject}
      css={styles}
    />
  );
};
export default Block;
