import { LanguageOutlined, Add, DeleteOutlined, Cancel, Delete, Edit, Save } from '@mui/icons-material';
import { Button, FilledInput, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField, useTheme } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridEventListener, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, GridRowSelectionModel, MuiEvent } from '@mui/x-data-grid';
import React from 'react';
import useRouteParam from '../../../hooks/useRouteParam';
import { defaultDataSet, getDataSets, getModels, selectDynData, selectDynDataSetById, selectDynModels, updateDataSet } from '../../../redux/dyndata/dyndataSlice';
import { DynDataSet, DynDataType, DynProps } from '../../../redux/dyndata/types';
import { BackendError, useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getDefaultDynEntryData } from '../../../Utility';
import ConfirmationPopover from '../../utils/ConfirmationPopover';
import TitleBox from '../../widgets/TitleBox';
import yup from '../../../validation/yup';
import SnackBarOperations from '../../../components/SnackBar/SnackBarOperations';
import DeleteConfirmationDialog from '../../utils/DeleteConfirmationDialog';

export default function DataSetForm() {

    const dataSetId = useRouteParam('dataSetId');
    const projectId = useRouteParam('projectId');
    const theme = useTheme();
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);
    const [propConfirmationPopoverOpen, setPropConfirmationPopoverOpen] = React.useState(false);
    const [editDataSet, setEditDataSet] = React.useState(defaultDataSet);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const models = useAppSelector(selectDynModels);
    const dataSets = useAppSelector(selectDynData);
    const currentDataSet = useAppSelector(selectDynDataSetById(dataSetId));

    const currentModel = models.find(x => x.id === currentDataSet.dynModelId);
    const dispatch = useAppDispatch();

    const entryGridSource = currentDataSet?.dynDataEntries?.map((e) => {
        if (currentDataSet.dynModelId) {
            const data = JSON.parse(e.data);
            return {
                id: e.id, name: e.name, ...currentModel?.dynPropSets.reduce((trans: any, prop: DynProps) => {
                    trans[prop.tag] = data[prop.tag];
                    return trans;
                }, {})
            };
        } else {
            return { id: 0 }
        }
    }) ?? [];

    React.useEffect(() => {
        if (currentDataSet)
            setEditDataSet({ ...currentDataSet });
    }, [currentDataSet])

    React.useEffect(() => {
        dispatch(getDataSets(projectId));
        dispatch(getModels(projectId));
    }, [])

    const handleEditClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }, [setRowModesModel, rowModesModel]);

    const handleSaveClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }, [setRowModesModel, rowModesModel]);

    const getDatasetValidationSchema = React.useCallback((id: number) => yup.object().shape({
        // name: yup.string().required('Dataset name is required.')
        //     .unique('Name already exists within project', (name: string) => !dataSets.filter(x => x.id !== id).map(x => x.name).includes(name)),
        dynDataEntries: yup.array().of(yup.object().shape({
            //name: yup.string().required('Data entry name is required.')
        }))
        //.uniqueByProp('Data entry name has to be unique.', 'name')
    }), [currentDataSet.dynDataEntries, dataSets])

    const handleDeleteClick = React.useCallback((id: GridRowId) => () => {
        let changedDataSet = { ...currentDataSet }
        changedDataSet.dynDataEntries = currentDataSet.dynDataEntries.filter(x => x.id !== id)

        getDatasetValidationSchema(changedDataSet.id).validate(changedDataSet)
            .then(() => {
                dispatch(updateDataSet(changedDataSet));
            })
            .catch((err: unknown) => {
                if (err instanceof yup.ValidationError) {
                    SnackBarOperations.error(err.message);
                }
            })

    }, [currentDataSet, dispatch, getDatasetValidationSchema]);

    const handleCancelClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    }, [setRowModesModel, rowModesModel]);

    const columns: GridColDef[] = currentModel ? [
        ...(currentModel?.dynPropSets ? (
            currentModel.dynPropSets.map((p): GridColDef => {
                return {
                    field: p.tag,
                    headerName: p.tag,
                    minWidth: 50,
                    flex: p.type === DynDataType.boolean ? 0 : 1,
                    editable: true,
                }
            })) : []),
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key="save"
                            icon={<Save />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key="cancel"
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key="edit"
                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleDeleteClick(id)}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}                            
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ] : [];

    const handleDataEntryConfirmationPopoverSubmit = React.useCallback(async () => {
        const changedDataSet: DynDataSet = { ...currentDataSet, dynDataEntries: currentDataSet.dynDataEntries.filter(x => !selectedIds.includes(x.id)) };

        try {
            await getDatasetValidationSchema(changedDataSet.id).validate(changedDataSet);
            await dispatch(updateDataSet(changedDataSet));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error updating data entry: ${err.message}`);
            }
        }

    }, [dispatch, currentDataSet, selectedIds, getDatasetValidationSchema]);

    const handleDataSetChange = React.useCallback(async () => {
        const entries = editDataSet.dynModelId === currentDataSet.dynModelId ? editDataSet.dynDataEntries : [];

        try {
            const updated = { ...editDataSet, dynDataEntries: entries };
            await getDatasetValidationSchema(editDataSet.id).validate(updated);
            await dispatch(updateDataSet(updated));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error updating data entry: ${err.message}`);
            }
        }

    }, [dispatch, editDataSet, getDatasetValidationSchema]);

    const handleDataEntryAdd = React.useCallback(async () => {
        if (currentModel) {
            const defaultData = getDefaultDynEntryData(currentModel);
            try {
                const updated = { ...currentDataSet, dynDataEntries: [...currentDataSet.dynDataEntries, { id: 0, name: '', data: JSON.stringify(defaultData), dynDataSetId: currentDataSet.id, projectId: projectId }] };
                await dispatch(updateDataSet(updated));
            } catch (err) {
                if (err instanceof BackendError) {
                    SnackBarOperations.error(`Error updating data entry: ${err.message}`);
                }
            }
        }
    }, [dispatch, currentDataSet]);

    const handleDataSetModelChanged = React.useCallback((id: number) => {
        setEditDataSet({ ...editDataSet, dynModelId: id });
    }, [setEditDataSet, editDataSet, models]);

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const processRowUpdate = React.useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        const oldEntry = currentDataSet.dynDataEntries.find(x => x.id === newRow.id);

        if (!oldEntry)
            return;

        let newEntry = { ...oldEntry };
        delete newRow.id;

        newEntry.data = JSON.stringify(newRow);

        let newDataSet = { ...currentDataSet };

        newDataSet.dynDataEntries = [...newDataSet.dynDataEntries.filter(x => x.id !== oldEntry.id), newEntry]

        try {
            console.log("Validate: ", newDataSet)
            //await getDatasetValidationSchema(newDataSet.id).validate(newDataSet);
            await dispatch(updateDataSet(newDataSet));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error updating data entry: ${err.message}`);
            }
            // to prevent row from exiting edit mode
            throw err;
        }

        return updatedRow;
    }, [currentDataSet, getDatasetValidationSchema, dispatch]);

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Edit dataset'
                subTitle='Lorem ipsum'>
            </TitleBox>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ width: '100%' }}>
                        <Stack direction="row" spacing={2} padding={2}>
                            <TextField sx={{ width: '250px' }} id="dataset-name" label="Name" variant="filled" value={editDataSet.name} onChange={(e) => setEditDataSet({ ...editDataSet, name: e.target.value })} onBlur={handleDataSetChange} />
                            
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel id="model-select">Model</InputLabel>
                                    <Select
                                        id='model-select'
                                        label="Model"
                                        value={editDataSet?.dynModelId ?? ""}
                                        input={<FilledInput sx={{ width: '250px' }} />}
                                        onBlur={handleDataSetChange}
                                        onChange={(e) => { handleDataSetModelChanged(Number(e.target.value)) }}
                                    >
                                        <MenuItem value="">
                                            None
                                        </MenuItem>
                                        {
                                            models.map(m => (
                                                <MenuItem key={m.id} value={m.id}>{m.name}</MenuItem>
                                            ))
                                        }
                                    </Select>                    
                                </FormControl>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ height: '800px', width: '100%', padding: theme.spacing(2) }}>
                        <TitleBox
                            mainTitle='Properties'
                            subTitle='Lorem ipsum'
                            maintitleVariant='h6'
                            subTitleVariant='subtitle2'>
                            <ConfirmationPopover
                                handleClose={() => setPropConfirmationPopoverOpen(false)}
                                handleSubmit={handleDataEntryConfirmationPopoverSubmit}
                                open={propConfirmationPopoverOpen}>
                                <IconButton component="label" disabled={selectedIds.length < 1} onClick={() => setPropConfirmationPopoverOpen(true)}>
                                    <DeleteOutlined />
                                </IconButton>
                            </ConfirmationPopover>
                            <Button variant="contained" onClick={handleDataEntryAdd} disabled={!currentDataSet.dynModelId} sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />}>
                                ADD ENTRY
                            </Button>
                        </TitleBox>
                        <DataGrid
                            rows={entryGridSource}
                            columns={columns}
                            pageSizeOptions={[10]}
                            checkboxSelection
                            onRowSelectionModelChange={(newSM) => {
                                setSelectedIds(newSM);
                            }}
                            rowSelectionModel={selectedIds}
                            disableRowSelectionOnClick
                            editMode='row'
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={setRowModesModel}
                            onRowEditStart={handleRowEditStart}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
