import React, { useCallback, useMemo, useState } from "react";
import { ActionSequence } from "../../redux/actionsequence/types/helpers/ActionSequence";
import TitleBox from "../widgets/TitleBox";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddIcon from "@mui/icons-material/Add";
import { Button, IconButton, Paper, useTheme } from "@mui/material";
import ConfirmationPopover from "../utils/ConfirmationPopover";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import MorePopover from "../utils/MorePopOver";
import { Edit, Delete } from "@mui/icons-material";
import DeleteConfirmationDialog from "../utils/DeleteConfirmationDialog";

interface Props {
  actionSequences: ActionSequence[];
  subTitle: string;
  handleSequenceCreate: () => Promise<void>;
  handleSequencesDelete: (ids: number[]) => Promise<void>;
  handleSequenceEditClick: (id: number) => void;
}

const ActionSequences: React.FC<Readonly<Props>> = (props) => {
  const {
    actionSequences,
    subTitle,
    handleSequenceCreate,
    handleSequenceEditClick,
    handleSequencesDelete,
  } = props;

  const theme = useTheme();

  const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>(
    []
  );

  const handleListConfirmationPopoverSubmit = useCallback(() => {
    const idsToDelete = [...selectedIds.map((x) => Number(x))];
    handleSequencesDelete(idsToDelete);
  }, [selectedIds]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "action",
        headerName: "Actions",
        width: 75,
        renderCell(params) {
          return (
            <MorePopover
              actions={[
                {
                  action() {
                    handleSequenceEditClick(params.row.id);
                  },
                  text: "Edit",
                },
                {
                  action() {
                    handleSequencesDelete([params.row.id]);
                  },
                  text: "Delete",
                },
              ]}
            />
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        cellClassName: 'actions',
        headerName: 'Actions',
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              key="edit"

              icon={<Edit />}
              label="Edit"
              disabled={row.isDefault}
              className="textPrimary"
              onClick={() => handleSequenceEditClick(Number(id))}
              color="inherit"
            />,
            <DeleteConfirmationDialog onDeleteConfirm={() => handleSequencesDelete([Number(id)])}>
              <GridActionsCellItem
                key="delete"
                icon={<Delete />}
                label="Delete"
                disabled={row.isDefault}
                color="inherit"
              />
            </DeleteConfirmationDialog>,
          ];
        },
      }
    ],
    [handleSequenceEditClick, handleSequencesDelete]
  );

  return (
    <>
      <TitleBox
        avatarIcon={<AccountTreeIcon />}
        mainTitle="Actionsequences"
        subTitle={subTitle}
      >
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          onClick={handleSequenceCreate}
        >
          ADD SEQUENCE
        </Button>
        <DeleteConfirmationDialog onDeleteConfirm={handleListConfirmationPopoverSubmit}>
          <IconButton
            disabled={selectedIds.length < 1}
            component="label"
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </DeleteConfirmationDialog>
      </TitleBox>
      <Paper sx={{ width: "100%", height: "700px", padding: theme.spacing(2) }}>
        <DataGrid
          rows={actionSequences}
          columns={columns}
          pageSizeOptions={[10]}
          checkboxSelection
          onRowSelectionModelChange={(newSM) => {
            setSelectedIds(newSM);
          }}
          rowSelectionModel={selectedIds}
          disableRowSelectionOnClick
        />
      </Paper>
    </>
  );
};

export default ActionSequences;
