import React from 'react'
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types'
import { Button, FilledInput, FormControl, Grid, MenuItem, Select, Slider, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import { Clear, Remove, MoreHoriz, Add } from '@mui/icons-material';
import ValueWithUnitInput from '../../../../../utils/Inputs/ValueWithUnitInput';
import AdvancedColorPicker from '../../../../../utils/Inputs/AdvancedColorPicker';
import { Cursors, getStyleById } from '../../../../../../Utility';
import StyleTextField from '../../../../../utils/Inputs/StyleTextField';
import StyleSelect from '../../../../../utils/Inputs/StyleSelect';
import StyleToggleButtonGroup from '../../../../../utils/Inputs/StyleToggleButtonGroup';
import { BlockStyleAttribute } from '../BlockStyleInputs';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void,
    selectedBlock?: BlockData,
}

export default function EffectsInputs(props: Props) {
    const { currentBlockStyle, handleAttributeChange, selectedBlock } = props;

    return (
        <>
            <BlockStyleDataLabel name="Events" />
            <Grid item xs={10}>
                <StyleSelect {...props} attributeKey='pointer-events'>
                    <MenuItem key="none" value="none">None</MenuItem>
                    <MenuItem key="auto" value="auto">Auto</MenuItem>
                    <MenuItem key="visiblePainted" value="visiblePainted">Visible Painted (SVG only)</MenuItem>
                    <MenuItem key="visibleFill" value="visibleFill">Visible Fill (SVG only)</MenuItem>
                    <MenuItem key="visibleStroke" value="visibleStroke">Visible Stroke (SVG only)</MenuItem>
                    <MenuItem key="visible" value="visible">Visible (SVG only)</MenuItem>
                    <MenuItem key="painted" value="painted">Painted (SVG only)</MenuItem>
                    <MenuItem key="fill" value="fill">Fill (SVG only)</MenuItem>
                    <MenuItem key="stroke" value="stroke">Stroke (SVG only)</MenuItem>
                    <MenuItem key="all" value="all">All (SVG only)</MenuItem>
                </StyleSelect>
            </Grid>
            <BlockStyleDataLabel name="Blending" />
            <Grid item xs={10}>
                <StyleSelect {...props} attributeKey='mix-blend-mode'>
                    <MenuItem key="normal" value="normal">Normal</MenuItem>
                    <MenuItem key="multiply" value="multiply">Multiply</MenuItem>
                    <MenuItem key="screen" value="screen">Screen</MenuItem>
                    <MenuItem key="overlay" value="overlay">Overlay</MenuItem>
                    <MenuItem key="darken" value="darken">Darken</MenuItem>
                    <MenuItem key="lighten" value="lighten">Lighten</MenuItem>
                    <MenuItem key="color-dodge" value="color-dodge">Color-dodge</MenuItem>
                    <MenuItem key="color-burn" value="color-burn">Color-burn</MenuItem>
                    <MenuItem key="hard-light" value="hard-light">Hard-light</MenuItem>
                    <MenuItem key="soft-light" value="soft-light">Soft-light</MenuItem>
                    <MenuItem key="difference" value="difference">Difference</MenuItem>
                    <MenuItem key="exclusion" value="exclusion">Exclusion</MenuItem>
                    <MenuItem key="hue" value="hue">Hue</MenuItem>
                    <MenuItem key="saturation" value="saturation">Saturation</MenuItem>
                    <MenuItem key="color" value="color">Color</MenuItem>
                    <MenuItem key="luminosity" value="luminosity">Luminosity</MenuItem>
                </StyleSelect>
            </Grid>
            <BlockStyleDataLabel name="Opacity" />
            <Grid item xs={10}>
                <Slider
                    value={Number(currentBlockStyle.blockStyleData.find(x => x.attributeKey === 'opacity')?.attributeValue ?? getStyleById(selectedBlock, 'opacity'))}
                    onChangeCommitted={(e, value) => handleAttributeChange([{ attributeKey: 'opacity', attributeValue: value.toString() }], true)}
                    getAriaValueText={(value: number) => value.toString()}
                    step={0.1}
                    min={0}
                    max={1}
                    valueLabelDisplay="auto"
                    sx={{ width: '90%' }}
                />
            </Grid>
            <BlockStyleDataLabel name="Outline" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='outline'>
                    <ToggleButton value="none" key="none">
                        <Tooltip title="Removes any borders from the element">
                            <Clear />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="solid" key="solid">
                        <Tooltip title="Applies a continuous, unbroken outline">
                            <Remove />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="dashed" key="dashed">
                        <Tooltip title="Sets the outline to a series of short line segments">
                            <Remove />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="dotted" key="dotted">
                        <Tooltip title="Creates a outline with evenly spaced dots">
                            <MoreHoriz />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            {
                currentBlockStyle.blockStyleData.find(x => x.attributeKey === 'outline')?.attributeValue !== undefined && currentBlockStyle.blockStyleData.find(x => x.attributeKey === 'outline')?.attributeValue !== 'none' ?
                    <>
                        <BlockStyleDataLabel name="Width" />
                        <Grid item xs={4}>
                            <ValueWithUnitInput attributeKey={'outline-width'} {...props} />
                        </Grid>
                        <BlockStyleDataLabel name="Offset" />
                        <Grid item xs={4}>
                            <ValueWithUnitInput attributeKey={'outline-offset'} {...props} />
                        </Grid>
                        <BlockStyleDataLabel name="Offset" />
                        <Grid item xs={10}>
                            <AdvancedColorPicker attributeKey={'outline-color'} {...props} />
                        </Grid>
                    </>
                    : null
            }
            <BlockStyleDataLabel name="Cursor" />
            <Grid item xs={10}>
                <StyleSelect {...props} attributeKey='cursor'>

                    {
                        Cursors.map(cursor => (
                            <MenuItem key={cursor} value={cursor}>{cursor}</MenuItem>
                        ))
                    }
                </StyleSelect>
            </Grid>
            <BlockStyleDataLabel name="Box shadow" size={12} />
            <Grid item xs={12}>
                <StyleTextField {...props} attributeKey='box-shadow' />
            </Grid>
            <BlockStyleDataLabel name="Transition" size={12} />
            <Grid item xs={12}>
                <StyleTextField {...props} attributeKey='transition' />
            </Grid>
            <BlockStyleDataLabel name="Transform" size={12} />
            <Grid item xs={12}>
                <StyleTextField {...props} attributeKey='transform' />
            </Grid>
            <BlockStyleDataLabel name="Filter" size={12} />
            <Grid item xs={12}>
                <StyleTextField {...props} attributeKey='filter' />
            </Grid>
            <BlockStyleDataLabel name="Animation" size={12} />
            <Grid item xs={12}>
                <StyleTextField {...props} attributeKey='animation' />
            </Grid>
            <BlockStyleDataLabel name="Content" size={12} />
            <Grid item xs={12}>
                <StyleTextField {...props} attributeKey='content' />
            </Grid>
        </>
    )
}