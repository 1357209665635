import React from 'react';
import useRouteParam from '../../hooks/useRouteParam';
import { selectProjectAnimations } from '../../redux/animation/animationSlice';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Animations from './Animations';

export default function ProjectAnimations() {
    const animations = useAppSelector(selectProjectAnimations);

    return (
        <Animations animations={animations} subTitle='Edit Project animations' />
    );
}