import React from 'react';
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import { Grid, FormControl, Select, FilledInput, MenuItem, Box, TextField } from '@mui/material';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import SpacingPart from '../../../../../utils/SpacingPart';
import { getStyleById, isStyleInheritedAndChanged } from '../../../../../../Utility';
import ValueWithUnitInput from '../../../../../utils/Inputs/ValueWithUnitInput';
import StyleTextField from '../../../../../utils/Inputs/StyleTextField';
import StyleSelect from '../../../../../utils/Inputs/StyleSelect';
import { BlockStyleAttribute } from '../BlockStyleInputs';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void, 
    selectedBlock?: BlockData,
}

export default function PositionInputs(props: Props) {
    return (
        <>
            <BlockStyleDataLabel name="Position" />
            <Grid item xs={10} >
                <StyleSelect {...props} attributeKey='position' >
                        <MenuItem key="static" value="static">Static</MenuItem>
                        <MenuItem key="relative" value="relative">Relative</MenuItem>
                        <MenuItem key="absolute" value="absolute">Absolute</MenuItem>
                        <MenuItem key="fixed" value="fixed">Fixed</MenuItem>
                        <MenuItem key="sticky" value="sticky">Sticky</MenuItem>
                </StyleSelect>
            </Grid>
            <BlockStyleDataLabel name="Top"/>
            <Grid item xs={4} >
                <ValueWithUnitInput attributeKey={'top'} additionalEntries={['auto']} {...props} />
            </Grid>
            <BlockStyleDataLabel name="Bottom"/>
            <Grid item xs={4} >
                <ValueWithUnitInput attributeKey={'bottom'} additionalEntries={['auto']} {...props} />
            </Grid>
            <BlockStyleDataLabel name="Left"/>
            <Grid item xs={4} >
                <ValueWithUnitInput attributeKey={'left'} additionalEntries={['auto']} {...props} />
            </Grid>
            <BlockStyleDataLabel name="Right"/>
            <Grid item xs={4} >
                <ValueWithUnitInput attributeKey={'right'} additionalEntries={['auto']} {...props} />
            </Grid>
            <BlockStyleDataLabel name="Z-Index"/>
            <Grid item xs={10}>
                <StyleTextField {...props} attributeKey='z-index' type='number'/>
            </Grid>
        </>
    )
}