import { createContext } from "react";
import {
  EventBehaviour,
  NonSourceTriggerBehaviour,
} from "../../redux/blocks/types";

type AnyFunction = (...args: any[]) => any;

interface BaseSubscription {
  source: number;
  initiallyTriggered: boolean;
}

interface OnceSubscription extends BaseSubscription {
  on: AnyFunction;
  type: EventBehaviour.Once;
}

interface ToggleSubscription extends BaseSubscription {
  on: AnyFunction;
  off: AnyFunction;
  type: EventBehaviour.ToggleInvariant;
}

interface ToggleSourceSubscription extends BaseSubscription {
  on: AnyFunction;
  off: AnyFunction;
  type: EventBehaviour.ToggleSource;
  onlyBySource: number[];
  nonSourceTriggerBehaviour: NonSourceTriggerBehaviour;
}

interface MultipleSubscription extends BaseSubscription {
  on: AnyFunction;
  type: EventBehaviour.Default;
}

export type Subscription =
  | OnceSubscription
  | ToggleSubscription
  | ToggleSourceSubscription
  | MultipleSubscription;

export type SubscriptionState = {
  [eventName: string]: Subscription[];
};

type SourceTargetTriggerInfo = {
  source: number;
  targets: {
    target: number;
    triggers: number;
    toggle?: boolean;
  }[];
};

export type TriggerState = {
  [eventName: string]: {
    lastSource?: number;
    triggers: number;
    toggle?: boolean;
    bySource: SourceTargetTriggerInfo[];
  };
};

/**
 * Interface defining the event context, which is the interface
 * exposed by the EventContext. The event context is used by the
 * block's event listeners and allows subscribing and triggering
 * events.
 */
export interface EventContext {
  dispatch(
    eventName: string,
    source: number,
    config?: { specific: "off" | "on" },
    ...args: any[]
  ): void;
  subscribe(eventName: string, subscription: Subscription): void;
  subscriptions: SubscriptionState;
  triggerState: TriggerState;
}

export const initialEventContext: EventContext = {
  dispatch(_eventName, _source, _config) {},
  subscribe(_eventName, _subscription) {},
  subscriptions: {},
  triggerState: {},
};

const EventContext = createContext<EventContext>(initialEventContext);

export default EventContext;
