import * as React from "react";
import { DynDataEntry, DynDataSet } from "../../redux/dyndata/types";
import { State, BlockDataContext } from "./BlockDataContext";

interface Props {
  children: React.ReactNode;
}

const FakeBlockDataContextProvider = (props: Props) => {
  const { children } = props;
  const [state, setState] = React.useState<State>({
    entries: [],
    dataSets: [],
  });

  React.useEffect(() => {
    setState({ entries: [], dataSets: [] });
  }, []);

  const updateDataSet = (dataSet: DynDataSet) => {
    setState({
      ...state,
      dataSets: [...state.dataSets.filter((x) => x.id !== dataSet.id), dataSet],
    });
  };

  const updateEntry = (entry: DynDataEntry) => {
    setState({
      ...state,
      entries: [...state.entries.filter((x) => x.id !== entry.id), entry],
    });
  };

  const value = React.useMemo(
    () => ({
      updateDataSet,
      updateEntry,
    }),
    [updateDataSet, updateEntry]
  );

  return (
    <BlockDataContext.Provider value={value}>
      {children}
    </BlockDataContext.Provider>
  );
};

export default FakeBlockDataContextProvider;
