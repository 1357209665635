import { Cancel } from '@mui/icons-material';
import { Box, Chip, FilledInput, Grid, List, ListItem, ListItemButton, ListItemText, ListSubheader, Select, Theme, useTheme } from '@mui/material';
import * as React from 'react';
import GridSelectList from './GridSelectList';

interface GridAndOption<T> {
    title: string;
    options: T[];
}

interface Props<T> {
    values: T[],
    xs: number;
    disabled?: boolean,
    setValues: (t: T[]) => void | Promise<void>
    gridsAndOptions: GridAndOption<T>[],
    nameSelector: (t: T) => string,
    idSelector: (t: T) => string | number,
}

function getStyles<T>(x: T, coll: readonly T[] | undefined, theme: Theme, idSelector: (t: T) => number | string) {
    if (!coll) {
        return {};
    }

    const isSelected = coll.findIndex(y => idSelector(y) === idSelector(x)) < 0;

    return {
        fontWeight:
            isSelected
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
        color:
            isSelected
                ? 'unset'
                : theme.palette.primary.main,
    };
}

export default function GridSelect<T>(props: Props<T>) {

    const { values, setValues, gridsAndOptions, xs, nameSelector, idSelector, disabled } = props;

    const theme = useTheme();

    const handleSelect = React.useCallback((selected: T) => {
        if (values.findIndex(x => idSelector(x) === idSelector(selected)) >= 0)
            setValues(values.filter(x => idSelector(x) !== idSelector(selected)));
        else
            setValues([...values, selected]);
    }, [setValues, values, idSelector]);

    const handleDelete = React.useCallback((toDelete: T) => {
        const index = values.findIndex(x => idSelector(x) === idSelector(toDelete));
        if (index < 0)
            return;
        setValues(values.filter(x => idSelector(x) !== idSelector(toDelete)));
    }, [setValues, values, idSelector]);

    const options = React.useMemo(() => {
        if (gridsAndOptions.length === 0) {
            return null;
        }
        if (gridsAndOptions.length === 1) {
            const gao = gridsAndOptions[0];
            return (
                <List
                    component="div"
                    subheader={
                        <ListSubheader component="div">
                            {gao.title}
                        </ListSubheader>
                    }
                    dense
                >
                    {
                        gao.options.map(x => (
                            <ListItemButton dense key={idSelector(x)} onClick={() => handleSelect(x)}>
                                <ListItemText style={getStyles(x, values, theme, idSelector)} primary={nameSelector(x)} />
                            </ListItemButton>
                        ))
                    }
                </List>
            )
        } else {
            return (
                <Grid container component='div'>
                    {gridsAndOptions.map((gao) => (
                        <Grid key={gao.title} item xs={xs}>
                            <GridSelectList title={gao.title}>
                                {
                                    gao.options.map(x => (
                                        <ListItemButton dense key={idSelector(x)} onClick={() => handleSelect(x)}>
                                            <ListItemText style={getStyles(x, values, theme, idSelector)} primary={nameSelector(x)} />
                                        </ListItemButton>
                                    ))
                                }
                            </GridSelectList>
                        </Grid>
                    ))}
                </Grid>
            )
        }
    }, [gridsAndOptions, idSelector, handleSelect, values, theme, nameSelector])

    return (
        <Select
            disabled={disabled}
            labelId='permission-select-label'
            id='permission-select'
            MenuProps={{
                sx: {
                    overflow: 'scroll',
                }
            }}
            multiple
            value={values}
            input={<FilledInput sx={{ width: '100%' }} />}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                        <Chip
                            onDelete={() => handleDelete(value)}
                            size='small' key={idSelector(value)}
                            label={nameSelector(value)}
                            deleteIcon={
                                <Cancel
                                    onMouseDown={(event) => event.stopPropagation()}
                                />
                            }
                        />
                    ))}
                </Box>
            )}
        >
            <Box sx={{ maxWidth: '600px' }}>
                {options}
            </Box>
        </Select>
    )
}