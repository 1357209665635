import yup, { yupObjectOf } from "../../../../validation/yup";
import IfElseNodeComponent from "../../../../components_new/actions/SequenceBuilder/customFlowComponents/customNodes/control/IfElseNodeComponent";
import BreakNodeComponent from "../../../../components_new/actions/SequenceBuilder/customFlowComponents/customNodes/control/BreakNodeComponent";
import ContinueNodeComponent from "../../../../components_new/actions/SequenceBuilder/customFlowComponents/customNodes/control/ContinueNodeComponent";
import DelayNodeComponent from "../../../../components_new/actions/SequenceBuilder/customFlowComponents/customNodes/control/DelayNodeComponent";
import LoopNodeComponent from "../../../../components_new/actions/SequenceBuilder/customFlowComponents/customNodes/control/LoopNodeComponent";
import RequestNodeComponent from "../../../../components_new/actions/SequenceBuilder/customFlowComponents/customNodes/data/RequestNodeComponent";
import SetVariableComponent from "../../../../components_new/actions/SequenceBuilder/customFlowComponents/customNodes/data/SetVariableComponent";
import TriggerEventNodeComponent from "../../../../components_new/actions/SequenceBuilder/customFlowComponents/customNodes/data/TriggerEventNodeComponent";
import StartNodeComponent from "../../../../components_new/actions/SequenceBuilder/customFlowComponents/customNodes/control/StartNodeComponent";
import evaluateExpression, {
  expressionParserRegex,
} from "../../../../functions/evaluateExpression";
import {
  getOutgoing,
  getOutgoingNodeIds,
} from "../../../../functions/utils/graphUtils";
import { HandleType } from "../helpers/ActionConnection";
import { ActionCollection } from "../helpers/ActionCollection";
import BreakAction from "./control/BreakAction";
import ContinueAction from "./control/ContinueAction";
import DelayAction from "./control/DelayAction";
import IfElseAction from "./control/IfElseAction";
import LoopAction from "./control/LoopAction";
import StartAction from "./control/StartAction";
import RequestAction, { RequestMethod } from "./data/RequestAction";
import SetVariableAction from "./data/SetVariableAction";
import TriggerEventAction from "./data/TriggerEventAction";
import React from "react";
import { Typography } from "@mui/material";
import axios from "axios";
import { cloneDeep } from "lodash";
export type AnyAction =
  | IfElseAction
  | LoopAction
  | BreakAction
  | ContinueAction
  | DelayAction
  | SetVariableAction
  | StartAction
  | TriggerEventAction
  | RequestAction;

export enum ActionType {
  Start = "Start",
  // Control:
  IfElse = "IfElse",
  Loop = "Loop",
  Break = "Break",
  Continue = "Continue",
  Delay = "Delay",
  // Data Actions:
  SetVariable = "SetVariable",
  Request = "Request",
  TriggerEvent = "TriggerEvent",
}

// Add your new action to this so the type can be inferred from the action type enum
export type InferAction<AT extends ActionType> = AT extends ActionType.Break
  ? BreakAction
  : AT extends ActionType.Continue
  ? ContinueAction
  : AT extends ActionType.Delay
  ? DelayAction
  : AT extends ActionType.Loop
  ? LoopAction
  : AT extends ActionType.IfElse
  ? IfElseAction
  : AT extends ActionType.Request
  ? RequestAction
  : AT extends ActionType.SetVariable
  ? SetVariableAction
  : AT extends ActionType.Start
  ? StartAction
  : AT extends ActionType.TriggerEvent
  ? TriggerEventAction
  : never;

// Integrate your action into this object to make it available to the application
const ACTIONS: ActionCollection = {
  [ActionType.IfElse]: {
    name: "If/Else",
    description: "Execution a next action conditionally",
    component: IfElseNodeComponent,
    defaultValues: {
      left: "12",
      operator: "!=",
      right: "13",
      name: "If-Else",
    },
    async execute(action, state) {
      const left = evaluateExpression(state, action.left);
      const right = evaluateExpression(state, action.right);

      const i = eval(`${left} ${action.operator} ${right}`) as boolean;

      const nextOptions = state.connections.filter(
        (x) => x.source === action.id
      );
      const trueOptions = nextOptions
        .filter((x) => x.sourceHandle === HandleType.IfElseTrue)
        .map((x) => x.target);

      const falseOptions = nextOptions
        .filter((x) => x.sourceHandle === HandleType.IfElseFalse)
        .map((x) => x.target);

      let next;
      let remove;
      if (i) {
        next = trueOptions;
        remove = falseOptions;
      } else {
        next = falseOptions;
        remove = trueOptions;
      }
      return {
        controlOptions: {
          next,
          remove,
        },
      };
    },
    validationSchema: yup.object().shape({
      left: yup
        .string()
        .test("expressionvalid", "Expression is not valid", (val) => {
          if (val?.startsWith("$")) {
            return expressionParserRegex.test(val);
          }
          return true;
        })
        .required("Left expression is required"),
      operator: yup.mixed().oneOf(["==", "!=", ">", "<"]),
      right: yup
        .string()
        .test("expressionvalid", "Expression is not valid", (val) => {
          if (val?.startsWith("$")) {
            return expressionParserRegex.test(val);
          }
          return true;
        })
        .required("Right expression is required"),
    }),
  },
  [ActionType.Break]: {
    name: "Break",
    description: (
      <>
        <Typography>Break out of a loop</Typography>
        <Typography>
          If this is used outside of a loop, it has no effect
        </Typography>
      </>
    ),
    component: BreakNodeComponent,
    defaultValues: {
      name: "Break",
    },
    async execute(action, state) {
      return {
        controlOptions: {
          event: "break",
          next: getOutgoingNodeIds(action.id, state.connections),
        },
      };
    },
  },
  [ActionType.Continue]: {
    name: "Continue",
    description: (
      <>
        <Typography>Jump to the beginning of a loop</Typography>
        <Typography>
          If this is used outside of a loop, it has no effect
        </Typography>
      </>
    ),
    component: ContinueNodeComponent,
    defaultValues: {
      name: "Continue",
    },
    async execute(action, state) {
      return {
        controlOptions: {
          event: "continue",
          next: getOutgoingNodeIds(action.id, state.connections),
        },
      };
    },
  },
  [ActionType.Delay]: {
    name: "Delay",
    description: "Delay sequence execution by an amount of milliseconds",
    component: DelayNodeComponent,
    defaultValues: {
      name: "Delay 1s",
      delayMs: 1000,
    },
    async execute(action, state) {
      await new Promise<void>((res) => setTimeout(() => res(), action.delayMs));
      return {
        controlOptions: {
          next: getOutgoingNodeIds(action.id, state.connections),
        },
      };
    },
    validationSchema: yup.object().shape({
      delayMs: yup
        .number()
        .moreThan(0, "More than 0 ms delay")
        .required("Number is required"),
    }),
  },
  [ActionType.Loop]: {
    name: "Loop",
    description: "Enables loops in your sequence",
    component: LoopNodeComponent,
    defaultValues: {
      name: "Loop 10 times",
      max: 10,
    },
    async execute(action, state) {
      return {
        controlOptions: {
          next: getOutgoing(action.id, state.connections)
            .filter((x) => x.sourceHandle === HandleType.LoopStart)
            .map((x) => x.target),
          loop: {
            max: action.max,
          },
        },
      };
    },
    validationSchema: yup.object().shape({
      max: yup.number().moreThan(0).required(),
    }),
  },
  [ActionType.Request]: {
    name: "Web request",
    description: (
      <>
        <Typography>
          Executes a call on a web service and returns the result in it's output
        </Typography>
        <Typography>
          If the web service request fails, the actions at the 'error' output
          are called next
        </Typography>
      </>
    ),
    component: RequestNodeComponent,
    defaultValues: {
      name: "Send GET request",
      method: RequestMethod.get,
      url: "https://httpbin.org",
      body: "",
      queryParameters: {},
      requestHeaders: {},
    },
    validationSchema: yup.object().shape({
      body: yup.string().optional(),
      method: yup.mixed().oneOf(Object.values(RequestMethod)).required(),
      queryParameters: yup
        .object()
        .test(
          yupObjectOf(
            yup
              .string()
              .test("expressionvalid", "Expression is not valid", (val) => {
                if (val?.startsWith("$")) {
                  return expressionParserRegex.test(val);
                }
                return true;
              })
          )
        )
        .required(),
      requestHeaders: yup.object().required(),
      url: yup.string().required(),
    }),
    async execute(action, state) {
      // compile body
      let newBody;
      if (action.body) {
        const compileBody = (obj: any) => {
          Object.keys(obj).forEach((k) => {
            if (typeof obj[k] === "string") {
              let expr = evaluateExpression(state, obj[k]);
              if (!expr) {
                expr = obj[k];
              }
              obj[k] = expr;
            } else if (typeof obj[k] == "object") {
              compileBody(obj[k]);
            }
          });
        };
        newBody = JSON.parse(action.body);
        compileBody(newBody);
      }

      let response;
      response = await axios.request({
        method: action.method,
        url: action.url,
        headers: action.requestHeaders,
        params: action.queryParameters,
        data: newBody,
        validateStatus(status) {
          return true;
        },
      });

      return {
        controlOptions: {
          next: getOutgoingNodeIds(action.id, state.connections),
        },
        output: {
          headers: response.headers,
          code: response.status,
          data: response.data,
        },
      };
    },
  },
  [ActionType.SetVariable]: {
    name: "Set variable",
    description: "Set a variable for later use",
    component: SetVariableComponent,
    defaultValues: {
      expression: "12",
      name: "Set variable 'x' to 12",
      variableName: "x",
    },
    async execute(action, state) {
      const value = evaluateExpression(state, action.expression);
      return {
        controlOptions: {
          next: getOutgoingNodeIds(action.id, state.connections),
        },
        output: {
          [action.variableName]: value,
        },
        set: {
          [action.variableName]: value,
        },
      };
    },
    validationSchema: yup.object().shape({
      expression: yup
        .string()
        .test("expressionvalid", "Expression is not valid", (val) => {
          if (val?.startsWith("$")) {
            return expressionParserRegex.test(val);
          }
          return true;
        })
        .required("Expression is required"),
      variableName: yup.string().required("Variable name is required"),
    }),
  },
  [ActionType.TriggerEvent]: {
    name: "Trigger event",
    description: (
      <>
        <Typography>Triggers the given event, if run</Typography>
        <Typography>
          If the given event is not found, nothing happens
        </Typography>
      </>
    ),
    component: TriggerEventNodeComponent,
    defaultValues: {
      event: "",
      name: "Trigger event",
    },
    async execute(action, state, context, args) {
      context.eventContext.dispatch(action.event, args?.sourceBlock ?? 0);
      return {
        controlOptions: {
          next: getOutgoingNodeIds(action.id, state.connections),
        },
      };
    },
  },
  [ActionType.Start]: {
    name: "Start",
    description: "This defines the start of your sequence",
    component: StartNodeComponent,
    defaultValues: {
      name: "Start",
    },
    async execute(action, state) {
      return {
        controlOptions: {
          next: getOutgoingNodeIds(action.id, state.connections),
        },
      };
    },
  },
} as const;

export default ACTIONS;
