import { Paper, Typography, useTheme } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { ActionType } from "../../../redux/actionsequence/types/actions";
import { Colors } from "../../../theme/Colors";

interface Props {
  title: string;
  description: string | JSX.Element;
  type: ActionType;
}

const ActionOption: React.FC<Readonly<Props>> = (props) => {
  const { description, title, type } = props;

  const theme = useTheme();

  const descriptionDisplayed = useMemo(() => {
    if (typeof description == "string") {
      return <Typography>{description}</Typography>;
    } else {
      return description;
    }
  }, [description]);

  const onDragStart = useCallback((event: any, nodeType: any) => {
    event.dataTransfer.setData("application/addsequencenode", nodeType);
    event.dataTransfer.effectAllowed = "move";
  }, []);

  return (
    <Paper
      elevation={1}
      draggable
      onDragStart={(event) => onDragStart(event, type)}
      sx={{
        p: theme.spacing(1),
        background: Colors.node_background,
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        boxShadow:
          "2px 2px 0 #99c448, -2px -2px 0 #99c448, -2px 2px 0 #99c448, 2px -2px 0 #99c448",
        transition: "all .3s ease-in-out",
        borderTopLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        color: "#ffffff",
        minWidth: 200,
        width: "100%",
        textAlign: "left",
        [":hover"]: {
          boxShadow: "5px 5px 0 #99c448, -5px -5px 0 #99c448",
        },
        cursor: "pointer",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      {descriptionDisplayed}
    </Paper>
  );
};

export default ActionOption;
