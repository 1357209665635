import { AddOutlined } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import React, { useContext, useMemo } from "react";
import TitleBox from "../../../widgets/TitleBox";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  createEvent,
  defaultEvent,
  selectSelectedBlock,
} from "../../../../redux/blocks/blockSlice";
import BlockEventBox from "./BlockEventBox";
import { Colors } from "../../../../theme/Colors";
import { BlockInfoTabsContext } from "../BlockInfoTabs";

export default function BlockEventTab() {
  const dispatch = useAppDispatch();
  const block = useAppSelector(selectSelectedBlock);

  const blockEvents = useMemo(() => block?.blockEvents ?? [], [block]);

  const handleEventCreate = React.useCallback(() => {
    dispatch(createEvent(defaultEvent));
  }, [dispatch]);

  const events = React.useMemo(
    () =>
      [...blockEvents]
        .sort((a, b) => a.id - b.id)
        .map((blockEvent) => (
          <BlockEventBox key={blockEvent.id} blockEvent={blockEvent} />
        )),
    [blockEvents]
  );

  const theme = useTheme();

  const { setTab } = useContext(BlockInfoTabsContext);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <TitleBox
        mainTitle="Events"
        subTitle={"Edit events"}
        maintitleVariant="h6"
        subTitleVariant="subtitle2"
      >
        <IconButton aria-label="add" onClick={handleEventCreate}>
          <AddOutlined />
        </IconButton>
      </TitleBox>
      <Box sx={{ mt: theme.spacing(2), color: Colors.text_silver }}>
        <Typography>
          An event can be triggered by an{" "}
          <Box
            component="span"
            sx={{ color: Colors.primary_main, cursor: "pointer" }}
            onClick={() => setTab("4")}
          >
            action
          </Box>{" "}
          from any block on the canvas.
        </Typography>
        <Typography>
          If an event is triggered, the event name will be set as a class on the
          block.
        </Typography>
        <Typography>
          This is useful for setting styles only after a certain user
          interaction took place.
        </Typography>
      </Box>
      <Divider
        color={Colors.text_silver}
        sx={{
          mt: theme.spacing(2),
          mb: theme.spacing(2),
        }}
      />
      {events}
    </Box>
  );
}
