/** @jsxImportSource @emotion/react */
import { css, Global, jsx, ThemeProvider } from "@emotion/react";

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import {
  AppBar,
  Avatar,
  Button,
  ButtonGroup,
  IconButton,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useTheme,
} from "@mui/material";
import {
  BeachAccess,
  ChevronLeft,
  ChevronRight,
  CloseFullscreen,
  ContentCopy,
  ContentPaste,
  CopyAll,
  DeleteOutline,
  Home,
  Inventory2Outlined,
  Laptop,
  LibraryAddOutlined,
  Monitor,
  OpenInFull,
  Smartphone,
  StayPrimaryPortrait,
  Tablet,
  TabletAndroid,
  Tv,
  ViewQuilt,
} from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  addBlocks,
  clearBlocks,
  copySelectedBlockPath,
  defaultBlock,
  deleteBlock,
  getBlocks,
  getGlobalStyles,
  pasteBlocks,
  selectBlockClipboard,
  selectBlocks,
  selectGlobalBlockStyles,
  selectSelectedBlock,
} from "../redux/blocks/blockSlice";
import useRouteParam from "../hooks/useRouteParam";
import AddBlockGrid from "./builder/BlockToolBar/AddBlockGrid";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import BlockInfoTabs from "./builder/BlockInfo/BlockInfoTabs";
import { getPages, selectPageById } from "../redux/page/pageSlice";
import { getDataSets, getModels } from "../redux/dyndata/dyndataSlice";
import {
  createTemplate,
  defaultTemplateData,
  getTemplates,
} from "../redux/template/templateSlice";
import BlockMap from "./builder/BlockToolBar/BlockMap";
import { BlockData, ReferenceType, ScreenMode } from "../redux/blocks/types";
import BlockCanvas from "./builder/BlockCanvas";
import { getAnimationsAsync, selectGlobalAnimations, selectProjectAnimations } from "../redux/animation/animationSlice";
import { GetStyle, transformAnimationsToGlobalCSS } from "../Utility";
import CustomCursor from "../components/BlockBuilder/CustomCursor";
import BlockWall from "../components/BlockBuilder/BlockWall";
import TemplateDialog from "./builder/Templates/TemplateDialog";
import { useNavigate } from "react-router";
import { getFiles, selectMedia } from "../redux/media/mediaSlice";
import { Colors } from "../theme/Colors";
import { InnerTheme } from "../theme/InnerTheme";
import AddJsonBlocksDialog from "./builder/AddJsonBlocksDialog";
import { getFonts, selectFonts } from "../redux/fonts/fontSlice";
import { TranslationContext } from "../context/TranslationContext";
import {
  getLanguage,
  getTranslationTags,
  selectLanguages,
} from "../redux/language/languageSlice";
import DeleteConfirmationDialog from "./utils/DeleteConfirmationDialog";
import SelectedBlockOverlay from "./builder/SelectedBlockOverlay";

const drawerLeftWidth = 350;
const drawerRightWidth = 350;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function BuilderBar() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const refType = useRouteParam("ref");
  const refId = useRouteParam("refId");
  const projectId = useRouteParam("projectId");

  const blocks = useAppSelector(selectBlocks);
  const selectedBlock = useAppSelector(selectSelectedBlock);
  const animations = useAppSelector(selectProjectAnimations);
  const globalAnimations = useAppSelector(selectGlobalAnimations);
  const globalStyles = useAppSelector(selectGlobalBlockStyles);
  const media = useAppSelector(selectMedia);

  const [isBlockDragging, setIsBlockDragging] = React.useState(false);
  const [templateDialogOpen, setTemplateDialogOpen] = React.useState(false);
  const [addJsonBlockDialogOpen, setAddJsonBlockDialogOpen] =
    React.useState(false);
  const [entryIndex, setEntryIndex] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [currentScreenMode, setCurrentScreenMode] = React.useState(ScreenMode.xl);
  const navigate = useNavigate();

  const selectedPage = useAppSelector(
    selectPageById(refType === ReferenceType.Page ? refId : -1)
  );
  const clipBoard = useAppSelector(selectBlockClipboard);
  const fonts = useAppSelector(selectFonts);
  const languages = useAppSelector(selectLanguages);
  const translationContext = React.useContext(TranslationContext);

  React.useEffect(() => {
    dispatch(clearBlocks());
    dispatch(getBlocks({ refType: refType, refId: refId }));
    dispatch(getTemplates());
    dispatch(getAnimationsAsync());
    if (projectId > 0) {
      dispatch(getFiles(projectId));
      dispatch(getPages(projectId));
      dispatch(getDataSets(projectId));
      dispatch(getModels(projectId));
      dispatch(getGlobalStyles(projectId));
      dispatch(getFonts(projectId));
      dispatch(getTranslationTags(projectId));
      dispatch(getLanguage(projectId));
    }
  }, [refType, refId, projectId]);

  const handleBlockDelete = React.useCallback(() => {
    dispatch(deleteBlock());
  }, [dispatch]);

  const handleDrawerOpenChange = React.useCallback(
    (_: React.MouseEvent<HTMLElement>, open: boolean) => {
      if (open !== null) setDrawerOpen(open);
    },
    [setDrawerOpen]
  );

  const handleBlockCopy = React.useCallback(() => {
    dispatch(copySelectedBlockPath());
  }, [dispatch]);

  const handleBlockPaste = React.useCallback(() => {
    dispatch(pasteBlocks({ refId, refType }));
  }, [dispatch, refId, refType]);

  const handleTemplateCreate = React.useCallback(() => {
    dispatch(createTemplate({ ...defaultTemplateData, projectId: projectId }))
      .unwrap()
      .then((payload) => {
        navigate(
          `/builder/${projectId}/${ReferenceType.Template}/${payload.id}`
        );
      });
  }, [dispatch, defaultTemplateData, projectId]);

  const handleBlocksToJson = React.useCallback(async () => {
    if (!selectedBlock) return;

    const blockJson = JSON.stringify(
      blocks.filter((x) =>
        x.locationPath.startsWith(selectedBlock?.locationPath)
      )
    );

    await navigator.clipboard.writeText(blockJson);
  }, [selectedBlock, blocks]);

  const handleJsonBlockDialogOpen = React.useCallback(() => {
    setAddJsonBlockDialogOpen((prev) => !prev);
  }, [addJsonBlockDialogOpen, setAddJsonBlockDialogOpen]);

  const blockWall = React.useMemo(() => {
    console.log("Rerendering Wall");
    return (
      <BlockWall
        blocks={blocks}
        canvasMode={currentScreenMode}
        dataEntries={
          selectedPage?.dynDataSet?.dynDataEntries?.length
            ? [selectedPage?.dynDataSet?.dynDataEntries[entryIndex]]
            : []
        }
      />
    );
  }, [currentScreenMode, blocks, selectedPage, entryIndex]);

  const changeEntryIndex = React.useCallback(
    (factor: number) => {
      setEntryIndex(entryIndex + factor);
    },
    [setEntryIndex, entryIndex]
  );

  const submitJsonBlocks = React.useCallback(
    (blocks: BlockData[]) => {
      if (blocks.length) {
        dispatch(addBlocks({ refId, refType, blocks }));
      }
    },
    [dispatch, refId, refType]
  );

  const fontFaces = fonts
    .map((fontFace) => {
      const file = media.find((x) => x.id === fontFace.fileId);
      if (file) {
        const format =
          file.extension === ".ttf"
            ? "truetype"
            : file.extension === "otf"
              ? "opentype"
              : file.extension.replace(".", "");

        return `@font-face {
                font-family: '${fontFace.family}';
                src: url(root${file.url}) format('${format}');
                font-weight: ${fontFace.weight};
                font-style: ${fontFace.style};
                }
            `;
      }
    })
    .join("");

  function handleHomeNavigation(): void {
    if (projectId >= 0)
      navigate(`/project-detail/${projectId}`);
    else
      navigate("/global-templates");
  }

  const allAnimations = [...animations, ...globalAnimations];

  const handleWidthChange = React.useCallback((width: number) => {
    let newScreenMode = ScreenMode.xxl;

    if (width < 576)
      newScreenMode = ScreenMode.xs;
    else if (width < 768)
      newScreenMode = ScreenMode.sm;
    else if (width < 992)
      newScreenMode = ScreenMode.md;
    else if (width < 1200)
      newScreenMode = ScreenMode.lg;
    else if (width < 1400)
      newScreenMode = ScreenMode.xl;

    if (newScreenMode != currentScreenMode)
      setCurrentScreenMode(newScreenMode);
  }, [setCurrentScreenMode, currentScreenMode]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SelectedBlockOverlay />
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={InnerTheme}>
          <AppBar
            position="fixed"
            component="div"
            sx={{
              ...(drawerOpen && { width: `calc(100% - ${drawerLeftWidth + drawerRightWidth}px)`, ml: `${drawerLeftWidth}px`, mr: `${drawerRightWidth}px` }),
              ...(!drawerOpen && { paddingLeft: `${drawerLeftWidth}px`, paddingRight: `${drawerRightWidth}px` }),
              boxShadow: "none",
              transition: "225ms",
            }}
          >
            <Toolbar
              variant="dense"
              sx={{
                "& > :not(:first-child)": { marginLeft: theme.spacing(1) },
              }}
            >
              <Avatar>
                {
                  currentScreenMode === ScreenMode.xs ?
                    <StayPrimaryPortrait /> : currentScreenMode === ScreenMode.sm ?
                      <TabletAndroid /> : currentScreenMode === ScreenMode.md ?
                        <Tablet /> : currentScreenMode === ScreenMode.lg ?
                          <Laptop /> : currentScreenMode === ScreenMode.xl ?
                            <Monitor /> : <Tv />
                }
              </Avatar>
              <ToggleButtonGroup
                value={drawerOpen}
                onChange={handleDrawerOpenChange}
                exclusive={true}
                size="small"
                sx={{ flex: 1 }}
              >
                <ToggleButton value={true} key="true">
                  <Tooltip title="Opens the editor controls">
                    <CloseFullscreen />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value={false} key="false">
                  <Tooltip title="Sets the editing Area to fullscreen">
                    <OpenInFull />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
              {selectedPage?.dynamic ? (
                <ButtonGroup variant="contained" size="small" sx={{ flex: 1 }}>
                  <Tooltip title="Switch to previous entry">
                    <span>
                      <Button
                        onClick={() => changeEntryIndex(-1)}
                        disabled={entryIndex === 0}
                      >
                        <ChevronLeft />
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title="Displays the current entry that is injected into the dynamic page">
                    <Box
                      sx={{
                        backgroundColor: Colors.text_silver,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: theme.spacing(6),
                        cursor: "default",
                      }}
                    >
                      {entryIndex}
                    </Box>
                  </Tooltip>
                  <Tooltip title="Switch to next entry">
                    <span>
                      <Button
                        onClick={() => changeEntryIndex(1)}
                        disabled={
                          selectedPage?.dynDataSet?.dynDataEntries &&
                          selectedPage?.dynDataSet?.dynDataEntries?.length <=
                          entryIndex + 1
                        }
                      >
                        <ChevronRight />
                      </Button>
                    </span>
                  </Tooltip>
                </ButtonGroup>
              ) : null}
              {languages.length ? (
                <ButtonGroup variant="contained" size="small" sx={{ flex: 1 }}>
                  <Tooltip title="Switch to previous language">
                    <span>
                      <Button
                        onClick={() =>
                          translationContext.setSelectedLanguageIndex(
                            translationContext.languageIndex - 1
                          )
                        }
                        disabled={translationContext.languageIndex === 0}
                      >
                        <ChevronLeft />
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title="Displays the current language that is injected into the page">
                    <Box
                      sx={{
                        backgroundColor: Colors.text_silver,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: theme.spacing(6),
                        cursor: "default",
                      }}
                    >
                      {languages[translationContext.languageIndex].abbreviation}
                    </Box>
                  </Tooltip>
                  <Tooltip title="Switch to next language">
                    <span>
                      <Button
                        onClick={() =>
                          translationContext.setSelectedLanguageIndex(
                            translationContext.languageIndex + 1
                          )
                        }
                        disabled={
                          languages.length <=
                          translationContext.languageIndex + 1
                        }
                      >
                        <ChevronRight />
                      </Button>
                    </span>
                  </Tooltip>
                </ButtonGroup>
              ) : null}
              <Tooltip title="Delete selected block">
                <span>
                  <DeleteConfirmationDialog onDeleteConfirm={handleBlockDelete} >
                    <IconButton
                      disabled={selectedBlock === undefined}
                      onClick={handleBlockDelete}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </DeleteConfirmationDialog>
                </span>
              </Tooltip>
              <Tooltip title="Insert a template">
                <span>
                  <IconButton onClick={() => setTemplateDialogOpen(true)}>
                    <Inventory2Outlined />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Copy the selected block">
                <span>
                  <IconButton
                    disabled={selectedBlock === undefined}
                    onClick={handleBlockCopy}
                  >
                    <ContentCopy />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Paste the copied block">
                <span>
                  <IconButton
                    disabled={clipBoard === ""}
                    onClick={handleBlockPaste}
                  >
                    <ContentPaste />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Create a new template based on the selected block">
                <span>
                  <IconButton
                    disabled={selectedBlock === undefined}
                    onClick={handleTemplateCreate}
                  >
                    <LibraryAddOutlined />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Insert selected block to clipboard">
                <span>
                  <IconButton
                    disabled={selectedBlock === undefined}
                    onClick={handleBlocksToJson}
                  >
                    <CopyAll />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Transform json to blocks">
                <span>
                  <IconButton onClick={handleJsonBlockDialogOpen}>
                    <ViewQuilt />
                  </IconButton>
                </span>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              ...(drawerOpen && { width: drawerLeftWidth }),
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerLeftWidth,
                boxSizing: "border-box",
              },
              color: Colors.text_silver,
            }}
            variant="persistent"
            anchor="left"
            open={drawerOpen}
          >
            <DrawerHeader
              sx={{
                backgroundImage: "linear-gradient(#75B53B, #00D3FD);",
                paddingLeft: theme.spacing(2.5),
                minHeight: theme.spacing(6) + "!important",
                justifyContent: "space-between",
                color: Colors.common_black,
              }}
            >
              <BeachAccess />
              <IconButton edge="end" size="small" onClick={() => handleHomeNavigation()}>
                <Home />
              </IconButton>
            </DrawerHeader>
            <BlockInfoTabs
              selectedBlock={selectedBlock}
            />
          </Drawer>
        </ThemeProvider>
        <BlockCanvas drawerOpen={drawerOpen} handleScreenModeChange={handleWidthChange}>
          <Global
            styles={css`
              ${fontFaces}
            `}
          />
          <Global styles={css`${transformAnimationsToGlobalCSS(allAnimations)}`} />
          <Global
            styles={css`
              ${GetStyle(currentScreenMode, undefined, [], globalStyles)}
            `}
          />
          {/* <CustomCursor /> */}
          {blockWall}
        </BlockCanvas>
        <ThemeProvider theme={InnerTheme}>
          <Drawer
            sx={{
              ...(drawerOpen && { width: drawerRightWidth }),
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerRightWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="right"
            open={drawerOpen}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box sx={{ flexGrow: 0 }}>
                <AddBlockGrid
                  handleDraggingChange={(toggle: boolean) =>
                    setIsBlockDragging(toggle)
                  }
                />
              </Box>
              <Box sx={{ flex: 1, overflowY: "scroll" }}>
                <BlockMap
                  blocks={blocks}
                  selectedBlock={selectedBlock}
                  isBlockDragging={isBlockDragging}
                />
              </Box>
            </Box>
          </Drawer>
        </ThemeProvider>
      </DndProvider>
      <TemplateDialog
        open={templateDialogOpen}
        onClose={() => setTemplateDialogOpen(false)}
      />
      <AddJsonBlocksDialog
        open={addJsonBlockDialogOpen}
        handleClose={() => setAddJsonBlockDialogOpen(false)}
        handleSubmit={submitJsonBlocks}
      />
    </Box>
  );
}
