import { AddOutlined } from '@mui/icons-material';
import { Box, IconButton, Paper, useTheme } from '@mui/material';
import React from 'react';
import useRouteParam from '../../../hooks/useRouteParam';
import { selectAnimationById } from '../../../redux/animation/animationSlice';
import { BlockAnimation, BlockAnimationKeyFrame } from '../../../redux/animation/types';
import { useAppSelector } from '../../../redux/hooks';
import TitleBox from '../../widgets/TitleBox';
import AnimationFrameStyleBox from './AnimationFrameStyleBox';

interface Props {
    currentAnimation: BlockAnimation | undefined,
    handleBlockAnimationSave: () => void,
    handleFrameChange: (frame: BlockAnimationKeyFrame) => void,
    handleFrameCreate: () => void,
}

export default function AnimationFramesTab(props: Props) {
    const { currentAnimation, handleFrameCreate, handleBlockAnimationSave, handleFrameChange } = props;
    const theme = useTheme();

    const frames = React.useMemo(() => currentAnimation ? 
            [...currentAnimation?.blockAnimationKeyFrames]
                .sort((a, b) => a.percent - b.percent)
                .map((frame) => (
                    <AnimationFrameStyleBox key={frame.id} animationKeyFrame={frame} handleBlockAnimationSave={handleBlockAnimationSave} handleFrameChange={handleFrameChange} />
                ))
            : null
    , [currentAnimation]);

    return(
        <Box sx={{ width: '100%', height: '100%' }}>
            <TitleBox
                mainTitle='Animation frames'
                subTitle='Edit key frames of the current animation'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>                    
                <IconButton aria-label="add" onClick={handleFrameCreate}>
                    <AddOutlined />
                </IconButton>
            </TitleBox>
            <Paper sx={{ maxHeight: 'calc(100vh - 49px - 48px - 46.25px - 32px)', overflowY: 'scroll' }}>
                {frames}
            </Paper>
        </Box>
    )
}