import { createSlice, isAnyOf, isRejected } from "@reduxjs/toolkit";
import { AnyAction } from "./types/actions";
import { ActionConnectionSubmission } from "./types/helpers/ActionConnectionSubmission";
import { ActionNode } from "./types/helpers/ActionNode";
import { ActionConnection } from "./types/helpers/ActionConnection";
import { ActionSequence } from "./types/helpers/ActionSequence";
import { createAppAsyncThunk } from "../hooks";
import axios from "axios";
import { RootState } from "../store";
import { ActionNodeSubmission } from "./types/helpers/ActionNodeSubmission";
import { SequenceChange } from "../../context/SequenceContext";

const PREFIX = "sequence";
const prefix = (s: string) => `${PREFIX}/${s}`;

type IdAndProjectId = {
  id: number;
  projectId: number;
};

const getLink = <T extends IdAndProjectId>(args: T, postFix?: string) => {
  if (!postFix) {
    return `/project/${args.projectId}/actionsequence/${args.id}`;
  }
  return `/project/${args.projectId}/actionsequence/${args.id}${postFix}`;
};

export interface SequenceState {
  sequences: ActionSequence[];
}

const initialState: SequenceState = {
  sequences: [],
};

export const listSequences = createAppAsyncThunk(
  prefix("listSequences"),
  async (args: { projectId: number }) => {
    const response = await axios.get<ActionSequence[]>(
      `/project/${args.projectId}/actionsequence`
    );
    return response.data;
  }
);

export const listGlobalSequences = createAppAsyncThunk(
  prefix("listGlobalSequences"),
  async () => {
    const response = await axios.get<ActionSequence[]>(
      `/actionsequence/global`
    );
    return response.data;
  }
);

export const getSequence = createAppAsyncThunk(
  prefix("getSequence"),
  async (args: IdAndProjectId) => {
    const response = await axios.get<ActionSequence>(getLink(args));
    return response.data;
  }
);

export const getGlobalSequence = createAppAsyncThunk(
  prefix("getGlobalSequence"),
  async (args: { id: number }) => {
    const response = await axios.get<ActionSequence>(
      `/actionsequence/global/${args.id}`
    );
    return response.data;
  }
);

export const createSequence = createAppAsyncThunk(
  prefix("create"),
  async (args: { projectId: number }) => {
    const response = await axios.post<ActionSequence>(
      `/project/${args.projectId}/actionsequence`
    );
    return response.data;
  }
);

export const createGlobalSequence = createAppAsyncThunk(
  prefix("createGlobal"),
  async () => {
    const response = await axios.post<ActionSequence>("/actionsequence/global");
    return response.data;
  }
);

export const updateSequence = createAppAsyncThunk(
  prefix("update"),
  async (
    args: IdAndProjectId & {
      submission: SequenceChange;
    }
  ) => {
    const response = await axios.put<ActionSequence>(
      getLink(args),
      args.submission
    );
    return response.data;
  }
);

export const updateGlobalSequence = createAppAsyncThunk(
  prefix("updateGlobal"),
  async (args: { id: number; submission: SequenceChange }) => {
    const response = await axios.put<ActionSequence>(
      `/actionsequence/global/${args.id}`,
      args.submission
    );
    return response.data;
  }
);

export const deleteSequence = createAppAsyncThunk(
  prefix("delete"),
  async (args: IdAndProjectId) => {
    await axios.delete(getLink(args));
    return args.id;
  }
);

export const deleteGlobalSequence = createAppAsyncThunk(
  prefix("deleteGlobal"),
  async (args: { id: number }) => {
    await axios.delete(`/actionsequence/global/${args.id}`);
    return args.id;
  }
);

export const addActionNode = createAppAsyncThunk(
  prefix("addNode"),
  async function (
    args: IdAndProjectId & { submission: ActionNodeSubmission<AnyAction> }
  ) {
    const response = await axios.post<ActionNode<AnyAction>>(
      getLink(args, "/node"),
      args.submission
    );
    return response.data;
  }
);

export const addActionNodeToGlobalSequence = createAppAsyncThunk(
  prefix("addNodeGlobal"),
  async (args: { id: number; submission: ActionNodeSubmission<AnyAction> }) => {
    const response = await axios.post<ActionNode<AnyAction>>(
      `/actionsequence/global/${args.id}/node`,
      args.submission
    );
    return response.data;
  }
);

export const addActionConnection = createAppAsyncThunk(
  prefix("addConnection"),
  async (args: IdAndProjectId & { submission: ActionConnectionSubmission }) => {
    const response = await axios.post<ActionConnection>(
      getLink(args, "/connection"),
      args.submission
    );
    return response.data;
  }
);

export const addActionConnectionToGlobalSequence = createAppAsyncThunk(
  prefix("addConnectionGlobal"),
  async (args: { id: number; submission: ActionConnectionSubmission }) => {
    const response = await axios.post<ActionConnection>(
      `/actionsequence/global/${args.id}/connection`,
      args.submission
    );
    return response.data;
  }
);

export const updateActionNode = createAppAsyncThunk(
  prefix("updateNode"),
  async (args: IdAndProjectId & { submission: ActionNode }) => {
    const response = await axios.put<ActionNode>(
      getLink(args, `/node/${args.submission.id}`),
      args.submission
    );
    return response.data;
  }
);

export const updateActionNodeOnGlobalSequence = createAppAsyncThunk(
  prefix("updateNodeGlobal"),
  async (args: { id: number; submission: ActionNode }) => {
    const response = await axios.put<ActionNode>(
      `/actionsequence/global/${args.id}/node/${args.submission.id}`,
      args.submission
    );
    return response.data;
  }
);

export const deleteActionNode = createAppAsyncThunk(
  prefix("deleteNode"),
  async (args: IdAndProjectId & { nodeId: string }, thunkAPI) => {
    await axios.delete(getLink(args, `/node/${args.nodeId}`));
    const connections =
      thunkAPI.getState().sequences.sequences.find((x) => x.id === args.id)
        ?.actionConnections ?? [];
    connections.forEach((conn) => {
      if (conn.source === args.nodeId || conn.target === args.nodeId) {
        thunkAPI.dispatch(
          deleteActionConnection({ connectionId: conn.id, ...args })
        );
      }
    });
    return args.nodeId;
  }
);

export const deleteActionNodeOnGlobalSequence = createAppAsyncThunk(
  prefix("deleteNodeGlobal"),
  async (args: { id: number; nodeId: string }) => {
    await axios.delete(`/actionsequence/global/${args.id}/node/${args.nodeId}`);
    return args.nodeId;
  }
);

export const deleteActionConnection = createAppAsyncThunk(
  prefix("deleteConnection"),
  async (args: IdAndProjectId & { connectionId: string }) => {
    await axios.delete(getLink(args, `/connection/${args.connectionId}`));
    return args.connectionId;
  }
);

export const deleteActionConnectionOnGlobalSequence = createAppAsyncThunk(
  prefix("deleteConnectionGlobal"),
  async (args: { id: number; connectionId: string }) => {
    await axios.delete(
      `/actionsequence/global/${args.id}/connection/${args.connectionId}`
    );
    return args.connectionId;
  }
);

const sequenceSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(listSequences.fulfilled, listGlobalSequences.fulfilled),
      (state, action) => {
        state.sequences = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getSequence.fulfilled, getGlobalSequence.fulfilled),
      (state, action) => {
        const idx = state.sequences.findIndex(
          (x) => x.id === action.payload.id
        );
        if (idx >= 0) {
          state.sequences[idx] = { ...state.sequences[idx], ...action.payload };
        } else {
          state.sequences.push(action.payload);
        }
      }
    );

    builder.addMatcher(
      isAnyOf(createSequence.fulfilled, createGlobalSequence.fulfilled),
      (state, action) => {
        state.sequences.push(action.payload);
      }
    );

    builder.addMatcher(
      isAnyOf(updateSequence.fulfilled, updateGlobalSequence.fulfilled),
      (state, action) => {
        const idx = state.sequences.findIndex(
          (x) => x.id === action.payload.id
        );
        if (idx >= 0) {
          state.sequences[idx].name = action.payload.name;
        } else {
          console.error("Could not find sequence to update");
        }
      }
    );

    builder.addMatcher(
      isAnyOf(deleteSequence.fulfilled, deleteGlobalSequence.fulfilled),
      (state, action) => {
        const idx = state.sequences.findIndex((x) => x.id === action.payload);
        if (idx >= 0) {
          state.sequences.splice(idx, 1);
        }
      }
    );

    builder.addMatcher(
      isAnyOf(addActionNode.fulfilled, addActionNodeToGlobalSequence.fulfilled),
      (state, action) => {
        const idx = state.sequences.findIndex(
          (x) => x.id === action.meta.arg.id
        );
        if (idx >= 0) {
          state.sequences[idx].actions.push(action.payload);
        } else {
          console.error("Could not find sequence to add action node to");
        }
      }
    );

    builder.addMatcher(
      isAnyOf(
        addActionConnection.fulfilled,
        addActionConnectionToGlobalSequence.fulfilled
      ),
      (state, action) => {
        const idx = state.sequences.findIndex(
          (x) => x.id === action.meta.arg.id
        );
        if (idx >= 0) {
          state.sequences[idx].actionConnections.push(action.payload);
        } else {
          console.error("Could not find sequence to add action connection to");
        }
      }
    );

    builder.addMatcher(
      isAnyOf(
        updateActionNode.fulfilled,
        updateActionNodeOnGlobalSequence.fulfilled
      ),
      (state, action) => {
        const idx = state.sequences.findIndex(
          (x) => x.id === action.meta.arg.id
        );
        if (idx >= 0) {
          const nodeIdx = state.sequences[idx].actions.findIndex(
            (x) => x.id === action.payload.id
          );
          if (nodeIdx >= 0) {
            state.sequences[idx].actions[nodeIdx] = action.payload;
          } else {
            console.error("Could not find node to update");
          }
        } else {
          console.error("Could not find sequence to update action node on");
        }
      }
    );

    builder.addMatcher(
      isAnyOf(
        deleteActionNode.fulfilled,
        deleteActionNodeOnGlobalSequence.fulfilled
      ),
      (state, action) => {
        const idx = state.sequences.findIndex(
          (x) => x.id === action.meta.arg.id
        );
        if (idx >= 0) {
          const nodeIdx = state.sequences[idx].actions.findIndex(
            (x) => x.id === action.payload
          );
          if (nodeIdx >= 0) {
            state.sequences[idx].actions.splice(nodeIdx, 1);
          } else {
            console.error("Could not find node to delete");
          }
        } else {
          console.error("Could not find sequence to delete action node on");
        }
      }
    );

    builder.addMatcher(
      isAnyOf(
        deleteActionConnection.fulfilled,
        deleteActionConnectionOnGlobalSequence.fulfilled
      ),
      (state, action) => {
        const idx = state.sequences.findIndex(
          (x) => x.id === action.meta.arg.id
        );
        if (idx >= 0) {
          const connIdx = state.sequences[idx].actionConnections.findIndex(
            (x) => x.id === action.payload
          );
          if (connIdx >= 0) {
            state.sequences[idx].actionConnections.splice(connIdx, 1);
          } else {
            console.error("Could not find connection to delete");
          }
        } else {
          console.error(
            "Could not find sequence to delete action connection on"
          );
        }
      }
    );

    builder.addMatcher(isRejected, (_, action) => {
      console.error(PREFIX, action.error);
    });
  },
});

export const selectSequence = (id?: number) => (state: RootState) =>
  state.sequences.sequences.find((x) => x.id === id);

export const selectSequences = (ids: number[]) => (state: RootState) =>
  state.sequences.sequences.filter((x) => ids.includes(x.id));

export const selectGlobalSequences = (s: RootState) =>
  s.sequences.sequences.filter((x) => x.isGlobal);
export const selectProjectSequences = (projId: number) => (s: RootState) =>
  s.sequences.sequences.filter((x) => x.projectDataId === projId);
export const selectProjectSequence =
  (id: number, projId: number) => (s: RootState) =>
    selectProjectSequences(projId)(s).find((x) => x.id === id);

export default sequenceSlice.reducer;
