import React from 'react';
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import { ViewDay, ViewColumn, GridView, ViewArray, FormatColorText, VisibilityOff, VerticalAlignBottom, VerticalAlignCenter, Expand, Clear, Start, KeyboardTab, ZoomOutMap, AlignHorizontalCenter } from '@mui/icons-material';
import { Grid, Typography, Box, ToggleButtonGroup, ToggleButton, TextField, Tooltip } from '@mui/material';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import { getStyleById, isStyleInheritedAndChanged } from '../../../../../../Utility';
import ValueWithUnitInput from '../../../../../utils/Inputs/ValueWithUnitInput';
import StyleTextField from '../../../../../utils/Inputs/StyleTextField';
import StyleToggleButtonGroup from '../../../../../utils/Inputs/StyleToggleButtonGroup';
import { BlockStyleAttribute } from '../BlockStyleInputs';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void, 
    selectedBlock?: BlockData,
}

export default function FlexChildInputs(props: Props) {
    return (
        <>
            <BlockStyleDataLabel name="Grow" />
            <Grid item xs={4}>
                <StyleTextField {...props} attributeKey='flex-grow' type='number' />
            </Grid>
            <BlockStyleDataLabel name="Shrink" />
            <Grid item xs={4}>
                <StyleTextField {...props} attributeKey='flex-shrink' type='number' />
            </Grid>
            <BlockStyleDataLabel name="Basis" />
            <Grid item xs={10}>
                <ValueWithUnitInput attributeKey={'flex-basis'} additionalEntries={['auto']} {...props} />
            </Grid>
            <BlockStyleDataLabel name="Align" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='align-self'>
                    <ToggleButton value="start" key="start">
                        <Tooltip title="Aligns the item at the start of the cross axis">
                            <Start />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="center" key="center">
                        <Tooltip title="Centers the item along the cross axis">
                            <VerticalAlignCenter />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="end" key="end">
                        <Tooltip title="Aligns the item at the end of the cross axis">
                            <KeyboardTab />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="stretch" key="stretch">
                        <Tooltip title="Stretches the item to fill the container along the cross axis">
                            <ZoomOutMap />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="baseline" key="baseline">
                        <Tooltip title="Aligns the item based on the baseline of the content">
                            <Clear />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Justify" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='justify-self'>
                    <ToggleButton value="start" key="start">
                        <Tooltip title="Aligns items to the start of the main axis">
                            <Start />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="center" key="center">
                        <Tooltip title="Centers items along the main axis">
                            <AlignHorizontalCenter />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="end" key="end">
                        <Tooltip title="Aligns items to the end of the main axis">
                            <GridView />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="space-between" key="space-between">
                        <Tooltip title="Distributes items evenly, with the first item at the start and the last item at the end">
                            <ViewArray />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="space-around" key="space-around">
                        <Tooltip title="Distributes items evenly with equal space around them">
                            <ViewArray />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="space-evenly" key="space-evenly">
                        <Tooltip title="Distributes items so that the spacing between any two items (and the space to the edges) is equal">
                            <ViewArray />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Order" />
            <Grid item xs={10}>
                <StyleTextField {...props} attributeKey='order' type='number' />
            </Grid>
        </>
    );
}