import { ThemeProvider } from "@emotion/react";
import {
  Box,
  Button,
  CssBaseline,
  Drawer,
  Grid,
  MenuItem,
  Select,
  Tab,
  TextField,
  Toolbar,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Add,
  ArrowRightSharp,
  BeachAccess,
  Info,
  Science,
} from "@mui/icons-material";
import { Colors } from "../../../theme/Colors";
import TitleBox from "../../widgets/TitleBox";
import { ActionNode } from "../../../redux/actionsequence/types/helpers/ActionNode";
import { ActionConnection } from "../../../redux/actionsequence/types/helpers/ActionConnection";
import SequenceContext from "../../../context/SequenceContext";
import NodeSystemTheme from "../../../theme/NodeSystemTheme";
import AddActionSideBar from "./AddActionSideBar";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import useActionDebugContext from "../../../hooks/useActionDebugContext";
import { useAppSelector } from "../../../redux/hooks";
import { selectSequence } from "../../../redux/actionsequence/sequenceSlice";
import SnackBarOperations from "../../../components/SnackBar/SnackBarOperations";
import DebugControlFloater from "./DebugControlFloater";
import SequenceFlow from "./Flow/SequenceFlow";
import ActionSequenceType from "../../../redux/actionsequence/types/helpers/ActionSequenceType";
import { ReactFlowProvider } from "reactflow";
import JSONInput from "react-json-editor-ajrm";
//@ts-ignore
import locale from "react-json-editor-ajrm/locale/en";

const drawerLeftWidth = 350;
const drawerRightWidth = 350;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${drawerLeftWidth}px)`,
  marginLeft: `${drawerLeftWidth}px`,
  ...(open && {
    width: `calc(100% - ${drawerLeftWidth + drawerRightWidth}px)`,
    marginRight: `${drawerRightWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: `-${drawerRightWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export type OnActionNodeUpdate = (
  actionNode: ActionNode
) => Promise<ActionNode>;
export type OnActionNodeDelete = (id: string) => Promise<string>;
export type OnActionNodeAdd = (
  actionNode: Omit<ActionNode, "id">
) => Promise<ActionNode>;
export type OnActionConnectionDelete = (id: string) => Promise<string>;
export type OnActionConnectionAdd = (
  connection: Omit<ActionConnection, "id">
) => Promise<ActionConnection>;
export type OnSequenceChange = (change: { name: string }) => Promise<void>;

type BuilderMode = "edit" | "debug";

const SequenceBuilder: React.FC = () => {
  const theme = useTheme();

  const { sequenceId, onSequenceChange } = useContext(SequenceContext);

  const actionSequence = useAppSelector(selectSequence(sequenceId));

  const [name, setName] = useState("");
  const [triggerType, setTriggerType] = useState(ActionSequenceType.OnClick);

  useEffect(() => {
    if (actionSequence) {
      setName(actionSequence.name);
      console.log(actionSequence.type);
      setTriggerType(actionSequence.type);
    }
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [leftTab, setLeftTab] = useState("0");

  const [mode, setMode] = useState<BuilderMode>("edit");
  const [isFinished, setIsFinished] = useState(false);

  const [args, setArgs] = useState({ argument1: 12 });

  const { run, initialize, step, cancel, processState } =
    useActionDebugContext();

  const actions = useMemo(
    () => actionSequence?.actions ?? [],
    [actionSequence]
  );
  const connections = useMemo(
    () => actionSequence?.actionConnections ?? [],
    [actionSequence]
  );

  const initializeSession = useCallback(async () => {
    setIsFinished(false);
    try {
      await initialize({
        actions: actions.map((x) => x.data),
        connections: connections,
        args: args,
      });
    } catch (err) {
      if (err instanceof Error) {
        SnackBarOperations.error(err.message);
      }
    }
  }, [actions, connections, initialize, args]);

  const handleDebugClick = useCallback(async () => {
    setIsFinished(false);
    setMode("debug");
    await initializeSession();
  }, [initializeSession, setMode, setIsFinished]);

  const handleStepClick = useCallback(async () => {
    const finalState = await step();
    if (finalState) {
      setIsFinished(true);
    }
  }, [step]);

  const handleContinueClick = useCallback(async () => {
    await run();
    setIsFinished(true);
  }, [run, setIsFinished]);

  const handleCancelClick = useCallback(async () => {
    setMode("edit");
    await cancel();
  }, [cancel]);

  useEffect(() => {
    if (isFinished) {
      SnackBarOperations.info("Finished");
    }
  }, [isFinished]);

  const testRunOptions = useMemo(() => {
    if (mode === "edit") {
      return (
        <>
          <Grid
            sx={{
              display:
                triggerType === ActionSequenceType.OnSubmit ? "unset" : "none",
            }}
            item
            xs={12}
          >
            <Typography>Arguments</Typography>
            <JSONInput
              id={`${actionSequence?.id}-args`}
              placeholder={{ argument1: 12 }}
              locale={locale}
              height="200px"
              onChange={(val: any) => {
                setArgs(val.jsObject);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleDebugClick} fullWidth variant="contained">
              Start debug session
            </Button>
          </Grid>
        </>
      );
    }
    return null;
  }, [mode, handleDebugClick, processState, triggerType, setArgs]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ThemeProvider theme={NodeSystemTheme}>
        <AppBar
          open={drawerOpen}
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerLeftWidth}px)`,
            ml: `${drawerLeftWidth}px`,
            backgroundColor:
              mode === "debug" ? "orangered" : Colors.common_black,
          }}
        >
          <Toolbar variant="dense">
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              variant={drawerOpen ? "text" : "contained"}
              sx={{
                display: mode === "edit" ? undefined : "none",
              }}
              aria-label="open drawer"
              onClick={() => setDrawerOpen(!drawerOpen)}
              endIcon={drawerOpen ? <ArrowRightSharp /> : <Add />}
            >
              {drawerOpen ? "CLOSE" : "ADD ACTION"}
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerLeftWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerLeftWidth,
              boxSizing: "border-box",
              color: Colors.text_silver,
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <DrawerHeader
            sx={{
              backgroundImage: "linear-gradient(#75B53B, #00D3FD);",
              paddingLeft: theme.spacing(2.5),
              minHeight: theme.spacing(6) + "!important",
              justifyContent: "space-between",
              color: Colors.common_black,
            }}
          >
            <BeachAccess />
          </DrawerHeader>
          <Box
            sx={{ width: "100%", height: "100%", padding: theme.spacing(1) }}
          >
            <TabContext value={leftTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={(ev, n) => setLeftTab(n)}>
                  <Tab
                    label="Details"
                    icon={<Info />}
                    iconPosition="start"
                    value="0"
                  />
                  <Tab
                    label="Test"
                    icon={<Science />}
                    iconPosition="start"
                    value="1"
                  />
                </TabList>
              </Box>
              <TabPanel value="0">
                <Box>
                  <TitleBox
                    mainTitle="Sequence Details"
                    subTitle="Edit sequence Details"
                  />
                  <Grid
                    sx={{ flexGrow: 1, marginTop: theme.spacing(2) }}
                    container
                    spacing={theme.spacing(2)}
                  >
                    <Grid item xs={12}>
                      <TextField
                        label="Sequence name"
                        id="sequence-name"
                        variant="filled"
                        fullWidth
                        value={name}
                        onChange={(ev) => setName(ev.target.value)}
                        onBlur={() =>
                          onSequenceChange({ name, type: triggerType })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        label="Sequence trigger type"
                        value={triggerType}
                        onChange={(ev) =>
                          setTriggerType(ev.target.value as ActionSequenceType)
                        }
                        onBlur={() =>
                          onSequenceChange({ name, type: triggerType })
                        }
                      >
                        <MenuItem value={ActionSequenceType.OnClick}>
                          On click
                        </MenuItem>
                        <MenuItem value={ActionSequenceType.OnScroll}>
                          On scroll
                        </MenuItem>
                        <MenuItem value={ActionSequenceType.OnSubmit}>
                          On submit
                        </MenuItem>
                        <MenuItem value={ActionSequenceType.OnLoad}>
                          On load
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel value="1">
                <Box>
                  <TitleBox
                    mainTitle="Test/Run"
                    subTitle="Run, test and debug your sequence"
                  />

                  <Grid
                    sx={{ flexGrow: 1, marginTop: theme.spacing(2) }}
                    container
                    spacing={theme.spacing(2)}
                  >
                    {testRunOptions}
                  </Grid>
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </Drawer>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            height: `calc(100vh - ${theme.spacing(6)})`,
            mt: theme.spacing(6),
          }}
        >
          <Main
            sx={{ display: "flex", height: "100%", position: "relative" }}
            open={drawerOpen}
          >
            <ReactFlowProvider>
              <SequenceFlow />
            </ReactFlowProvider>
            {mode === "debug" && (
              <DebugControlFloater
                isFinished={isFinished}
                handleCancelClick={handleCancelClick}
                handleContinueClick={handleContinueClick}
                handleStepClick={handleStepClick}
                info={processState}
              />
            )}
          </Main>
        </Box>
        <Drawer
          onClose={() => setDrawerOpen(false)}
          sx={{
            width: drawerRightWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerRightWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          open={drawerOpen}
          anchor="right"
        >
          <DrawerHeader sx={{ justifyContent: "center" }}>
            <Typography variant="h5" sx={{}}>
              Actions
            </Typography>
          </DrawerHeader>
          <Box sx={{ margin: theme.spacing(2) }}>
            <Typography variant="body1">
              Drag and drop any action into the canvas
            </Typography>
            <AddActionSideBar />
          </Box>
        </Drawer>
      </ThemeProvider>
    </Box>
  );
};

export default SequenceBuilder;
