import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar, CardActionArea, Tooltip, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  createBlock,
  selectSelectedBlock,
} from "../../../redux/blocks/blockSlice";
import useRouteParam from "../../../hooks/useRouteParam";
import {
  BlockData,
  BlockType,
  BlockTypes,
  CreateBlockDirection,
  VoidBlockTypes,
} from "../../../redux/blocks/types";
import { useDrag } from "react-dnd";

interface Props {
  name: string;
  icon: React.ReactNode;
  blockType: BlockType;
  handleDraggingChange: (toggle: boolean) => void;
}

interface DropResult {
  block: BlockData;
  direction: CreateBlockDirection;
}

export default function AddBlockCard(props: Props) {
  const { name, icon, blockType, handleDraggingChange } = props;
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const refType = useRouteParam("ref");
  const refId = useRouteParam("refId");
  const selectedBlock = useAppSelector(selectSelectedBlock);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: BlockTypes.create,
    item: { name },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult) {
        dispatch(
          createBlock({
            refType,
            refId,
            blockType,
            blockId: dropResult.block.id,
            direction: dropResult.direction,
          })
        );
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  React.useEffect(() => {
    handleDraggingChange(isDragging);
  }, [isDragging]);

  const handleBlockCreate = React.useCallback(() => {
    dispatch(
      createBlock({
        refType,
        refId,
        blockType,
        blockId: selectedBlock?.id ?? 0,
        direction: CreateBlockDirection.in,
      })
    );
  }, [dispatch, refType, refId, blockType, selectedBlock]);

  return (
    <Card ref={drag}>
      <Tooltip title="Create a new block">
        <CardActionArea
          onClick={handleBlockCreate}
          disabled={
            selectedBlock && VoidBlockTypes.includes(selectedBlock.blockType)
          }
        >
          <CardContent sx={{ padding: theme.spacing(1), textAlign: "center" }}>
            {icon}
            <Typography variant="button" component="div">
              {name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Tooltip>
    </Card>
  );
}
