import {
  Grid,
  TextField,
  IconButton,
  Button,
  Box,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { produce } from "immer";
import React from "react";

type KeyValue = Record<string, string>;

interface KeyValueInputProps {
  keyValue: KeyValue;
  onChange: (newKeyValue: KeyValue) => void;
  onRemove: () => void;
}

const KeyValueInput: React.FC<KeyValueInputProps> = ({
  keyValue,
  onChange,
  onRemove,
}) => {
  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...keyValue, [event.target.name]: event.target.value });
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Key"
          name="key"
          value={keyValue.key || ""}
          onChange={handleKeyChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Value"
          name="value"
          value={keyValue.value || ""}
          onChange={handleKeyChange}
        />
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

interface KeyValueListProps {
  values?: KeyValue;
  onChange: (newValues: KeyValue) => void;
}

const KeyValueList: React.FC<KeyValueListProps> = ({
  values = {},
  onChange,
}) => {
  const handleAdd = () => {
    onChange({ ...values, [`key${Object.keys(values).length + 1}`]: "" });
  };

  const handleRemove = (key: string) => {
    const newHeaders = { ...values };
    delete newHeaders[key];
    onChange(newHeaders);
  };

  const handleKeyValueChange = (oldKey: string, key: string, value: string) => {
    const newVals = produce(values, (vals) => {
      if (oldKey === key) {
        vals[key] = value;
      } else {
        delete vals[oldKey];
        vals[key] = value;
        return vals;
      }
    });
    onChange(newVals);
  };

  return (
    <Box sx={{ ml: "10px", mb: "10px" }}>
      {Object.keys(values).length <= 0 && (
        <Box sx={{ mb: "10px" }}>
          <Typography variant="body1">No items...</Typography>
        </Box>
      )}
      {Object.entries(values).map(([key, value], idx) => (
        <Box key={idx} sx={{ mb: "10px" }}>
          <KeyValueInput
            keyValue={{ key, value }}
            onChange={(newKeyValue) =>
              handleKeyValueChange(key, newKeyValue.key, newKeyValue.value)
            }
            onRemove={() => handleRemove(key)}
          />
        </Box>
      ))}
      <Button variant="outlined" onClick={handleAdd}>
        Add new
      </Button>
    </Box>
  );
};

export default KeyValueList;
