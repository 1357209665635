export const TYPES = [
  "MEDIA",
  "TEMPLATE",
  "USER",
  "ANIMATIONS",
  "PROJECT",
] as const;

export type GlobalObjectTypes = (typeof TYPES)[number];

export const ACTION = ["VIEW", "EDIT", "DELETE"] as const;

export type GlobalObjectActions = (typeof ACTION)[number];

export const GLOBAL_PERMISSIONS = [
  "MEDIA_VIEWER",
  "TEMPLATE_VIEWER",
  "ANIMATIONS_VIEWER",
  "USER_VIEWER",
  "ACTION_SEQUENCE_VIEWER",
  "MEDIA_EDITOR",
  "TEMPLATE_EDITOR",
  "ANIMATIONS_EDITOR",
  "USER_EDITOR",
  "ACTION_SEQUENCE_EDITOR",
  "MEDIA_DELETER",
  "TEMPLATE_DELETER",
  "ANIMATIONS_DELETER",
  "USER_DELETER",
  "ACTION_SEQUENCE_DELETER",
  "MEDIA_ADMIN",
  "TEMPLATE_ADMIN",
  "ANIMATIONS_ADMIN",
  "USER_ADMIN",
  "ACTION_SEQUENCE_ADMIN",
  "PROJECT_CREATOR",
  "ACTION_SEQUENCE_CREATOR",
] as const;

export type Permission = (typeof GLOBAL_PERMISSIONS)[number];

export const PROJECT_PERMISSIONS = [
  "ANIMATIONS_VIEWER",
  "ANIMATIONS_CREATOR",
  "ANIMATIONS_EDITOR",
  "ANIMATIONS_DELETER",
  "ANIMATIONS_ADMIN",
  "DATASET_VIEWER",
  "DATASET_CREATOR",
  "DATASET_EDITOR",
  "DATASET_DELETER",
  "DATASET_ADMIN",
  "LANGUAGE_VIEWER",
  "LANGUAGE_CREATOR",
  "LANGUAGE_EDITOR",
  "LANGUAGE_DELETER",
  "LANGUAGE_ADMIN",
  "MAIL_CONNECTION_VIEWER",
  "MAIL_CONNECTION_CREATOR",
  "MAIL_CONNECTION_EDITOR",
  "MAIL_CONNECTION_DELETER",
  "MAIL_CONNECTION_ADMIN",
  "MAIL_TEMPLATE_VIEWER",
  "MAIL_TEMPLATE_CREATOR",
  "MAIL_TEMPLATE_EDITOR",
  "MAIL_TEMPLATE_DELETER",
  "MAIL_TEMPLATE_ADMIN",
  "MEDIA_VIEWER",
  "MEDIA_CREATOR",
  "MEDIA_EDITOR",
  "MEDIA_DELETER",
  "MEDIA_ADMIN",
  "MODEL_VIEWER",
  "MODEL_CREATOR",
  "MODEL_EDITOR",
  "MODEL_DELETER",
  "MODEL_ADMIN",
  "PAGE_VIEWER",
  "PAGE_CREATOR",
  "PAGE_EDITOR",
  "PAGE_DELETER",
  "PAGE_ADMIN",
  "PROJECT_VIEWER",
  "PROJECT_EDITOR",
  "PROJECT_DELETER",
  "PROJECT_ADMIN",
  "TEMPLATE_VIEWER",
  "TEMPLATE_CREATOR",
  "TEMPLATE_EDITOR",
  "TEMPLATE_DELETER",
  "TEMPLATE_ADMIN",
  "TRANSLATION_TAG_VIEWER",
  "TRANSLATION_TAG_CREATOR",
  "TRANSLATION_TAG_EDITOR",
  "TRANSLATION_TAG_DELETER",
  "TRANSLATION_TAG_ADMIN",
] as const;

export type ProjectPermission = (typeof PROJECT_PERMISSIONS)[number];

export const PROJECT_EDIT_ROLES: ProjectPermission[] = [
  "PROJECT_ADMIN",
  "PROJECT_EDITOR",
];
export const PROJECT_DELETE_ROLES: ProjectPermission[] = [
  "PROJECT_ADMIN",
  "PROJECT_DELETER",
];
export type Role = {
  id: number;
  name: string;
  permissions: string[];
};

export type ProjectRole = Role & {
  projectDataId: number;
  projectName: string;
  permissions: ProjectPermission[];
  isDefault: boolean;
};

export type GlobalRole = Role & {
  permissions: Permission[];
};
export type User = {
  username: string;
  firstName: string | undefined;
  lastName: string | undefined;
  id: number;
  // passwordChangeRequired: boolean;
  email: string;
  // roles: Role[];
  lastLogin: number | undefined;
  // activeSince?: string;
  userRoles: GlobalRole[];
  projectRoles: ProjectRole[];
  isActive: boolean;
};

export type AuthenticateResponse = {
  id: number;
  username: string;
  firstName: string | undefined;
  lastName: string | undefined;
  jwtToken: string;
  userRoles: GlobalRole[];
  projectRoles: ProjectRole[];
  email: string;
  lastLogin: number | undefined;
  isActive: boolean;
  // active: boolean;
  // passwordChangeRequired: boolean;
};
