import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { BlockAnimationKeyFrame } from '../../../redux/animation/types';

const marks = [
    {
        value: 0,
    },
    {
        value: 25,
    },
    {
        value: 50,
    },
    {
        value: 75,
    },
    {
        value: 100,
    },
];

function valuetext(value: number) {
    return `${value}%`;
}

interface Props {
    animationFrames: BlockAnimationKeyFrame[],
    handleKeyFrameChange: (frames: BlockAnimationKeyFrame[]) => void,
    handleBlockAnimationSave: () => void
}

export default function AnimationFrameSlider(props: Props) {

    const { animationFrames, handleKeyFrameChange, handleBlockAnimationSave } = props;

    const handleSliderChange = React.useCallback((_: Event, newValues: number | number[]) => {

        const values = newValues as unknown as number[];

        const frameToChange = animationFrames.find(x => !values.includes(x.percent));

        if(!frameToChange || frameToChange.percent === 100 || frameToChange.percent === 0)
            return;

        const newValue = values.find(x => animationFrames.findIndex(y => y.percent === x) === -1);

        if(newValue === 100 || newValue === 0)
            return;

        if(newValue !== undefined)
            handleKeyFrameChange([...animationFrames.filter(x => x.id !== frameToChange.id), {...frameToChange, percent: newValue}]);
    }, [animationFrames, handleKeyFrameChange]);

    return (
            <Box sx={{ width: '100%'}}>
                <Slider
                    track={false}
                    getAriaValueText={valuetext}
                    value={animationFrames.map((frame) => frame.percent) ?? 0}
                    onChange={handleSliderChange}
                    onBlur={handleBlockAnimationSave}
                    marks={marks}
                         
                />
            </Box>
    );
}
