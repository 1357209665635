import * as React from "react";
import useDeepEffect from "../hooks/useDeepEffect";
import { selectLanguages } from "../redux/language/languageSlice";
import { useAppSelector } from "../redux/hooks";
import { selectTranslationTags } from "../redux/language/languageSlice";

interface TranslationContext {
  translate: (tag: string) => string;
  setSelectedLanguageIndex: (index: number) => void;
  languageIndex: number;
}

export const TranslationContext = React.createContext<TranslationContext>({
  translate: () => "",
  setSelectedLanguageIndex: () => {},
  languageIndex: 0,
});

interface Props {
  children: React.ReactNode;
}

const BlockTranslationContext = (props: Props) => {
  const { children } = props;

  const translationTags = useAppSelector(selectTranslationTags);
  const languages = useAppSelector(selectLanguages);
  const [languageIndex, setLanguageIndex] = React.useState(0);

  const setSelectedLanguageIndex = (index: number) => {
    setLanguageIndex((prev) => prev + index);
  };

  const translate = (tag: string): string => {
    const language = languages[languageIndex];
    if (!translationTags.length) return tag;

    try {
      const translationTag = translationTags?.find((x) => x.name === tag);

      if (translationTag) {
        const translation = translationTag?.translations?.find(
          (x) => x.languageId === language.id
        );
        return translation?.text ?? tag;
      }
    } catch (e) {
      console.log(translationTags);
      console.log(e);
    }
    return tag;
  };

  return (
    <TranslationContext.Provider
      value={{
        translate: translate,
        setSelectedLanguageIndex: setSelectedLanguageIndex,
        languageIndex: languageIndex,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export default BlockTranslationContext;
