import React from "react";
import BlockEventContext from "./blockEvents/BlockEventContext";
import { Outlet } from "react-router";
import BlockDataContextProvider from "./blockData/BlockDataContextProvider";
import TranslationContext from "./TranslationContext";
import LocalStorageContextProvider from "./localStorage/LocalStorageContextProvider";
import { ActionRunContextProvider } from "./blockActions/ActionRunContext";

export default function ContextOutlet() {
  return (
    <LocalStorageContextProvider>
      <BlockDataContextProvider>
        <BlockEventContext>
          <ActionRunContextProvider>
            <TranslationContext>
              <Outlet />
            </TranslationContext>
          </ActionRunContextProvider>
        </BlockEventContext>
      </BlockDataContextProvider>
    </LocalStorageContextProvider>
  );
}
