import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InputBase from "@mui/material/InputBase";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { Button, ButtonGroup, Collapse, Popover } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SegmentIcon from "@mui/icons-material/Segment";
import SearchIcon from "@mui/icons-material/Search";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import AddIcon from "@mui/icons-material/Add";
import { ExpandLess, ExpandMore, PortraitOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  createProject,
  defaultProjectData,
  getProjects,
  selectProject,
  selectProjects,
  selectSelectedProject,
} from "../redux/project/projectSlice";
import { ProjectData } from "../redux/project/types";
import { Outlet } from "react-router";
import { createPage, defaultPageData } from "../redux/page/pageSlice";
import {
  createTemplate,
  defaultTemplateData,
  getTemplates,
} from "../redux/template/templateSlice";
import { selectIsAuthenticated, selectUser } from "../redux/auth/authSlice";
import { getOnlineUsers } from "../redux/users/usersSlice";
import UserHubProvider, { UserHubContext } from "../context/UserHubContext";
import { getAnimationsAsync } from "../redux/animation/animationSlice";
import { Colors } from "../theme/Colors";
import { ThemeProvider } from "@emotion/react";
import { InnerTheme } from "../theme/InnerTheme";
import { listGlobalSequences } from "../redux/actionsequence/sequenceSlice";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);
  const [projectsOpen, setProjectsOpen] = React.useState(false);
  const [libraryOpen, setLibraryOpen] = React.useState(false);
  const [addAnchorEl, setAddAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const location = useLocation();
  const selectedProject = useAppSelector(selectSelectedProject);
  const projects = useAppSelector(selectProjects);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const me = useAppSelector(selectUser);

  const canView = React.useCallback(
    (
      module: "users" | "roles" | "templates" | "animations" | "actionsequences"
    ) => {
      if (!me) return false;
      switch (module) {
        case "users":
          return me.userRoles
            .flatMap((x) => x.permissions)
            .some((x) => x.startsWith("USER"));
        case "roles":
          return me.userRoles
            .flatMap((x) => x.permissions)
            .includes("USER_ADMIN");
        case "templates":
          return me.userRoles
            .flatMap((x) => x.permissions)
            .some((x) => x.startsWith("TEMPLATE_"));
        case "animations":
          return me.userRoles
            .flatMap((x) => x.permissions)
            .some((x) => x.startsWith("ANIMATIONS_"));
        case "actionsequences":
          return me.userRoles
            .flatMap((x) => x.permissions)
            .some((x) => x.startsWith("ACTION_SEQUENCE_"));
        default:
          return false;
      }
    },
    [me]
  );

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  React.useEffect(() => {
    dispatch(getProjects());
    dispatch(getTemplates());
    dispatch(getOnlineUsers());
    dispatch(getAnimationsAsync());
    dispatch(listGlobalSequences());

    if (location.pathname.startsWith("/project-detail")) setProjectsOpen(true);
  }, [isAuthenticated]);

  const handleAddAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAddAnchorEl(null);
  };

  const mainListItemButtonSX = {
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
    textTransform: "uppercase",
  };

  const mainListItemIconSX = {
    minWidth: 0,
    mr: open ? 3 : "auto",
    justifyContent: "center",
  };

  const secondaryListITemIconButtonSX = {
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
    paddingLeft: theme.spacing(8.25),
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const createProjectHandler = () => {
    dispatch(createProject(defaultProjectData))
      .unwrap()
      .then((data) => {
        navigate(`/project-detail/${data.id}`);
        handleClose();
        setProjectsOpen(true);
      });
  };

  const createPageHandler = () => {
    dispatch(createPage(defaultPageData)).then(() => {
      handleClose();
    });
  };

  const createTemplateHandler = () => {
    dispatch(createTemplate(defaultTemplateData)).then(() => {
      handleClose();
    });
  };

  const setSelectedProject = (project: ProjectData) => {
    navigate(`/project-detail/${project.id}`);
  };

  const handleTemplateListNavigation = () => {
    dispatch(selectProject());
    navigate("/global-templates");
  };

  const handleAnimationListNavigation = () => {
    dispatch(selectProject());
    navigate("/global-animations");
  };

  const handleActionSequencesListNavigation = () => {
    dispatch(selectProject());
    navigate("/global-actionsequences");
  };

  const canViewTemplates = React.useMemo(() => canView("templates"), [canView]);
  const canViewAnimations = React.useMemo(
    () => canView("animations"),
    [canView]
  );
  const canViewSequences = React.useMemo(
    () => canView("actionsequences"),
    [canView]
  );

  const shouldDisplayLibrary = React.useMemo(
    () => canViewTemplates || canViewAnimations || canViewSequences,
    [canViewTemplates, canViewAnimations, canViewSequences]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ThemeProvider theme={InnerTheme}>
        <AppBar position="fixed" open={open}>
          <Toolbar variant="dense" sx={{ position: "relative" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon sx={{ color: Colors.text_silver }} />
            </IconButton>
            <ButtonGroup
              variant="contained"
              sx={{
                borderRadius: 100,
                overflow: "hidden",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                width: "fit-content",
              }}
            >
              <Button sx={{ color: Colors.bloxxi_red }}>
                <NotificationsOutlinedIcon />
              </Button>
              <Button sx={{ color: Colors.background_default }}>
                <ImportContactsOutlinedIcon />
              </Button>
              <Button sx={{ color: Colors.primary_main }}>
                <FavoriteBorderOutlinedIcon />
              </Button>
              <Button onClick={handleAddAnchorClick}>
                <AddIcon />
              </Button>
            </ButtonGroup>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader
            sx={{
              backgroundImage: "linear-gradient(#75B53B, #00D3FD);",
              paddingLeft: theme.spacing(2.5),
              minHeight: theme.spacing(6) + "!important",
              justifyContent: "space-between",
              color: Colors.common_black,
            }}
          >
            <BeachAccessIcon />
            {/* <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton> */}
          </DrawerHeader>
          <List>
            <ListItem
              dense
              key="Dashboard"
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                onClick={() => navigate("/")}
                sx={{
                  ...mainListItemButtonSX,
                  ...(location.pathname === "/dashboard" && {
                    color: Colors.primary_main,
                  }),
                }}
              >
                <ListItemIcon sx={mainListItemIconSX}>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary="Dashboard"
                  sx={{ opacity: open ? 1 : 0, fontWeight: "bold" }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              dense
              key="Project"
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  ...mainListItemButtonSX,
                  ...(location.pathname.includes("/project-detail") && {
                    color: Colors.primary_main,
                  }),
                }}
                disabled={projects.length < 1}
                onClick={() => setProjectsOpen((prev) => !prev)}
              >
                <ListItemIcon sx={mainListItemIconSX}>
                  <SegmentIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary="Project"
                  sx={{ opacity: open ? 1 : 0, fontWeight: "bold" }}
                />
                {projects.length ? (
                  projectsOpen ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItemButton>
            </ListItem>
            <Collapse in={projectsOpen}>
              <List disablePadding>
                {projects?.map((project) => (
                  <ListItem
                    key={project.id}
                    disableGutters
                    disablePadding
                    dense
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      onClick={() => setSelectedProject(project)}
                      sx={{
                        ...secondaryListITemIconButtonSX,
                        ...(location.pathname.includes(
                          `/project-detail/${project.id}`
                        ) && { color: Colors.primary_main }),
                        minHeight: "auto",
                      }}
                    >
                      <ListItemText
                        primary={project.name}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <ListItem
              dense
              key="Library"
              disablePadding
              sx={{ display: shouldDisplayLibrary ? "block" : "none" }}
            >
              <ListItemButton
                sx={{
                  ...mainListItemButtonSX,
                  ...([
                    "/global-templates",
                    "/global-animations",
                    "/global-actionsequences",
                  ].indexOf(location.pathname) > -1 && {
                    color: Colors.primary_main,
                  }),
                }}
                onClick={() => setLibraryOpen((prev) => !prev)}
              >
                <ListItemIcon sx={mainListItemIconSX}>
                  <ViewQuiltOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary="Library"
                  sx={{ opacity: open ? 1 : 0, fontWeight: "bold" }}
                />
                {libraryOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={libraryOpen}>
              <List disablePadding>
                <ListItem
                  key="templates"
                  disableGutters
                  disablePadding
                  dense
                  sx={{
                    display: canViewTemplates ? "block" : "none",
                  }}
                >
                  <ListItemButton
                    onClick={handleTemplateListNavigation}
                    sx={{
                      ...secondaryListITemIconButtonSX,
                      ...(location.pathname === "/global-templates" && {
                        color: Colors.primary_main,
                      }),
                      minHeight: "auto",
                    }}
                  >
                    <ListItemText
                      primary="Templates"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  key="animations"
                  disableGutters
                  disablePadding
                  dense
                  sx={{
                    display: canViewAnimations ? "block" : "none",
                  }}
                >
                  <ListItemButton
                    onClick={handleAnimationListNavigation}
                    sx={{
                      ...secondaryListITemIconButtonSX,
                      ...(location.pathname === "/global-animations" && {
                        color: Colors.primary_main,
                      }),
                      minHeight: "auto",
                    }}
                  >
                    <ListItemText
                      primary="Animations"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  key="actionsequences"
                  disableGutters
                  disablePadding
                  dense
                  sx={{
                    display: canViewSequences ? "block" : "none",
                  }}
                >
                  <ListItemButton
                    onClick={handleActionSequencesListNavigation}
                    sx={{
                      ...secondaryListITemIconButtonSX,
                      ...(location.pathname === "/global-actionsequences" && {
                        color: Colors.primary_main,
                      }),
                      minHeight: "auto",
                    }}
                  >
                    <ListItemText
                      primary="Action sequences"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              dense
              key="Users"
              disablePadding
              sx={{
                display:
                  canView("users") || canView("roles") ? "block" : "none",
              }}
            >
              <ListItemButton
                onClick={() => navigate("/users")}
                sx={{
                  ...mainListItemButtonSX,
                  ...(location.pathname.includes("users") && {
                    color: Colors.primary_main,
                  }),
                }}
              >
                <ListItemIcon sx={mainListItemIconSX}>
                  <PortraitOutlined />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={"Users"}
                  sx={{ opacity: open ? 1 : 0, fontWeight: "bold" }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </ThemeProvider>
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: theme.spacing(6) }}>
        <UserHubProvider>
          <Outlet />
        </UserHubProvider>
      </Box>
      <Popover
        id="add-popover"
        open={Boolean(addAnchorEl)}
        anchorEl={addAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton onClick={createProjectHandler}>
              <ListItemText primary="Project" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={createTemplateHandler}>
              <ListItemText primary="Template" />
            </ListItemButton>
          </ListItem>
          {selectedProject ? (
            <ListItem disablePadding>
              <ListItemButton onClick={createPageHandler}>
                <ListItemText primary="Page" />
              </ListItemButton>
            </ListItem>
          ) : null}
        </List>
      </Popover>
    </Box>
  );
}
