import { Add, ArrowCircleRightOutlined, Delete, LinkOff, PortraitOutlined, Remove, RemoveOutlined } from '@mui/icons-material';
import { Avatar, Button, Card, Chip, Divider, Grid, IconButton, Paper, Popover, Skeleton, styled, Tab, Tabs, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addRoleToUser, getUser, removeRoleFromuser, selectUserById } from '../../redux/users/usersSlice';
import TitleBox from '../widgets/TitleBox';
import TabPanel from '@mui/lab/TabPanel';
import { TabContext, TabList } from '@mui/lab';
import { getFormattedDate, getPermissionDescription } from '../../Utility';
import { selectProjects } from '../../redux/project/projectSlice';
import { DataGrid } from '@mui/x-data-grid';
import { GlobalRole, Permission } from '../../redux/auth/types';
import { selectUser } from '../../redux/auth/authSlice';
import useRouteParam from '../../hooks/useRouteParam';
import AddRoleForm from './AddRoleForm';
import StyledDataGrid from '../utils/StyledDataGrid';
import UserDetails from './UserDetails';

export default function User() {
    
    const theme = useTheme();
    const dispatch = useAppDispatch();
    
    const userId = useRouteParam('userId');
    
    const me = useAppSelector(selectUser);

    React.useEffect(() => {
        dispatch(getUser(userId))
    }, [userId, dispatch]);
    
    const user = useAppSelector(selectUserById(userId));
    
    const userRoles = React.useMemo(() => {
        return user?.userRoles ?? [];
    }, [user])
    
    const [tabValue, setTabValue] = React.useState("1");
    const [addRoleAnchor, setAddRoleAnchor] = React.useState<HTMLButtonElement | null>(null);
    const [addRoleLoading, setAddRoleLoading] = React.useState(false);

    const onTabValueChange = React.useCallback((event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    }, [setTabValue]);

    const submitRole = React.useCallback((role: GlobalRole) => {
        setAddRoleLoading(true);
        dispatch(addRoleToUser({
            userId,
            role
        }))
        .unwrap()
        .then(() => setAddRoleAnchor(null))
        .catch(console.error)
        .finally(() => setAddRoleLoading(false))
    }, [setAddRoleLoading, dispatch]);

    const userCard = React.useMemo(() => {
        const paperStyles = { 
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        };
        const avatarStyles = {
            width: 100,
            height: 100,
            mb: theme.spacing(2),
        };

        if (user) {
            const nameLine = user.firstName || user.lastName ? `${user.firstName ?? ''} ${user.lastName ?? ''}` : user.username
            return (
                <Paper sx={paperStyles}>
                    <Avatar 
                        src="/placeholder.JPG"
                        alt="placeholder"
                        sx={avatarStyles}
                    />
                    <Typography sx={{ fontWeight: 'bold' }} variant='h6'>{nameLine}</Typography>
                    <Typography sx={{ mb: theme.spacing(4) }} variant='subtitle1'>{user.email}</Typography>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Last seen</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{getFormattedDate(user.lastLogin, true)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Username</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{user.username}</Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ width: '100%', mt: theme.spacing(4), mb: theme.spacing(4) }}/>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Project</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Role</Typography>
                        </Grid>
                        {
                            (user.projectRoles.length <= 0) && (
                                <Grid item xs={12}>
                                    <Typography>No projects associated with user</Typography>
                                </Grid>
                            ) 
                        }
                        {
                            user.projectRoles.map(pr => (
                                <React.Fragment key={pr.id}>
                                    <Grid item xs={6}>
                                        <Typography>{`${pr.projectName} (${pr.projectDataId})`}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>{pr.name}</Typography>
                                    </Grid>
                                </React.Fragment>
                            ))
                        }
                    </Grid>
                </Paper>);
        } else {
            return (
                <Paper sx={paperStyles}>
                    <Skeleton variant='circular' sx={avatarStyles} />
                    <Skeleton variant='text' />
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Last seen</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton variant='text' />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Username</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton variant='text' />
                        </Grid>
                    </Grid>
                    <Divider sx={{ width: '100%', mt: theme.spacing(4), mb: theme.spacing(4) }}/>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Project</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Roles</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>No projects associated with user</Typography>
                        </Grid>
                    </Grid>

                </Paper>
            )
        }
    }, [user, theme]);

    return (
        <>
            <TitleBox
                avatarIcon={<PortraitOutlined />}
                mainTitle='Manage user'
                subTitle='Edit contact details and permissions'
                key={'single-user-main-page'}
            />
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    {userCard}
                </Grid>
                <Grid item xs={8}>
                    <Paper sx={{ width: '100%' }}>
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList value={tabValue} onChange={onTabValueChange}>
                                    <Tab label="DETAILS" value="1" />
                                    <Tab label="ROLES" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value='1'>
                                {
                                    user && <UserDetails user={user} />
                                }
                            </TabPanel>
                            <TabPanel value='2'>
                                <TitleBox
                                    mainTitle='Roles'
                                    maintitleVariant='h6'
                                    subTitle='Global roles and permissions'
                                    subTitleVariant='subtitle2'
                                    key={'user-roles-table'}
                                >
                                    <Button 
                                        onClick={(ev) => setAddRoleAnchor(ev.currentTarget)}
                                        variant="contained" 
                                        sx={{ marginLeft: theme.spacing(1) }} 
                                        endIcon={<Add />}>
                                        ADD ROLE
                                    </Button>
                                </TitleBox>
                                <Popover
                                    open={Boolean(addRoleAnchor)}
                                    anchorEl={addRoleAnchor}
                                    onClose={() => setAddRoleAnchor(null)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                >
                                    <AddRoleForm
                                        userRoles={userRoles} 
                                        submitLoading={addRoleLoading} 
                                        onSubmit={submitRole}/>
                                </Popover>
                                <StyledDataGrid
                                    pageSizeOptions={[10]}
                                    rowCount={10}
                                    disableRowSelectionOnClick
                                    sx={{
                                        height: '500px'
                                    }}
                                    rows={userRoles}
                                    columns={[
                                        {
                                            field: 'name',
                                            headerName: 'Role',
                                            flex: .7
                                        },
                                        {
                                            field: 'permissions',
                                            headerName: 'Object permissions',
                                            flex: 2,
                                            sortable: false,
                                            renderCell(params) {
                                                const values : Permission[] = params.value;
                                                const permissions = getPermissionDescription(values);
                                                if (values) {
                                                    return <Box sx={{
                                                        margin: '3px',
                                                        display: 'flex',
                                                        flexWrap: 'wrap'
                                                    }}>
                                                        {permissions.map((p) => (
                                                            <Chip
                                                             size='small'
                                                             sx={{
                                                                mr: '3px',
                                                                mb: '2px'
                                                             }}
                                                             label={p}
                                                            />
                                                        ))}
                                                    </Box>
                                                }
                                                return '';
                                            },
                                        },
                                        {
                                            field: 'actions',
                                            headerName: 'Actions',
                                            flex: .5,
                                            renderCell(params){
                                                // TODO: implement remove role
                                                return (
                                                    <Tooltip
                                                        title={
                                                            me?.id === user?.id ? "Cannot remove own role" : "Remove role from user"
                                                        }
                                                        arrow
                                                        placement='left'
                                                    >
                                                        <span>

                                                            <IconButton onClick={() => {
                                                                dispatch(removeRoleFromuser({
                                                                    userId: user?.id ?? 0,
                                                                    roleId: params.row.id,
                                                                }))
                                                            }} disabled={me?.id === user?.id}>
                                                                <LinkOff />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                );
                                            }
                                        }
                                    ]}
                                />
                            </TabPanel>
                        </TabContext>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}