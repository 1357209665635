import React from 'react';
import { selectGlobalAnimations } from '../../redux/animation/animationSlice';
import { useAppSelector } from '../../redux/hooks';
import Animations from './Animations';

export default function GlobalAnimations() {

    const animations = useAppSelector(selectGlobalAnimations);

    return (
        <Animations animations={animations} subTitle='Edit global animations' />
    );
}