import React, { useMemo, useState } from "react";
import {
  Alert,
  Box,
  Grid,
  InputBase,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import actions, {
  ActionType,
} from "../../../redux/actionsequence/types/actions";
import ActionOption from "./ActionOption";
import SearchIcon from "@mui/icons-material/Search";

const actionOptions = Object.entries(actions).map(([k, v]) => ({
  title: v.name,
  description: v.description,
  type: k,
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const AddActionSideBar: React.FC = () => {
  const [search, setSearch] = useState("");

  const theme = useTheme();

  const filtered = useMemo(
    () =>
      actionOptions.filter(
        (x) =>
          x.title.toLowerCase().includes(search) && x.type !== ActionType.Start
      ),
    [actionOptions, search]
  );

  return (
    <Box>
      <Search
        sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
      >
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          onChange={(ev) => setSearch(ev.target.value.toLowerCase())}
        />
      </Search>
      <Grid container>
        {filtered.length <= 0 && (
          <Grid xs={12} item>
            <Alert severity="info">
              Did not find any actions matching your search
            </Alert>
          </Grid>
        )}
        {filtered.map((opt) => (
          <Grid xs={12} key={opt.type} item>
            <ActionOption
              title={opt.title}
              description={opt.description}
              type={opt.type as ActionType}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AddActionSideBar;
