import {
  createSlice,
  isAnyOf,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { DynPropAssignment } from "../dyndata/types";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import {
  BlockData,
  BlockEvent,
  BlockStyle,
  BlockStyleData,
  CreateBlockDirection,
  BlockResponse,
  MoveProperties,
  ReferenceProperties,
  ScreenMode,
  BlockType,
  BlockDataActionSequence,
  EventBehaviour,
  NonSourceTriggerBehaviour,
  BlockUpdate,
} from "./types";
import { DeploymentProperties } from "../project/types";

const PREFIX = "blocks";

const prefix = (str: string) => `${PREFIX}/${str}`;

export type BlockState = {
  blocks: BlockData[];
  globalStyles: BlockStyle[];
  currentBlockId: number | undefined;
  blockClipboard: string;
};

export const initialState: BlockState = {
  blocks: [],
  globalStyles: [],
  currentBlockId: undefined,
  blockClipboard: "",
};

export const defaultBlockStyle: BlockStyle = {
  id: 0,
  blockStyleData: [],
  mode: ScreenMode.xl,
  selector: "newStyle",
  projectId: 0,
};

export const defaultBlockStyleData: BlockStyleData = {
  id: 0,
  attributeKey: "",
  attributeValue: "",
};

export const defaultBlock: BlockData = {
  id: 0,
  blockEvents: [],
  displayName: "",
  actionSequences: [],
  blockStyles: [],
  dynPropAssignments: [],
  locationPath: "",
  content: "",
  overwriteWindowScroll: false,
  referenceProps: false,
  sortPath: 0,
  templateReference: null,
  blockType: BlockType.DIV,
  blockAttributes: [],
  pageDataId: 0,
};

export const defaultEvent: BlockEvent = {
  id: 0,
  nonSourceTriggerBehaviour: NonSourceTriggerBehaviour.DoNothing,
  eventName: "newEvent",
  onlyTriggerFrom: [],
  behaviour: EventBehaviour.Default,
  propagateSource: false,
  unTriggerOnClickAway: false,
};

export const defaultPropAssignment: DynPropAssignment = {
  id: 0,
  blockProp: "",
};

export const getGlobalStyles = createAppAsyncThunk(
  prefix("getglobalstyles"),
  async function (projectId: number, thunkAPI) {
    try {
      const response = await axios.get<BlockStyle[]>(
        `/block/blockstyles/${projectId}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err as { error: string });
    }
  }
);

export const updateBlockPropAssignments = createAppAsyncThunk(
  prefix("updateBlockPropAssignments"),
  async function (assignments: DynPropAssignment[], thunkAPI) {
    const blockId: number | undefined =
      thunkAPI.getState().blocks.currentBlockId;
    if (!blockId) {
      return Promise.reject({
        error: "No block selected! Cannot update blockpropassignments",
      });
    }
    const response = await axios.post<DynPropAssignment[]>(
      "/block/propassignments",
      {
        assignments,
        blockId,
      }
    );
    return {
      data: response.data,
      blockId,
    };
  }
);

export const getBlocks = createAppAsyncThunk(
  prefix("get"),
  async function (args: ReferenceProperties) {
    const response = await axios.post<BlockData[]>("/block/blocks", {
      refType: args.refType,
      refId: args.refId,
    });
    return response.data;
  }
);

export const createBlock = createAppAsyncThunk(
  prefix("create"),
  async function (
    args: ReferenceProperties & {
      blockType: BlockType;
      blockId: number;
      direction: CreateBlockDirection;
    },
    thunkAPI
  ) {
    const response = await axios.post<BlockResponse>("/block", {
      ...args,
    });
    return response.data;
  }
);

export const addBlocks = createAppAsyncThunk(
  prefix("add"),
  async function (args: ReferenceProperties & { blocks: BlockData[] }) {
    const response = await axios.post<BlockData[]>("/block/add", {
      ...args,
    });
    return response.data;
  }
);

export const moveBlock = createAppAsyncThunk(
  prefix("move"),
  async function (args: MoveProperties, thunkAPI) {
    const blocks = thunkAPI.getState().blocks.blocks;
    const dragBlock = blocks.find((x) => x.id === args.dragBlock);
    const dropBlock = blocks.find((x) => x.id === args.dropBlock);
    const response = await axios.post<BlockResponse>("/block/move", {
      direction: args.direction,
      dragBlock,
      dropBlock,
    });
    return response.data;
  }
);

export const updateBlock = createAppAsyncThunk(
  prefix("udpate"),
  async function (update: BlockUpdate) {
    const response = await axios.post<BlockUpdate>("/block/update", update);
    return response.data;
  }
);

export const sortBlocks = createAppAsyncThunk(
  prefix("sort"),
  async function (direction: number, thunkAPI) {
    let indexOfSecond = 0;
    let secondBlock;

    const blockState = thunkAPI.getState().blocks;

    const selectedBlock = blockState.blocks.find(
      (x) => x.id === blockState.currentBlockId
    );
    if (selectedBlock) {
      const searchLocationPath =
        selectedBlock.locationPath.indexOf("-") > -1
          ? selectedBlock.locationPath.substring(
              0,
              selectedBlock.locationPath.lastIndexOf("-") + 1
            )
          : "";

      const blocks = thunkAPI.getState().blocks.blocks;
      const filtered = blocks.filter(
        (x) => x.locationPath === searchLocationPath + x.id
      );
      filtered.sort((a, b) => a.sortPath - b.sortPath);

      const sortedLocationPaths = filtered.map((b) => b.locationPath);

      indexOfSecond =
        sortedLocationPaths.indexOf(selectedBlock.locationPath) + direction;
      if (indexOfSecond < filtered.length && indexOfSecond >= 0) {
        secondBlock = filtered[indexOfSecond];
      }

      const response = await axios.post<BlockData[]>("/block/sort", {
        firstBlock: selectedBlock,
        secondBlock,
      });

      return response.data;
    } else {
      return Promise.reject(
        new Error("No block was selected, cannot sort blocks.")
      );
    }
  }
);

export const pasteBlocks = createAppAsyncThunk(
  prefix("paste"),
  async function (args: ReferenceProperties, thunkAPI) {
    const blockState = thunkAPI.getState().blocks;
    const copyLocationPath = blockState.blockClipboard;
    const selectedBlockId = blockState.currentBlockId;
    const response = await axios.post<BlockData[]>("/block/paste", {
      copyLocationPath,
      blockId: selectedBlockId,
      ...args,
    });
    return response.data;
  }
);

export const appendBlockTemplate = createAppAsyncThunk(
  prefix("appendTemplate"),
  async function (
    args: ReferenceProperties & { templateId: number; referenced: boolean },
    thunkAPI
  ) {
    const blockState = thunkAPI.getState().blocks;
    const response = await axios.post<BlockData[]>("/template/append", {
      blockId: blockState.currentBlockId,
      ...args,
    });
    return response.data;
  }
);

export const deleteBlock = createAppAsyncThunk(
  prefix("delete"),
  async function (_, thunkAPI) {
    const selectedBlock = thunkAPI.getState().blocks.currentBlockId;
    if (!selectedBlock) {
      return;
    }
    await axios.delete(`/block/${selectedBlock}`);
    return selectedBlock;
  }
);

export const deployData = createAppAsyncThunk(
  prefix("deploy"),
  async function (args: DeploymentProperties) {
    await axios.post("/deployment", args);
  }
);

export const createEvent = createAppAsyncThunk(
  prefix("createEvent"),
  async function (blockEvent: BlockEvent, thunkAPI) {
    const blockId = thunkAPI.getState().blocks.currentBlockId;
    if (!blockId) {
      return Promise.reject(
        new Error("No block selected to create an event on!")
      );
    }

    const response = await axios.post<BlockEvent>("/block/addevent", {
      blockEvent,
      blockId,
    });

    return {
      data: response.data,
      blockId,
    };
  }
);

export const deleteEvent = createAppAsyncThunk(
  prefix("deleteEvent"),
  async function (eventId: number, thunkAPI) {
    const blockId = thunkAPI.getState().blocks.currentBlockId;
    if (!blockId) {
      return Promise.reject("No block selected!");
    }
    await axios.delete(`/block/event/${eventId}`);
    return {
      data: eventId,
      blockId,
    };
  }
);

export const updateEvent = createAppAsyncThunk(
  prefix("updateEvent"),
  async function (blockEvent: BlockEvent, thunkAPI) {
    const blockId = thunkAPI.getState().blocks.currentBlockId;
    if (!blockId) {
      return Promise.reject("No block selected!");
    }
    const response = await axios.post<BlockEvent>(
      "/block/updateevent",
      blockEvent
    );
    return {
      data: response.data,
      blockId,
    };
  }
);

export const createBlockStyle = createAppAsyncThunk(
  prefix("createBlockStyle"),
  async (newBlockStyle: BlockStyle, thunkAPI) => {
    const blockId = thunkAPI.getState().blocks.currentBlockId;
    const response = await axios.post<BlockStyle>("/block/addblockstyle", {
      newBlockStyle,
      blockId,
    });

    return {
      data: response.data,
      blockId,
    };
  }
);

export const deleteBlockStyle = createAppAsyncThunk(
  prefix("deleteBlockStyle"),
  async (blockStyleId: number, thunkAPI) => {
    const blockId = thunkAPI.getState().blocks.currentBlockId;

    await axios.delete<void>(`/block/blockstyle/${blockStyleId}`);

    return {
      data: blockStyleId,
      blockId,
    };
  }
);

export const updateBlockStyle = createAppAsyncThunk(
  prefix("updateBlockStyle"),
  async (blockStyle: BlockStyle, thunkAPI) => {
    const response = await axios.post<BlockStyle>("/block/updateblockstyle", {
      ...blockStyle,
    });

    return {
      data: response.data,
    };
  }
);

export const assignSequenceToBlock = createAppAsyncThunk(
  prefix("assignSequenceToBlock"),
  async (args: { blockDataId: number; actionSequenceId: number }) => {
    const response = await axios.post<BlockDataActionSequence>(
      "/block/assignSequence",
      args
    );
    console.log(response.data);
    return response.data;
  }
);

export const removeSequenceFromBock = createAppAsyncThunk(
  prefix("removeSequenceFromBock"),
  async (args: { blockDataId: number; actionSequenceId: number }) => {
    await axios.delete<void>(
      `/block/${args.blockDataId}/removeSequence/${args.actionSequenceId}`
    );
  }
);

export const updateSequenceAssignment = createAppAsyncThunk(
  prefix("updateSequenceAssignment"),
  async (args: BlockDataActionSequence) => {
    const response = await axios.put<BlockDataActionSequence>(
      "/block/updateSequenceAssignment",
      args
    );
    return response.data;
  }
);

const blockSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    copySelectedBlockPath(state) {
      const selectedBlockPath =
        state.blocks.find((x) => x.id === state.currentBlockId)?.locationPath ??
        "";
      state.blockClipboard = selectedBlockPath;
    },
    clearBlocks(state) {
      state.blocks = [];
      state.currentBlockId = undefined;
    },
    selectBlock(state, action: PayloadAction<number | undefined>) {
      const block = action.payload;
      const currentSelectedBlock = state.currentBlockId;
      if (block && currentSelectedBlock && block === currentSelectedBlock) {
        return;
      } else {
        state.currentBlockId = block;
      }
    },
  },
  extraReducers: (builder) => {
    // on get globalstyles success
    builder.addCase(getGlobalStyles.fulfilled, (state, action) => {
      state.globalStyles = action.payload;
    });

    // on update block ass success
    builder.addCase(updateBlockPropAssignments.fulfilled, (state, action) => {
      const index = state.blocks.findIndex(
        (x) => x.id === action.payload.blockId
      );
      if (index >= 0) {
        state.blocks[index].dynPropAssignments = action.payload.data;
        state.currentBlockId = state.blocks[index].id;
      }
    });

    // on get block success
    builder.addCase(getBlocks.fulfilled, (state, action) => {
      state.blocks = action.payload;
    });

    // on update block success
    builder.addCase(updateBlock.fulfilled, (state, action) => {
      const index = state.blocks.findIndex((x) => x.id === action.payload.id);
      if (index >= 0) {
        state.blocks[index] = {
          ...state.blocks[index],
          ...action.payload,
        };
        state.currentBlockId = action.payload.id;
      }
    });

    // on sort block success
    builder.addCase(sortBlocks.fulfilled, (state, action) => {
      action.payload.forEach((blockData) => {
        const index = state.blocks.findIndex((x) => x.id === blockData.id);
        if (index >= 0) {
          state.blocks[index] = blockData;
        } else {
          state.blocks.push(blockData);
        }
      });
    });

    // on delete block success
    builder.addCase(deleteBlock.fulfilled, (state, action) => {
      state.blocks = state.blocks.filter((x) => x.id !== action.payload);
    });

    // on create event success
    builder.addCase(createEvent.fulfilled, (state, action) => {
      const index = state.blocks.findIndex(
        (x) => x.id === action.payload.blockId
      );
      if (index < 0) {
        return;
      }
      state.blocks[index].blockEvents = [
        ...state.blocks[index].blockEvents,
        action.payload.data,
      ];
      state.currentBlockId = state.blocks[index].id;
    });

    // on delete event success
    builder.addCase(deleteEvent.fulfilled, (state, action) => {
      const index = state.blocks.findIndex(
        (x) => x.id === action.payload.blockId
      );
      if (index < 0) {
        return;
      }
      state.blocks[index].blockEvents = state.blocks[index].blockEvents.filter(
        (x) => x.id !== action.payload.data
      );
      state.currentBlockId = state.blocks[index].id;
    });

    // on update event success
    builder.addCase(updateEvent.fulfilled, (state, action) => {
      const blockIndex = state.blocks.findIndex(
        (x) => x.id === action.payload.blockId
      );
      if (blockIndex < 0) {
        console.warn("Could not update event -> block not found in state.");
        return;
      }
      const blockEventIndex = state.blocks[blockIndex].blockEvents.findIndex(
        (x) => x.id === action.payload.data.id
      );
      if (blockEventIndex < 0) {
        console.warn(
          "Could not update event -> event not found on block in state."
        );
        return;
      }
      state.blocks[blockIndex].blockEvents[blockEventIndex] =
        action.payload.data;
      state.currentBlockId = state.blocks[blockIndex].id;
    });

    builder.addCase(createBlockStyle.fulfilled, (state, action) => {
      // lazy evaluation
      const readBlockIndex = () =>
        state.blocks.findIndex((x) => x.id === action.payload.blockId);
      if (action.payload.blockId == undefined || readBlockIndex() < 0) {
        state.globalStyles.push(action.payload.data);
      } else {
        const blockIndex = readBlockIndex();
        if (blockIndex < 0) {
          console.warn(
            "Could not create Blockstyle -> block not found in state."
          );
          return;
        }

        state.blocks[blockIndex].blockStyles.push(action.payload.data);
        state.currentBlockId = state.blocks[blockIndex].id;
      }
    });

    builder.addCase(deleteBlockStyle.fulfilled, (state, action) => {
      const globalBlockStyleIndex = state.globalStyles.findIndex(
        (x) => x.id == action.payload.data
      );
      if (globalBlockStyleIndex >= 0) {
        state.globalStyles = state.globalStyles.filter(
          (x) => x.id !== action.payload.data
        );
      } else {
        const blockIndex = state.blocks.findIndex(
          (x) => x.id === action.payload.blockId
        );
        if (blockIndex < 0) {
          console.warn(
            "Could not delete Blockstyle -> block not found in state."
          );
        }
        state.blocks[blockIndex].blockStyles = state.blocks[
          blockIndex
        ].blockStyles.filter((x) => x.id !== action.payload.data);
        state.currentBlockId = state.blocks[blockIndex].id;
      }
    });

    builder.addCase(updateBlockStyle.fulfilled, (state, action) => {
      const blockId = action.payload.data.blockDataId;
      if (blockId) {
        const index = state.blocks.findIndex((x) => x.id === blockId);
        const blockStyleIndex = state.blocks[index].blockStyles.findIndex(
          (x) => x.id === action.payload.data.id
        );

        if (blockStyleIndex < 0) {
          state.blocks[index].blockStyles.push(action.payload.data);
        } else {
          state.blocks[index].blockStyles[blockStyleIndex] =
            action.payload.data;
        }

        state.currentBlockId = state.blocks[index].id;
      } else {
        const index = state.globalStyles.findIndex(
          (x) => x.id === action.payload.data.id
        );
        if (index < 0) {
          console.warn("Could not update global style -> not found.");
          return;
        }
        state.globalStyles[index] = action.payload.data;
      }
    });

    builder.addCase(addBlocks.fulfilled, (state, action) => {
      state.blocks = [
        ...state.blocks.filter(
          (x) => action.payload.findIndex((y) => y.id == x.id) < 0
        ),
        ...action.payload,
      ];
    });

    builder.addCase(createBlock.fulfilled, (state, action) => {
      state.blocks = [
        ...state.blocks.filter(
          (x) => action.payload.changedBlocks.findIndex((y) => y.id == x.id) < 0
        ),
        action.payload.block,
        ...action.payload.changedBlocks,
      ];
      state.currentBlockId = action.payload.block.id;
    });

    builder.addCase(moveBlock.fulfilled, (state, action) => {
      state.blocks = [
        ...state.blocks.filter(
          (x) =>
            action.payload.changedBlocks.findIndex((y) => y.id == x.id) < 0 &&
            x.id !== action.payload.block.id
        ),
        action.payload.block,
        ...action.payload.changedBlocks,
      ];
      state.currentBlockId = action.payload.block.id;
    });

    builder.addCase(assignSequenceToBlock.fulfilled, (state, action) => {
      const blockIdx = state.blocks.findIndex(
        (x) => x.id === action.payload.blockDataId
      );

      if (blockIdx < 0) {
        return;
      }

      const idx = state.blocks[blockIdx].actionSequences.findIndex(
        (x) => x.actionSequenceId === action.payload.actionSequenceId
      );

      if (idx >= 0) {
        console.error("Sequence is already associated with block");
        return;
      }

      state.blocks[blockIdx].actionSequences.push(action.payload);
    });

    builder.addCase(removeSequenceFromBock.fulfilled, (state, action) => {
      const blockIdx = state.blocks.findIndex(
        (x) => x.id === action.meta.arg.blockDataId
      );

      if (blockIdx < 0) {
        console.error("Block was not found -> cannot remove sequence");
        return;
      }

      state.blocks[blockIdx].actionSequences = state.blocks[
        blockIdx
      ].actionSequences.filter(
        (x) => x.actionSequenceId !== action.meta.arg.actionSequenceId
      );
    });

    builder.addCase(updateSequenceAssignment.fulfilled, (state, action) => {
      const blockIdx = state.blocks.findIndex(
        (x) => x.id === action.payload.blockDataId
      );

      if (blockIdx < 0) {
        console.error("Block was not found -> cannot remove sequence");
        return;
      }

      const idx = state.blocks[blockIdx].actionSequences.findIndex(
        (x) => x.actionSequenceId === action.payload.actionSequenceId
      );

      if (idx < 0) {
        state.blocks[blockIdx].actionSequences.push(action.payload);
      } else {
        state.blocks[blockIdx].actionSequences[idx] = {
          ...state.blocks[blockIdx].actionSequences[idx],
          zeroBased: action.payload.zeroBased,
          scrollHeight: action.payload.scrollHeight,
        };
      }
    });

    // matches success of create paste and appendTemplate
    builder.addMatcher(
      isAnyOf(pasteBlocks.fulfilled, appendBlockTemplate.fulfilled),
      (state, action) => {
        state.blocks = [...state.blocks, ...action.payload];
        if (action.payload.length > 0) {
          state.currentBlockId = action.payload[0].id;
        }
      }
    );

    // base cases

    // logs error if a request is rejected
    builder.addMatcher(isRejected, (_, action) => {
      console.error(action);
    });
  },
});

export const { selectBlock, clearBlocks, copySelectedBlockPath } =
  blockSlice.actions;

// selectors
export const selectBlocks = (rootState: RootState) => rootState.blocks.blocks;
export const selectBlockById = (id: number) => (rootState: RootState) =>
  rootState.blocks.blocks.find((x) => x.id === id);

export const selectPageEvents = (pageId: number) => (rootState: RootState) =>
  rootState.blocks.blocks
    .filter((x) => x.pageDataId === pageId)
    .flatMap((x) => x.blockEvents);
export const selectSelectedBlock = (rootState: RootState) =>
  rootState.blocks.blocks.find((x) => x.id === rootState.blocks.currentBlockId);

export const selectBlockClipboard = (rootState: RootState) =>
  rootState.blocks.blockClipboard;
export const selectGlobalBlockStyles = (rootState: RootState) =>
  rootState.blocks.globalStyles;

export const selectParentBlock = (
  rootState: RootState
): BlockData | undefined => {
  const selectedBlock = rootState.blocks.blocks.find(
    (x) => x.id === rootState.blocks.currentBlockId
  );

  if (!selectedBlock || !selectedBlock.locationPath.includes("-")) return;

  const locationPath = selectedBlock?.locationPath.replace(
    `-${selectedBlock.id}`,
    ""
  );
  return rootState.blocks.blocks.find((x) => x.locationPath === locationPath);
};

export const selectBlockEvents = (rootState: RootState): BlockEvent[] => {
  return rootState.blocks.blocks.reduce((accumulator, block) => {
    return accumulator.concat(block.blockEvents);
  }, [] as BlockEvent[]);
};

export default blockSlice.reducer;
