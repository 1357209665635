import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import TopHandle from "../../helpers/TopHandle";
import BottomHandle from "../../helpers/BottomHandle";
import createNodeComponent from "../../../../../../functions/createNodeComponent";
import TriggerEventAction from "../../../../../../redux/actionsequence/types/actions/data/TriggerEventAction";

const TriggerEventNodeComponent = createNodeComponent<TriggerEventAction>(
  (props) => {
    const { actionData, onChange, id, errors } = props;

    return (
      <Box
        key={id}
        sx={{
          minWidth: "460px",
        }}
      >
        <TopHandle />
        <TextField
          className="nodrag"
          color="primary"
          fullWidth
          label="Name"
          defaultValue={actionData.name}
          size="small"
          variant="filled"
          required
          error={"name" in errors}
          helperText={errors.name}
          onChange={(ev) => onChange("name", ev.target.value)}
        />
        <TextField
          className="nodrag"
          color="primary"
          fullWidth
          label="Event name"
          defaultValue={actionData.event}
          size="small"
          variant="filled"
          required
          error={"event" in errors}
          helperText={errors.event}
          onChange={(ev) => onChange("event", ev.target.value)}
        />
        <BottomHandle />
      </Box>
    );
  }
);

export default TriggerEventNodeComponent;
