import * as React from "react";
import EventContext, {
  SubscriptionState,
  TriggerState,
  EventContext as EventContextType,
} from "./EventContext";
import {
  EventBehaviour,
  NonSourceTriggerBehaviour,
} from "../../redux/blocks/types";

const BlockEventContext: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  const [subscriptions, setSubscriptions] = React.useState<SubscriptionState>(
    {}
  );
  const [triggerState, setTriggerState] = React.useState<TriggerState>({});
  const [blockedEvents, setBlockedEvents] = React.useState<string[]>([]);

  /**
   * Dispatch an event
   * @param eventName the name of the event
   * @param source the source of the event
   * @param config event behaviour for this dispatch, default is 'none'
   * @param args event arguments
   */
  const dispatch = React.useCallback<EventContextType["dispatch"]>(
    (eventName, source, config, ...args) => {
      console.log(
        `Event ${eventName} triggered by ${source} using specifics '${
          config?.specific ?? "none"
        }' and args [${args.join(",")}]`
      );
      if (!(eventName in subscriptions) || blockedEvents.includes(eventName)) {
        return;
      }
      const eventSubscriptions = subscriptions[eventName];

      eventSubscriptions.forEach((sub) => {
        const ts = triggerState[eventName];

        const target = sub.source;

        let newToggle: boolean | undefined = undefined;

        switch (sub.type) {
          case EventBehaviour.Once: {
            if ((ts?.triggers ?? 0) > 0) {
              return;
            }
            sub.on(args);
            break;
          }
          case EventBehaviour.Default: {
            sub.on(args);
            break;
          }
          case EventBehaviour.ToggleInvariant: {
            if (config?.specific) {
              sub[config.specific](args);
              newToggle = config.specific === "on";
            } else if (ts.toggle) {
              sub.off(args);
              newToggle = false;
            } else {
              sub.on(args);
              newToggle = true;
            }
            break;
          }
          case EventBehaviour.ToggleSource: {
            let sourceToggle = false;
            const currentToggle = ts.bySource
              .find((x) => x.source === source)
              ?.targets.find((x) => x.target === target)?.toggle;
            if (sub.onlyBySource.includes(source)) {
              if (config?.specific) {
                sub[config.specific](args);
                sourceToggle = config.specific === "on";
              } else if (currentToggle) {
                sub.off(args);
                sourceToggle = false;
              } else {
                sub.on(args);
                sourceToggle = true;
              }
              setTriggerState((prev) => {
                let sourceIndex = prev[eventName]?.bySource.findIndex(
                  (x) => x.source === source
                );

                if (sourceIndex < 0) {
                  prev[eventName].bySource.push({
                    source: source,
                    targets: [
                      {
                        target: target,
                        triggers: 1,
                        toggle: sourceToggle,
                      },
                    ],
                  });
                } else {
                  const targetIndex = prev[eventName].bySource[
                    sourceIndex
                  ].targets.findIndex((x) => x.target === target);
                  if (targetIndex < 0) {
                    prev[eventName].bySource[sourceIndex].targets.push({
                      target: target,
                      triggers: 1,
                      toggle: sourceToggle,
                    });
                  } else {
                    prev[eventName].bySource[sourceIndex].targets[targetIndex]
                      .triggers++;
                    prev[eventName].bySource[sourceIndex].targets[
                      targetIndex
                    ].toggle = sourceToggle;
                  }
                }
                return prev;
              });
            } else {
              switch (sub.nonSourceTriggerBehaviour) {
                case NonSourceTriggerBehaviour.DoNothing:
                  break;
                case NonSourceTriggerBehaviour.Untrigger:
                case NonSourceTriggerBehaviour.Trigger: {
                  let fn;
                  let setNewToggle = false;
                  if (
                    sub.nonSourceTriggerBehaviour ===
                    NonSourceTriggerBehaviour.Untrigger
                  ) {
                    fn = sub.off;
                    setNewToggle = false;
                  } else {
                    fn = sub.on;
                    setNewToggle = true;
                  }
                  fn(args);
                  // all trigger sources for this target and change their toggles
                  const prevsources = ts.bySource
                    .filter((x) => x.targets.some((t) => t.target === target))
                    .map((x) => x.source);
                  setTriggerState((prev) => {
                    prevsources.forEach((sauce) => {
                      const sIdx = prev[eventName].bySource.findIndex(
                        (x) => x.source === sauce
                      );
                      if (sIdx < 0) {
                        prev[eventName].bySource.push({
                          source: sauce,
                          targets: [
                            {
                              target: target,
                              triggers: 1,
                              toggle: setNewToggle,
                            },
                          ],
                        });
                        return;
                      }
                      const tIdx = prev[eventName].bySource[
                        sIdx
                      ].targets.findIndex((x) => x.target === target);
                      if (tIdx < 0) {
                        prev[eventName].bySource[sIdx].targets.push({
                          target: target,
                          triggers: 1,
                          toggle: setNewToggle,
                        });
                        return;
                      }
                      prev[eventName].bySource[sIdx].targets[tIdx].toggle =
                        setNewToggle;
                      prev[eventName].bySource[sIdx].targets[tIdx].triggers++;
                    });
                    return prev;
                  });
                  break;
                }
              }
            }
            break;
          }
        }

        setTriggerState((prev) => {
          prev[eventName].triggers++;
          prev[eventName].toggle = newToggle;
          prev[eventName].lastSource = source;

          return prev;
        });

        // Block events for a few ticks
        setBlockedEvents((prev) => [...prev, eventName]);
        setTimeout(
          () => setBlockedEvents((prev) => prev.filter((x) => x !== eventName)),
          100
        );
      });
    },
    [
      subscriptions,
      triggerState,
      setTriggerState,
      setBlockedEvents,
      blockedEvents,
    ]
  );

  /**
   * Subscribe to an event
   *
   * @param eventName The name of the event to subscribe to
   * @param subscription The event subscription to add, with its source
   */
  const subscribe = React.useCallback<EventContextType["subscribe"]>(
    (eventName, subscription) => {
      setSubscriptions((prev) => {
        if (!(eventName in prev)) {
          prev[eventName] = [];
        }

        const idx = prev[eventName].findIndex(
          (x) => x.source === subscription.source
        );
        if (idx >= 0) {
          prev[eventName][idx] = {
            ...prev[eventName][idx],
            ...subscription,
          };
        } else {
          prev[eventName].push(subscription);
        }
        return prev;
      });

      if (subscription.initiallyTriggered) {
        subscription.on();
      }

      setTriggerState((prev) => {
        if (!(eventName in prev)) {
          prev[eventName] = {
            triggers: 0,
            bySource: [],
          };
        }

        let toggle: boolean | undefined = undefined;
        let triggers = subscription.initiallyTriggered ? 1 : 0;

        if (
          [
            EventBehaviour.ToggleInvariant,
            EventBehaviour.ToggleSource,
          ].includes(subscription.type)
        ) {
          toggle = subscription.initiallyTriggered;
        }

        prev[eventName].toggle = toggle;
        prev[eventName].triggers = triggers;

        return prev;
      });
    },
    [setSubscriptions, setTriggerState]
  );

  const value = React.useMemo(
    () => ({
      subscribe,
      dispatch,
      subscriptions,
      triggerState,
    }),
    [dispatch, subscriptions, subscribe, triggerState]
  );

  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
};

export default BlockEventContext;
