import { Box, TextField } from "@mui/material";
import TopHandle from "../../helpers/TopHandle";
import React from "react";
import createNodeComponent from "../../../../../../functions/createNodeComponent";
import ContinueAction from "../../../../../../redux/actionsequence/types/actions/control/ContinueAction";

const ContinueNodeComponent = createNodeComponent<ContinueAction>((props) => {
  const { actionData, onChange, errors } = props;

  return (
    <>
      <TopHandle />
      <Box>
        <TextField
          className="nodrag"
          color="primary"
          fullWidth
          label="Action name"
          defaultValue={actionData.name}
          size="small"
          variant="filled"
          required
          error={"name" in errors}
          helperText={errors.name}
          onChange={(ev) => onChange("name", ev.target.value)}
        />
      </Box>
    </>
  );
});

export default ContinueNodeComponent;
