import React from 'react';
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import { ViewDay, ViewColumn, GridView, ViewArray, FormatColorText, VisibilityOff, VerticalAlignBottom, VerticalAlignCenter, Expand, Clear, Start, KeyboardTab, ZoomOutMap } from '@mui/icons-material';
import { Grid, Typography, Box, ToggleButtonGroup, ToggleButton, TextField } from '@mui/material';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import GridAutoFlowInputs from './GridAutoFlowInputs';
import { getStyleById, isStyleInheritedAndChanged } from '../../../../../../Utility';
import StyleTextField from '../../../../../utils/Inputs/StyleTextField';
import { BlockStyleAttribute } from '../BlockStyleInputs';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void, 
    selectedBlock?: BlockData,
}

export default function GridInputs(props: Props) {
    const { currentBlockStyle, handleAttributeChange, selectedBlock } = props;

    return (
        <>
            <GridAutoFlowInputs {...props} />
            <BlockStyleDataLabel name="Sizing" />
            <Grid item xs={10}>
                <ToggleButtonGroup value={currentBlockStyle.blockStyleData.find(x => x.attributeKey === 'flex-basis')?.attributeValue ?? getStyleById(selectedBlock, 'flex-basis')}
                    onChange={(e, value) => handleAttributeChange([{attributeKey: 'flex-basis', attributeValue: value}], true)}
                    exclusive={true}
                    size='small'
                    fullWidth>
                    <ToggleButton value="0 1 auto" key="shrink">
                        <VerticalAlignCenter />
                    </ToggleButton>
                    <ToggleButton value="1 0 auto" key="grow">
                        <Expand />
                    </ToggleButton>
                    <ToggleButton value="0 0 auto" key="none">
                        <Clear />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Align" />
            <Grid item xs={10}>
                <ToggleButtonGroup value={currentBlockStyle.blockStyleData.find(x => x.attributeKey === 'align-self')?.attributeValue ?? getStyleById(selectedBlock, 'align-self')}
                    onChange={(e, value) => handleAttributeChange([{attributeKey: 'align-self', attributeValue: value}], true)}
                    exclusive={true}
                    size='small'
                    fullWidth>
                    <ToggleButton value="start" key="start">
                        <Start />
                    </ToggleButton>
                    <ToggleButton value="center" key="center">
                        <VerticalAlignCenter />
                    </ToggleButton>
                    <ToggleButton value="end" key="end">
                        <KeyboardTab />
                    </ToggleButton>
                    <ToggleButton value="stretch" key="stretch">
                        <ZoomOutMap />
                    </ToggleButton>
                    <ToggleButton value="baseline" key="baseline">
                        <Clear />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Order" />
            <Grid item xs={10}>
                <StyleTextField {...props} attributeKey='order' type='number' /> 
            </Grid>
        </>
    );
}