import React, { useContext, useMemo } from "react";
import NodeInfoContext from "../../../../../context/NodeInfoContext";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const NodeStatusIcon = () => {
  const { nodeState } = useContext(NodeInfoContext);

  const statusColor = useMemo(() => {
    switch (nodeState?.status) {
      case "waiting":
      case "executing":
        return "#3269b5";
      case "finished":
        return "#049E09";
      case "error":
        return "#c6574f";
      case "removed":
        return "#9e9e9e";
      default:
        return "#ffffff";
    }
  }, [nodeState?.status]);

  const statusIcon = useMemo(() => {
    switch (nodeState?.status) {
      case "executing":
        return (
          <LoopIcon
            sx={{
              animation: "spin 1s linear infinite",
            }}
          />
        );
      case "finished":
        return <CheckIcon />;
      case "error":
        return <ErrorIcon />;
      case "removed":
        return <SkipNextIcon />;
      case "waiting":
        return <MoreHorizIcon />;
      default:
        return null;
    }
  }, [nodeState?.status]);

  const theme = useTheme();

  const statusDisplay = useMemo(() => {
    if (!nodeState?.status) {
      return null;
    }

    return (
      <Tooltip
        title={
          <Box
            sx={{
              padding: theme.spacing(1),
            }}
          >
            <Typography variant="h5">Output</Typography>
            <Typography variant="body1">
              {nodeState.output ? (
                <pre>{JSON.stringify(nodeState.output)}</pre>
              ) : (
                "No output"
              )}
            </Typography>
          </Box>
        }
      >
        <Box
          sx={{
            position: "absolute",
            bottom: "-15px",
            right: "-15px",
            background: statusColor,
            borderRadius: 10,
            border: `0px #414a4c solid`,
            width: "30px",
            height: "30px",
            justifyContent: "center",
            alignItems: "center",
            display: nodeState ? "flex" : "none",
          }}
        >
          {statusIcon}
        </Box>
      </Tooltip>
    );
  }, [nodeState, statusColor, statusIcon, theme]);

  return <>{statusDisplay}</>;
};

export default NodeStatusIcon;
