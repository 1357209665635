import React, { useImperativeHandle } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { Handle, Position } from "reactflow";
import BottomHandle from "../../helpers/BottomHandle";
import { HANDLE_STYLE } from "../../helpers/NodeWrapper";
import TopHandle from "../../helpers/TopHandle";
import createNodeComponent from "../../../../../../functions/createNodeComponent";
import LoopAction from "../../../../../../redux/actionsequence/types/actions/control/LoopAction";
import { HandleType } from "../../../../../../redux/actionsequence/types/helpers/ActionConnection";

const LoopNodeComponent = createNodeComponent<LoopAction>((props) => {
  const { actionData, errors, onChange } = props;

  return (
    <>
      <TopHandle />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ["&>.MuiTextField-root"]: {
              marginBottom: "20px",
            },
            marginRight: "70px",
          }}
        >
          <TextField
            className="nodrag"
            color="primary"
            fullWidth
            label="Action name"
            defaultValue={actionData.name}
            size="small"
            variant="filled"
            required
            error={"name" in errors}
            helperText={errors.name}
            onChange={(ev) => onChange("name", ev.target.value)}
          />
          <TextField
            className="nodrag"
            type="number"
            color="primary"
            fullWidth
            label="Max loops"
            defaultValue={actionData.max}
            size="small"
            variant="filled"
            required
            error={"max" in errors}
            helperText={errors.max}
            onChange={(ev) => onChange("max", Number(ev.target.value))}
          />
        </Box>
        <Box
          sx={{
            height: "inherit",
            position: "relative",
          }}
        >
          <Typography
            sx={{ position: "absolute", top: "2%", right: 10 }}
            color="primary"
          >
            Start
          </Typography>
          <Typography
            sx={{ position: "absolute", top: "82%", right: 10 }}
            color="primary"
          >
            End
          </Typography>
          <Handle
            id={HandleType.LoopStart}
            style={{ ...HANDLE_STYLE, top: "10%", right: -19 }}
            position={Position.Right}
            type="source"
          />
          <Handle
            id={HandleType.LoopEnd}
            style={{ ...HANDLE_STYLE, top: "90%", right: -19 }}
            position={Position.Right}
            type="target"
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: "20px", width: "100%", textAlign: "center" }}>
        <Typography color="primary">Exit</Typography>
      </Box>
      <BottomHandle id={HandleType.LoopExit} />
    </>
  );
});

export default LoopNodeComponent;
