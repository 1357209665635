import { ProcessState } from "../context/blockActions/ActionExecutionContext";
export const expressionParserRegex =
  /^\$(?<access>actionStates\[\'(?<action>[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})\'\]|args|variables)\.(?<path>[.a-zA-Z0-9]+)$/m;
const pathRegex = /^[a-zA-Z0-9.]+$/;

export default function evaluateExpression(
  _state: ProcessState,
  expression: string
) {
  if (!expression.startsWith("$")) return expression;
  const match = expressionParserRegex.exec(expression);
  if (
    (!match?.groups?.access && !match?.groups?.action) ||
    !match.groups.path ||
    !pathRegex.test(match.groups.path)
  ) {
    return expression;
  }

  let value = expression;
  if (match.groups?.action) {
    value = eval(
      `_state.actionStates['${match.groups.action}'].${match.groups.path}`
    );
  } else {
    value = eval(`_state.${match.groups.access}.${match.groups.path}`);
  }
  return value;
}
