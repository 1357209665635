import * as React from "react";
import { DynDataEntry, DynDataSet } from "../../redux/dyndata/types";

export interface State {
  entries: DynDataEntry[];
  dataSets: DynDataSet[];
}
interface DataContextProps {
  updateDataSet: (dataSet: DynDataSet) => void;
  updateEntry: (entry: DynDataEntry) => void;
}
const initial: DataContextProps = {
  updateDataSet(dataSet) {},
  updateEntry(entry) {},
};

export const BlockDataContext = React.createContext(initial);
