import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  BackendError,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/hooks";
import { listGlobalSequences } from "../../../../redux/actionsequence/sequenceSlice";
import SnackBarOperations from "../../../../components/SnackBar/SnackBarOperations";
import useRouteParam from "../../../../hooks/useRouteParam";
import {
  assignSequenceToBlock,
  selectSelectedBlock,
} from "../../../../redux/blocks/blockSlice";
import TitleBox from "../../../widgets/TitleBox";
import { Box, IconButton, List, Popover, useTheme } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import ActionSequenceSelect from "./BlockActionsEdit/ActionSequenceSelect";
import ActionSequenceType from "../../../../redux/actionsequence/types/helpers/ActionSequenceType";
import BlockActionGrid from "./BlockActionsEdit/BlockActionGrid";

type ReducedActionSequenceTypes<T> = {
  [key in ActionSequenceType]: T;
};

const BlockActionSequenceTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const projectId = useRouteParam("projectId");

  const currentBlock = useAppSelector(selectSelectedBlock);

  const theme = useTheme();

  const [sectionsOpen, setSectionsOpen] = useState<
    ReducedActionSequenceTypes<boolean>
  >({
    [ActionSequenceType.OnClick]: false,
    [ActionSequenceType.OnLoad]: false,
    [ActionSequenceType.OnScroll]: false,
    [ActionSequenceType.OnSubmit]: false,
  });

  const [addSequenceAnchor, setAddSequenceAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  const handleSequenceAdd = useCallback(
    (sequenceId: number) => {
      if (!currentBlock) {
        return;
      }
      dispatch(
        assignSequenceToBlock({
          blockDataId: currentBlock.id,
          actionSequenceId: sequenceId,
        })
      )
        .unwrap()
        .then(() => setAddSequenceAnchor(null))
        .catch((err) => {
          if (err instanceof BackendError) {
            SnackBarOperations.error(
              `Error adding sequence to block: ${err.message}`
            );
          }
        });
    },
    [currentBlock, dispatch, setAddSequenceAnchor]
  );

  useEffect(() => {
    const fetchRelevantSequences = async () => {
      try {
        await dispatch(listGlobalSequences()).unwrap();
        // TODO:Uncomment this as soon as project sequences are introduced
        // await dispatch(listSequences({ projectId })).unwrap();
      } catch (err) {
        if (err instanceof BackendError) {
          SnackBarOperations.error(
            `Failed to fetch all sequences: ${err.message}`
          );
        } else {
          console.error(err);
        }
      }
    };
    fetchRelevantSequences();
  }, [projectId]);

  const actionSequences = useMemo(() => {
    return (
      currentBlock?.actionSequences
        ?.filter((x) => x.actionSequence !== undefined)
        ?.map((x) => x.actionSequence!) ?? []
    );
  }, [currentBlock]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <TitleBox
        mainTitle="Action sequences"
        subTitle={"Edit workflow sequences"}
        maintitleVariant="h6"
        subTitleVariant="subtitle2"
      >
        <IconButton
          aria-label="add"
          onClick={(ev) => setAddSequenceAnchor(ev.currentTarget)}
        >
          <AddOutlined />
        </IconButton>
      </TitleBox>
      <Popover
        open={Boolean(addSequenceAnchor)}
        anchorEl={addSequenceAnchor}
        onClose={() => setAddSequenceAnchor(null)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <ActionSequenceSelect
          onClose={() => setAddSequenceAnchor(null)}
          onSubmit={handleSequenceAdd}
        />
      </Popover>
      <Box
        sx={{
          marginTop: theme.spacing(2),
        }}
      >
        <List dense disablePadding>
          {Object.values(ActionSequenceType).map((v) => (
            <BlockActionGrid
              key={v}
              actionSequences={actionSequences}
              open={sectionsOpen[v]}
              toggleSectionOpen={() =>
                setSectionsOpen((prev) => ({ ...prev, [v]: !prev[v] }))
              }
              type={v}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default BlockActionSequenceTab;
