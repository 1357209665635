import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import * as React from "react";

import { selectIsAuthenticated } from "../redux/auth/authSlice";
import { useAppSelector } from "../redux/hooks";
import { addOnlineUser, removeOnlineUser } from "../redux/users/usersSlice";
import { store } from "../redux/store";

type UserHubContextType = {
  send: (methodName: string, ...args: any[]) => Promise<void>;
  state: HubConnectionState;
};

const def = {
  send: (methodName: string, ...args: any[]) =>
    Promise.reject("Are you using this outside of a SignalRProvider?"),
  state: HubConnectionState.Disconnected,
};

export const UserHubContext = React.createContext<UserHubContextType>(def);

export const useUserHub = () => React.useContext(UserHubContext);

interface Props {
  children: React.ReactNode;
}

export default function UserHubProvider(props: Props) {
  const { children } = props;

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const userHubConnection = new HubConnectionBuilder()
    .withUrl("/hubs/userstatus", {
      accessTokenFactory: () => store.getState().auth.token,
      transport: HttpTransportType.WebSockets,
    })
    .withAutomaticReconnect()
    .build();

  userHubConnection.on("userConnected", (data) => {
    console.log(data);
    store.dispatch(addOnlineUser(data));
  });
  userHubConnection.on("userDisconnected", (data) => {
    console.log(data);
    store.dispatch(removeOnlineUser(data));
  });

  React.useEffect(() => {
    if (isAuthenticated) userHubConnection.start();
  }, [isAuthenticated]);

  return (
    <UserHubContext.Provider
      value={{
        send: userHubConnection.send,
        state: userHubConnection.state,
      }}
    >
      {children}
    </UserHubContext.Provider>
  );
}
