import { Box, Button, Container, Link, Paper, TextField, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router';
import SnackBarOperations from '../../components/SnackBar/SnackBarOperations';
import { authenticate, refreshToken } from '../../redux/auth/authSlice';
import { useAppDispatch } from '../../redux/hooks';


export default function Login() {

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const theme = useTheme();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(refreshToken())
            .unwrap()
            .then(() => navigate('/'));
    }, []);


    const login = React.useCallback(() => {
        dispatch(authenticate({
            username,
            password
        }))
        .unwrap()
        .then(() => {
            navigate('/');
        })
        .catch((err) => {
            console.error('auth error: ', err);
            SnackBarOperations.error(err.message);
        });
    }, [dispatch, navigate, username, password]);

    const textFieldStyles = {
        marginTop: '2rem',
        width: '100%'
    }

    return (
        <Box sx={{ 
            display: 'flex', 
            position: 'relative',
            justifyContent: 'center', 
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            margin: 0,
            background: 'url(\'/login.png\')',
            backgroundSize: 'cover'
        }}>
            <Box sx={{ position: 'absolute', width: '100%', height: '100%', background: `linear-gradient(55deg, ${theme.palette.primary.main}75 25%, ${theme.palette.info.main}75 100%)` }} />
            <Paper elevation={1} sx={{
                padding: '2rem', 
                paddingLeft: '6rem',
                paddingRight: '6rem',
                width: '20%',
                minWidth: '200px',
                maxWidth: '300px',
                display: 'flex',
                zIndex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant='subtitle2'>WELCOME BACK</Typography>
                    <Typography variant='h3'>LOGIN</Typography>
                </Box>
                <TextField
                    id='login-username'
                    label='Username or email'
                    variant='outlined'
                    value={username}
                    sx={textFieldStyles}
                    onChange={(e) => setUsername(e.target.value)}
                    />
                <TextField
                    id='login-pw'
                    label='Password'
                    variant='outlined'
                    type='password'
                    sx={textFieldStyles}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    />
                <Button 
                    variant='contained' 
                    onClick={login}
                    sx={{
                        marginTop: '2rem'
                    }}
                >
                    Login
                </Button>
            </Paper>
        </Box>
    );
}