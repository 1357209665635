import { FilledInput, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Switch, TextField, debounce, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import useRouteParam from '../../../../../hooks/useRouteParam';
import { BackendError, useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { selectProjectTemplates, selectTemplateById, updateTemplate } from '../../../../../redux/template/templateSlice';
import { TemplateData } from '../../../../../redux/template/types';
import yup from '../../../../../validation/yup';
import { ProjectContext } from '../../../../project/context/ProjectContext';
import SnackBarOperations from '../../../../../components/SnackBar/SnackBarOperations';

export default function TemplateDetailInputs() {

    const [currentTemplate, setCurrentTemplate] = React.useState<TemplateData | undefined>();

    const {
        id: projectId
    } = useContext(ProjectContext);

    const refId = useRouteParam('refId');
    const selectedTemplate = useAppSelector(selectTemplateById(refId));
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        setCurrentTemplate(selectedTemplate);
    }, [selectedTemplate])

    const templateValidationSchema = React.useMemo(() => yup.object().shape({
        name: yup.string().required('Template name is required.')
    }), [])

    const handleTemplateSave = React.useCallback(async (template: TemplateData) => {
        try {
            await templateValidationSchema.validate(template)
            dispatch(updateTemplate(template));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error deleting header: ${err.message}`);
            }
            console.error(err);
        }
    }, [currentTemplate, dispatch, templateValidationSchema]);

    const debouncedChangeHandler = React.useCallback(
        debounce(handleTemplateSave, 500),
        [handleTemplateSave],
    );

    const handleTemplateChange = React.useCallback((template: TemplateData) => {
        setCurrentTemplate(template);
        debouncedChangeHandler(template);
    }, [setCurrentTemplate]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    id="template-name"
                    label="Name"
                    placeholder="Template"
                    fullWidth
                    variant="filled"
                    value={currentTemplate?.name ?? ''}
                    onChange={(e) => handleTemplateChange({ ...currentTemplate as TemplateData, name: e.target.value })}
                />
            </Grid>
        </Grid>
    )
}