import { Box, IconButton, Tooltip, debounce, useTheme } from "@mui/material";
import React from "react";
import {
  selectSelectedBlock,
  updateBlock,
} from "../../../../redux/blocks/blockSlice";
import {
  BlockData,
  BlockUpdate,
  ReferenceType,
} from "../../../../redux/blocks/types";
import {
  BackendError,
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/hooks";
import TitleBox from "../../../widgets/TitleBox";
import BaseInputs from "./BlockDetailEdit/BaseInputs";
import { OpenInNew } from "@mui/icons-material";
import AttributeInputs from "./BlockDetailEdit/AttributeInputs";
import PropAssignmentInputs from "./BlockDetailEdit/PropAssignmentInputs";
import yup from "../../../../validation/yup";
import SnackBarOperations from "../../../../components/SnackBar/SnackBarOperations";
import { useNavigate } from "react-router";
import useRouteParam from "../../../../hooks/useRouteParam";

export default function BlockDetailTab() {
  const projectId = useRouteParam("projectId");

  const dispatch = useAppDispatch();
  const selectedBlock = useAppSelector(selectSelectedBlock);
  const [currentBlock, setCurrentBlock] = React.useState<
    BlockData | undefined
  >();
  const theme = useTheme();
  const navigate = useNavigate();

  React.useEffect(() => {
    setCurrentBlock(selectedBlock);
  }, [selectedBlock]);

  const blockAttributeValidationSchema = React.useMemo(
    () =>
      yup.object().shape({
        blockAttributes: yup.array().of(
          yup.object().shape({
            name: yup.string().required("Attribute name is required."),
            value: yup.string().required("Attribute value is required."),
          })
        ),
      }),
    []
  );

  const handleBlockSave = React.useCallback(async (block: BlockData) => {
      try {
        await blockAttributeValidationSchema.validate(block);
        await dispatch(updateBlock(block as BlockUpdate));
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          SnackBarOperations.error(err.message);
        }
        if (err instanceof BackendError) {
          SnackBarOperations.error(`Error updating block: ${err.message}`);
        }
        console.error(err);
      }
  }, [dispatch, currentBlock, blockAttributeValidationSchema]);
  
  const debouncedChangeHandler = React.useCallback(
    debounce(handleBlockSave, 500),
    [handleBlockSave],
  );

  const handleBlockChange = React.useCallback(
    (block: BlockData) => {
      setCurrentBlock(block);
      debouncedChangeHandler(block);
    },
    [setCurrentBlock]
  );

  const handleBlockChangeAndSave = React.useCallback(
    async (block: BlockData) => {
      setCurrentBlock(block);
      try {
        await blockAttributeValidationSchema.validate(block);
        await dispatch(updateBlock(block));
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          SnackBarOperations.error(err.message);
        }
        if (err instanceof BackendError) {
          SnackBarOperations.error(`Error updating block: ${err.message}`);
        }
        console.error(err);
      }
    },
    [setCurrentBlock, dispatch, blockAttributeValidationSchema]
  );

  const navigateToReferencedTemplate = React.useCallback(() => {
    if (currentBlock?.templateReference)
      navigate(
        `/builder/${projectId}/${ReferenceType.Template}/${currentBlock.templateReference.templateDataId}`
      );
  }, [currentBlock, navigate]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          "&>*": {
            marginBottom: theme.spacing(1),
          },
        }}
      >
        <TitleBox
          mainTitle="Block details"
          subTitle="Edit block details"
          maintitleVariant="h6"
          subTitleVariant="subtitle2"
        >
          <Tooltip title="Edit referenced template">
            <span>
              <IconButton
                aria-label="add"
                disabled={!currentBlock?.templateReference}
                onClick={navigateToReferencedTemplate}
              >
                <OpenInNew />
              </IconButton>
            </span>
          </Tooltip>
        </TitleBox>
        {currentBlock ? (
          <>
            <BaseInputs
              currentBlock={currentBlock}
              handleBlockChange={handleBlockChange}
              handleBlockChangeAndSave={handleBlockChangeAndSave}
            />
            <AttributeInputs
              currentBlock={currentBlock}
              handleBlockChange={handleBlockChange}
              handleBlockChangeAndSave={handleBlockChangeAndSave}
            />
            <PropAssignmentInputs currentBlock={currentBlock} />
          </>
        ) : null}
      </Box>
    </>
  );
}
