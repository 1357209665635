import { LanguageOutlined, CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { FilledInput, FormControl, InputLabel, MenuItem, Paper, Select, Stack, TextField, useTheme, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import useRouteParam from '../../../hooks/useRouteParam';
import { BackendError, useAppDispatch, useAppSelector } from '../../../redux/hooks';
import TitleBox from '../../widgets/TitleBox';
import yup from '../../../validation/yup';
import SnackBarOperations from '../../../components/SnackBar/SnackBarOperations';
import { defaultMailTemplate, getMailTemplates, selectMailTemplateById, updateMailTemplate } from '../../../redux/mail/mailSlice';

export default function MailTemplateForm() {

    const templateId = useRouteParam('templateId');
    const projectId = useRouteParam('projectId');
    const theme = useTheme();
    const [editTemplate, setEditTemplate] = React.useState(defaultMailTemplate);
 
    const currentEntry = useAppSelector(selectMailTemplateById(templateId));
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (currentEntry)
            setEditTemplate({ ...currentEntry });
    }, [currentEntry])

    React.useEffect(() => {
        dispatch(getMailTemplates(projectId));
    }, [])

    const mailTemplateValidationModel = React.useMemo(() => yup.object().shape({
        name: yup.string()
            .required('Template name is required.')
    }), []);

    const handleTemplateChange = React.useCallback(async () => {
        try {
            await mailTemplateValidationModel.validate(editTemplate);
            await dispatch(updateMailTemplate(editTemplate));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error updating mail template: ${err.message}.`);
            }
        }
    }, [dispatch, editTemplate]);

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Edit Mail Template'
                subTitle='Lorem ipsum'>
            </TitleBox>
            <Paper sx={{ width: '100%', padding: theme.spacing(2) }}>
                <TextField
                    sx={{ width: '250px' }}
                    id="mail-template-name"
                    label="Name"
                    variant="filled"
                    value={editTemplate.name}
                    onChange={(e) => setEditTemplate({ ...editTemplate, name: e.target.value })}
                    onBlur={handleTemplateChange} />

            </Paper>
            <Paper sx={{ width: '100%', height: '800px', padding: theme.spacing(2) }}>
                <TextField
                    id="mail-template-content"
                    label="Content"
                    variant="filled"
                    fullWidth
                    multiline
                    minRows={30}
                    value={editTemplate.content}
                    onChange={(e) => setEditTemplate({ ...editTemplate, content: e.target.value })}
                    onBlur={handleTemplateChange} />
            </Paper>
        </>
    );
}