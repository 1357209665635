import React, { useCallback, useMemo, useState } from "react";
import LocalStorageContext from "./LocalStorageContext";

const FakeLocalStorageContextProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  const { children } = props;

  const [storage, setStorage] = useState<Record<string, string>>({});

  const setItem = useCallback(
    (key: string, value: string) => {
      setStorage((prev) => {
        prev[key] = value;
        return prev;
      });
    },
    [setStorage]
  );

  const getItem = useCallback(
    (key: string) => {
      if (key in storage) {
        return storage[key];
      }
      return null;
    },
    [storage]
  );

  const clear = useCallback(() => {
    setStorage({});
  }, [setStorage]);

  const removeItem = useCallback(
    (key: string) => {
      setStorage((prev) => {
        delete prev[key];
        return prev;
      });
    },
    [setStorage]
  );

  const value = useMemo(
    () => ({
      clear,
      removeItem,
      setItem,
      getItem,
    }),
    [clear, removeItem, setItem, getItem]
  );

  return (
    <LocalStorageContext.Provider value={value}>
      {children}
    </LocalStorageContext.Provider>
  );
};

export default FakeLocalStorageContextProvider;
