import { createContext } from "react";

interface LocalStorageContext {
  clear(): void;
  getItem(key: string): string | null;
  removeItem(key: string): void;
  setItem(key: string, value: string): void;
}

const initial: LocalStorageContext = {
  clear() {},
  getItem(key) {
    return null;
  },
  removeItem(key) {},
  setItem(key, value) {},
};

const Context = createContext(initial);

export default Context;
