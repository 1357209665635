import { createTheme } from "@mui/material";
import { OuterTheme } from "./OuterTheme";
import { Colors } from "./Colors";

export const InnerTheme = createTheme({
  ...OuterTheme,
  palette: {
    primary: {
      main: Colors.common_black,
    },
    secondary: {
      main: Colors.primary_main,
    },
    background: {
      default: "black",
      paper: Colors.common_black,
    },
    text: {
      primary: Colors.fontColor,
    }
  },
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          color: Colors.fontColor,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.inner_icon_button_background,
          color: Colors.fontColor,
          "&:hover": {
            backgroundColor: Colors.inner_button_hover_background,
          },
          "&.Mui-disabled": {
            backgroundColor: Colors.inner_icon_button_disabled,
            color: Colors.text_silver,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: Colors.fontColor,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.inner_icon_button_background,
          color: Colors.text_silver,
          "&:hover": {
            backgroundColor: Colors.inner_button_hover_background,
          },
          "&.Mui-selected": {
            backgroundColor: Colors.inner_button_hover_background,
            color: Colors.fontColor,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: Colors.fontColor,
          backgroundColor: Colors.inner_icon_button_background,
          "&:hover": {
            backgroundColor: Colors.inner_button_hover_background,
          },
          "&.Mui-disabled": {
            backgroundColor: Colors.inner_button_background,
            color: Colors.text_silver,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: Colors.fontColor,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: Colors.fontColor
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.inner_icon_button_background,
          color: Colors.fontColor,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTabs-indicator": {
            backgroundColor: Colors.primary_main,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: Colors.fontColor,
          "&.Mui-selected": {
            color: Colors.primary_main,
          },
          "&.Mui-disabled": {
            color: Colors.text_silver,
            backgroundColor: Colors.inner_icon_button_disabled,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          "& .MuiSwitch-track": {
            backgroundColor: `${Colors.inner_icon_button_background} !important`,
          },
          "& .MuiSwitch-thumb": {
            backgroundColor: Colors.text_silver,
          },
          "& .Mui-checked .MuiSwitch-thumb": {
            backgroundColor: Colors.primary_main,
          },
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.inner_icon_button_background,
          "&:hover": {
            backgroundColor: Colors.inner_button_hover_background,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {},
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: Colors.primary_main,
          "&.Mui-checked": {
            color: Colors.primary_main,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          color: Colors.fontColor,
          "& .blockmap .MuiListItem-root": {
            "&:hover": {
              backgroundColor: Colors.inner_button_hover_background,
            },
            "&:active": {
              backgroundColor: Colors.inner_icon_button_disabled,
            },
            "& .Mui-selected": {
              color: Colors.primary_main,
              backgroundColor: Colors.inner_button_hover_background,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          backgroundColor: Colors.inner_button_hover_background,
          color: Colors.fontColor,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .Mui-disabled": {
            backgroundColor: `${Colors.inner_icon_button_disabled} !important`,
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: Colors.primary_main,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.inner_button_background,
          "&:hover": {
            backgroundColor: Colors.inner_button_hover_background,
          },
        },
      },
    },
  },
});
