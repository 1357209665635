import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { BlockData } from '../../redux/blocks/types';

interface Props {
    open: boolean,
    handleClose: () => void,
    handleSubmit: (blocks: BlockData[]) => void,
}

export default function AddJsonBlocksDialog(props: Props) {
    const { open, handleClose, handleSubmit } = props;
    const [jsonText, setJsonText] = React.useState("");
    const [isValid, setIsValid] = React.useState(false);

    const handleJsonToBlocks = () => {
        const blocks: BlockData[] = JSON.parse(jsonText);
        handleSubmit(blocks);
    }

    const handleJsonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        setJsonText(newValue);    
        try {
          const blocks: BlockData[] = JSON.parse(newValue);
          setIsValid(true);
        } catch (error) {
            setIsValid(false);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>Add json</DialogTitle>
            <DialogContent>
                <TextField
                    label="Multiline"
                    multiline
                    onChange={handleJsonChange}
                    value={jsonText}
                    fullWidth
                    rows={20}
                    defaultValue="Default Value"
                    variant="filled"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button disabled={!isValid} onClick={handleJsonToBlocks}>Add</Button>
            </DialogActions>
        </Dialog>
    );
}