import { SaveOutlined } from '@mui/icons-material';
import { Button, Grid, TextField } from '@mui/material';
import * as React from 'react';
import { useForm, Controller } from "react-hook-form";
import { User } from '../../redux/auth/types';
import TitleBox from '../widgets/TitleBox';
import yup from '../../validation/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../redux/hooks';
import { setUserData } from '../../redux/users/usersSlice';
import { LoadingButton } from '@mui/lab';

const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
}

export type EditUserData = typeof defaultValues;

interface Props {
    user: User,
}


const schema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().email("Not a valid email address").nullable()
})

export default function UserDetails(props: Props) {

    const { user } = props;

    const dispatch = useAppDispatch();

    const [loading, setLoading] = React.useState(false);

    const userDefaults = React.useMemo(() => {
        const ud: EditUserData = {
            firstName: user.firstName ?? '',
            lastName: user.lastName ?? '',
            email: user.email,
        };
        return ud;
    }, [user]);

    const { handleSubmit, register, reset, control, formState } = useForm({
        defaultValues,
        values: userDefaults,
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        mode: 'onTouched'
    });

    const handleFormSubmit = React.useCallback((val: EditUserData) => {
        setLoading(true);
        dispatch(setUserData({ userId: user.id, userData: val }))
            .unwrap()
            .then(() => console.log('success'))
            .catch(console.error /*TODO: handle errors*/)
            .finally(() => setLoading(false))
    }, [dispatch, setLoading, user]);

    return (
        <>
            <TitleBox
                mainTitle='Details'
                maintitleVariant='h6'
                subTitle='Edit contact details and user info'
                subTitleVariant='subtitle2'
                key={'user-details-title'}
            >
                <LoadingButton
                    variant='contained'
                    loading={loading}
                    disabled={!formState.isDirty}
                    onClick={handleSubmit(handleFormSubmit)} endIcon={<SaveOutlined />}>
                    SAVE
                </LoadingButton>
            </TitleBox>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        disabled={loading}
                        variant='filled'
                        id="firstName"
                        label="First name"
                        fullWidth
                        {...register('firstName')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={loading}
                        variant='filled'
                        id="lastName"
                        label="Last name"
                        fullWidth
                        {...register('lastName')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={loading}
                        variant='filled'
                        id="email"
                        label="Email"
                        fullWidth
                        {...register('email')}
                        error={formState.errors.email !== undefined}
                        helperText={formState.errors.email?.message}
                    />
                </Grid>
            </Grid>
        </>
    )
}