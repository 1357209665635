import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { BlockData } from '../../redux/blocks/types';

interface Props {
    open: boolean,
    handleClose: () => void,
    handleSubmit: (name: string) => void,
}

export default function MediaRenameDialog(props: Props) {
    const { open, handleClose, handleSubmit } = props;
    const [folderName, setFolderName] = React.useState("");

    const handleCancel = React.useCallback(() => {
        setFolderName("");
        handleClose();
    }, [setFolderName, handleClose]);

    const handleRenameSubmit = React.useCallback(() => {
        handleSubmit(folderName)
        setFolderName("");
    }, [folderName, setFolderName]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Rename</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    onChange={(e) => setFolderName(e.target.value)}
                    value={folderName}
                    fullWidth
                    variant="filled"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button disabled={folderName.length < 1} onClick={handleRenameSubmit}>Rename</Button>
            </DialogActions>
        </Dialog>
    );
}