import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { produce } from "immer";
import step from "../../functions/step";
import {
  ActionType,
  AnyAction,
} from "../../redux/actionsequence/types/actions";
import { ActionConnection } from "../../redux/actionsequence/types/helpers/ActionConnection";
import lodash, { cloneDeep } from "lodash";
import EventContext, {
  initialEventContext,
  EventContext as EventContextType,
} from "../blockEvents/EventContext";

type ActionStatus =
  | "executing"
  | "waiting"
  | "finished"
  | "error"
  | "removed"
  | undefined;

export interface ActionState {
  status: ActionStatus;
  output?: unknown;
  error?: Error;
  durationMS?: number;
}

interface ExecutionMetadata {
  blockContext: number;
}

export interface ProcessState {
  isActive: boolean;
  actionStates: Record<string, ActionState>;
  variables: Record<string, unknown>;
  actions: AnyAction[];
  connections: ActionConnection[];
  args?: any;
  queue: string[];
  removed: string[];
  last?: string;
  metadata?: ExecutionMetadata;
}

export interface ExecutionContext {
  eventContext: EventContextType;
}

type ActionExecutionContext = {
  initialize(submission: ActionExecSubmission): Promise<void>;
  step(): Promise<false | ProcessState>;
  run(): Promise<void>;
  cancel(): Promise<void>;
  processState: ProcessState;
  context: ExecutionContext;
};

const initial: ActionExecutionContext = {
  initialize(_submission) {
    return Promise.reject(Error("NIMP"));
  },
  step() {
    return Promise.reject(Error("NIMP"));
  },
  cancel() {
    return Promise.reject(Error("NIMP"));
  },
  run() {
    return Promise.reject(Error("NIMP"));
  },
  processState: {
    isActive: false,
    actionStates: {},
    variables: {},
    connections: [],
    actions: [],
    queue: [],
    removed: [],
  },
  context: {
    // TODO:
    // - Add storage api
    // - Add other needed apis
    eventContext: initialEventContext,
  },
};

export const ActionExecutionContext = createContext(initial);

export type ActionExecSubmission = {
  metadata?: ExecutionMetadata;
  actions: AnyAction[];
  connections: ActionConnection[];
  args?: any;
};

const ActionExecutionContextProvider = (props: PropsWithChildren) => {
  const { children } = props;

  const [processState, setProcessState] = useState<ProcessState>({
    actionStates: {},
    variables: {},
    connections: [],
    actions: [],
    queue: [],
    removed: [],
    isActive: false,
  });

  const isRunning = useRef(false);
  const cancelRef = useRef(false);

  useEffect(() => {
    //@ts-ignore
    window.getDebugProcessState = () => {
      return processState;
    };
  }, [processState]);

  const eventContext = useContext(EventContext);

  const context: ExecutionContext = useMemo(
    () => ({ eventContext }),
    [eventContext]
  );

  const handleInit = useCallback(
    async (subm: ActionExecSubmission) => {
      setProcessState(
        produce((state) => {
          state.actionStates = subm.actions.reduce(
            (obj: Record<string, ActionState>, a: AnyAction) => {
              obj[a.id] = {
                status: "waiting",
              };
              return obj;
            },
            {}
          );
          state.actions = subm.actions;
          state.connections = subm.connections;
          const start = subm.actions.find((x) => x.type === ActionType.Start);
          if (!start) {
            throw new Error("No start action found");
          }
          state.queue = [start.id];
          state.isActive = true;
          state.metadata = subm.metadata;
          state.args = subm.args;
          return state;
        })
      );
    },
    [setProcessState]
  );

  const handleCancel = useCallback(async () => {
    if (isRunning.current) {
      cancelRef.current = true;
      return;
    }
    setProcessState(
      produce((state) => {
        state.isActive = false;
        Object.keys(state.actionStates).forEach((k) => {
          state.actionStates[k].status = undefined;
        });
        state.variables = {};
        state.queue = [];
        state.removed = [];
        state.actions = [];
        state.connections = [];
        return state;
      })
    );
  }, [setProcessState, isRunning.current]);

  const handleStep = useCallback(async () => {
    const state = lodash.cloneDeep(processState);
    const act = state.queue.find(Boolean);
    if (!act) {
      console.log("No action to step");
      return state;
    }
    setProcessState(
      produce((s) => {
        s.actionStates[act].status = "executing";
        return s;
      })
    );
    try {
      const newState = await step(state, context);
      setProcessState(newState);
      if (newState.queue.length <= 0) {
        return cloneDeep(newState);
      }
      return false;
    } catch (err) {
      setProcessState(
        produce((s) => {
          s.actionStates[act].status = "error";
          return s;
        })
      );
      throw err;
    }
  }, [setProcessState, processState, context]);

  const handleRun = useCallback(async () => {
    isRunning.current = true;
    let finished = false;
    let state = lodash.cloneDeep(processState);
    while (!finished && !cancelRef.current) {
      const act = state.queue.find(Boolean);
      if (!act) {
        return;
      }
      await new Promise<void>((res) => setTimeout(() => res(), 100)); // Padding -> time does not really matter in debug mode
      setProcessState(
        produce((s) => {
          s.actionStates[act].status = "executing";
          return s;
        })
      );
      try {
        state = await step(state, context);
        setProcessState(state);
      } catch (err) {
        setProcessState(
          produce((s) => {
            s.actionStates[act].status = "error";
            return s;
          })
        );
        break;
      }
    }
    isRunning.current = false;
    if (cancelRef.current) {
      cancelRef.current = false;
      handleCancel();
    }
  }, [
    processState,
    handleCancel,
    setProcessState,
    isRunning.current,
    cancelRef.current,
    context,
  ]);

  const value: ActionExecutionContext = useMemo(
    () => ({
      initialize: handleInit,
      step: handleStep,
      cancel: handleCancel,
      run: handleRun,
      processState,
      context,
    }),
    [handleInit, handleStep, handleCancel, handleRun, context, processState]
  );

  return (
    <ActionExecutionContext.Provider value={value}>
      {children}
    </ActionExecutionContext.Provider>
  );
};

export default ActionExecutionContextProvider;
