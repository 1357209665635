import { Add, Save, Cancel, Edit, Delete, DeleteOutlined, LanguageOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridEventListener, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, MuiEvent, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { createLanguage, defaultLanguage, deleteLanguage, getLanguage, getTranslationTags, selectLanguagesByProjectId, updateLanguage } from '../../../redux/language/languageSlice';
import ConfirmationPopover from '../../utils/ConfirmationPopover';
import StyledDataGrid from '../../utils/StyledDataGrid';
import TitleBox from '../../widgets/TitleBox';
import yup from '../../../validation/yup';
import SnackBarOperations from '../../../components/SnackBar/SnackBarOperations';
import { Language } from '../../../redux/language/types';
import DeleteConfirmationDialog from '../../utils/DeleteConfirmationDialog';

interface Props {
    projectId: number
}

export default function LanguageSettings(props: Readonly<Props>) {
    const { projectId } = props;

    const [languageConfirmationPopoverOpen, setLanguageConfirmationPopoverOpen] = useState(false);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const [selectedLanguageIds, setSelectedLanguageIds] = React.useState<GridRowSelectionModel>([]);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const languages = useAppSelector(selectLanguagesByProjectId(projectId));

    React.useEffect(() => {
        dispatch(getLanguage(projectId));
        dispatch(getTranslationTags(projectId));
    }, []);

    const handleRowEditStart = React.useCallback((
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = React.useCallback((params, event) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleEditClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }, [setRowModesModel, rowModesModel]);

    const handleSaveClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }, [setRowModesModel, rowModesModel]);

    const handleCancelClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    }, [setRowModesModel, rowModesModel]);

    const handleDeleteClick = React.useCallback((id: GridRowId) => () => {
        dispatch(deleteLanguage(Number(id)))
    }, [dispatch]);

    const getLanguageUniqueSchema = React.useCallback((id: number) => yup.object().shape({
        name: yup.string().required('Name is required.'),
        abbreviation: yup.string()
            .unique('Language abbreviation already exists (abbreviation must be unique).', (val: string) => !languages.filter(x => x.id !== id).map(x => x.abbreviation).includes(val)),
    }), [languages]);

    const processRowUpdate = React.useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };

        try {
            await getLanguageUniqueSchema(newRow.id).validate(updatedRow, { abortEarly: false })
            await dispatch(updateLanguage(newRow as Language));
        }
        catch (err) {
            if (err instanceof yup.ValidationError) {
                err.errors.forEach((strErr) => SnackBarOperations.error(strErr));
                throw err;
            }
        }
        return updatedRow;
    }, [dispatch, getLanguageUniqueSchema, rowModesModel, setRowModesModel]);

    const languageDatatableColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
            editable: true,
        },
        {
            field: 'abbreviation',
            headerName: 'Abbreviation',
            minWidth: 100,
            flex: 1,
            editable: true,
        },
        {
            field: 'defaultLanguage',
            headerName: 'default',
            width: 75,
            editable: true,
            type: 'boolean'
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key="save-language"
                            icon={<Save />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key="cancel"
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Edit />}
                        key="edit-language"
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleDeleteClick(id)}>
                        <GridActionsCellItem
                            key="delete-language"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        },
    ];

    const handleLanguageConfirmationPopoverSubmit = React.useCallback(() => {
        selectedLanguageIds.forEach(x => void dispatch(deleteLanguage(Number(x))));
        setLanguageConfirmationPopoverOpen(false);
    }, [selectedLanguageIds, setSelectedLanguageIds, setLanguageConfirmationPopoverOpen, dispatch]);

    const handleLanguageAdd = React.useCallback(() => {
        dispatch(createLanguage({ ...defaultLanguage, projectId }));
    }, [dispatch, projectId]);

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Languages'
                subTitle='Lorem ipsum'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>
                <ConfirmationPopover
                    handleClose={() => setLanguageConfirmationPopoverOpen(false)}
                    handleSubmit={handleLanguageConfirmationPopoverSubmit}
                    open={languageConfirmationPopoverOpen}>
                    <IconButton component="label" disabled={selectedLanguageIds.length < 1} onClick={() => setLanguageConfirmationPopoverOpen(true)}>
                        <DeleteOutlined />
                    </IconButton>
                </ConfirmationPopover>
                <Button variant="contained" onClick={handleLanguageAdd} sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />}>
                    ADD Language
                </Button>
            </TitleBox>
            <Box sx={{ height: '600px', width: '100%', mb: theme.spacing(2) }}>
                <StyledDataGrid
                    rows={languages}
                    columns={languageDatatableColumns}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    onRowSelectionModelChange={(newSM) => {
                        setSelectedLanguageIds(newSM);
                    }}
                    editMode='row'
                    rowModesModel={rowModesModel}
                    rowSelectionModel={selectedLanguageIds}
                    onRowModesModelChange={setRowModesModel}
                    disableRowSelectionOnClick
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                />
            </Box>
        </>
    )
}