import { LanguageOutlined, DeleteOutlined, Download } from '@mui/icons-material';
import { Box, Button, IconButton, Paper, useTheme } from '@mui/material';
import { GridRowSelectionModel, GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectRequests, getRequests, createRequest, defaultRequest, deleteRequest } from '../../redux/requests/requestSlice';
import ConfirmationPopover from '../utils/ConfirmationPopover';
import MorePopover from '../utils/MorePopOver';
import StyledDataGrid from '../utils/StyledDataGrid';
import TitleBox from '../widgets/TitleBox';
import { DeploymentData } from '../../redux/project/types';
import { deleteDeployments } from '../../redux/project/projectSlice';
import DeleteConfirmationDialog from '../utils/DeleteConfirmationDialog';

interface Props {
    deployments: DeploymentData[]
    projectId: number
}

export default function Deployments(props: Props) {
    const { deployments, projectId } = props;
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);

    const theme = useTheme();
    const dispatch = useAppDispatch();

    const handleDeploymentDelete = React.useCallback(() => {
        selectedIds.forEach(x => dispatch(deleteDeployments({ id: Number(x), projectId })));
    }, [selectedIds, dispatch])

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: 'Created',
            minWidth: 100,
            flex: 1,
            renderCell(params) {
                return new Date(params.value).toLocaleString()
            },
        },
        {
            field: 'path',
            headerName: '',
            width: 75,
            renderCell(params) {
                return (
                    <a href={params.value}>
                        <IconButton>
                            <Download />
                        </IconButton>
                    </a>
                );
            }
        }
    ];

    return (
        <Paper sx={{ padding: theme.spacing(2) }}>
            <TitleBox
                mainTitle='Deployments'
                subTitle='Lorem ipsum'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>
                <DeleteConfirmationDialog onDeleteConfirm={handleDeploymentDelete}>
                    <IconButton component="label">
                        <DeleteOutlined />
                    </IconButton>
                </DeleteConfirmationDialog>
            </TitleBox>
            <Box sx={{ height: '500px', width: '100%' }}>
                <StyledDataGrid
                    rows={deployments}
                    columns={columns}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    onRowSelectionModelChange={(newSM) => {
                        setSelectedIds(newSM);
                    }}
                    rowSelectionModel={selectedIds}
                    disableRowSelectionOnClick
                    loading={false}
                />
            </Box>
        </Paper>
    )
}