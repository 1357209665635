import { FilledInput, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Switch, TextField, useTheme } from '@mui/material';
import React from 'react';
import useRouteParam from '../../../../../hooks/useRouteParam';
import { selectDynData } from '../../../../../redux/dyndata/dyndataSlice';
import { BackendError, useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { selectPageById, selectPages, updatePage } from '../../../../../redux/page/pageSlice';
import { PageData } from '../../../../../redux/page/types';
import yup from '../../../../../validation/yup';
import SnackBarOperations from '../../../../../components/SnackBar/SnackBarOperations';
import { debounce } from 'lodash';

export default function PageDetailInputs() {

    const [currentPage, setCurrentPage] = React.useState<PageData | undefined>();

    const refId = useRouteParam('refId');
    const selectedPage = useAppSelector(selectPageById(refId));
    const dataSets = useAppSelector(selectDynData);
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const projectPages = useAppSelector(selectPages)

    React.useEffect(() => {
        setCurrentPage(selectedPage);
    }, [selectedPage])

    const getPageValidationSchema = React.useCallback((id: number) => yup.object().shape({
        name: yup.string().required('Page name is required.').unique('Page name must be unique in project', (pageName: string) => !projectPages.filter(x => x.id !== id).map(x => x.name).includes(pageName))
    }), [projectPages])

    const handlePageSave = React.useCallback(async (page: PageData) => {
        try {
            await getPageValidationSchema(page.id).validate(page)
            await dispatch(updatePage(page));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error deleting header: ${err.message}`);
            }
            console.error(err);
        }
    }, [currentPage, dispatch, getPageValidationSchema]);

    const debouncedChangeHandler = React.useCallback(
        debounce(handlePageSave, 500),
        [handlePageSave],
    );

    const handlePageChange = React.useCallback((page: PageData) => {
        setCurrentPage(page);
        debouncedChangeHandler(page);
    }, [setCurrentPage]);


    const handleToggleChange = React.useCallback((value: boolean) => {
        if (currentPage) {
            dispatch(updatePage({ ...currentPage, dynamic: value }));
        }
    }, [currentPage, dispatch]);

    const handleDataSetChange = React.useCallback((dataSetId: number) => {
        const dataSet = dataSets.find(x => x.id === dataSetId);
        if (currentPage) {
            setCurrentPage({ ...currentPage, dynDataSet: dataSet });
        }
    }, [setCurrentPage, dataSets, currentPage]);

    if (currentPage) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        id="page-name"
                        label="Name"
                        placeholder="Home"
                        fullWidth
                        variant="filled"
                        value={currentPage.name}
                        onChange={(e) => handlePageChange({ ...currentPage as PageData, name: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="page-meta-title"
                        label="Meta title"
                        placeholder="Meta title"
                        multiline
                        fullWidth
                        minRows={3}
                        variant="filled"
                        value={currentPage.metaTitle}
                        onChange={(e) => handlePageChange({ ...currentPage as PageData, metaTitle: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="page-meta-description"
                        label="Meta description"
                        placeholder="Meta description"
                        multiline
                        fullWidth
                        minRows={7}
                        variant="filled"
                        value={currentPage.metaDescription}
                        onChange={(e) => handlePageChange({ ...currentPage as PageData, metaDescription: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel sx={{ margin: 0 }} control={<Switch checked={currentPage.dynamic} onChange={(e, checked) => handleToggleChange(checked)} />} labelPlacement="start" label="Dynamic" />
                </Grid>
                {
                    currentPage.dynamic ? (
                        <Grid item xs={12}>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel id="loop-block-label">Mode</InputLabel>
                                <Select
                                    id='loop-block-select'
                                    labelId="loop-block-label"
                                    value={currentPage?.dynDataSet?.id ?? 0}
                                    fullWidth
                                    input={<FilledInput />}
                                    size='small'
                                    onChange={(e) => handleDataSetChange(Number(e.target.value))}
                                >
                                    <MenuItem value="0">None</MenuItem>
                                    {
                                        dataSets?.map((data) => <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    ) : null
                }
            </Grid>
        )
    } else {
        return <></>
    }
}