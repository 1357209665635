import { LanguageOutlined, Add, DeleteOutlined, Save, Cancel, Edit, Delete } from '@mui/icons-material';
import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridEventListener, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, MuiEvent, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useContext } from 'react';
import useRouteParam from '../../../hooks/useRouteParam';
import { BackendError, useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ConfirmationPopover from '../../utils/ConfirmationPopover';
import TitleBox from '../../widgets/TitleBox';
import StyledDataGrid from '../../utils/StyledDataGrid';
import { defaultHeaderEntry, defaultRequest, getRequests, selectRequestById, selectRequests, updateRequest } from '../../../redux/requests/requestSlice';
import { RequestHeaderEntry, RequestType } from '../../../redux/requests/types';
import yup from '../../../validation/yup';
import { ProjectContext } from '../context/ProjectContext';
import SnackBarOperations from '../../../components/SnackBar/SnackBarOperations';
import DeleteConfirmationDialog from '../../utils/DeleteConfirmationDialog';

export default function RequestForm() {

    const requestId = useRouteParam('requestId');
    const {
        id: projectId,
    } = useContext(ProjectContext)
    const theme = useTheme();
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [headerConfirmationPopoverOpen, setHeaderConfirmationPopoverOpen] = React.useState(false);
    const [editRequest, setEditRequest] = React.useState(defaultRequest);

    const currentRequest = useAppSelector(selectRequestById(requestId));

    const projectRequests = useAppSelector(selectRequests)

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (currentRequest)
            setEditRequest({ ...currentRequest });
    }, [currentRequest])

    React.useEffect(() => {
        dispatch(getRequests(projectId));
    }, [projectId])

    const handleRowEditStart = React.useCallback((
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = React.useCallback((params, event) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleEditClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }, []);

    const handleSaveClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }, []);

    const getRequestValidationSchema = React.useCallback((id: number) => yup.object().shape({
        name: yup.string().required('Request name is required.').unique('Request name must be unique in the project', (name: string) => !projectRequests.filter(x => x.id !== id).map(x => x.name).includes(name)),
        uri: yup.string().url('Request url must be a valid url'),
        header: yup.array().of(yup.object().shape({
            name: yup.string().required('Header name is required'),
            value: yup.string().required('Header value is required'),
        })).uniqueByProp('Header names must be unique.', 'name')
    }), [projectRequests]);

    const handleDeleteClick = React.useCallback((id: GridRowId) => async () => {
        const updated = { ...currentRequest, header: [...currentRequest.header.filter(x => x.id !== id)] };
        try {
            await dispatch(updateRequest(updated));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error deleting header: ${err.message}`);
            }
        }
    }, [dispatch, currentRequest]);

    const handleCancelClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    }, [setRowModesModel, rowModesModel]);

    const processRowUpdate = React.useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };

        const headerEntry = newRow as RequestHeaderEntry;

        try {
            const updated = { ...currentRequest, header: [...currentRequest.header.filter(x => x.id !== headerEntry.id), headerEntry] }
            await getRequestValidationSchema(currentRequest.id).validate(updated)
            await dispatch(updateRequest(updated));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
                throw err;
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error updating header: ${err.message}`);
            }
            console.error(err);
        }

        return updatedRow;
    }, [dispatch, currentRequest, getRequestValidationSchema]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
            editable: true
        },
        {
            field: 'value',
            headerName: 'Value',
            minWidth: 100,
            flex: 1,
            editable: true
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Save />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleDeleteClick(id)}>
                    <GridActionsCellItem
                        icon={<Delete />}
                        label="Delete"
                        disabled={row.isDefault}
                        color="inherit"
                    />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];

    const handleHeaderEntryConfirmationPopoverSubmit = React.useCallback(async () => {
        const headers = editRequest.header.filter(x => !selectedIds.includes(x.id));
        try {
            const updated = { ...editRequest, header: headers };
            await getRequestValidationSchema(editRequest.id).validate(updated)
            await dispatch(updateRequest(updated));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error deleting header: ${err.message}`);
            }
            console.error(err);
        }
    }, [editRequest, selectedIds, dispatch, getRequestValidationSchema]);

    const handleRequestChange = React.useCallback(async () => {
        try {
            await getRequestValidationSchema(editRequest.id).validate(editRequest)
            await dispatch(updateRequest(editRequest));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error deleting header: ${err.message}`);
            }
            console.error(err);
        }
    }, [dispatch, editRequest, getRequestValidationSchema]);

    const handleHeaderEntryAdd = React.useCallback(() => {
        let changedRequest = { ...editRequest };
        changedRequest.header = [...changedRequest.header, { ...defaultHeaderEntry }];
        dispatch(updateRequest(changedRequest));
    }, [editRequest, dispatch]);

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Edit Request'
                subTitle='Lorem ipsum' />

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{
                        width: '100%',
                        padding: theme.spacing(2),
                        '&>*': {
                            marginRight: `${theme.spacing(1)} !important`,
                        }
                    }}>
                        <TextField sx={{ width: '250px' }} id="request-name" label="Name" variant="filled" value={editRequest.name} onChange={(e) => setEditRequest({ ...editRequest, name: e.target.value })} onBlur={handleRequestChange} />
                        <TextField sx={{ width: '250px' }} id="request-uri" label="Uri" variant="filled" value={editRequest.uri} onChange={(e) => setEditRequest({ ...editRequest, uri: e.target.value })} onBlur={handleRequestChange} />
                        <FormControl variant="filled" sx={{ width: '250px' }} >
                            <InputLabel id="request-method">Type</InputLabel>
                            <Select
                                labelId="request-method"
                                value={editRequest.method}
                                onChange={(e) => setEditRequest({ ...editRequest, method: e.target.value as RequestType })}
                            >
                                {Object.values(RequestType).map((requestType) => (
                                    <MenuItem key={requestType} value={requestType}>
                                        {requestType}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ height: '600px', width: '100%', padding: theme.spacing(2) }}>
                        <TitleBox
                            mainTitle='Header'
                            subTitle='Lorem ipsum'
                            maintitleVariant='h6'
                            subTitleVariant='subtitle2'>
                            <ConfirmationPopover
                                handleClose={() => setHeaderConfirmationPopoverOpen(false)}
                                handleSubmit={handleHeaderEntryConfirmationPopoverSubmit}
                                open={headerConfirmationPopoverOpen}>
                                <IconButton component="label" disabled={selectedIds.length < 1} onClick={() => setHeaderConfirmationPopoverOpen(true)}>
                                    <DeleteOutlined />
                                </IconButton>
                            </ConfirmationPopover>
                            <Button variant="contained" onClick={handleHeaderEntryAdd} sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />}>
                                ADD HEADER ENTRY
                            </Button>
                        </TitleBox>
                        <StyledDataGrid
                            rows={currentRequest?.header ?? []}
                            columns={columns}
                            pageSizeOptions={[10]}
                            checkboxSelection
                            onRowSelectionModelChange={(newSM) => {
                                setSelectedIds(newSM);
                            }}
                            editMode='row'
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={setRowModesModel}
                            onRowEditStart={handleRowEditStart}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                            rowSelectionModel={selectedIds}
                            disableRowSelectionOnClick
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}