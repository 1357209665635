import React, { ReactNode } from 'react';
import { Container } from 'react-grid-system';
import { BlockData, ScreenMode } from '../../../redux/blocks/types';
/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
interface Props {
    blockData: BlockData,
    classes: string,
    blockClickHandler: (e: React.MouseEvent<HTMLElement>) => void,
    children: ReactNode,
    styles: any,
    canvasMode: ScreenMode,
    id: string,
}

const ContainerBlock = (props: Props) => {
    const { canvasMode, children, blockData, classes, blockClickHandler, styles, id } = props;
    let maxWidth: string = "";
    switch (canvasMode) {
        case ScreenMode.sm:
            maxWidth = "540px";
            break;
        case ScreenMode.sm:
            maxWidth = "720px";
            break;
        case ScreenMode.lg:
            maxWidth = "960px";
            break;
        case ScreenMode.xl:
            maxWidth = "1140px";
            break;
        default:
            maxWidth = "";
            break;
    }

    return (
        <Container id={id} css={{...styles, maxWidth}} className={classes} onClick={blockClickHandler}>
            { children }
        </Container>
    );
}

export default ContainerBlock;