import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import * as React from 'react';

interface Props {
    title: string;
    children: any;
}

export default function GridSelectList(props: Props) {

    const { title, children } = props;

    const [open, setOpen] = React.useState(false);

    const onOpenToggle = React.useCallback(() => {
        setOpen(x => !x);
    }, [setOpen]);

    return (
        <List dense>
            <ListItemButton onClick={onOpenToggle}>
                <ListItemText primary={title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open}>
                {children}
            </Collapse>
        </List>
    )
}