import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import { Language, Translation, TranslationTag } from "./types";

const PREFIX = "config";

const prefix = (str: string) => `${PREFIX}/${str}`;

export interface LanguageState {
  languages: Language[];
  translationTags: TranslationTag[];
}

export const initialState: LanguageState = {
  languages: [],
  translationTags: [],
};

export const defaultLanguage: Language = {
  id: 0,
  name: "newLanguage",
  abbreviation: "new",
  defaultLanguage: false,
};
export const defaultTag: TranslationTag = {
  id: 0,
  name: "newTag",
  translations: [],
};

export const getTranslationTags = createAppAsyncThunk(
  prefix("getTranslationTags"),
  async (projectId: number) => {
    const response = await axios.get<TranslationTag[]>(
      `/language/tag/${projectId}`
    );
    return response.data;
  }
);

export const createTranslationTag = createAppAsyncThunk(
  prefix("createTranslationTag"),
  async (translationTag: TranslationTag) => {
    const response = await axios.post<TranslationTag>(
      "/language/tag",
      translationTag
    );
    return response.data;
  }
);

export const updateTranslationTag = createAppAsyncThunk(
  prefix("updateTranslationTag"),
  async (translationTag: TranslationTag) => {
    const response = await axios.post<TranslationTag>(
      "/language/tag/update",
      translationTag
    );
    return response.data;
  }
);

export const deleteTranslationTag = createAppAsyncThunk(
  prefix("deleteTranslationTag"),
  async (id: number) => {
    await axios.delete(`/language/tag/${id}`);
    return id;
  }
);

export const getLanguage = createAppAsyncThunk(
  prefix("getLanguages"),
  async (projectId: number) => {
    const response = await axios.get<Language[]>(`/language/${projectId}`);
    return response.data;
  }
);

export const createLanguage = createAppAsyncThunk(
  prefix("createLanguage"),
  async (language: Language) => {
    const response = await axios.post<Language>("/language", language);
    return response.data;
  }
);

export const updateLanguage = createAppAsyncThunk(
  prefix("updateLanguage"),
  async (language: Language) => {
    const response = await axios.post<Language>("/language/update", language);
    return response.data;
  }
);

export const deleteLanguage = createAppAsyncThunk(
  prefix("deleteLanguage"),
  async (id: number) => {
    await axios.delete(`/language/${id}`);
    return id;
  }
);

const languageSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    setLanguages(state, action: PayloadAction<Language[]>) {
      state.languages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTranslationTags.fulfilled, (state, action) => {
      state.translationTags = action.payload;
    });

    builder.addCase(createTranslationTag.fulfilled, (state, action) => {
      state.translationTags.push(action.payload);
    });

    builder.addCase(updateTranslationTag.fulfilled, (state, action) => {
      const index = state.translationTags.findIndex(
        (x) => x.id === action.payload.id
      );
      state.translationTags[index] = action.payload;
    });

    builder.addCase(deleteTranslationTag.fulfilled, (state, action) => {
      state.translationTags = state.translationTags.filter(
        (x) => x.id !== action.payload
      );
    });

    builder.addCase(getLanguage.fulfilled, (state, action) => {
      state.languages = action.payload;
    });

    builder.addCase(createLanguage.fulfilled, (state, action) => {
      state.languages.push(action.payload);
    });

    builder.addCase(updateLanguage.fulfilled, (state, action) => {
      const index = state.languages.findIndex(
        (x) => x.id === action.payload.id
      );
      if (index >= 0) {
        state.languages[index] = action.payload;
      }
    });

    builder.addCase(deleteLanguage.fulfilled, (state, action) => {
      state.languages = state.languages.filter((x) => x.id !== action.payload);
    });

    // base cases

    // logs error if a request is rejected
    builder.addMatcher(isRejected, (_, action) => {
      console.error(action.error.message);
    });
  },
});

// selectors
export const selectTagsByProjectId =
  (projectId: number) =>
  (rootState: RootState): TranslationTag[] =>
    rootState.language.translationTags.filter((x) => x.projectId === projectId);
export const selectLanguagesByProjectId =
  (projectId: number) =>
  (rootState: RootState): Language[] =>
    rootState.language.languages.filter((x) => x.projectId === projectId);
export const selectTranslationTags = (rootState: RootState): TranslationTag[] =>
  rootState.language.translationTags;
export const selectLanguages = (rootState: RootState): Language[] =>
  rootState.language.languages;

export default languageSlice.reducer;
