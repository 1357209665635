import { PortraitOutlined } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Paper, Avatar, Typography, Grid, Divider, Skeleton, Box, Tab, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import useRouteParam from '../../hooks/useRouteParam';
import { selectUserCan } from '../../redux/auth/authSlice';
import { useAppSelector } from '../../redux/hooks';
import { selectProjectById } from '../../redux/project/projectSlice';
import TitleBox from '../widgets/TitleBox';
import DynamicDataModelSettings from './ProjectSettingsTab/DynamicDataModel';
import DynamicDataSetSets from './ProjectSettingsTab/DynamicDataSets';
import LanguageSettings from './ProjectSettingsTab/LanguageSettings';
import Fonts from './ProjectSettingsTab/Fonts';
import MailSettings from './ProjectSettingsTab/MailSettings';
import ProjectUsersAndRoles from './ProjectSettingsTab/ProjectUsersAndRoles';
import TranslationTagSettings from './ProjectSettingsTab/TranslationTagSettings';
import Requests from './ProjectSettingsTab/Requests';
import DataEntriesTab from './ProjectSettingsTab/DataEntriesTab';

export default function ProjectSettings(){

    const theme = useTheme();
    const navigate = useNavigate();
    
    const projectId = useRouteParam('projectId');
    const tabId = useRouteParam('tabId').toString();

    const project = useAppSelector(selectProjectById(projectId));

    const canAccessUsers = useAppSelector(selectUserCan('VIEW', 'USER'))

    const projectDetailsCard = React.useMemo(() => {
        const paperStyles = { 
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        };
        const avatarStyles = {
            width: 100,
            height: 100,
            mb: theme.spacing(2),
        };

        if (project) {
            return (
                <Paper sx={paperStyles}>
                    <Avatar 
                        src="/placeholder.JPG"
                        alt="placeholder"
                        sx={avatarStyles}
                    />
                    <Typography sx={{ fontWeight: 'bold' }} variant='h6'>{project.name}</Typography>
                    <Typography sx={{ mb: theme.spacing(4) }} variant='subtitle1'>pages: {project?.pages?.length ?? 0}</Typography>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Test</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>asdfasdf</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Test</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>asdfasdf</Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ width: '100%', mt: theme.spacing(4), mb: theme.spacing(4) }}/>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Test</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Test</Typography>
                        </Grid>
                    </Grid>
                </Paper>);
        } else {
            return (
                <Paper sx={paperStyles}>
                    <Skeleton variant='circular' sx={avatarStyles} />
                    <Skeleton variant='text' />
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Test</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton variant='text' />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Test</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton variant='text' />
                        </Grid>
                    </Grid>
                    <Divider sx={{ width: '100%', mt: theme.spacing(4), mb: theme.spacing(4) }}/>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Test</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>Test</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Test</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            )
        }
    }, [project, theme]);

    const onTabValueChange = React.useCallback((event: React.SyntheticEvent, newValue: string) => {
        navigate(`/project-detail/${projectId}/settings/${newValue}`);
    }, [projectId]);

    return (
        <>
            <TitleBox
                avatarIcon={<PortraitOutlined />}
                mainTitle='Project administration'
                subTitle='Edit project settings'
                key={'project-detail-settings-page'}
            />
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    {projectDetailsCard}
                </Grid>
                <Grid item xs={8}>
                    <Paper sx={{ width: '100%' }}>
                        <TabContext value={tabId}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList value={tabId} variant="fullWidth" onChange={onTabValueChange}>
                                    <Tab label="EMAIL" value="0" />
                                    <Tab label="LANGUAGE" value="1" />
                                    <Tab label="TRANSLATION" value="2" />
                                    <Tab label="MODELS" value="3" />
                                    <Tab label="DATA SETS" value="4" />
                                    <Tab label="DATA ENTRIES" value="5" />
                                    <Tab disabled={!canAccessUsers} label="USERS" value="6" />
                                    <Tab label="REQUESTS" value="7" />
                                    <Tab label="FONTS" value="8" />
                                </TabList>
                            </Box>
                            <TabPanel value='0'>
                                <MailSettings projectId={projectId} />
                            </TabPanel>
                            <TabPanel value='1'>
                                <LanguageSettings projectId={projectId} />
                            </TabPanel>
                            <TabPanel value='2'>
                                <TranslationTagSettings projectId={projectId} />
                            </TabPanel>
                            <TabPanel value='3'>
                                <DynamicDataModelSettings projectId={projectId} />
                            </TabPanel>
                            <TabPanel value='4'>
                                <DynamicDataSetSets projectId={projectId} />
                            </TabPanel>
                            <TabPanel value='5'>
                                <DataEntriesTab projectId={projectId} />
                            </TabPanel>
                            {
                                canAccessUsers && (
                                    <TabPanel value='6'>
                                        <ProjectUsersAndRoles projectId={projectId} /> 
                                    </TabPanel>
                                )
                            }
                            <TabPanel value='7'>
                                <Requests projectId={projectId}/>
                            </TabPanel>
                            <TabPanel value='8'>
                                <Fonts projectId={projectId} />
                            </TabPanel>
                        </TabContext>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}