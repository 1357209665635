/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React from 'react';

const DynamicBlock = React.forwardRef(({ tagName, children, ...props }, ref) => {
  if (typeof tagName !== 'string') {
    console.error("tagName must be a string");
    return null;
  }

  const Tag = tagName;

  return <Tag ref={ref} {...props}>{children}</Tag>;
});

export default DynamicBlock;
