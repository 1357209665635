import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Colors } from '../../theme/Colors';

export default function LoadingScreen() {

  return (
    <div>
      <Backdrop
        sx={{ color: Colors.primary_main, zIndex: 9999, backgroundColor: '#fff' }}
        open={true}
      >
        <CircularProgress color="inherit" sx={{ width: '100px', height: '100px' }}/>
      </Backdrop>
    </div>
  );
}