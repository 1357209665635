import { Add, DeleteOutlined, TranslateOutlined, LanguageOutlined, MoreVert, Delete, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, List, ListItem, ListItemButton, ListItemText, Popover, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { createDataEntry, createModel, defaultEntry, defaultModel, deleteDataEntry, deleteModel, getDataEntries, getModels, selectDynModels, selectEntries } from '../../../redux/dyndata/dyndataSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ConfirmationPopover from '../../utils/ConfirmationPopover';
import MorePopover from '../../utils/MorePopOver';
import StyledDataGrid from '../../utils/StyledDataGrid';
import TitleBox from '../../widgets/TitleBox';
import DeleteConfirmationDialog from '../../utils/DeleteConfirmationDialog';

interface Props {
    projectId: number
}

export default function DynamicDataEntrySettings(props: Props) {
    const { projectId } = props;
    const [entryConfirmationPopoverOpen, setEntryConfirmationPopoverOpen] = useState(false);
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const entries = useAppSelector(selectEntries);

    React.useEffect(() => {
        dispatch(getDataEntries(projectId));
    }, [])

    const handleEntryCreate = React.useCallback(() => {
        dispatch(createDataEntry({ ...defaultEntry, projectId }))
            .unwrap()
            .then(payload => {
                navigate(`/project-detail/${projectId}/settings/entry/${payload.id}`)
            });
    }, [dispatch, projectId, navigate]);

    const handleEntryDelete = React.useCallback((id: number) => {
        dispatch(deleteDataEntry(id));
    }, [dispatch]);

    const handleEntryEdit = React.useCallback((id: number) => {
        navigate(`/project-detail/${projectId}/settings/entry/${id}`);
    }, [navigate, projectId]);

    const handleEntryConfirmationPopoverSubmit = React.useCallback(() => {
        selectedIds.forEach(x => dispatch(deleteDataEntry(Number(x))));
        setEntryConfirmationPopoverOpen(false);
    }, [selectedIds, setEntryConfirmationPopoverOpen, dispatch])

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                return [
                    <GridActionsCellItem
                        key="edit"

                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={() => handleEntryEdit(Number(id))}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={() => handleEntryDelete(Number(id))}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}                            
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        },
    ];

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Entries'
                subTitle='Lorem ipsum'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>
                <ConfirmationPopover
                    handleClose={() => setEntryConfirmationPopoverOpen(false)}
                    handleSubmit={handleEntryConfirmationPopoverSubmit}
                    open={entryConfirmationPopoverOpen}>
                    <IconButton component="label" onClick={() => setEntryConfirmationPopoverOpen(true)}>
                        <DeleteOutlined />
                    </IconButton>
                </ConfirmationPopover>
                <Button variant="contained" sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />} onClick={handleEntryCreate}>
                    ADD ENTRY
                </Button>
            </TitleBox>
            <Box sx={{ height: '600px', width: '100%', mb: theme.spacing(2) }}>
                <StyledDataGrid
                    rows={entries}
                    columns={columns}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    onRowSelectionModelChange={(newSM) => {
                        setSelectedIds(newSM);
                    }}
                    rowSelectionModel={selectedIds}
                    disableRowSelectionOnClick
                    loading={false}
                />
            </Box>
        </>
    )
}