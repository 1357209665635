import React from 'react';
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import { TableRows, ViewWeek } from '@mui/icons-material';
import { Grid, ToggleButtonGroup, ToggleButton, Switch, Tooltip } from '@mui/material';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import { isStyleInheritedAndChanged } from '../../../../../../Utility';
import { BlockStyleAttribute } from '../BlockStyleInputs';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void, 
    selectedBlock?: BlockData,
}

export default function GridAutoFlowInputs(props: Props) {
    const { currentBlockStyle, handleAttributeChange } = props;

    const isRow = currentBlockStyle.blockStyleData.find(x => x.attributeKey === 'grid-auto-flow')?.attributeValue?.includes('row') ?? true;
    const isDense = currentBlockStyle.blockStyleData.find(x => x.attributeKey === 'grid-auto-flow')?.attributeValue?.includes('dense') ?? false;

    const changeDenseHandler = React.useCallback((value: boolean) => {
        const newValue = `${isRow ? 'row' : 'column'}${value ? ' dense' : ''}`;
        handleAttributeChange([{attributeKey: 'grid-auto-flow', attributeValue: newValue}], true);
    }, [handleAttributeChange, isRow])

    const changeDirectionHandler = React.useCallback((value: string) => {
        const newValue = `${value}${value ? ' dense' : ''}`;
        handleAttributeChange([{attributeKey: 'grid-auto-flow', attributeValue: newValue}], true);
    }, [handleAttributeChange])

    return (
        <>
            <BlockStyleDataLabel name="Direction" />
            <Grid item xs={4}>
                <ToggleButtonGroup
                    value={isRow ? 'row' : 'column'}
                    onChange={(e, value) => changeDirectionHandler(value)}
                    exclusive={true}
                    size='small'
                    fullWidth>
                    <ToggleButton value="row" key="row">
                        <Tooltip title="">
                            <TableRows />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="column" key="column">
                        <ViewWeek />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <BlockStyleDataLabel name="Dense" />
            <Grid item xs={4}>
                <Switch
                    checked={isDense}
                    onChange={(e, checked) => changeDenseHandler(checked)}
                    aria-label="Dense"/>
            </Grid>
        </>
    )
}