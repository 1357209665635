import { Add, DeleteOutlined, TranslateOutlined, Save, Cancel, Edit, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridEventListener, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, MuiEvent, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { createTranslationTag, defaultTag, deleteTranslationTag, getLanguage, getTranslationTags, selectLanguagesByProjectId, selectTagsByProjectId, updateTranslationTag } from '../../../redux/language/languageSlice';
import ConfirmationPopover from '../../utils/ConfirmationPopover';
import StyledDataGrid from '../../utils/StyledDataGrid';
import TitleBox from '../../widgets/TitleBox';
import yup from '../../../validation/yup';
import SnackBarOperations from '../../../components/SnackBar/SnackBarOperations';
import DeleteConfirmationDialog from '../../utils/DeleteConfirmationDialog';

interface Props {
    projectId: number
}

export default function TranslationTagSettings(props: Readonly<Props>) {
    const { projectId } = props;

    const [translationConfirmationPopoverOpen, setTranslationConfirmationPopoverOpen] = useState(false);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const [selectedTagIds, setSelectedTagIds] = React.useState<GridRowSelectionModel>([]);

    const theme = useTheme();
    const dispatch = useAppDispatch();
    const languages = useAppSelector(selectLanguagesByProjectId(projectId));
    const translationTags = useAppSelector(selectTagsByProjectId(projectId));

    const tagGridSource = React.useMemo(() => translationTags.map(tag => {
        return {
            ...tag,
            ...languages.reduce((trans: any, lang) => {
                const currentTranslation = tag.translations.find(x => x.languageId === lang.id);
                trans[lang.abbreviation] = currentTranslation?.text;
                return trans;
            }, {}),
        }
    }), [translationTags, languages]);


    React.useEffect(() => {
        dispatch(getLanguage(projectId));
        dispatch(getTranslationTags(projectId));
    }, []);

    const handleRowEditStart = React.useCallback((
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = React.useCallback((params, event) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleEditClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }, [setRowModesModel, rowModesModel]);

    const handleSaveClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }, [setRowModesModel, rowModesModel]);

    const handleDeleteClick = React.useCallback((id: GridRowId) => () => {
        // TODO: delete role
        dispatch(deleteTranslationTag(Number(id)))
    }, [dispatch]);

    const handleCancelClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    }, [setRowModesModel, rowModesModel]);

    const getTranslationTagSchema = React.useCallback((id: number) => yup.object().shape({
        name: yup.string().required('Translation name is required.').unique('Translation tag name has to be unque', (val: string) => !translationTags.filter(x => x.id !== id).map(x => x.name).includes(val))
    }), [translationTags]);

    const processRowUpdate = React.useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        const oldTag = translationTags.find(x => x.id === newRow.id);

        if (!oldTag)
            return;

        let newTag = { ...oldTag };
        newTag.name = newRow.name;

        newTag.translations = languages.map(x => {
            const translation = newTag.translations.find(y => y.languageId === x.id);

            if (translation)
                return { ...translation, text: newRow[x.abbreviation] }
            else
                return { id: 0, languageId: x.id, text: newRow[x.abbreviation] }
        })

        try {
            await getTranslationTagSchema(newTag.id).validate(newTag);
            await dispatch(updateTranslationTag(newTag));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                err.errors.forEach((strErr) => SnackBarOperations.error(strErr));
                throw err;
            } else {
                console.error(err);
            }
        }

        return updatedRow;
    }, [translationTags, dispatch, languages]);

    const tagDatatableColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
            editable: true
        },
        ...languages
            .map((lang): GridColDef => {
                return {
                    field: lang.abbreviation,
                    headerName: lang.abbreviation,
                    minWidth: 100,
                    flex: 1,
                    editable: true,
                }
            }),
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key="save"
                            icon={<Save />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key="cancel"
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />
                    ];
                }

                return [
                    <GridActionsCellItem
                        key="edit"
                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleDeleteClick(id)}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }

    ];

    const handleTagAdd = React.useCallback(() => {
        dispatch(createTranslationTag({
            ...defaultTag,
            projectId,
            translations: languages.map(x => {
                return {
                    id: 0,
                    languageId: x.id,
                    text: ''
                }
            })
        }));
    }, [dispatch, projectId, languages]);

    const handleLanguageConfirmationPopoverSubmit = React.useCallback(() => {
        selectedTagIds.forEach(x => dispatch(deleteTranslationTag(Number(x))));
        setTranslationConfirmationPopoverOpen(false);
    }, [selectedTagIds, setTranslationConfirmationPopoverOpen, dispatch]);

    return (
        <>
            <TitleBox
                avatarIcon={<TranslateOutlined />}
                mainTitle='Translations'
                subTitle='Lorem ipsum'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>
                <ConfirmationPopover
                    handleClose={() => setTranslationConfirmationPopoverOpen(false)}
                    handleSubmit={handleLanguageConfirmationPopoverSubmit}
                    open={translationConfirmationPopoverOpen}>
                    <IconButton component="label">
                        <DeleteOutlined />
                    </IconButton>
                </ConfirmationPopover>
                <Button variant="contained" sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />} onClick={handleTagAdd}>
                    ADD TRANSLATION
                </Button>
            </TitleBox>
            <Box sx={{ height: '600px', width: '100%', mb: theme.spacing(2) }}>
                <StyledDataGrid
                    rows={tagGridSource}
                    columns={tagDatatableColumns}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    onRowSelectionModelChange={(newSM) => {
                        setSelectedTagIds(newSM);
                    }}
                    rowSelectionModel={selectedTagIds}
                    editMode='row'
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={setRowModesModel}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    disableRowSelectionOnClick
                    loading={false}
                />
            </Box>
        </>
    )
}