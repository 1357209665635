import { ColorLensOutlined, InfoOutlined } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, useTheme } from '@mui/material';
import React from 'react';
import { defaultAnimationKeyFrame, updateAnimationAsync } from '../../../redux/animation/animationSlice';
import { BlockAnimation, BlockAnimationKeyFrame } from '../../../redux/animation/types';
import { useAppDispatch } from '../../../redux/hooks';
import AnimationDetailTab from './AnimationDetailTab';
import AnimationFramesTab from './AnimationFramesTab';
import { Colors } from '../../../theme/Colors';

interface Props {
    currentAnimation: BlockAnimation | undefined,
    handleBlockAnimationChange: (animation: BlockAnimation) => void,
    handleBlockAnimationSave: () => void,
}

export default function AnimationInfoTabs(props: Props) {
    const { currentAnimation, handleBlockAnimationChange, handleBlockAnimationSave } = props;
    const [currentTab, setCurrentTab] = React.useState("0");
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const onTabValueChange = React.useCallback((event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    }, [setCurrentTab]);

    const handleFrameCreate = React.useCallback(() => {
        if (!currentAnimation)
            return;

        let newPercentage = 1;
        while (currentAnimation.blockAnimationKeyFrames.findIndex(x => x.percent == newPercentage) > -1) {
            newPercentage++;
        }

        dispatch(updateAnimationAsync({
            ...currentAnimation,
            blockAnimationKeyFrames: [
                ...currentAnimation.blockAnimationKeyFrames,
                { ...defaultAnimationKeyFrame, percent: newPercentage }
            ]
        }));
    }, [currentAnimation])

    const handleKeyFrameChange = (frame: BlockAnimationKeyFrame) => {
        if (currentAnimation)
            handleBlockAnimationChange(
                {
                    ...currentAnimation,
                    blockAnimationKeyFrames: [
                        ...currentAnimation.blockAnimationKeyFrames.filter(x => x.id !== frame.id),
                        frame
                    ]
                }
            )
    }

    return (
        <TabContext value={currentTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', color: Colors.text_silver }}>
                <TabList value={currentTab}
                    variant="fullWidth"
                    onChange={onTabValueChange}
                    sx={{
                        '& button': {
                            minWidth: 'auto',
                        }
                    }}>
                    <Tab icon={<InfoOutlined />} value="0" />
                    <Tab icon={<ColorLensOutlined />} value="1" />
                </TabList>
            </Box>
            <TabPanel sx={{ padding: theme.spacing(1), color: Colors.text_silver }} value='0'>
                <AnimationDetailTab {...props} />
            </TabPanel>
            <TabPanel sx={{ padding: theme.spacing(1), color: Colors.text_silver }} value='1'>
                <AnimationFramesTab
                    currentAnimation={currentAnimation}
                    handleFrameCreate={handleFrameCreate}
                    handleBlockAnimationSave={handleBlockAnimationSave}
                    handleFrameChange={handleKeyFrameChange} />
            </TabPanel>
        </TabContext>
    )
}