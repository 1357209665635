import * as React from 'react';
import AddBlockCard from './AddBlockCard';
import { SquareOutlined } from '@mui/icons-material';
import { Box, Paper, useTheme } from '@mui/material';
import { BlockType } from '../../../redux/blocks/types';

interface Props {
    handleDraggingChange: (toggle: boolean) => void
}

export default function AddBlockGrid(props: Props) {
    const theme = useTheme();
    return (
        <Box sx={{ flexGrow: 1, padding: theme.spacing(1) }}>
            <AddBlockCard {...props} name="BLOCK" icon={<SquareOutlined />} blockType={BlockType.DIV} />
        </Box>
    );
}