import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import { TemplateData } from "./types";

const PREFIX = "templates";

const emptyGuid = "00000000-0000-0000-0000-000000000000";

const prefix = (str: string) => `${PREFIX}/${str}`;

export interface TemplateState {
  templates: TemplateData[];
}

export const initialState: TemplateState = {
  templates: [],
};

export const defaultTemplateData: TemplateData = {
  id: 0,
  name: "new template",
  systemId: emptyGuid,
};

export const createEmptyTemplate = createAppAsyncThunk(
  prefix("createEmptyTemplate"),
  async (template: TemplateData) => {
    const response = await axios.post<TemplateData>("/template", {
      template: { ...template },
      locationPath: "",
    });

    return response.data;
  }
);

export const createTemplate = createAppAsyncThunk(
  prefix("createTemplate"),
  async (template: TemplateData, thunkAPI) => {
    const blockState = thunkAPI.getState().blocks;

    const selectedBlock = blockState.blocks.find(
      (x) => x.id === blockState.currentBlockId
    );
    const response = await axios.post<TemplateData>("/template", {
      template: { ...template },
      locationPath: selectedBlock?.locationPath,
    });

    return response.data;
  }
);

export const updateTemplate = createAppAsyncThunk(
  prefix("updateTemplate"),
  async (template: TemplateData) => {
    const response = await axios.post<TemplateData>(
      "/template/update",
      template
    );
    return response.data;
  }
);

export const deleteTemplate = createAppAsyncThunk(
  prefix("deleteTemplate"),
  async (id: number) => {
    await axios.delete(`/template/${id}`);
    return id;
  }
);

export const getTemplates = createAppAsyncThunk(
  prefix("getTemplates"),
  async () => {
    const response = await axios.get<TemplateData[]>("/template");
    return response.data;
  }
);

const templateSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTemplate.fulfilled, (state, action) => {
      state.templates.push(action.payload);
    });

    builder.addCase(createEmptyTemplate.fulfilled, (state, action) => {
      state.templates.push(action.payload);
    });

    builder.addCase(updateTemplate.fulfilled, (state, action) => {
      const index = state.templates.findIndex(
        (x) => x.id === action.payload.id
      );
      if (index >= 0) {
        state.templates[index] = action.payload;
      }
    });

    builder.addCase(deleteTemplate.fulfilled, (state, action) => {
      state.templates = state.templates.filter((x) => x.id !== action.payload);
    });

    builder.addCase(getTemplates.fulfilled, (state, action) => {
      state.templates = action.payload;
    });

    // base cases

    // logs error if a request is rejected
    builder.addMatcher(isRejected, (_, action) => {
      console.error(action.error.message);
    });
  },
});

// selectors
export const selectGlobalTemplates = (s: RootState) =>
  s.templates.templates.filter((x) => !x.projectId && x.systemId === emptyGuid);
export const selectSystemTemplates = (s: RootState) =>
  s.templates.templates.filter((x) => !x.projectId && x.systemId !== emptyGuid);
export const selectProjectTemplates = (id: number) => (s: RootState) =>
  s.templates.templates.filter((x) => x.projectId === id);
export const selectTemplateById = (id: number) => (s: RootState) =>
  s.templates.templates.find((x) => x.id === id);

export default templateSlice.reducer;
