import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  ActionType,
  AnyAction,
} from "../../redux/actionsequence/types/actions";
import { ActionConnection } from "../../redux/actionsequence/types/helpers/ActionConnection";
import {
  ActionState,
  ExecutionContext,
  ProcessState,
} from "./ActionExecutionContext";
import step from "../../functions/step";
import EventContext from "../blockEvents/EventContext";
import { produce } from "immer";

interface Submission {
  context: {
    source: number;
  };
  args?: any;
  actions: AnyAction[];
  connections: ActionConnection[];
}

interface ActionRunContext {
  run: (submission: Submission) => Promise<void>;
}

const log = (...msg: any[]) => {
  if ("bloxxi_log_actions" in window) {
    console.log("[BLOXXI ACTIONS]: ", msg);
  }
};

const initial: ActionRunContext = {
  run: (_submission) =>
    Promise.reject(
      new Error(
        "Context not initialized, please use inside 'ActionContextProvider'"
      )
    ),
};

const ActionRunContext = createContext(initial);

export const ActionRunContextProvider = (props: PropsWithChildren) => {
  const { children } = props;

  const eventContext = useContext(EventContext);

  const context: ExecutionContext = useMemo(
    () => ({ eventContext }),
    [eventContext]
  );

  const run = useCallback<ActionRunContext["run"]>(
    async (submission) => {
      log("subm:", submission);
      let state: ProcessState = {
        actionStates: {},
        variables: {},
        connections: [],
        actions: [],
        queue: [],
        removed: [],
        isActive: false,
      };

      // initialize state
      state.actionStates = submission.actions.reduce(
        (obj: Record<string, ActionState>, a: AnyAction) => {
          obj[a.id] = {
            status: "waiting",
          };
          return obj;
        },
        {}
      );
      state.actions = submission.actions;
      state.connections = submission.connections;
      const start = submission.actions.find((x) => x.type === ActionType.Start);
      if (!start) {
        throw new Error("No start action found");
      }
      state.queue = [start.id];
      state.isActive = true;
      state.metadata = {
        blockContext: submission.context.source,
      };
      state.args = submission.args;

      let startTime = Date.now();
      let executed = 0;
      const maxExecutedPerSecond = 50;

      while (true) {
        const act = state.queue.find(Boolean);
        if (!act) {
          log("No action found in queue");
          return;
        }

        let endTime = Date.now();
        if (endTime - startTime > 1000) {
          startTime = Date.now();
          executed = 0;
        }

        if (executed > maxExecutedPerSecond) {
          log(
            "Rate limit reached, please configure your actions in a way that does not run 50 actions in one second"
          );
        }

        log(`Executing action ${act}`);
        state = produce(state, (s) => {
          s.actionStates[act].status = "executing";
          return s;
        });

        try {
          state = await step(state, context);
          executed++;
        } catch (err) {
          log("Error encountered while executing actions: ", err);
          const a = state.actions.find((x) => x.id === act);
          log("Error occured while exeuting action: ", a);

          state = produce(state, (s) => {
            s.actionStates[act].status = "error";
            return s;
          });
          break;
        }
      }

      log("Finished execution", state);
    },
    [context]
  );

  const value = useMemo<ActionRunContext>(
    () => ({
      run,
    }),
    [run]
  );

  return (
    <ActionRunContext.Provider value={value}>
      {children}
    </ActionRunContext.Provider>
  );
};

export default ActionRunContext;
