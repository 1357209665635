import {
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import axios from "axios";
import { createAppAsyncThunk } from "../hooks";
import { BlockRequest, RequestHeaderEntry, RequestType } from "./types";
import { RootState } from "../store";

const PREFIX = "request";

const prefix = (str: string) => `${PREFIX}/${str}`;

export interface RequestState {
  loading: boolean;
  requests: BlockRequest[];
}

export const initialState: RequestState = {
  loading: false,
  requests: [],
};

export const defaultRequest: BlockRequest = {
  id: 0,
  method: RequestType.GET,
  header: [],
  name: "NewRequest",
  uri: "",
};

export const defaultHeaderEntry: RequestHeaderEntry = {
  id: 0,
  name: "",
  value: "",
};

export const getRequests = createAppAsyncThunk(
  prefix("getRequests"),
  async (projectId: number) => {
    const response = await axios.get<BlockRequest[]>(`/request/${projectId}`);
    return response.data;
  }
);

export const createRequest = createAppAsyncThunk(
  prefix("createRequest"),
  async (request: BlockRequest) => {
    const response = await axios.post<BlockRequest>("/request", request);
    return response.data;
  }
);

export const updateRequest = createAppAsyncThunk(
  prefix("updateRequest"),
  async (request: BlockRequest) => {
    const response = await axios.post<BlockRequest>("/request/update", request);
    return response.data;
  }
);

export const deleteRequest = createAppAsyncThunk(
  prefix("deleteRequest"),
  async (id: number) => {
    await axios.delete(`/request/${id}`);
    return id;
  }
);

const requestSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRequests.fulfilled, (state, action) => {
      state.requests = action.payload;
    });

    builder.addCase(createRequest.fulfilled, (state, action) => {
      state.requests.push(action.payload);
    });

    builder.addCase(updateRequest.fulfilled, (state, action) => {
      const index = state.requests.findIndex((x) => x.id === action.payload.id);
      if (index >= 0) {
        state.requests[index] = action.payload;
      }
    });

    builder.addCase(deleteRequest.fulfilled, (state, action) => {
      state.requests = state.requests.filter((x) => x.id !== action.payload);
    });

    // sets loadind to 'true' while request is pending for all thunks
    builder.addMatcher(isPending, (state) => {
      state.loading = true;
    });

    // logs error if a request is rejected
    builder.addMatcher(isRejected, (_, action) => {
      console.error(action.error.message);
    });

    // sets loading to 'false' if a request completed (rejected or resolved)
    builder.addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
      state.loading = false;
    });
  },
});

export const selectRequests = (rootState: RootState) =>
  rootState.requests.requests;
export const selectRequestById =
  (requestId: number) => (rootState: RootState) =>
    rootState.requests.requests.find((x) => x.id === requestId) ??
    defaultRequest;

export default requestSlice.reducer;
