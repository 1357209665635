import {
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import axios from "axios";
import { createAppAsyncThunk } from "../hooks";
import { RootState } from "../store";
import { FontStyle, ProjectFont } from "./types";

const PREFIX = "font";

const prefix = (str: string) => `${PREFIX}/${str}`;

export interface FontState {
  loading: boolean;
  fonts: ProjectFont[];
}

export const initialState: FontState = {
  loading: false,
  fonts: [],
};

export const defaultFont: ProjectFont = {
  id: 0,
  family: "",
  style: FontStyle.normal,
  weight: 400,
};

export const getFonts = createAppAsyncThunk(
  prefix("getFonts"),
  async (projectId: number) => {
    const response = await axios.get<ProjectFont[]>(`/font/${projectId}`);
    return response.data;
  }
);

export const createFont = createAppAsyncThunk(
  prefix("createFont"),
  async (font: ProjectFont) => {
    const response = await axios.post<ProjectFont>("/font", font);
    return response.data;
  }
);

export const updateFont = createAppAsyncThunk(
  prefix("updateFont"),
  async (font: ProjectFont) => {
    const response = await axios.post<ProjectFont>("/font/update", font);
    return response.data;
  }
);

export const deleteFont = createAppAsyncThunk(
  prefix("deleteFont"),
  async (id: number) => {
    await axios.delete(`/font/${id}`);
    return id;
  }
);

const fontSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFonts.fulfilled, (state, action) => {
      state.fonts = action.payload;
    });

    builder.addCase(createFont.fulfilled, (state, action) => {
      state.fonts.push(action.payload);
    });

    builder.addCase(updateFont.fulfilled, (state, action) => {
      const index = state.fonts.findIndex((x) => x.id === action.payload.id);
      if (index >= 0) {
        state.fonts[index] = action.payload;
      }
    });

    builder.addCase(deleteFont.fulfilled, (state, action) => {
      state.fonts = state.fonts.filter((x) => x.id !== action.payload);
    });

    // sets loadind to 'true' while request is pending for all thunks
    builder.addMatcher(isPending, (state) => {
      state.loading = true;
    });

    // logs error if a request is rejected
    builder.addMatcher(isRejected, (_, action) => {
      console.error(action.error.message);
    });

    // sets loading to 'false' if a request completed (rejected or resolved)
    builder.addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
      state.loading = false;
    });
  },
});

export const selectFonts = (rootState: RootState) => rootState.fonts.fonts;
export const selectFontById = (fontId: number) => (rootState: RootState) =>
  rootState.fonts.fonts.find((x) => x.id === fontId) ?? defaultFont;

export default fontSlice.reducer;
