import { createTheme } from "@mui/material";
import { OuterTheme } from "./OuterTheme";
import { Colors } from "./Colors";

const NodeSystemTheme = createTheme({
  ...OuterTheme,
  palette: {
    ...OuterTheme.palette,
    mode: "dark",
    primary: {
      main: Colors.primary_main,
    },
    secondary: {
      main: Colors.common_black,
    },
    background: {
      default: "#000000",
      paper: Colors.common_black,
    },
    text: {
      primary: Colors.text_silver,
      secondary: Colors.text_silver,
    },
  },
  components: {
    ...OuterTheme.components,
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: Colors.text_silver,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.inner_icon_button_background,
          color: "#ffffff",
          ":before": {
            borderBottom: "1px solid " + Colors.text_silver,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {},
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: Colors.primary_main,
          "&.Mui-checked": {
            color: Colors.primary_main,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .blockmap .MuiListItem-root": {
            "&:hover": {
              backgroundColor: Colors.inner_button_hover_background,
            },
            "&:active": {
              backgroundColor: Colors.inner_icon_button_disabled,
            },
            "& .Mui-selected": {
              color: Colors.primary_main,
              backgroundColor: Colors.inner_button_hover_background,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          backgroundColor: Colors.inner_button_hover_background,
          color: Colors.text_silver,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .Mui-disabled": {
            backgroundColor: `${Colors.inner_icon_button_disabled} !important`,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.text_silver,
        },
      },
    },
  },
});

export default NodeSystemTheme;
