import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";


const StyledDataGrid = styled(DataGrid)(() => ({
    "& .MuiDataGrid-renderingZone": {
        maxHeight: "none !important"
      },
      "& .MuiDataGrid-cell": {
        lineHeight: "unset !important",
        maxHeight: "none !important",
        whiteSpace: "normal"
      },
      "& .MuiDataGrid-row": {
        maxHeight: "none !important"
      }
}));

export default StyledDataGrid;