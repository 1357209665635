import { Box, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import SpacingPart from '../../../../../utils/SpacingPart';
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import ValueWithUnitInput from '../../../../../utils/Inputs/ValueWithUnitInput';
import { BlockStyleAttribute } from '../BlockStyleInputs';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void, 
    selectedBlock?: BlockData,
}

export default function SpacingInput(props: Props) {
    const theme = useTheme();

    return (
        <>
            <BlockStyleDataLabel name='Padding' size={12} />
            <BlockStyleDataLabel name='Top' />
            <Grid item xs={4}>
                <ValueWithUnitInput {...props} attributeKey='padding-top' />
            </Grid>
            <BlockStyleDataLabel name='Right' />
            <Grid item xs={4}>
                <ValueWithUnitInput {...props} attributeKey='padding-right' />
            </Grid>
            <BlockStyleDataLabel name='Bottom' />
            <Grid item xs={4}>
                <ValueWithUnitInput {...props} attributeKey='padding-bottom' />
            </Grid>
            <BlockStyleDataLabel name='Left' />
            <Grid item xs={4}>
                <ValueWithUnitInput {...props} attributeKey='padding-left' />
            </Grid>
            <BlockStyleDataLabel name='Margin' size={12} />
            <BlockStyleDataLabel name='Top' />
            <Grid item xs={4}>
                <ValueWithUnitInput {...props} attributeKey='margin-top' additionalEntries={['auto']}/>
            </Grid>
            <BlockStyleDataLabel name='Right' />
            <Grid item xs={4}>
                <ValueWithUnitInput {...props} attributeKey='margin-right' additionalEntries={['auto']} />
            </Grid>
            <BlockStyleDataLabel name='Bottom' />
            <Grid item xs={4}>
                <ValueWithUnitInput {...props} attributeKey='margin-bottom' additionalEntries={['auto']} />
            </Grid>
            <BlockStyleDataLabel name='Left' />
            <Grid item xs={4}>
                <ValueWithUnitInput {...props} attributeKey='margin-left' additionalEntries={['auto']} />
            </Grid>
        </>
    );
}