import { Add, DeleteOutlined, TranslateOutlined, MoreVert, Delete, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, List, ListItem, ListItemButton, ListItemText, Popover, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { createDataSet, defaultDataSet, deleteDataSet, getDataSets, selectDynData } from '../../../redux/dyndata/dyndataSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ConfirmationPopover from '../../utils/ConfirmationPopover';
import MorePopover from '../../utils/MorePopOver';
import StyledDataGrid from '../../utils/StyledDataGrid';
import TitleBox from '../../widgets/TitleBox';
import DeleteConfirmationDialog from '../../utils/DeleteConfirmationDialog';

interface Props {
    projectId: number
}

export default function DynamicDataSetSets(props: Props) {
    const { projectId } = props;
    const [dataSetConfirmationPopoverOpen, setDataSetConfirmationPopoverOpen] = useState(false);
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);

    const theme = useTheme();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const dataSets = useAppSelector(selectDynData);

    React.useEffect(() => {
        dispatch(getDataSets(projectId));
    }, [])

    const handleDataSetDelete = React.useCallback((id: number) => {
        dispatch(deleteDataSet(id));
    }, [dispatch]);

    const handleDataSetEdit = React.useCallback((id: number) => {
        navigate(`/project-detail/${projectId}/settings/dataset/${id}`);
    }, [navigate, projectId]);

    const handleDataSetCreate = React.useCallback(() => {
        dispatch(createDataSet({...defaultDataSet, projectId }))
            .unwrap()
            .then((payload) => {
                navigate(`/project-detail/${projectId}/settings/dataset/${payload.id}`);
            });
    }, [dispatch, projectId, navigate]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'dynModel',
            headerName: 'Model',
            minWidth: 100,
            flex: 1,
            renderCell(params) {
                return params.value ? params.value.name : 'No model selected';
            }
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                return [
                    <GridActionsCellItem
                        key="edit"

                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={() => handleDataSetEdit(Number(id))}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={() => handleDataSetDelete(Number(id))}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}                            
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];

    return (
        <>
            <TitleBox
                avatarIcon={<TranslateOutlined />}
                mainTitle='Data sets'
                subTitle='Lorem ipsum'
                maintitleVariant='h6'
                subTitleVariant='subtitle2'>
                <ConfirmationPopover
                    handleClose={() => setDataSetConfirmationPopoverOpen(false)}
                    handleSubmit={() => { }}
                    open={dataSetConfirmationPopoverOpen}>
                    <IconButton component="label">
                        <DeleteOutlined />
                    </IconButton>
                </ConfirmationPopover>
                <Button variant="contained" sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />} onClick={handleDataSetCreate}>
                    ADD DATASET
                </Button>
            </TitleBox>
            <Box sx={{ height: '600px', width: '100%', mb: theme.spacing(2) }}>
                <StyledDataGrid
                    rows={dataSets}
                    columns={columns}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    onRowSelectionModelChange={(newSM) => {
                        setSelectedIds(newSM);
                    }}
                    rowSelectionModel={selectedIds}
                    disableRowSelectionOnClick
                    loading={false}
                />
            </Box>
        </>
    )
}