import { FC } from "react";
import { AnyAction } from "../redux/actionsequence/types/actions";
import asNode, {
  NodeComponentProps,
} from "../components_new/actions/SequenceBuilder/customFlowComponents/customNodes/asNode";

/**
 * Creates a higher-order component that wraps a functional component to make it interoperable with the Block Action React Flow.
 *
 * The wrapped component handles state management, validation, and saving of action data to the backend.
 * It receives the following important props:
 *
 * - `onChange`: A function to update the action data. It takes a property key and a new value, validates the change, and updates the `actionData` state if there are no validation errors.
 * - `actionData`: The current action data object. This is a reactive state, so it should be used instead of the `data` prop for things like text fields.
 * - `errors`: An object containing validation errors for each property of the action data.
 *
 * The wrapped component is rendered inside a `NodeWrapper` component, which provides the title, tooltip, and other styling for the node.
 *
 * @param {FC<NodeComponentProps<ACT>>} fn The functional component to be wrapped.
 * @returns {FC<NodeProps<ACT>>} The wrapped functional component.
 *
 * @template ACT The type of the action data object.
 */
export default function createNodeComponent<ACT extends AnyAction>(
  fn: FC<NodeComponentProps<ACT>>
) {
  return asNode(fn);
}

export type CreateNodeComponent<ACT extends AnyAction> = ReturnType<
  typeof createNodeComponent<ACT>
>;
