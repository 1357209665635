import { Paper, Box, Typography, Grid, Card, CardActionArea, CardMedia, CardContent, useTheme, Skeleton, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useNavigate } from 'react-router';
import TitleBox from './TitleBox';

interface Props {
    mainTitle: string,
    subTitle: string,
    itemTitlePropName: string,
    items: any[],
    maxItemAmount: number,
    redirectUrl: string,
    navigationTooltip: string
}

export default function CardWidget(props: Props) {
    const { mainTitle, subTitle, itemTitlePropName, items, maxItemAmount, redirectUrl, navigationTooltip } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    const cards = React.useMemo(() => items
        .slice(0, maxItemAmount)
        .map((item, index) => (
            <Grid key={item["id"] ?? index} item xs={4}>
                <Card>
                    <CardActionArea disabled>
                        <CardMedia
                            component="img"
                            height="100"
                            image={item.hasOwnProperty("path") ? item["path"] : "/placeholder.JPG"}
                            alt="placeholder"
                        />
                        <CardContent>
                            <Typography sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}} variant="subtitle1">
                                {item[itemTitlePropName]}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        )), [items, maxItemAmount, itemTitlePropName]);

    const itemDifference = maxItemAmount - items.length;
    const skeletons = React.useMemo(() => itemDifference < 1 ? <></> : [...Array(itemDifference).keys()].map((value) => (
        <Grid key={`skeleton-${value}`} item xs={4}>
            <Skeleton variant="rectangular" animation={false} height="156px" />
        </Grid>
    )), [itemDifference]);

    return (
        <Paper sx={{ padding: theme.spacing(2) }}>
            <TitleBox
                mainTitle={mainTitle}
                maintitleVariant='h6'
                subTitle={subTitle}
                subTitleVariant='subtitle2'
                key={'card-widget-title'}
            >

                <Tooltip title={navigationTooltip}>
                    <span>
                        <IconButton onClick={() => navigate(redirectUrl)}>
                            <ArrowCircleRightOutlinedIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </TitleBox>
            <Grid container spacing={3}>
                {cards}
                {skeletons}
            </Grid>
        </Paper>
    );
}