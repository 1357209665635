import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import actions, {
  ActionType,
  AnyAction,
  InferAction,
} from "../../../../redux/actionsequence/types/actions";
import { ActionNode } from "../../../../redux/actionsequence/types/helpers/ActionNode";
import {
  ConnectionType,
  HandleType,
} from "../../../../redux/actionsequence/types/helpers/ActionConnection";
import ReactFlow, {
  useNodesState,
  Node,
  useEdgesState,
  ReactFlowInstance,
  Connection,
  addEdge,
  MiniMap,
  Background,
  BackgroundVariant,
  getOutgoers,
  useReactFlow,
} from "reactflow";
import ButtonEdge from "../customFlowComponents/customEdges/ButtonEdge";
import ConnectionLine from "../customFlowComponents/customLines/ConnectionLine";
import SequenceContext from "../../../../context/SequenceContext";
import { ActionNodeSubmission } from "../../../../redux/actionsequence/types/helpers/ActionNodeSubmission";
import useActionDebugContext from "../../../../hooks/useActionDebugContext";
import { useAppSelector } from "../../../../redux/hooks";
import { selectSequence } from "../../../../redux/actionsequence/sequenceSlice";
import "reactflow/dist/style.css";
import { getLoop } from "../../../../functions/utils/graphUtils";

export default function SequenceFlow() {
  const [nodes, setNodes, onNodesChange] = useNodesState<AnyAction>(
    [] as Node<AnyAction, ActionType>[]
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance<
    AnyAction,
    void
  > | null>(null);

  const { addNode, addConnection, updateNode, sequenceId } =
    useContext(SequenceContext);

  const sequence = useAppSelector(selectSequence(sequenceId));
  useEffect(() => {
    if (sequence?.actions) {
      setNodes(sequence.actions);
    }
    if (sequence?.actionConnections) {
      setEdges(sequence.actionConnections);
    }
  }, [sequence]);

  const nodeTypes = useMemo(
    () =>
      Object.entries(actions).reduce((prev, [k, v]) => {
        prev[k] = v.component;
        return prev;
      }, {} as any),
    [actions]
  );

  const edgeTypes = useMemo(
    () => ({
      [ConnectionType.Cancellable]: ButtonEdge,
    }),
    []
  );

  const handleNodeDragStop = useCallback(
    (_: React.MouseEvent, node: Node) => {
      console.log(`Change node ${node.id} in backend...`);
      updateNode(node as ActionNode);
    },
    [updateNode]
  );

  const onDragOver: React.DragEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    },
    []
  );

  const onDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/addsequencenode");

      // check if the dropped element is valid
      if (
        typeof type === "undefined" ||
        !type ||
        typeof type !== "string" ||
        !(type in ActionType)
      ) {
        return;
      }

      const _type = ActionType[type as keyof typeof ActionType] as const;

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance?.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNode = {
        position: {
          x: position?.x ?? 0,
          y: position?.y ?? 0,
        },
        type: _type,
        data: {
          type: _type,
          ...actions[_type].defaultValues,
        },
      } as ActionNodeSubmission<InferAction<typeof _type>>;

      const addedNode = await addNode(newNode);

      setNodes((n) => n.concat(addedNode as Node));
    },
    [reactFlowInstance, setNodes, addNode]
  );

  const onConnect = useCallback(
    async (params: Connection) => {
      const newEdge = {
        ...params,
        type: ConnectionType.Cancellable,
      };
      let sourceHandle = newEdge.sourceHandle as HandleType | null;
      if (sourceHandle && !(sourceHandle in HandleType)) {
        sourceHandle = null;
      }
      let targetHandle = newEdge.targetHandle as HandleType | null;
      if (targetHandle && !(targetHandle in HandleType)) {
        targetHandle = null;
      }

      const added = await addConnection({
        source: newEdge.source ?? "",
        target: newEdge.target ?? "",
        sourceHandle: sourceHandle,
        targetHandle: targetHandle,
        type: newEdge.type,
      });
      setEdges((e) => addEdge(added, e));
    },
    [setEdges, addConnection]
  );

  const { getEdges, getNodes } = useReactFlow();

  const isValidConnection = useCallback(
    (connection: Connection) => {
      const edges = getEdges();
      const nodes = getNodes();

      const target = nodes.find((node) => node.id === connection.target);
      if (!target) {
        return false;
      }

      const hasCycle = (
        node: Node,
        visited = new Set(),
        encounteredLoopNode = false
      ) => {
        if (visited.has(node.id)) return false;

        if (node.type === ActionType.Loop) {
          encounteredLoopNode = true;
        }

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return !encounteredLoopNode;
          if (hasCycle(outgoer, visited, encounteredLoopNode)) return true;
        }
      };

      if (target.id === connection.source) return false;
      return !hasCycle(target);
    },
    [getEdges, getNodes]
  );

  const {
    processState: { isActive },
  } = useActionDebugContext();

  return (
    // <fieldset
    //   style={{
    //     height: "100%",
    //     width: "100%",
    //     padding: 0,
    //     margin: 0,
    //     border: 0,
    //   }}
    //   disabled={isActive}
    // >
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onInit={setReactFlowInstance}
      nodeTypes={nodeTypes}
      nodesDraggable={!isActive}
      nodesConnectable={!isActive}
      isValidConnection={isValidConnection}
      edgeTypes={edgeTypes}
      onConnect={onConnect}
      fitView
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodeDragStop={handleNodeDragStop}
      onDrop={onDrop}
      onDragOver={onDragOver}
      connectionLineComponent={ConnectionLine}
      deleteKeyCode={null}
      style={{
        background: "#000000",
      }}
    >
      <MiniMap style={{ background: "#000000" }} />
      <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
    </ReactFlow>
    // </fieldset>
  );
}
