import React, { useEffect, useMemo, useState } from "react";
import TopHandle from "../../helpers/TopHandle";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import KeyValueList from "../../helpers/KeyValueList";
import BottomHandle from "../../helpers/BottomHandle";
import clsx from "clsx";
import createNodeComponent from "../../../../../../functions/createNodeComponent";
import RequestAction, {
  RequestMethod,
} from "../../../../../../redux/actionsequence/types/actions/data/RequestAction";
import JSONInput from "react-json-editor-ajrm";
//@ts-ignore
import locale from "react-json-editor-ajrm/locale/en";
import { Colors } from "../../../../../../theme/Colors";

const RequestNodeComponent = createNodeComponent<RequestAction>((props) => {
  const { actionData, errors, onChange } = props;

  const [initialBody, setInitialBody] = useState<string | undefined>();

  const initBody = useMemo(() => {
    return initialBody && initialBody.length > 0 ? JSON.parse(initialBody) : {};
  }, [initialBody]);

  useEffect(() => {
    setInitialBody(actionData.body);
  }, []);

  return (
    <Box
      sx={{
        minWidth: "460px",
      }}
    >
      <TopHandle />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ["&>.formItem"]: {
            marginBottom: "20px",
          },
        }}
      >
        <TextField
          className={clsx("nodrag", "formItem")}
          color="primary"
          fullWidth
          label="Action name"
          defaultValue={actionData.name}
          size="small"
          variant="filled"
          required
          error={"name" in errors}
          helperText={errors.name}
          onChange={(ev) => onChange("name", ev.target.value)}
        />
        <TextField
          className={clsx("nodrag", "formItem")}
          color="primary"
          fullWidth
          label="Request url"
          defaultValue={actionData.url}
          size="small"
          variant="filled"
          required
          error={"url" in errors}
          helperText={errors.url}
          onChange={(ev) => onChange("url", ev.target.value)}
        />
        <FormControl
          className={clsx("nodrag", "formItem")}
          variant="filled"
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="select-request-method-input-label">Method</InputLabel>
          <Select
            labelId="select-request-method-input-label"
            value={actionData.method}
            onChange={(ev) => {
              onChange("method", ev.target.value as RequestMethod);
            }}
            label="Method"
          >
            <MenuItem value={RequestMethod.get}>GET</MenuItem>
            <MenuItem value={RequestMethod.post}>POST</MenuItem>
            <MenuItem value={RequestMethod.put}>PUT</MenuItem>
            <MenuItem value={RequestMethod.delete}>DELETE</MenuItem>
          </Select>
        </FormControl>
        <Box className={clsx("nodrag", "formItem")}>
          <Typography variant="h6" sx={{ mb: "10px" }}>
            Headers
          </Typography>
          {errors.requestHeaders && (
            <Typography variant="caption" color={Colors.bloxxi_red}>
              {errors.requestHeaders}
            </Typography>
          )}
          <KeyValueList
            values={actionData.requestHeaders}
            onChange={(newValues) => {
              console.log(newValues);
              onChange("requestHeaders", newValues);
            }}
          />
        </Box>
        <Box className={clsx("nodrag", "formItem")}>
          <Typography variant="h6" sx={{ mb: "10px" }}>
            Query parameters
          </Typography>
          <KeyValueList
            values={actionData.queryParameters}
            onChange={(newValues) => {
              onChange("queryParameters", newValues);
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: [RequestMethod.post, RequestMethod.put].includes(
            actionData.method
          )
            ? "unset"
            : "none",
        }}
        className={clsx("nodrag", "formItem")}
      >
        <Typography variant="h6" sx={{ mb: "10px" }}>
          Body
        </Typography>
        <JSONInput
          id={`${actionData.id}-body`}
          placeholder={initBody}
          locale={locale}
          height="300px"
          onChange={(val: any) => {
            if (val.error) {
              return;
            }
            onChange("body", val.json);
          }}
        />
      </Box>
      <BottomHandle />
    </Box>
  );
});

export default RequestNodeComponent;
