import { IconButton, Grid, Paper, useTheme, Button, List, ListItemButton, ListItemIcon, ListItemText, Card, CardContent, CardMedia, Typography, CardActionArea, Box } from '@mui/material';
import React, { ChangeEvent } from 'react';
import TitleBox from '../widgets/TitleBox';
import SegmentIcon from '@mui/icons-material/Segment';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MediaTree from './MediaTree';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addFolder, defaultFolder, deleteFile, getFiles, moveFile, renameMedia, selectMedia, uploadFiles } from '../../redux/media/mediaSlice';
import useRouteParam from '../../hooks/useRouteParam';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { MediaFile } from '../../redux/media/types';
import ConfirmationPopover from '../utils/ConfirmationPopover';
import MediaRenameDialog from './MediaRenameDialog';
import { DriveFileMove, DriveFileRenameOutline } from '@mui/icons-material';
import MediaMoveDialog from './MediaMoveDialog';
import { Colors } from '../../theme/Colors';

interface Props {
    onSelectMedia?: (id: number) => void,
    includedFileTypes?: string[];
}

export default function Media(props: Props) {
    const { onSelectMedia, includedFileTypes = [] } = props;
    const projectId = useRouteParam('projectId');

    const [selectedFolder, setSelectedFolder] = React.useState(projectId.toString());
    const [selectedFiles, setSelectedFiles] = React.useState<number[]>([]);
    const [nameDialogOpen, setNameDialogOpen] = React.useState<boolean>(false);
    const [moveDialogOpen, setMoveDialogOpen] = React.useState<boolean>(false);
    const [fileIdsToDelete, setFileIdsToDelete] = React.useState<number[]>([]);
    const [titleBoxDeleteConfirmationOpen, setTitleBoxDeleteConfirmationOpen] = React.useState(false);
    const mediaFiles = useAppSelector(selectMedia);
    const files = includedFileTypes.length ?
        mediaFiles.filter(x => includedFileTypes.includes(x.extension) || x.extension === 'folder')
        : mediaFiles;

    const theme = useTheme();
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(getFiles(projectId));
    }, []);

    const handleFolderAdd = React.useCallback(() => {
        dispatch(addFolder({ media: { ...defaultFolder, projectId }, path: selectedFolder }))
            .unwrap()
            .then(payload => setSelectedFolder(payload[0].directoryPath));
    }, [dispatch, selectedFolder, projectId, files]);

    const handleFolderChange = React.useCallback((path: string) => {
        if (selectedFolder === path || (path === "" && selectedFolder === projectId.toString()))
            return;

        setSelectedFolder(path);
        setSelectedFiles([]);
    }, [selectedFolder, projectId, setSelectedFiles, setSelectedFolder]);

    const handleChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.files?.length)
            return;

        dispatch(uploadFiles({ formFiles: event.target.files, path: selectedFolder, projectId }));
    }, [selectedFolder, projectId, projectId, dispatch]);

    const handleSelectedFilesChanged = React.useCallback((id: number) => {
        setSelectedFiles(prev => {
            if (selectedFiles.find(x => x === id)) {
                return [...prev].filter(x => x !== id);
            } else {
                return [...prev, id];
            }
        })
    }, [setSelectedFiles, selectedFiles]);

    const handleConfirmationPopoverSubmit = React.useCallback(() => {
        const filesToDelete = files.filter(x => fileIdsToDelete.includes(x.id));
        fileIdsToDelete.forEach(x => dispatch(deleteFile(x)));

        if (filesToDelete.find(x => x.path === selectedFolder)) {
            setSelectedFolder(projectId.toString());
        }

        setSelectedFiles([]);
        setFileIdsToDelete([]);
        setTitleBoxDeleteConfirmationOpen(false);
    }, [files, fileIdsToDelete, selectedFolder, dispatch, setSelectedFolder, projectId, setSelectedFiles, setFileIdsToDelete, setTitleBoxDeleteConfirmationOpen]);

    const handleConfirmationPopoverClose = React.useCallback(() => {
        setFileIdsToDelete([]);
        setTitleBoxDeleteConfirmationOpen(false);
    }, [setFileIdsToDelete, setTitleBoxDeleteConfirmationOpen]);

    const handleSelectedFilesDelete = React.useCallback((ev: React.MouseEvent<HTMLLabelElement>) => {
        setTitleBoxDeleteConfirmationOpen(true);
        const selectedMediaFiles = files.filter(x => selectedFiles.includes(x.id));
        let markedFiles: MediaFile[] = [];
        selectedMediaFiles.forEach(x => {
            if (x.extension === 'folder') {
                const childFiles = files.filter(y => y.path.startsWith(x.path));
                childFiles.forEach(z => {
                    if (!markedFiles.includes(z))
                        markedFiles.push(z);
                })
            } else if (!markedFiles.includes(x)) {
                markedFiles.push(x);
            }
        });
        setFileIdsToDelete([...markedFiles.map(x => x.id)]);
    }, [files, selectedFiles, setTitleBoxDeleteConfirmationOpen]);

    const filesToDisplay = React.useMemo(() => files.filter(x => x.directoryPath.replace(selectedFolder, '').length < 2 && x.extension !== 'folder'), [files, selectedFolder]);
    const fileCards = React.useMemo(() => filesToDisplay.map(file => (
        <Grid key={`media-card-${file.id}`} item xs={3}>
            <Card sx={{ position: 'relative' }}>
                <CardActionArea disabled={!onSelectMedia} onClick={onSelectMedia ? () => onSelectMedia(file.id) : undefined}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image={['.woff', '.woff2', '.ttf', '.otf'].includes(file.extension) ? 
                        '/letterA.webp' : ['.pdf', '.mov'].includes(file.extension) ? 
                        'placeholder.JPG' : `root${file.url}`}
                        sx={{ backgroundColor: Colors.text_silver }}
                    />
                    <CardContent>
                        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} gutterBottom variant="h6" component="div">
                            {file.name + file.extension}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid >
    )), [filesToDisplay]);

    const openCloseNameDialog = React.useCallback(() => {
        setNameDialogOpen(prev => !prev);
    }, [setNameDialogOpen])

    const openCloseMoveDialog = React.useCallback(() => {
        setMoveDialogOpen(prev => !prev);
    }, [setMoveDialogOpen])

    const submitNameDialog = React.useCallback((name: string) => {
        const media = files.find(x => x.id === selectedFiles[0]);

        if (media) {
            const path = `${media.path.substring(0, media.path.length - media.name.length)}${name}`;
            dispatch(renameMedia({ media, path, name }))
                .unwrap()
                .then(payload => {
                    setSelectedFiles([]);
                    const file = payload.find(x => x.id === media.id);
                    if (file)
                        setSelectedFolder(file.directoryPath);
                });
        }

        openCloseNameDialog();
    }, [files, dispatch, selectedFiles, openCloseMoveDialog]);

    const submitMoveDialog = React.useCallback((path: string) => {

        selectedFiles.forEach(x => {
            const media = files.find(f => f.id === x);

            if (media)
                dispatch(moveFile({ media, path }));
        });
        setSelectedFiles([]);
        openCloseMoveDialog();
    }, [files, dispatch, selectedFiles, openCloseMoveDialog]);

    return (
        <>
            <TitleBox
                avatarIcon={<SegmentIcon />}
                mainTitle='Media'
                subTitle={selectedFolder}
            >
                <ConfirmationPopover
                    handleClose={handleConfirmationPopoverClose}
                    handleSubmit={handleConfirmationPopoverSubmit}
                    open={titleBoxDeleteConfirmationOpen}>
                    <IconButton onClick={handleSelectedFilesDelete} disabled={selectedFiles.length === 0} component="label" >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </ConfirmationPopover>
                <Button variant="contained" disabled={selectedFiles.length === 0 || files.filter(x => x.extension === 'folder' && selectedFiles.includes(x.id)).length > 0} onClick={openCloseMoveDialog} sx={{ marginLeft: theme.spacing(1) }} endIcon={<DriveFileMove />}>
                    Move file
                </Button>
                <Button variant="contained" disabled={selectedFiles.length !== 1 || files.find(x => x.id === selectedFiles[0])?.extension !== 'folder'} onClick={openCloseNameDialog} sx={{ marginLeft: theme.spacing(1) }} endIcon={<DriveFileRenameOutline />}>
                    Rename folder
                </Button>
                <Button variant="contained" sx={{ marginLeft: theme.spacing(1) }} component="label" endIcon={<CloudUploadIcon />}>
                    Upload
                    <input hidden multiple type="file" onChange={handleChange} />
                </Button>
                <Button variant="contained" onClick={handleFolderAdd} sx={{ marginLeft: theme.spacing(1) }} endIcon={<AddIcon />}>
                    Add folder
                </Button>
            </TitleBox>
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <Paper sx={{ width: '100%', height: 'calc(100vh - 156px)', display: 'flex', flexDirection: 'column', padding: theme.spacing(2) }}>
                        <TitleBox
                            mainTitle='Files'
                            maintitleVariant='h6'
                            subTitle={`File in folder ${selectedFolder}`}
                            subTitleVariant='subtitle2'
                            key={'media-explorer'}
                        >
                        </TitleBox>
                        <Box sx={{ overflowY: 'scroll', flex: 1, paddingBottom: '1px' }}>
                            <Grid container spacing={3}>
                                {fileCards}
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper sx={{ width: '100%', height: 'calc(100vh - 156px)', display: 'flex', flexDirection: 'column', padding: theme.spacing(2) }}>
                        <TitleBox
                            mainTitle='Explorer'
                            maintitleVariant='h6'
                            subTitle='File structure of the current project'
                            subTitleVariant='subtitle2'
                            key={'media-explorer'}
                        >
                        </TitleBox>
                        <Paper sx={{ width: '100%', flex: 1, padding: theme.spacing(1), overflowY: 'scroll' }}>
                            <List dense disablePadding>
                                <ListItemButton key={`media-file-root`} dense onClick={() => handleFolderChange(projectId.toString())}>
                                    <ListItemIcon>
                                        <FolderOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={projectId.toString()} />
                                </ListItemButton>
                                <MediaTree
                                    selectedFiles={selectedFiles}
                                    files={files}
                                    currentFolder={projectId.toString()}
                                    folderOpen={true}
                                    handleChangeFolder={handleFolderChange}
                                    level={3}
                                    selectedFolder={selectedFolder}
                                    handleSelectedFilesChanged={handleSelectedFilesChanged} />
                            </List>
                        </Paper>
                    </Paper>
                </Grid>
                <MediaRenameDialog handleClose={openCloseNameDialog} handleSubmit={submitNameDialog} open={nameDialogOpen} />
                <MediaMoveDialog
                    paths={files.filter(x => x.extension === 'folder').map(x => x.path)}
                    handleClose={openCloseMoveDialog}
                    handleSubmit={submitMoveDialog}
                    open={moveDialogOpen}
                    projectId={projectId} />
            </Grid>
        </>
    )
}