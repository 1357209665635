import { Handle, HandleProps, Position } from "reactflow";
import { HANDLE_STYLE } from "./NodeWrapper";
import React from "react";

const TopHandle = (
  props: Partial<HandleProps & Omit<React.HTMLAttributes<HTMLDivElement>, "id">>
) => {
  return (
    <Handle
      {...props}
      style={{ ...HANDLE_STYLE, top: -10 }}
      position={Position.Top}
      type="target"
    />
  );
};

export default TopHandle;
