import {
  AccountTreeOutlined,
  ColorLensOutlined,
  DatasetOutlined,
  InfoOutlined,
  WebOutlined,
} from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Tooltip, useTheme } from "@mui/material";
import React, { createContext, useMemo } from "react";
import { BlockData, ReferenceType } from "../../../redux/blocks/types";
import BlockDetailTab from "./BlockDetail/BlockDetailTab";
import BlockStyleTab from "./BlockDetail/BlockStyleTab";
import ContextTab from "./BlockDetail/ContextTab";
import BlockEventTab from "./BlockDetail/BlockEventTab";
import { Colors } from "../../../theme/Colors";
import useRouteParam from "../../../hooks/useRouteParam";
import BlockActionSequenceTab from "./BlockDetail/BlockActionSequenceTab";

interface Props {
  selectedBlock: BlockData | undefined;
}

export const BlockInfoTabsContext = createContext({
  setTab(_tab: string) {},
  tab: "",
});

export default function BlockInfoTabs(props: Readonly<Props>) {
  const { selectedBlock } = props;

  const theme = useTheme();
  const [currentTab, setCurrentTab] = React.useState("0");
  const refType = useRouteParam("ref");

  React.useEffect(() => {
    if (!selectedBlock && currentTab === "1") setCurrentTab("2");

    if (selectedBlock && currentTab === "0") setCurrentTab("1");
  }, [selectedBlock]);

  const onTabValueChange = React.useCallback(
    (_event: React.SyntheticEvent, newValue: string) => {
      setCurrentTab(newValue);
    },
    [setCurrentTab]
  );

  const tabsValue = useMemo(
    () => ({
      setTab(tab: string) {
        setCurrentTab(tab);
      },
      tab: currentTab,
    }),
    [currentTab, setCurrentTab]
  );

  return (
    <BlockInfoTabsContext.Provider value={tabsValue}>
      <TabContext value={currentTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            flexShrink: 0,
          }}
        >
          <TabList
            value={currentTab}
            variant="fullWidth"
            onChange={onTabValueChange}
            sx={{
              "& button": {
                minWidth: "auto",
              },
            }}
          >
            <Tab
              icon={
                <Tooltip
                  title={`${
                    refType === ReferenceType.Page ? "Page" : "Template"
                  } details`}
                >
                  <WebOutlined />
                </Tooltip>
              }
              value="0"
            />
            <Tab
              disabled={!selectedBlock}
              icon={
                <Tooltip title="Block details">
                  <InfoOutlined />
                </Tooltip>
              }
              value="1"
            />
            <Tab
              icon={
                <Tooltip title="Styles">
                  <ColorLensOutlined />
                </Tooltip>
              }
              value="2"
            />
            <Tab
              disabled={!selectedBlock}
              icon={
                <Tooltip title="Events">
                  <DatasetOutlined />
                </Tooltip>
              }
              value="3"
            />
            <Tab
              disabled={!selectedBlock}
              icon={
                <Tooltip title="Workflows">
                  <AccountTreeOutlined />
                </Tooltip>
              }
              value="4"
            />
          </TabList>
        </Box>
        <TabPanel
          sx={{ padding: theme.spacing(1), color: Colors.fontColor }}
          value="0"
        >
          <ContextTab />
        </TabPanel>
        <TabPanel
          sx={{ padding: theme.spacing(1), color: Colors.fontColor }}
          value="1"
        >
          <BlockDetailTab />
        </TabPanel>
        <TabPanel
          sx={{
            padding: 0,
            color: Colors.fontColor,
            display: "flex",
            flexDirection: "column",
          }}
          value="2"
        >
          <BlockStyleTab selectedBlock={selectedBlock} />
        </TabPanel>
        <TabPanel
          sx={{ padding: theme.spacing(1), color: Colors.fontColor }}
          value="3"
        >
          <BlockEventTab />
        </TabPanel>
        <TabPanel
          sx={{ padding: theme.spacing(1), color: Colors.fontColor }}
          value="4"
        >
          <BlockActionSequenceTab />
        </TabPanel>
      </TabContext>
    </BlockInfoTabsContext.Provider>
  );
}
