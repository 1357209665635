
import React from 'react';
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import { FilledInput, FormControl, Grid, MenuItem, Select } from '@mui/material';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import { getStyleById } from '../../../../../../Utility';
import { useAppSelector } from '../../../../../../redux/hooks';
import { selectFonts } from '../../../../../../redux/fonts/fontSlice';
import { selectMedia } from '../../../../../../redux/media/mediaSlice';
import { BlockStyleAttribute } from '../BlockStyleInputs';
import { ProjectFont } from '../../../../../../redux/fonts/types';
import BlockStyleDataResetBadge from '../../../../../utils/Inputs/BlockStyleDataResetBadge';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void,
    selectedBlock?: BlockData,
}

const webSafeFonts: string[] = [
    "Times New Roman",
    "Georgia",
    "Courier",
    "Arial",
    "Verdana",
    "Helvetica",
    "Tahoma",
    "Trebuchet MS",
    "Courier New",
    "Lucida Console",
    "Monaco",
    "Comic Sans MS",
    "Impact"
];

const fallBackFonts = ', Helvetica, sans-serif';

function getUniqueFamilyFonts(fonts: ProjectFont[]): ProjectFont[] {
    return fonts.reduce((unique, font) => {
        if (!unique.some(f => f.family === font.family)) {
            unique.push(font);
        }
        return unique;
    }, [] as ProjectFont[]);
}

export default function FontFamilyInput(props: Props) {
    const { currentBlockStyle, handleAttributeChange, selectedBlock } = props;

    const value = currentBlockStyle.blockStyleData.find(x => x.attributeKey === 'font-family')?.attributeValue ?? getStyleById(selectedBlock, 'font-family');
    const fonts = useAppSelector(selectFonts);

    const handleSave = (newValue: string) => {
        handleAttributeChange([{ attributeKey: 'font-family', attributeValue: newValue + fallBackFonts }], true)
    }

    const fontMedia = getUniqueFamilyFonts(fonts);

    return (
        <>
            <BlockStyleDataLabel name="Font" />
            <Grid item xs={10}>
                <BlockStyleDataResetBadge attributeKey='font-family' {...props}>
                    <FormControl variant="filled" fullWidth hiddenLabel>
                        <Select
                            id='font-select'
                            value={value.replace(fallBackFonts, '')}
                            fullWidth
                            input={<FilledInput />}
                            onChange={(e) => handleSave(e.target.value)}
                            size='small'
                        >
                            {
                                fontMedia
                                    .map(x => (
                                        <MenuItem key={x.id} value={x.family} sx={{ fontFamily: x.family }}>{x.family}</MenuItem>
                                    ))
                            }
                            {
                                webSafeFonts.map(x => (
                                    <MenuItem key={x} value={x} sx={{ fontFamily: x }}>{x}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </BlockStyleDataResetBadge>
            </Grid>
        </>
    )
}