import {
  Collapse,
  IconButton,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  useTheme,
  Box,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDrag } from "react-dnd";
import {
  BlockData,
  BlockType,
  BlockTypes,
  CreateBlockDirection,
} from "../../../redux/blocks/types";
import { moveBlock, selectBlock } from "../../../redux/blocks/blockSlice";
import { useAppDispatch } from "../../../redux/hooks";
import {
  ChevronRightOutlined,
  ExpandMoreOutlined,
  Remove,
} from "@mui/icons-material";
import BlockMapDropZone from "./BlockMapDropZone";
import { Colors } from "../../../theme/Colors";
import prettyBlockString from "../../../functions/utils/prettyBlockString";

interface Props {
  blocks: BlockData[];
  selectedBlock: BlockData | undefined;
  currentBlock: BlockData;
  level: number;
  firstEntry: boolean;
  lastEntry: boolean;
  isItemDragging: boolean;
  handleDragging: (toggle: boolean) => void;
}

interface DropResult {
  direction: CreateBlockDirection;
  block: BlockData;
}

const BlockMapBlock = (props: Props) => {
  const {
    blocks,
    selectedBlock,
    currentBlock,
    level,
    isItemDragging,
    handleDragging,
  } = props;

  const [childrenOpen, setChildrenOpen] = useState(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: BlockTypes.move,
    item: { path: currentBlock.locationPath },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      console.log(currentBlock);
      if (item && dropResult && currentBlock) {
        dispatch(
          moveBlock({
            direction: dropResult.direction,
            dragBlock: currentBlock.id,
            dropBlock: dropResult.block.id,
          })
        );
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  React.useEffect(() => {
    if (
      selectedBlock?.locationPath.startsWith(currentBlock.locationPath) &&
      !childrenOpen
    )
      setChildrenOpen(true);
  }, [selectedBlock, currentBlock]);

  React.useEffect(() => {
    handleDragging(isDragging);
  }, [isDragging]);

  const filteredBlocks = React.useMemo(
    () =>
      blocks.filter(
        (x) =>
          x.locationPath?.split("-").length - 1 ===
          currentBlock.locationPath?.split("-").length
      ),
    [blocks, currentBlock]
  );
  const displayedBlocks = React.useMemo(
    () =>
      filteredBlocks
        .sort((a, b) => a.sortPath - b.sortPath)
        .map((block, index) => {
          return (
            <BlockMapBlock
              key={block.id}
              currentBlock={block}
              blocks={blocks.filter((x) =>
                x.locationPath?.startsWith(block.locationPath + "-")
              )}
              selectedBlock={selectedBlock}
              level={level + 1}
              firstEntry={index === 0}
              lastEntry={filteredBlocks.length - 1 === index}
              isItemDragging={isItemDragging}
              handleDragging={handleDragging}
            />
          );
        }),
    [
      filteredBlocks,
      selectedBlock,
      blocks,
      isItemDragging,
      handleDragging,
      level,
    ]
  );

  const handleSelectBlock = useCallback(() => {
    dispatch(selectBlock(currentBlock.id));
  }, [currentBlock]);

  return (
    <>
      <ListItem
        disablePadding
        sx={{ position: "relative", zIndex: 15 }}
        ref={drag}
      >
        {
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              flexDirection: "column",
              display: !isItemDragging ? "none" : "flex",
              zIndex: isItemDragging && !isDragging ? 20 : 0,
            }}
          >
            <BlockMapDropZone
              size="25%"
              block={currentBlock}
              direction={CreateBlockDirection.up}
            />
            {![
              BlockType.IMG,
              BlockType.P,
              BlockType.H1,
              BlockType.H2,
              BlockType.H3,
              BlockType.H4,
              BlockType.H5,
              BlockType.H6,
            ].includes(currentBlock.blockType) ? (
              <BlockMapDropZone
                size="50%"
                block={currentBlock}
                direction={CreateBlockDirection.in}
              />
            ) : (
              <Box sx={{ height: "50%" }} />
            )}
            <BlockMapDropZone
              size="25%"
              block={currentBlock}
              direction={CreateBlockDirection.down}
            />
          </Box>
        }
        <ListItemButton
          onClick={handleSelectBlock}
          selected={selectedBlock?.id === currentBlock.id}
          disableGutters
          disableRipple
        >
          <IconButton
            disabled={!filteredBlocks.length}
            onClick={(e) => {
              e.stopPropagation();
              setChildrenOpen((prev) => !prev);
            }}
            size="small"
            sx={{
              padding: 0,
              background: "none",
              "&.Mui-disabled": { background: "none" },
            }}
          >
            {filteredBlocks.length ? (
              childrenOpen ? (
                <ExpandMoreOutlined />
              ) : (
                <ChevronRightOutlined />
              )
            ) : (
              <Remove />
            )}
          </IconButton>
          <ListItemText
            secondaryTypographyProps={{
              color: Colors.text_silver,
            }}
            sx={{
              ...(selectedBlock?.locationPath.startsWith(
                currentBlock.locationPath
              ) &&
                selectedBlock.id !== currentBlock.id && {
                  color: Colors.info_main,
                }),
            }}
            primary={prettyBlockString(currentBlock)}
          />
        </ListItemButton>
      </ListItem>
      <Collapse
        in={childrenOpen}
        sx={{ display: displayedBlocks.length ? "block" : "none" }}
      >
        <List
          component="div"
          dense
          sx={{
            marginLeft: theme.spacing(1),
            borderLeft: `1px solid ${Colors.text_silver}`,
          }}
          disablePadding
        >
          {displayedBlocks}
        </List>
      </Collapse>
    </>
  );
};

export default BlockMapBlock;
