import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Box, useTheme, styled, IconButton, Button } from '@mui/material';
import { Colors } from '../../theme/Colors';
import { DragHandle, NoEncryption } from '@mui/icons-material';
import { extractNumber } from '../../Utility';
import { ScreenMode } from '../../redux/blocks/types';
import { debounce } from 'lodash';

interface Props {
    children: React.ReactNode;
    drawerOpen: boolean;
    handleScreenModeChange?: (width: number) => void;
    canvasMode?: ScreenMode;
}

interface InitialPosition {
    x: number;
    isLeft: boolean;
    initialWidth: number;
}

const BlockCanvas: React.FC<Props> = ({ children, drawerOpen, handleScreenModeChange, canvasMode }) => {
    const theme = useTheme();

    const [canvasWidth, setCanvasWidth] = useState<string>('100%');
    const [initialPos, setInitialPos] = useState<InitialPosition | null>(null);
    const [resizing, setResizing] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!canvasMode)
            return;

        switch (canvasMode) {
            case ScreenMode.xxl:
                setCanvasWidth("100%");
                break;
            case ScreenMode.xl:
                setCanvasWidth("1399px");
                break;
            case ScreenMode.lg:
                setCanvasWidth("1199px");
                break;
            case ScreenMode.md:
                setCanvasWidth("991px");
                break;
            case ScreenMode.sm:
                setCanvasWidth("767px");
                break;
            default:
                setCanvasWidth("575px");
        }
    }, [canvasMode])

    React.useEffect(() => {
        if (containerRef.current)
            debouncedChangeHandler(containerRef.current.offsetWidth);
    }, [canvasWidth])

    const startResize = useCallback((event: React.MouseEvent<HTMLButtonElement>, isLeft: boolean) => {
        const currentWidth = canvasWidth === '100%' ? containerRef.current?.offsetWidth ?? window.innerWidth : parseInt(canvasWidth);
        setResizing(true);
        setInitialPos({ x: event.clientX, isLeft, initialWidth: currentWidth });
        event.preventDefault();
    }, [canvasWidth]);

    const handleMouseMove = useCallback((event: MouseEvent) => {
        if (!resizing || initialPos === null) return;
        const diff = (event.clientX - initialPos.x) * 2;
        let newWidth = initialPos.isLeft ? initialPos.initialWidth - diff : initialPos.initialWidth + diff;
        newWidth = Math.max(newWidth, 350);
        setCanvasWidth(`${newWidth}px`);
    }, [initialPos, resizing]);

    const stopResize = useCallback(() => {
        setResizing(false);
        setInitialPos(null);

        if (containerRef.current) {
            setCanvasWidth(`${containerRef.current.offsetWidth}px`);
        }

        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', stopResize);
    }, [handleMouseMove]);

    const handleWidthChange = React.useCallback((width: number) => {
        if (handleScreenModeChange)
            handleScreenModeChange(width);
    }, [handleScreenModeChange]);

    const debouncedChangeHandler = useCallback(
        debounce(handleWidthChange, 150),
        [handleWidthChange],
    );

    useEffect(() => {
        if (resizing) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', stopResize);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', stopResize);
        };
    }, [resizing, handleMouseMove, stopResize]);

    useEffect(() => {
        if (!drawerOpen) {
            setCanvasWidth('100%');
        } else if (containerRef.current)
            setCanvasWidth(`${containerRef.current.offsetWidth}px`);
    }, [drawerOpen]);

    const handleStyle = React.useMemo(() => ({
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1000,
        cursor: 'ew-resize',
        padding: theme.spacing(3, 0.5, 3, 0.5),
        minWidth: 'auto',
        display: 'none'
    }), [theme])

    return (
        <Box
            id="canvas-root"
            ref={containerRef}
            sx={{
                width: canvasWidth,
                height: `calc(100vh - ${theme.spacing(6)})`,
                backgroundColor: 'white',
                position: 'relative',
                overflow: 'hidden',
                margin: 'auto',
                mt: theme.spacing(6),
                userSelect: 'none',
                '&:hover .canvas-handle': {
                    display: 'block',
                }
            }}
        >
            <Button
                className='canvas-handle'
                size="small"
                sx={{
                    ...handleStyle,
                    left: 0,
                }}
                onMouseDown={(event) => startResize(event, true)}
            >
                <DragHandle sx={{ transform: 'rotate(90deg)' }} />
            </Button>
            <Button
                className='canvas-handle'
                size="small"
                sx={{
                    ...handleStyle,
                    right: 0,
                }}
                onMouseDown={(event) => startResize(event, false)}
            >
                <DragHandle sx={{ transform: 'rotate(90deg)' }} />
            </Button>
            <Box id="canvas" sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                {children}
            </Box>
        </Box>
    );
};

export default BlockCanvas;
