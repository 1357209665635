import React from 'react';
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import { Box, Grid, IconButton, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { BorderBottom, BorderLeft, BorderRight, BorderStyle, BorderTop, Clear, CropSquare, MoreHoriz, Remove } from '@mui/icons-material';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import ValueWithUnitInput from '../../../../../utils/Inputs/ValueWithUnitInput';
import AdvancedColorPicker from '../../../../../utils/Inputs/AdvancedColorPicker';
import { getStyleById } from '../../../../../../Utility';
import { Colors } from '../../../../../../theme/Colors';
import { BlockStyleAttribute } from '../BlockStyleInputs';
import BlockStyleDataResetBadge from '../../../../../utils/Inputs/BlockStyleDataResetBadge';
import StyleToggleButtonGroup from '../../../../../utils/Inputs/StyleToggleButtonGroup';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void,
    selectedBlock?: BlockData
}

export default function BorderConfigInputs(props: Props) {
    const { currentBlockStyle, handleAttributeChange, selectedBlock } = props;
    const [currentBorder, setCurrentBorder] = React.useState<'border' | 'border-left' | 'border-top' | 'border-right' | 'border-bottom'>('border');

    return (
        <>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <Tooltip title="Edit top border">
                    <span>
                        <IconButton
                            sx={{
                                ...(currentBorder === 'border-top' && { backgroundColor: Colors.primary_main })
                            }}
                            onClick={() => setCurrentBorder('border-top')}>
                            <BorderTop />
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <BlockStyleDataLabel name="Style" />
            <Grid item xs={4}>
                <StyleToggleButtonGroup
                    {...props}
                    attributeKey={`${currentBorder}-style`}
                    additionalStyle={{
                        '&>*': {
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingTop: '7px',
                            paddingBottom: '7px',
                        }
                    }}>
                    <ToggleButton value="none" key="none">
                        <Tooltip title='Removes border from the element'>
                            <Clear />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="solid" key="solid">
                        <Tooltip title='Applies a continuous, unbroken border line'>
                            <Remove />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="dashed" key="dashed">
                        <Tooltip title='Sets the border to a series of short line segments'>
                            <Remove />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="dotted" key="dotted">
                        <Tooltip title='Creates a border with evenly spaced dots'>
                            <MoreHoriz />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <Tooltip title="Edit left border">
                    <span>
                        <IconButton
                            sx={{
                                ...(currentBorder === 'border-left' && { backgroundColor: Colors.primary_main })
                            }}
                            onClick={() => setCurrentBorder('border-left')}>
                            <BorderLeft />
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Tooltip title="Edit all borders">
                    <span>
                        <IconButton
                            sx={{
                                ...(currentBorder === 'border' && { backgroundColor: Colors.primary_main })
                            }}
                            onClick={() => setCurrentBorder('border')}>
                            <CropSquare />
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Tooltip title="Edit right border">
                    <span>
                        <IconButton
                            sx={{
                                ...(currentBorder === 'border-right' && { backgroundColor: Colors.primary_main })
                            }}
                            onClick={() => setCurrentBorder('border-right')}>
                            <BorderRight />
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <BlockStyleDataLabel name="Width" />
            <Grid item xs={4}>
                <ValueWithUnitInput attributeKey={`${currentBorder}-width`} {...props} />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Tooltip title="Edit bottom border">
                    <span>
                        <IconButton
                            sx={{
                                ...(currentBorder === 'border-bottom' && { backgroundColor: Colors.primary_main })
                            }}
                            onClick={() => setCurrentBorder('border-bottom')}>
                            <BorderBottom />
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <BlockStyleDataLabel name="Color" />
            <Grid item xs={4}>
                <AdvancedColorPicker attributeKey={`${currentBorder}-color`} {...props} />
            </Grid>
        </>
    )
}