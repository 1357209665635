import * as React from "react";
import { DynDataEntry, DynDataSet } from "../../redux/dyndata/types";
import { useAppSelector } from "../../redux/hooks";
import { selectDynData, selectEntries } from "../../redux/dyndata/dyndataSlice";
import { State, BlockDataContext } from "./BlockDataContext";

interface Props {
  children: React.ReactNode;
}

const BlockDataContextProvider = (props: Props) => {
  const { children } = props;
  const [state, setState] = React.useState<State>({
    entries: [],
    dataSets: [],
  });

  const dataSets = useAppSelector(selectDynData);
  const entries = useAppSelector(selectEntries);

  React.useEffect(() => {
    setState({ entries, dataSets });
  }, [entries, dataSets]);

  const updateDataSet = (dataSet: DynDataSet) => {
    setState({
      ...state,
      dataSets: [...state.dataSets.filter((x) => x.id !== dataSet.id), dataSet],
    });
  };

  const updateEntry = (entry: DynDataEntry) => {
    setState({
      ...state,
      entries: [...state.entries.filter((x) => x.id !== entry.id), entry],
    });
  };

  const value = React.useMemo(
    () => ({
      updateDataSet,
      updateEntry,
    }),
    [updateDataSet, updateEntry]
  );

  return (
    <BlockDataContext.Provider value={value}>
      {children}
    </BlockDataContext.Provider>
  );
};

export default BlockDataContextProvider;
