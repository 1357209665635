import React from 'react';
import BlockMapBlock from './BlockMapBlock';
import { Box, List, ListItem, ListItemButton, ListItemText, styled, useTheme } from '@mui/material';
import { useDrop } from 'react-dnd';
import { defaultBlock, selectBlock } from '../../../redux/blocks/blockSlice';
import { BlockData, BlockTypes, CreateBlockDirection } from '../../../redux/blocks/types';
import { useAppDispatch } from '../../../redux/hooks';
import { Colors } from '../../../theme/Colors';

interface Props {
    blocks: BlockData[];
    selectedBlock: BlockData | undefined;
    isBlockDragging: boolean;
}

export default function BlockMap(props: Props) {
    const { blocks, selectedBlock, isBlockDragging } = props;
    const filteredBlocks = blocks.filter(x => x.locationPath?.indexOf('-') === -1);
    const [isItemDragging, setIsItemDragging] = React.useState(false);
    const dispatch = useAppDispatch();
    const theme = useTheme();

    //TODO: canDrop
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: BlockTypes.create,
        drop: () => ({ block: defaultBlock, direction: CreateBlockDirection.in }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))

    const displayedBlocks = React.useMemo(() => filteredBlocks
        .sort((a, b) => a.sortPath - b.sortPath)
        .map((block, index) => {

            return (
                <BlockMapBlock
                    key={block.id}
                    currentBlock={block}
                    blocks={blocks.filter(x => x.locationPath?.startsWith(block.locationPath + '-'))}
                    selectedBlock={selectedBlock}
                    level={1}
                    firstEntry={index === 0}
                    lastEntry={filteredBlocks.length - 1 === index}
                    isItemDragging={isBlockDragging || isItemDragging}
                    handleDragging={(toggle: boolean) => setIsItemDragging(toggle)}
                />
            )
        }), [filteredBlocks, setIsItemDragging, blocks, selectedBlock, isBlockDragging, isItemDragging]);

    return (
        <Box className="blockmap" sx={{ padding: theme.spacing(1) }}>
            <List component="div" dense disablePadding sx={{ userDrag: 'none' }}>
                <ListItem disablePadding sx={{ ...(isOver && { backgroundColor: Colors.primary_main }) }} ref={drop}>
                    <ListItemButton sx={{ padding: 0 }} onClick={() => dispatch(selectBlock())} selected={selectedBlock === undefined} disableGutters>
                        <ListItemText primary="MAIN" />
                    </ListItemButton>
                </ListItem>
                <List sx={{ borderLeft: `1px solid ${Colors.text_silver}` }} component="div" dense disablePadding>
                    {displayedBlocks}
                </List>
            </List>
        </Box>
    )
}