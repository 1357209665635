import { SettingsBackupRestore } from '@mui/icons-material';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import * as React from 'react';
import { User } from '../../redux/auth/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUsers, selectUsers } from '../../redux/users/usersSlice';

interface Props {
    onChange(user: User | null): void;
    excludeIds: number[];
}

export default function UserAutoComplete(props: Props) {

    const { onChange, excludeIds } = props;

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [user, setUser] = React.useState<User | null>(null);

    const allUsers = useAppSelector(selectUsers);

    const users = React.useMemo(() => {
        return allUsers.filter(x => !excludeIds.includes(x.id));
    }, [excludeIds, allUsers])

    const dispatch = useAppDispatch();
    
    React.useEffect(() => {
        setLoading(true);
        dispatch(getUsers()).unwrap()
            .finally(() => setLoading(false));
    }, []);

    const onSelect = React.useCallback((_ev: React.SyntheticEvent<Element, Event>, user: User | null) => {
        setUser(user);
        onChange(user);
    }, [setUser, onChange]);

    return (
        <Autocomplete 
            open={open}
            sx={{ width: '100%'}}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={users}
            isOptionEqualToValue={(option, value) => option.username === value.username}
            getOptionLabel={(opt) => opt.username}
            loading={loading}
            value={user}
            onChange={onSelect}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='filled'
                    label='User'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}