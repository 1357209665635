import React from "react";
import {
  BlockData,
  BlockStyle,
  BlockType,
} from "../../../../../redux/blocks/types";
import {
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  createBlockStyle,
  selectParentBlock,
  updateBlockStyle,
} from "../../../../../redux/blocks/blockSlice";
import DisplayInput from "./BlockStyleDataInputs/DisplayInput";
import FlexInputs from "./BlockStyleDataInputs/FlexInputs";
import SpacingInput from "./BlockStyleDataInputs/SpacingInput";
import SizeInputs from "./BlockStyleDataInputs/SizeInputs";
import GapInputs from "./BlockStyleDataInputs/GapInputs";
import PositionInputs from "./BlockStyleDataInputs/PositionInputs";
import TypographyInputs from "./BlockStyleDataInputs/TypographyInputs";
import TitleBox from "../../../../widgets/TitleBox";
import BorderInputs from "./BlockStyleDataInputs/BorderInputs";
import EffectsInputs from "./BlockStyleDataInputs/EffectsInputs";
import FlexChildInputs from "./BlockStyleDataInputs/FlexChildInputs";
import GridChildInputs from "./BlockStyleDataInputs/GridChildInputs";
import {
  StyleTable,
  getDefaultValue,
  isStyleInheritedAndChanged,
} from "../../../../../Utility";
import GridInputs from "./BlockStyleDataInputs/GridInputs";
import BackgroundInputs from "./BlockStyleDataInputs/BackgroundInputs";
import ListElementInputs from "./BlockStyleDataInputs/ListElementInputs";
import BackgroundList from "./BlockStyleDataInputs/BackgroundList";

interface Props {
  baseStyle: BlockStyle;
  currentBlockStyle: BlockStyle;
  selectedBlock: BlockData | undefined;
}

export interface BlockStyleAttribute {
  attributeKey: string;
  attributeValue: string;
}

export default function BlockStyleInputs(props: Props) {
  const { currentBlockStyle, baseStyle, selectedBlock } = props;

  const [openSections, setOpenSections] = React.useState(["layout"]);

  const dispatch = useAppDispatch();
  const parentBlock = useAppSelector(selectParentBlock);
  const theme = useTheme();

  const selectedBlockId = selectedBlock ? `b${selectedBlock?.id}` : "";

  const handleAttributeChange = (
    attributes: BlockStyleAttribute[],
    ignoreDefault?: boolean
  ) => {
    let newBlockStyle: BlockStyle = { ...baseStyle };

    attributes.forEach((blockAttribute) => {
      const changedAttribute = baseStyle.blockStyleData.find(
        (x) => x.attributeKey === blockAttribute.attributeKey
      );
      const style = StyleTable.find(
        (x) => x.name === blockAttribute.attributeKey
      );
      const defaultValue = getDefaultValue(selectedBlock?.blockType, style);

      if (
        !ignoreDefault &&
        blockAttribute.attributeValue === defaultValue &&
        !currentBlockStyle.blockStyleData.find(
          (x) =>
            x.attributeKey === blockAttribute.attributeKey &&
            x.attributeValue !== defaultValue
        ) &&
        !(
          isStyleInheritedAndChanged(
            selectedBlockId,
            blockAttribute.attributeKey,
            defaultValue
          ) &&
          currentBlockStyle.blockStyleData.find(
            (x) => x.attributeKey === blockAttribute.attributeKey
          ) === undefined
        )
      ) {
        newBlockStyle.blockStyleData = [
          ...newBlockStyle.blockStyleData.filter(
            (x) => x.attributeKey !== blockAttribute.attributeKey
          ),
        ];
      } else if (changedAttribute) {
        newBlockStyle.blockStyleData = [
          ...newBlockStyle.blockStyleData.filter(
            (x) => x.id !== changedAttribute.id
          ),
          {
            ...changedAttribute,
            attributeValue: blockAttribute.attributeValue,
          },
        ];
      } else {
        newBlockStyle.blockStyleData = [
          ...newBlockStyle.blockStyleData,
          {
            id: 0,
            attributeKey: blockAttribute.attributeKey,
            attributeValue: blockAttribute.attributeValue,
          },
        ];
      }
    });

    if (newBlockStyle.id === 0) {
      dispatch(createBlockStyle(newBlockStyle));
    } else {
      dispatch(updateBlockStyle(newBlockStyle));
    }
  };

  const handleSectionOpenChange = (tag: string) => {
    if (openSections.includes(tag))
      setOpenSections([...openSections.filter((x) => x != tag)]);
    else setOpenSections([...openSections, tag]);
  };

  const handleBlockStyleDataReset = (attributeKey: string) => {
    const newBlockStyle = {
      ...baseStyle,
      blockStyleData: baseStyle.blockStyleData.filter(
        (x) => x.attributeKey !== attributeKey
      ),
    };
    dispatch(updateBlockStyle(newBlockStyle));
  };

  const childProps = {
    baseBlockStyle: baseStyle,
    currentBlockStyle: currentBlockStyle,
    handleAttributeChange: handleAttributeChange,
    handleBlockStyleDataReset: handleBlockStyleDataReset,
    selectedBlock: selectedBlock,
  };

  return (
    <>
      <List disablePadding>
        {(selectedBlock?.blockType === BlockType.UL ||
          selectedBlock?.blockType === BlockType.LI) && (
          <>
            <TitleBox
              mainTitle={"List element"}
              maintitleVariant="subtitle1"
            ></TitleBox>
            <Divider />
            <Grid
              container
              rowSpacing={0.5}
              columnSpacing={0.5}
              sx={{
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(1),
              }}
            >
              <ListElementInputs {...childProps} />
            </Grid>
          </>
        )}
        {parentBlock?.blockStyles.find(
          (x) =>
            x.selector === currentBlockStyle.selector &&
            x.blockStyleData.find(
              (y) => y.attributeKey === "display" && y.attributeValue === "grid"
            )
        ) && (
          <>
            <TitleBox
              mainTitle={"Grid child"}
              maintitleVariant="subtitle1"
            ></TitleBox>
            <Divider />
            <Grid
              container
              rowSpacing={0.5}
              columnSpacing={0.5}
              sx={{
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(1),
              }}
            >
              <GridChildInputs {...childProps} />
            </Grid>
          </>
        )}
        {parentBlock?.blockStyles.find(
          (x) =>
            x.selector === currentBlockStyle.selector &&
            x.blockStyleData.find(
              (y) => y.attributeKey === "display" && y.attributeValue === "flex"
            )
        ) && (
          <>
            <TitleBox
              mainTitle={"Flex child"}
              maintitleVariant="subtitle1"
            ></TitleBox>
            <Divider />
            <Grid
              container
              rowSpacing={0.5}
              columnSpacing={0.5}
              sx={{
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(1),
              }}
            >
              <FlexChildInputs {...childProps} />
            </Grid>
          </>
        )}
        <ListItem dense key="Layout" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => handleSectionOpenChange("layout")}
          >
            <ListItemText
              disableTypography
              primary="Layout"
              sx={{ fontWeight: "bold" }}
            />
            {openSections.includes("layout") ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openSections.includes("layout")}>
          <Grid
            container
            rowSpacing={0.5}
            columnSpacing={0.5}
            sx={{ marginTop: theme.spacing(0), marginBottom: theme.spacing(1) }}
          >
            <DisplayInput {...childProps} />
            {currentBlockStyle.blockStyleData.find(
              (x) => x.attributeKey === "display"
            )?.attributeValue === "flex" && (
              <>
                <FlexInputs {...childProps} />
                <GapInputs {...childProps} />
              </>
            )}
            {currentBlockStyle.blockStyleData.find(
              (x) => x.attributeKey === "display"
            )?.attributeValue === "grid" && (
              <>
                <GapInputs {...childProps} />
                <GridInputs {...childProps} />
              </>
            )}
          </Grid>
        </Collapse>
        <ListItem dense key="Spacing" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => handleSectionOpenChange("spacing")}
          >
            <ListItemText
              disableTypography
              primary="Spacing"
              sx={{ fontWeight: "bold" }}
            />
            {openSections.includes("spacing") ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openSections.includes("spacing")}>
          <Grid
            container
            rowSpacing={0.5}
            columnSpacing={0.5}
            sx={{ marginTop: theme.spacing(0), marginBottom: theme.spacing(1) }}
          >
            <SpacingInput {...childProps} />
          </Grid>
        </Collapse>
        <ListItem dense key="Size" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => handleSectionOpenChange("size")}
          >
            <ListItemText
              disableTypography
              primary="Size"
              sx={{ fontWeight: "bold" }}
            />
            {openSections.includes("size") ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openSections.includes("size")}>
          <Grid
            container
            rowSpacing={0.5}
            columnSpacing={0.5}
            sx={{ marginTop: theme.spacing(0), marginBottom: theme.spacing(1) }}
          >
            <SizeInputs {...childProps} />
          </Grid>
        </Collapse>
        <ListItem dense key="Position" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => handleSectionOpenChange("position")}
          >
            <ListItemText
              disableTypography
              primary="Position"
              sx={{ fontWeight: "bold" }}
            />
            {openSections.includes("position") ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
        </ListItem>
        <Collapse in={openSections.includes("position")}>
          <Grid
            container
            rowSpacing={0.5}
            columnSpacing={0.5}
            sx={{ marginTop: theme.spacing(0), marginBottom: theme.spacing(1) }}
          >
            <PositionInputs {...childProps} />
          </Grid>
        </Collapse>
        <ListItem
          dense
          key="Background"
          disablePadding
          sx={{ display: "block" }}
        >
          <ListItemButton
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => handleSectionOpenChange("background")}
          >
            <ListItemText
              disableTypography
              primary="Background"
              sx={{ fontWeight: "bold" }}
            />
            {openSections.includes("background") ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
        </ListItem>
        <Collapse in={openSections.includes("background")}>
          <Grid
            container
            rowSpacing={0.5}
            columnSpacing={0.5}
            sx={{ marginTop: theme.spacing(0), marginBottom: theme.spacing(1) }}
          >
            <BackgroundList {...childProps} />
          </Grid>
        </Collapse>
        <ListItem
          dense
          key="Typography"
          disablePadding
          sx={{ display: "block" }}
        >
          <ListItemButton
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => handleSectionOpenChange("typography")}
          >
            <ListItemText
              disableTypography
              primary="Typography"
              sx={{ fontWeight: "bold" }}
            />
            {openSections.includes("typography") ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
        </ListItem>
        <Collapse in={openSections.includes("typography")}>
          <Grid
            container
            rowSpacing={0.5}
            columnSpacing={0.5}
            sx={{ marginTop: theme.spacing(0), marginBottom: theme.spacing(1) }}
          >
            <TypographyInputs {...childProps} />
          </Grid>
        </Collapse>
        <ListItem dense key="Border" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => handleSectionOpenChange("border")}
          >
            <ListItemText
              disableTypography
              primary="Border"
              sx={{ fontWeight: "bold" }}
            />
            {openSections.includes("border") ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openSections.includes("border")}>
          <Grid
            container
            rowSpacing={0.5}
            columnSpacing={0.5}
            sx={{ marginTop: theme.spacing(0), marginBottom: theme.spacing(1) }}
          >
            <BorderInputs {...childProps} />
          </Grid>
        </Collapse>
        <ListItem dense key="Effects" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => handleSectionOpenChange("effects")}
          >
            <ListItemText
              disableTypography
              primary="Effects"
              sx={{ fontWeight: "bold" }}
            />
            {openSections.includes("effects") ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openSections.includes("effects")}>
          <Grid
            container
            rowSpacing={0.5}
            columnSpacing={0.5}
            sx={{ marginTop: theme.spacing(0), marginBottom: theme.spacing(1) }}
          >
            <EffectsInputs {...childProps} />
          </Grid>
        </Collapse>
      </List>
    </>
  );
}
