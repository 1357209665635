import { LanguageOutlined, Add, DeleteOutlined, Save, Cancel, Edit, Delete } from '@mui/icons-material';
import { Button, Grid, IconButton, Paper, TextField, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridEventListener, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, MuiEvent, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useContext } from 'react';
import useRouteParam from '../../../hooks/useRouteParam';
import { defaultModel, defaultProp, deleteDynProp, getModels, selectDynModelById, selectDynModels, updateDynProp, updateModel } from '../../../redux/dyndata/dyndataSlice';
import { DynDataType, DynProps } from '../../../redux/dyndata/types';
import { BackendError, useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ConfirmationPopover from '../../utils/ConfirmationPopover';
import TitleBox from '../../widgets/TitleBox';
import StyledDataGrid from '../../utils/StyledDataGrid';
import yup from '../../../validation/yup';
import SnackBarOperations from '../../../components/SnackBar/SnackBarOperations';
import DeleteConfirmationDialog from '../../utils/DeleteConfirmationDialog';

export default function DataModelForm() {

    const modelId = useRouteParam('modelId');

    const projectId = useRouteParam('projectId');

    const theme = useTheme();
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [propConfirmationPopoverOpen, setPropConfirmationPopoverOpen] = React.useState(false);
    const [editModel, setEditModel] = React.useState(defaultModel);

    const projectModels = useAppSelector(selectDynModels);

    const currentModel = useAppSelector(selectDynModelById(modelId));
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (currentModel)
            setEditModel({ ...currentModel });
    }, [currentModel])

    React.useEffect(() => {
        dispatch(getModels(projectId));
    }, [])

    const handleRowEditStart = React.useCallback((
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = React.useCallback((params, event) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleEditClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }, []);

    const handleSaveClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }, []);

    const handleDeleteClick = React.useCallback((id: GridRowId) => () => {
        dispatch(deleteDynProp(Number(id)))
    }, [dispatch]);

    const handleCancelClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    }, [setRowModesModel, rowModesModel]);

    const getDynPropValidationSchema = React.useCallback((id: number) => yup.object().shape({
        tag: yup.string().required('Property tag name is required').unique('Property tag name must be unique', (val: string) => {
            return !currentModel.dynPropSets.filter(x => x.id !== id).map(x => x.tag).includes(val);
        })
    }), [currentModel])

    const processRowUpdate = React.useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };

        try {
            await getDynPropValidationSchema(newRow.id).validate(updatedRow);
            await dispatch(updateDynProp(newRow as DynProps));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                err.errors.forEach((strErr) => SnackBarOperations.error(strErr));
                throw err;
            }
        }
        return updatedRow;
    }, [dispatch, getDynPropValidationSchema]);

    const columns: GridColDef[] = [
        {
            field: 'tag',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
            editable: true
        },
        {
            field: 'type',
            headerName: 'Type',
            minWidth: 100,
            flex: 1,
            type: 'singleSelect',
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.name,
            valueOptions: [
                { code: DynDataType.text, name: 'text' },
                { code: DynDataType.number, name: 'number' },
                { code: DynDataType.boolean, name: 'boolean' },
                { code: DynDataType.mediaFile, name: 'media' }
            ],
            editable: true
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key="save"
                            icon={<Save />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key="cancel"
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key="edit"
                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleDeleteClick(id)}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}                            
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];

    const handleConfirmationPopoverSubmit = React.useCallback(() => {
        const dynPropsToDelete = editModel.dynPropSets.filter(x => selectedIds.includes(x.id));
        dynPropsToDelete.forEach((p => {
            dispatch(deleteDynProp(p.id));
        }))
    }, [editModel, selectedIds, dispatch]);

    const getModelValidationSchema = React.useCallback((id: number) => yup.object().shape({
        name: yup.string().required('Model name is required.').unique('Model with that name already exists in this project.', (name: string) => !projectModels.filter(x => x.id != id).map(x => x.name).includes(name))
    }), [projectModels]);

    const handleModelChange = React.useCallback(async () => {
        try {
            await getModelValidationSchema(editModel.id).validate(editModel);
            await dispatch(updateModel(editModel));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            } if (err instanceof BackendError) {
                SnackBarOperations.error(`Error saving model: ${err.message}`);
            }
            console.error(err);
        }
    }, [dispatch, editModel, getModelValidationSchema]);

    const handleDynPropAdd = React.useCallback(() => {
        let changedModel = { ...editModel };
        changedModel.dynPropSets = [...changedModel.dynPropSets, { ...defaultProp, dynModelId: changedModel.id }];
        dispatch(updateModel(changedModel));
    }, [editModel, dispatch]);

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Edit model'
                subTitle='Lorem ipsum'>
            </TitleBox>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ width: '100%', padding: theme.spacing(2) }}>
                        <TextField sx={{ width: '250px' }} id="model-name" label="Name" variant="filled" value={editModel.name} onChange={(e) => setEditModel({ ...editModel, name: e.target.value })} onBlur={handleModelChange} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ height: '600px', width: '100%', padding: theme.spacing(2) }}>
                        <TitleBox
                            mainTitle='Properties'
                            subTitle='Lorem ipsum'
                            maintitleVariant='h6'
                            subTitleVariant='subtitle2'>
                            <ConfirmationPopover
                                handleClose={() => setPropConfirmationPopoverOpen(false)}
                                handleSubmit={handleConfirmationPopoverSubmit}
                                open={propConfirmationPopoverOpen}>
                                <IconButton component="label" disabled={selectedIds.length < 1} onClick={() => setPropConfirmationPopoverOpen(true)}>
                                    <DeleteOutlined />
                                </IconButton>
                            </ConfirmationPopover>
                            <Button variant="contained" onClick={handleDynPropAdd} sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />}>
                                ADD PROP
                            </Button>
                        </TitleBox>
                        <StyledDataGrid
                            rows={currentModel?.dynPropSets ?? []}
                            columns={columns}
                            pageSizeOptions={[10]}
                            checkboxSelection
                            onRowSelectionModelChange={(newSM) => {
                                setSelectedIds(newSM);
                            }}
                            editMode='row'
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={setRowModesModel}
                            onRowEditStart={handleRowEditStart}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                            rowSelectionModel={selectedIds}
                            disableRowSelectionOnClick
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}