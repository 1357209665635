import React from 'react';
import { BlockData, BlockStyle } from '../../../../../../redux/blocks/types';
import { ViewDay, ViewColumn, GridView, ViewArray, FormatColorText, VisibilityOff } from '@mui/icons-material';
import { Grid, Typography, Box, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import BlockStyleDataLabel from '../../../../../utils/BlockStyleDataLabel';
import { getStyleById, isStyleInheritedAndChanged } from '../../../../../../Utility';
import StyleToggleButtonGroup from '../../../../../utils/Inputs/StyleToggleButtonGroup';
import { BlockStyleAttribute } from '../BlockStyleInputs';
import BlockStyleDataResetBadge from '../../../../../utils/Inputs/BlockStyleDataResetBadge';

interface Props {
    baseBlockStyle: BlockStyle,
    currentBlockStyle: BlockStyle,
    handleAttributeChange: (blockAttributes: BlockStyleAttribute[], ignoreDefault?: boolean) => void,
    handleBlockStyleDataReset: (attributeKey: string) => void,
    selectedBlock?: BlockData,
}

export default function DisplayInput(props: Props) {
    const { currentBlockStyle, handleAttributeChange, selectedBlock } = props;

    return (
        <>
            <BlockStyleDataLabel name="Display" />
            <Grid item xs={10}>
                <StyleToggleButtonGroup {...props} attributeKey='display'>
                    <ToggleButton value="block" key="block">
                        <Tooltip title="Elements are stacked vertically, each occupying its own full-width line">
                            <ViewDay />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="flex" key="flex">
                        <Tooltip title="Enables a flexible box layout, allowing responsive and efficient arrangements of children elements in a container">
                            <ViewColumn />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="grid" key="grid">
                        <Tooltip title="Establishes a grid container, enabling complex, two-dimensional layouts using rows and columns">
                            <GridView />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="inline-block" key="inline-block">
                        <Tooltip title="Elements flow in line with text but maintain block-like features, such as setting width and height">
                            <ViewArray />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="inline" key="inline">
                        <Tooltip title="Elements display in line with text without starting a new line, maintaining the natural flow of content">
                            <FormatColorText />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="none" key="none">
                        <Tooltip title="Completely hides the element, and it takes up no space in the layout">
                            <VisibilityOff />
                        </Tooltip>
                    </ToggleButton>
                </StyleToggleButtonGroup>
            </Grid>
        </>
    );
}