import React, { useMemo, useState, createContext } from "react";
import EventContext, { EventContext as EventContextType } from "./EventContext";

interface Registered {
  source: number;
  eventName: string;
  triggers: number;
  isTriggered: boolean;
}

export const ReadFakeEventsContext = createContext<{ events: Registered[] }>({
  events: [],
});

const FakeBlockEventContext: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [events, setEvents] = useState<Registered[]>([]);

  const value = useMemo<EventContextType>(
    () => ({
      dispatch(eventName, source, config, ...args) {
        setEvents((prev) => {
          prev.push({
            eventName,
            source,
            isTriggered: false,
            triggers: 0,
          });
          return prev;
        });
      },
      subscribe(eventName, subscription) {},
      subscriptions: {},
      triggerState: {},
    }),
    [setEvents]
  );

  const evs = useMemo(() => ({ events }), [events]);

  return (
    <EventContext.Provider value={value}>
      <ReadFakeEventsContext.Provider value={evs}>
        {children}
      </ReadFakeEventsContext.Provider>
    </EventContext.Provider>
  );
};

export default FakeBlockEventContext;
