import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { AppBar, ThemeProvider, css, styled, useTheme } from "@mui/material";
import { BeachAccess } from "@mui/icons-material";
import { BackendError, useAppDispatch, useAppSelector } from "../redux/hooks";
import useRouteParam from "../hooks/useRouteParam";
import { GetFrameStyles } from "../Utility";
import {
  getAnimationsAsync,
  selectAnimationById,
  updateAnimationAsync,
} from "../redux/animation/animationSlice";
import AnimationFrameSlider from "./animation/animator/AnimationFrameSlider";
import {
  BlockAnimation,
  BlockAnimationKeyFrame,
} from "../redux/animation/types";
import AnimationInfoTabs from "./animation/animator/AnimationInfoTabs";

/** @jsxImportSource @emotion/react */
import { jsx, keyframes } from "@emotion/react";
import yup from "../validation/yup";
import SnackBarOperations from "../components/SnackBar/SnackBarOperations";
import { Colors } from "../theme/Colors";
import { InnerTheme } from "../theme/InnerTheme";

const drawerLeftWidth = 350;
const drawerRightWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function AnimationBar() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const animationId = useRouteParam("animationId");

  const animation = useAppSelector(selectAnimationById(animationId));
  const animObject = keyframes(
    GetFrameStyles(animation.blockAnimationKeyFrames)
  );

  const [currentAnimation, setCurrentAnimation] = React.useState<
    BlockAnimation | undefined
  >();

  React.useEffect(() => {
    dispatch(getAnimationsAsync());
  }, []);

  React.useEffect(() => {
    setCurrentAnimation(animation);
  }, [animation, setCurrentAnimation]);

  const handleKeyFrameChange = React.useCallback(
    (keyframes: BlockAnimationKeyFrame[]) => {
      if (currentAnimation)
        setCurrentAnimation({
          ...currentAnimation,
          blockAnimationKeyFrames: keyframes,
        });
    },
    [currentAnimation, setCurrentAnimation]
  );

  const handleBlockAnimationChange = React.useCallback(
    (anim: BlockAnimation) => {
      setCurrentAnimation(anim);
    },
    [setCurrentAnimation]
  );

  const animationBaseValidationSchema = React.useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required("Name is required"),
        easeFunction: yup.string(),
        milliseconds: yup
          .number()
          .required("Number of milliseconds is required.")
          .moreThan(0, "More than 0 milliseconds."),
        blockAnimationKeyFrames: yup.array().of(
          yup.object().shape({
            percent: yup
              .number()
              .required()
              .moreThan(-1, "Key frame percent must be 0-100.")
              .lessThan(101, "Key frame percent must be 0-100."),
            blockStyleData: yup.array().of(
              yup.object().shape({
                attributeKey: yup
                  .string()
                  .required("Arrtibute name is required."),
                attributeValue: yup.string(),
              })
            ),
          })
        ),
      }),
    []
  );

  const handleBlockAnimationSave = React.useCallback(async () => {
    if (currentAnimation) {
      try {
        await animationBaseValidationSchema.validate(currentAnimation);
        await dispatch(updateAnimationAsync(currentAnimation));
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          SnackBarOperations.error(err.message);
        }
        if (err instanceof BackendError) {
          SnackBarOperations.error(`Error saving animation: ${err.message}`);
        }
        console.error(err);
      }
    }
  }, [dispatch, currentAnimation, animationBaseValidationSchema]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ThemeProvider theme={InnerTheme}>
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerLeftWidth + drawerRightWidth}px)`,
            ml: `${drawerLeftWidth}px`,
            mr: `${drawerRightWidth}px`,
          }}
        >
          <Toolbar variant="dense">
            <AnimationFrameSlider
              animationFrames={currentAnimation?.blockAnimationKeyFrames ?? []}
              handleKeyFrameChange={handleKeyFrameChange}
              handleBlockAnimationSave={handleBlockAnimationSave}
            />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerLeftWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerLeftWidth,
              boxSizing: "border-box",
            },
            color: Colors.text_silver,
          }}
          variant="permanent"
          anchor="left"
        >
          <DrawerHeader
            sx={{
              backgroundImage: "linear-gradient(#75B53B, #00D3FD);",
              paddingLeft: theme.spacing(2.5),
              minHeight: theme.spacing(6) + "!important",
              justifyContent: "space-between",
            }}
          >
            <BeachAccess />
          </DrawerHeader>
          <AnimationInfoTabs
            currentAnimation={currentAnimation}
            handleBlockAnimationChange={handleBlockAnimationChange}
            handleBlockAnimationSave={handleBlockAnimationSave}
          />
        </Drawer>
      </ThemeProvider>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          height: `calc(100vh - ${theme.spacing(6)})`,
          mt: theme.spacing(6),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          css={css`
            animation: ${animObject} ${animation?.milliseconds}ms infinite
              ${animation?.easeFunction};
          `}
          style={{
            height: "100px",
            width: "100px",
            backgroundColor: "#000000",
            position: "relative",
          }}
        />
      </Box>
      <ThemeProvider theme={InnerTheme}>
        <Drawer
          sx={{
            width: drawerRightWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerRightWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="right"
        >
          <Box sx={{ display: "flex" }}></Box>
        </Drawer>
      </ThemeProvider>
    </Box>
  );
}
