import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Grid,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import React, { FC, useMemo, Fragment, useEffect } from "react";
import BlockActionGridItem from "./BlockActionGridItem";
import ActionSequenceType from "../../../../../redux/actionsequence/types/helpers/ActionSequenceType";
import { ActionSequence } from "../../../../../redux/actionsequence/types/helpers/ActionSequence";

interface Props {
  toggleSectionOpen(): void;
  open: boolean;
  actionSequences: Readonly<ActionSequence[]>;
  type: ActionSequenceType;
}

const BlockActionGrid: FC<Props> = (props) => {
  const { toggleSectionOpen, actionSequences, type, open } = props;

  const theme = useTheme();

  const relevantSequences = useMemo(
    () => actionSequences.filter((x) => x.type === type),
    [actionSequences, type]
  );

  useEffect(() => {
    if (!open && relevantSequences.length) {
      toggleSectionOpen();
    }
  }, []);

  return (
    <Fragment>
      <ListItem dense disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{ paddingLeft: 0, paddingRight: 0 }}
          onClick={toggleSectionOpen}
        >
          <ListItemText
            disableTypography
            primary={type}
            sx={{ fontWeight: "bold" }}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open}>
        <Grid
          container
          rowSpacing={0.5}
          columnSpacing={0.5}
          sx={{
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(1),
          }}
        >
          {relevantSequences.length ? (
            relevantSequences.map((a) => (
              <BlockActionGridItem sequenceId={a.id} key={a.id} />
            ))
          ) : (
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle2">
                  No sequences with type {type} found
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Fragment>
  );
};

export default BlockActionGrid;
