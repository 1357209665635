import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  createGlobalSequence,
  selectGlobalSequences,
  selectProjectSequences,
  selectSequences,
} from "../../../../../redux/actionsequence/sequenceSlice";
import useRouteParam from "../../../../../hooks/useRouteParam";
import { Colors } from "../../../../../theme/Colors";
import { selectSelectedBlock } from "../../../../../redux/blocks/blockSlice";

interface Props {
  onClose: () => void;
  onSubmit: (actionSequenceId: number) => void;
}

const ActionSequenceSelect: FC<Readonly<Props>> = (props) => {
  const { onClose, onSubmit } = props;

  const projectId = useRouteParam("projectId");

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const [selectedSequence, setSelectedSequence] = useState<
    number | undefined
  >();

  const onSelect = useCallback(
    (newSequence: number) => {
      if (newSequence === 0) {
        setLoading(true);
        dispatch(createGlobalSequence())
          .unwrap()
          .then((v) => {
            setLoading(false);
            onSubmit(v.id);
          });
      }
      setSelectedSequence(newSequence);
    },
    [setSelectedSequence, onSubmit, setLoading, dispatch]
  );

  const block = useAppSelector(selectSelectedBlock);

  const blockSequences = useMemo(() => {
    return block?.actionSequences?.map((x) => x.actionSequenceId) ?? [];
  }, [block]);

  const globalSequences = useAppSelector(selectGlobalSequences);
  const projectSequences = useAppSelector(selectProjectSequences(projectId));

  const availableSequences = useMemo(() => {
    return [...globalSequences, ...projectSequences].filter(
      (x) => !blockSequences.includes(x.id)
    );
  }, [globalSequences, projectSequences, blockSequences]);

  const theme = useTheme();

  if (loading) {
    return (
      <Box
        sx={{
          margin: theme.spacing(2),
          padding: theme.spacing(4),
          minWidth: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography>Creating new sequence...</Typography>
        <CircularProgress
          color="inherit"
          sx={{ width: "30px", height: "30px" }}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        margin: theme.spacing(2),
        minWidth: "200px",
      }}
    >
      <Box
        sx={{
          marginBottom: "25px",
        }}
      >
        <Typography>Select a action to add</Typography>
        <Select
          sx={{
            width: "100%",
            backgroundColor: Colors.inner_button_hover_background,
            color: Colors.text_silver,
          }}
          value={selectedSequence}
          onChange={(ev) => {
            const n = Number(ev.target.value);
            if (isNaN(n)) {
              return;
            }
            onSelect(n);
          }}
        >
          {availableSequences.map((s) => (
            <MenuItem
              key={s.id}
              value={s.id}
            >{`${s.name} (${s.type})`}</MenuItem>
          ))}
          <MenuItem key={0} value={0}>
            <i>Create new global sequence</i>
          </MenuItem>
        </Select>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button
          sx={{
            [":hover"]: {
              backgroundColor: Colors.primary_button_hover,
            },
            backgroundColor: Colors.primary_main,
          }}
          disabled={selectedSequence === undefined}
          onClick={() => {
            if (selectedSequence) {
              onSubmit(selectedSequence);
            }
          }}
          variant="contained"
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ActionSequenceSelect;
