import { LanguageOutlined, CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { FilledInput, FormControl, InputLabel, MenuItem, Paper, Select, Stack, TextField, useTheme, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import useRouteParam from '../../../hooks/useRouteParam';
import { defaultEntry, getDataEntries, getModels, selectDynDataEntrySetById, selectDynModels, updateDataEntry } from '../../../redux/dyndata/dyndataSlice';
import { DynDataEntry, DynDataType, DynProps } from '../../../redux/dyndata/types';
import { BackendError, useAppDispatch, useAppSelector } from '../../../redux/hooks';
import TitleBox from '../../widgets/TitleBox';
import yup from '../../../validation/yup';
import SnackBarOperations from '../../../components/SnackBar/SnackBarOperations';

export default function DataEntryForm() {

    const entryId = useRouteParam('entryId');
    const projectId = useRouteParam('projectId');
    const theme = useTheme();
    const [editDataEntry, setEditEntry] = React.useState(defaultEntry);

    const models = useAppSelector(selectDynModels);

    const currentEntry = useAppSelector(selectDynDataEntrySetById(entryId));
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (currentEntry)
            setEditEntry({ ...currentEntry });
    }, [currentEntry])

    React.useEffect(() => {
        dispatch(getDataEntries(projectId));
        dispatch(getModels(projectId));
    }, [])

    const dataEntryValidationModel = React.useMemo(() => yup.object().shape({
        name: yup.string()
            .required('Data entry name is required.')
    }), []);

    const handleEntryModelChange = React.useCallback(async (entry: DynDataEntry) => {
        try {
            await dataEntryValidationModel.validate(entry);
            await dispatch(updateDataEntry(entry));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error updating entry model: ${err.message}.`);
            }
        }
    }, [dispatch, dataEntryValidationModel]);

    const handleEntryChange = React.useCallback(async () => {
        try {
            await dataEntryValidationModel.validate(editDataEntry);
            await dispatch(updateDataEntry(editDataEntry));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error updating entry model: ${err.message}.`);
            }
        }
    }, [dispatch, editDataEntry]);

    const model = models.find(x => x.id === currentEntry.dynModelId);

    const handleEntryDataChange = React.useCallback((tag: string, value: any) => {
        let data = JSON.parse(editDataEntry.data);
        data[tag] = value;

        setEditEntry({ ...editDataEntry, data: JSON.stringify(data) });
    }, [setEditEntry, editDataEntry]);

    const handleEntryDataBooleanChange = React.useCallback((tag: string, value: any) => {
        let data = JSON.parse(editDataEntry.data);

        data[tag] = value;
        dispatch(updateDataEntry({ ...currentEntry, data: JSON.stringify(data) }));
    }, [editDataEntry, dispatch])

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Edit dataset'
                subTitle='Lorem ipsum'>
            </TitleBox>
            <Paper sx={{ height: '750px', width: '100%', padding: theme.spacing(2) }}>
                <Stack spacing={1}>
                    <TextField
                        sx={{ width: '250px' }}
                        id="dataentry-name"
                        label="Name"
                        variant="filled"
                        value={editDataEntry.name}
                        onChange={(e) => setEditEntry({ ...editDataEntry, name: e.target.value })}
                        onBlur={handleEntryChange} />
                    <FormControl variant="filled" sx={{ width: '250px' }}>
                        <InputLabel id="model-label">Model</InputLabel>
                        <Select
                            id='model-select'
                            labelId="model-label"
                            value={editDataEntry?.dynModelId ?? ""}
                            input={<FilledInput />}
                            onChange={(e) => handleEntryModelChange({ ...currentEntry, dynModelId: Number(e.target.value) })}
                        >
                            <MenuItem value="">
                                None
                            </MenuItem>
                            {
                                models.map(m => (
                                    <MenuItem key={m.id} value={m.id}>{m.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    {
                        model ? model.dynPropSets.map((prop: DynProps) => {
                            const data = JSON.parse(editDataEntry.data);

                            switch (prop.type) {
                                case DynDataType.boolean:
                                    return (
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize="small" />} checkedIcon={<CheckBox fontSize="small" />} color="primary" checked={data[prop.tag]} onChange={(e, checked) => handleEntryDataBooleanChange(prop.tag, checked)} />}
                                            label={prop.tag}
                                            key={prop.id}
                                            labelPlacement="end"
                                        />
                                    );
                                case DynDataType.number:
                                    return (
                                        <TextField
                                            sx={{ width: '250px' }}
                                            id={`${prop.tag}-${entryId}`}
                                            label={prop.tag}
                                            variant="filled"
                                            type="number"
                                            key={prop.id}
                                            value={data[prop.tag]}
                                            onChange={(e) => handleEntryDataChange(prop.tag, e.target.value)}
                                            onBlur={handleEntryChange} />
                                    );
                                case DynDataType.mediaFile:
                                    return (<></>);
                                default:
                                    return (
                                        <TextField
                                            sx={{ width: '250px' }}
                                            id={`${prop.tag}-${entryId}`}
                                            label={prop.tag}
                                            key={prop.id}
                                            variant="filled"
                                            value={data[prop.tag]}
                                            onChange={(e) => handleEntryDataChange(prop.tag, e.target.value)}
                                            onBlur={handleEntryChange} />
                                    );
                            }
                        }) : null
                    }
                </Stack>
            </Paper>
        </>
    );
}